import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { getSupplierType, get_GST_details, get_gst_treatment } from "../../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const schema = yup
  .object()
  .shape({
    supplierType: yup
      .array()
      .min(1, "Please select supplier type")
      .required("Please select supplier type"),
    supplierName: yup.string().required("Supplier name is required !"),
    email: yup.string().email("Enter valid email id").notRequired(),
    contactPersonContact: yup
      .string()
      .matches(/^[0-9]*$/, "Enter valid contact number")
      .notRequired(),
    gstTreat: yup
      .array()
      .min(1, "Please select gst registration type !")
      .required("Please select gst registration type !"),
    cst: yup
      .string()
      .matches(/^[A-Z0-9]*$/, "Enter valid cst no!")
      .notRequired(),
    vat: yup
      .string()
      .matches(/^[A-Z0-9]*$/, "Enter valid vat no!")
      .notRequired(),
    // pan: yup
    //   .string()
    //   .matches(/^[A-Z0-9]*$/, "Enter valid pan no!")
    //   .notRequired(),
    gst: yup.string().when(["gstTreat"], (gstTreat, schema) => {
      if (gstTreat !== undefined && gstTreat.length > 0) {
        const gst_typeSelected = gstTreat[0].name == "Regular";

        // If 'gst_tye' is in the category, make 'GST number' mandatory
        return gst_typeSelected
          ? schema
              .required("Please enter GST number !")
              .max(15, "GST number must be 15 characters only !")
              .matches(
                /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/,
                "Enter valid GST number!"
              )
          : schema;
      } else {
        return schema;
      }
    }),
    pan: yup.string().when(["gstTreat"], (gstTreat, schema) => {
      if (gstTreat !== undefined && gstTreat.length > 0) {
        const gst_typeSelected = gstTreat[0].name == "Regular";

        // If 'gst_tye' is in the category, make 'GST number' mandatory
        return gst_typeSelected
          ? schema
            .required("Please enter PAN number !")
            .min(10, "PAN number must be 10 characters only !")
            .max(10, "PAN number must be 10 characters only !")
            .matches(
              /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/,
              "Enter a valid PAN number!"
            )
          : schema;
      } else {
        return schema;
      }
    }),
  })
  .required();

function NewSupplierDetail(props) {
  let navigate = useNavigate();
  let location = useLocation();
  const [supType, setSupType] = useState({ data: [], state: false });
  const [gst_treat, setGST_Treat] = useState({ loading: false, data: [] });

  const [lock, setLock] = useState(false);
  const [gstin, setGSTin] = useState("");

  const [constLabel, setConstLabel] = useState({});
  const { register, handleSubmit, formState, control, reset, watch, setValue, setError, clearErrors } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  let gstType = watch("gstTreat");

  useEffect(() => {
    setConstLabel(getLabels("Party Master", "Supplier"));

    // resetting the form
    setSupType({ ...supType, state: true, data: [] });

    getSupplierType().then(
      (res) => {
        const result = res.data.data.filter((item) => item.name !== null);
        setSupType({
          ...supType,
          state: false,
          data: result,
        });
      },
      (err) => {
        //
        setSupType({ ...supType, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );

    fetchGSTTreatment();
  }, []);

  function fetchGSTTreatment() {
    setGST_Treat({ ...gst_treat, state: true, data: [] });

    get_gst_treatment().then(
      (res) => {
        
        if (location.state == null && location.pathname == "/pcms/addSupplier") {
          const result = res.data.data.find((item) => item.name === "Regular");
          if (result) {
            setValue("gstTreat", [result]);
          }
        }
        

        setGST_Treat({
          ...gst_treat,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setGST_Treat({ ...gst_treat, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      const basic = {
        partyType: "Supplier",
        supp_type: data.supplierType[0].name,
        supplier_type_id: data.supplierType[0].uuid,
        name: data.supplierName,
        contactPersonDesignation: data.contactPersonDesignation,
        contactPersonContact: data.contactPersonContact,
        contactPersonName: data.contactPersonName,
        CSTNO: data.cst,
        VATNO: data.vat,
        PANNO: data.pan,
        gst_treatment: data.gstTreat[0]?.name,
        gst: data.gst,
        description: data.description,
        email: data.email,
        createdBy: 1,
        modifiedBy: 1,
      };

      props.setMain_obj({ ...props.main_obj, ...basic });
      props.nextStep();
    } else {
      errors.showMessages();
    }
  };

  function validateGST(value){
    const gstRegex = /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/;
    if (gstRegex.test(value)) {
      clearErrors("check_gst");
      return true;
    } else {
      return false;
    }
}

async function fetchGSTDetails() {
  setLock(true);
  if (!validateGST(gstin)) {
    setError('check_gst', {
      type:"manual",
      message:"Please enter a valid GST number"
    })
    // setGSTin("");
    setLock(false);
    return;
  }
  clearErrors("check_gst");

 await get_GST_details({gstin: gstin}).then(
    (res) => {
      console.log("res", res);
      const obj = {
        name: res.data.data.data.lgnm || "",
        address: res.data.data.data.adadr[0].addr.bno +" "+ res.data.data.data.adadr[0].addr.bnm +" "+ res.data.data.data.adadr[0].addr.st +" "+ res.data.data.data.adadr[0].addr.loc,
        state_name: res.data.data.data.pradr.addr.stcd,
        city_name: res.data.data.data.pradr.addr.dst,
        postalCode: res.data.data.data.pradr.addr.pncd,
      }

      setValue("supplierName", res.data.data.data.lgnm || "", {shouldValidate: true});
      setValue("gst", res.data.data.data.gstin || "", {shouldValidate: true});

      props.setMain_obj({...props.main_obj, ...obj});
      setLock(false);
    },
    (err) => {
      setLock(false);
    }
  );
}

  return (
    <>
      <div className="row">
        <h4 className="mb-5 roboto-bold">
          {constLabel?.lbl_supplier_detail
            ? constLabel?.lbl_supplier_detail
            : "Supplier Detail"}
        </h4>
      </div>

      {location.state !== null && location.pathname == "/pcms/editSupplier" ? null :
        <div className="row">
          <div className="col-12 col-md-6 row me-0 me-md-1">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold col-12 col-md-3"
            >
              {constLabel?.lbl_gst_no ? constLabel?.lbl_gst_no : "GST No" }
            </label>

            <div className="col-12 col-md-9">
              <input
                className={
                  errors.check_gst
                    ? "form-control is-invalid"
                    : "form-control"
                }
                type="text"
                name="check_gst"
                placeholder={"Enter gst no"}
                onChange={(e) => setGSTin(e.target.value)}
                value={gstin}
              />
              <span className="text-danger err-msg">
                {errors.check_gst?.message}
              </span>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <button
              className="btn btn-grey px-3"
              onClick={() => fetchGSTDetails()}
              disabled={gstin.length == 0 ? true : false}
            >
              {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}{" "}
              {lock ? <Spinner animation="border" size="sm" /> : null}
            </button>
          </div>
        </div>
      }

      <hr className="my-4" />

      <div className="row">
        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_supplier_type
                ? constLabel.lbl_supplier_type
                : "Supplier Type"}
              <span className="text-danger"> *</span>
            </label>

            <Controller
              name="supplierType"
              control={control}
              defaultValue={
                props.main_obj.supp_type && [
                  { name: props.main_obj.supp_type, uuid: props.main_obj.supplier_type_id},
                ]
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  multiple={false}
                  // size="sm"
                  options={supType.data !== undefined ? supType.data : []}
                  isLoading={supType.state}
                  placeholder="Select supplier type"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    props.main_obj.supp_type && [
                      { name: props.main_obj.supp_type, uuid: props.main_obj.supplier_type_id },
                    ]
                  }
                  isInvalid={errors.supplierType ? true : false}
                />
              )}
            />
            <span className="text-danger err-msg">
              {errors.supplierType?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_supplier_name
                ? constLabel?.lbl_supplier_name
                : "Supplier Name"}
              <span className="text-danger"> *</span>
            </label>
            <input
              type="text"
              className={
                errors.supplierName
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="supplierName"
              name="supplierName"
              placeholder="Enter supplier name"
              defaultValue={
                props.main_obj.name && props.main_obj.name
              }
              {...register("supplierName")}
            />
            <span className="text-danger err-msg">
              {errors.supplierName?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_contact_person_name
                ? constLabel?.lbl_contact_person_name
                : "Contact Person Name"}
            </label>
            <input
              type="text"
              className="form-control"
              id="contactPersonName"
              name="contactPersonName"
              placeholder="Enter contact person name"
              defaultValue={
                props.main_obj.contactPersonName && props.main_obj.contactPersonName
              }
              {...register("contactPersonName")}
            />
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_contact_no
                ? constLabel?.lbl_contact_no
                : "Contact No"}
            </label>
            <input
              type="text"
              className="form-control"
              id="contactPersonContact"
              name="contactPersonContact"
              maxLength={10}
              placeholder="Enter contact no"
              defaultValue={
                props.main_obj.contactPersonContact && props.main_obj.contactPersonContact
              }
              {...register("contactPersonContact")}
            />
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_contact_person_designation
                ? constLabel?.lbl_contact_person_designation
                : "Contact Person Designation"}
            </label>
            <input
              type="text"
              className="form-control"
              id="contactPersonDesignation"
              name="contactPersonDesignation"
              placeholder="Enter contact person designation"
              defaultValue={
                props.main_obj.contactPersonDesignation && props.main_obj.contactPersonDesignation
              }
              {...register("contactPersonDesignation")}
            />
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_cst_no
                ? constLabel?.lbl_cst_no
                : "CST No"}
            </label>
            <input
              type="text"
              className="form-control"
              id="cst"
              name="cst"
              placeholder="Enter cst no"
              defaultValue={
                props.main_obj.CSTNO && props.main_obj.CSTNO
              }
              {...register("cst")}
            />
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_vat_no
                ? constLabel?.lbl_vat_no
                : "VAT No"}
            </label>
            <input
              type="text"
              className="form-control"
              id="vat"
              name="vat"
              placeholder="Enter vat no"
              defaultValue={
                props.main_obj.VATNO && props.main_obj.VATNO
              }
              {...register("vat")}
            />
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_pan_no
                ? constLabel?.lbl_pan_no
                : "PAN No"}
              { gstType && gstType?.[0]?.name == "Regular" ? <span className="text-danger" > *</span> : null }
            </label>
            <input
              type="text"
              className={
                errors.pan && (gstType && gstType?.[0]?.name == "Regular")
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="pan"
              name="pan"
              maxLength={10}
              placeholder="Enter pan no"
              defaultValue={
                props.main_obj.PANNO && props.main_obj.PANNO
              }
              {...register("pan")}
            />
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_email
                ? constLabel?.lbl_email
                : "Email"}
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter email"
              defaultValue={
                props.main_obj.email && props.main_obj.email
              }
              {...register("email")}
            />
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_gst_treatment
                ? constLabel.lbl_gst_treatment
                : "GST Treatment"}
              <span className="text-danger"> *</span>
            </label>

            <Controller
              name="gstTreat"
              control={control}
              defaultValue={
                props.main_obj?.gst_treatment !== undefined && props.main_obj?.gst_treatment?.length > 0
                  ? [{ name: props.main_obj.gst_treatment }]
                  : [{ name: "Regular" }]
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  multiple={false}
                  // size="sm"
                  options={gst_treat.data !== undefined ? gst_treat.data : []}
                  isLoading={gst_treat.state}
                  placeholder="Select gst treatment"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    props.main_obj?.gst_treatment !== undefined && props.main_obj?.gst_treatment?.length > 0
                      ? [{ name: props.main_obj.gst_treatment }]
                      : [{ name: "Regular" }]
                  }
                  isInvalid={errors.gstTreat ? true : false}
                />
              )}
            />
            <span className="text-danger err-msg">
              {errors.gstTreat?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_gst_no
                ? constLabel?.lbl_gst_no
                : "GST No"}
              { gstType && gstType?.[0]?.name == "Regular" ? <span className="text-danger" > *</span> : null }
            </label>
            <input
              type="text"
              className={
                errors.gst && (gstType && gstType?.[0]?.name == "Regular")
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="gst"
              name="gst"
              maxLength={15}
              placeholder="Enter gst"
              defaultValue={
                props.main_obj.gst && props.main_obj.gst
              }
              {...register("gst")}
            />
            {errors.gst && gstType && gstType?.[0]?.name == "Regular" ? 
                <span className="text-danger err-msg">
                  {errors.gst?.message}
                </span> : null
              }
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_description
                ? constLabel?.lbl_description
                : "Description"}
            </label>
            <textarea
              type="text"
              className="form-control"
              id="description"
              name="description"
              placeholder="Enter description"
              defaultValue={
                props.main_obj.description && props.main_obj.description
              }
              {...register("description")}
            ></textarea>
          </div>
        </div>
      </div>

      <hr className="my-3" />
      <div className="row  mt-0 ">
        <div className="d-md-flex justify-content-end">
          {/* <button
            onClick={() => {
              navigate(`/pcms/supplier`);
            }}
            className="btn btn-grey px-4 me-2"
          >
            {"Cancel "}
          </button> */}
          <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_save_continue
                ? constLabel?.lbl_save_continue
                : "Save & Continue"}
            </button>
          <button
            className="btn btn-grey px-4 me-2"
            disabled={Object.keys(props.main_obj).length > 4 ? false : true}
            onClick={() => props.nextStep()}
          >
            {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
          </button>
        </div>
      </div>
    </>
  );
}

export default NewSupplierDetail;
