import React, { useState, useEffect } from "react";
import "./productStepper.css"; // Import your CSS file for styling
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate, useLocation } from "react-router-dom";
import SweetAlert from "sweetalert2";
import {
  faArrowLeft,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getLabels,
  isExcelFile,
} from "../../../common/Functions/CommonFunctions";
import { getProductType } from "../../../api";
import {
  getRoutesApi,
  download_prod_oper_template,
  bulk_upload_prod_oper,
} from "../../../api/index";
import UploadProductImages from "./ProductForms/Image-Upload/UploadProductImages";
import ProductAttribute from "./ProductForms/Product-Attribute/ProductAttribute";
import ProductSpecification from "./ProductForms/Product-Specification/ProductSpecification";
import PreviewProduct from "./PreviewProduct";
import SemiFinishGoods from "./ProductForms/Product-Overview/SemiFinishGoods";
import RawMaterial from "./ProductForms/Product-Overview/RawMaterial";
import ManufacturedGoods from "./ProductForms/Product-Overview/ManufacturedGoods";
import ToolsFixtureView from "./ToolsFixturesView";
import Fixtures from "./ProductForms/Fixtures/Fixtures";
import Tools from "./ProductForms/Tool-Overview/Tools";
import ToolsFixturesOpration from "./ProductForms/Tool & Fixtures Operation/ToolsFixturesOperation";
import Gauges from "./ProductForms/Gauges/Gauges";
import GeneralItem from "./ProductForms/GeneralItem";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import info from "../../../assets/images/info.png";
import { Tooltip } from "react-tooltip";
import AddRoute from "../AddMasters/AddRoute";
import CreateBOM from "./ProductForms/Bill-Of-Material/CreateBOM";
import { CONSUMABLE_ITEMS, FINISHED_GOODS, FIXTURES, GAUGES, GENERAL_ITEM, MANUFACTURED_GOODS, RAW_MATERIAL, SEMI_FINISH_GOODS } from "../../../common/Constants/constants";

function EditProductConfigurator() {
  let navigate = useNavigate();
  let location = useLocation();
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [currentStep, setCurrentStep] = useState(1);
  const [main_obj, setMain_obj] = useState({
    prod_overview: location.state,
    prod_image: [],
    prod_attrib: [],
    prod_specs: [],
    obj_bom: { data: {}, flag: false },
    selected_routes: [],
  });
  // const steps_array = [
  //   "Product Overview",
  //   "Upload Product Images",
  //   "Operation Sequence",
  //   "Product Properties",
  // ];
  
  // const product_types = ["Manufactured Goods", "Finished Goods", "Raw material", "Semi Finish Goods", "Consumable Items", "Fixtures", "Gauges"];
  const product_types = [MANUFACTURED_GOODS, FINISHED_GOODS, RAW_MATERIAL, SEMI_FINISH_GOODS, CONSUMABLE_ITEMS, FIXTURES, GAUGES];
  const [steps_array, setSteps_array] = useState([
    "Product Overview",
    "Bill of Material",
    "Upload Product Images",
    "Operation Sequence",
    "Product Properties",
  ]);
  // const numSteps = 4;
  const [numSteps, setNumsteps] = useState(5);
  const [constLabel, setConstLabel] = useState({});
  const [routesList, setRoutesList] = useState({ data: [], loading: false });
  const [oneRouteAtOneTime, setOneRouteAtOneTime] = useState({
    counter: 0,
    enableError: false,
  });
  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const [importData, setImportData] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  const selProductType = watch("type");
  let selRoute = watch("routesType");

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    // setMain_obj({...main_obj, prod_overview: location.state});
    if (location?.state?.product_type_id?.productType == RAW_MATERIAL) {
      setSteps_array([
        "Product Overview",
        "Upload Product Images",
        // "Operation Sequence",
        // "Product Properties",
      ]);

      setNumsteps(2);
    }

    if (location?.state?.product_type_id?.productType == CONSUMABLE_ITEMS) {
      setSteps_array([
        "Consumable Item Overview",
        "Upload Consumable Item Images",
        "Consumable Item Operation",
      ]);

      setNumsteps(3);
    }
    if (location?.state?.product_type_id?.productType == FIXTURES) {
      setSteps_array([
        "Fixtuers Overview",
        "Upload Fixtures Images",
        "Fixtures Operation",
      ]);

      setNumsteps(3);
    }
    // console.log("location?.state",location.state)
    if (location?.state?.product_type_id?.productType == GAUGES) {
      // console.log("sdfjl")
      setSteps_array([
        "Gauges Overview",
        "Upload Gauges Images",
        "Gauges Operation",
      ]);

      setNumsteps(3);
    }

    if (location?.state?.product_type_id?.productType == GENERAL_ITEM) {
      // console.log("sdfjl")

      setSteps_array([GENERAL_ITEM]);

      setNumsteps(1);
    }

    fetchProductType();
    fetchRoutes();
  }, []);

  function fetchRoutes() {
    getRoutesApi().then(
      (res) => {
        setRoutesList({ ...routesList, data: res.data.data, loading: false });
      },
      (err) => {
        setRoutesList({ ...routesList, data: [], loading: false });
      }
    );
  }

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        setProdType({ ...prodType, data: res.data.data, loading: false });
        // setValue('type', [res.data.data[0]]);
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  const nextStep = () => {
    let next = currentStep + 1;
    // if (next > numSteps) {
    //     next = 1;
    // }
    setCurrentStep(next);
    setOneRouteAtOneTime({
      ...oneRouteAtOneTime,
      counter: 0,
      enableError: false,
    });
  };

  const previousStep = (stepNumber) => {
    if (stepNumber !== undefined && stepNumber >= 1 && stepNumber <= numSteps) {
      setCurrentStep(stepNumber);
    } else {
      let prev = currentStep - 1;
      if (prev < 1) {
        prev = numSteps;
      }
      setCurrentStep(prev);
    }
  };

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `Product_Opertions_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    setDownloadTemplate(false);
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const handleFileChange = async (event) => {
    // console.log("event triggered");
    const file = event.target.files[0];

    if (!file) {
      toast.error("No file selected!", { autoClose: 2000 });
      return;
    }

    // console.log("Selected file:", file);

    if (!isExcelFile(file.name)) {
      toast.error("Please import a valid Excel file!", { autoClose: 2000 });
      return;
    }

    try {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64String = reader.result.split(",")[1];
        setImportData(true);

        try {
          const res = await bulk_upload_prod_oper({
            file_base_64: base64String,
          });

          if (res.status === 200 || res.status === 201) {
            toast.success("Product operations imported successfully!", {
              autoClose: 3000,
            });
          }
        } catch (err) {
          const status = err.response?.status;
          if (status === 400) {
            toast.error("Invalid data in the file!", { autoClose: 2000 });
          } else {
            toast.error("Error during import operation!", { autoClose: 2000 });
          }
        } finally {
          setImportData(false);
        }
      };

      reader.onerror = () => {
        toast.error("Error reading the file!", { autoClose: 2000 });
      };
    } catch (err) {
      toast.error("Something went wrong!", { autoClose: 2000 });
    }
  };

  return (
    <React.Fragment>
      {currentStep !== 6 && (
        <div className="mb-4">
          {(currentStep === 5 &&
            ((selProductType &&
              selProductType[0]?.productType == RAW_MATERIAL) ||
              (selProductType &&
                selProductType[0]?.productType == CONSUMABLE_ITEMS) ||
              (selProductType &&
                selProductType[0]?.productType == FIXTURES) ||
              (selProductType && selProductType[0]?.productType == GAUGES) ||
              (selProductType &&
                selProductType[0]?.productType == GENERAL_ITEM))) ||
            (currentStep === 4 &&
            ((selProductType &&
                selProductType[0]?.productType == RAW_MATERIAL) ||
              (selProductType &&
              selProductType[0]?.productType == CONSUMABLE_ITEMS) ||
              (selProductType &&
                selProductType[0]?.productType == FIXTURES) ||
              (selProductType && selProductType[0]?.productType == GAUGES) ||
              (selProductType &&
                selProductType[0]?.productType == GENERAL_ITEM))) ||
            (currentStep === 3 &&
              ((selProductType &&
                  selProductType[0]?.productType == RAW_MATERIAL))) ? null : (
            <div className="card" id="product_configurator_card">
              <div className="card-header bg-grey d-flex align-items-center">
                <button
                  className="btn btn-yellow"
                  onClick={() => navigate("/pcms/product")}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                </button>

                <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                  {constLabel?.lbl_edit_product_configurator
                    ? constLabel?.lbl_edit_product_configurator
                    : "Edit Product Configurator"}
                </p>
              </div>
              <div className="card-body" id="party_master">
                {selProductType &&
                  product_types.includes(selProductType[0]?.productType) && (
                    <div className="stepper-horizontal" id="stepper1">
                      {[...Array(numSteps)].map((_, index) => {
                        const stepNum = index + 1;
                        const isEditing =
                          stepNum === currentStep ? "editing" : "";
                        const isDone = stepNum < currentStep ? "done" : "";

                        return (
                          <div
                            className={`step ${isEditing} ${isDone}`}
                            key={index}
                          >
                            <div className="step-circle">
                              <span>{stepNum}</span>
                            </div>
                            <div className="step-title">
                              {steps_array[stepNum - 1]}
                            </div>
                            <div className="step-bar-left"></div>
                            <div className="step-bar-right"></div>
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
              <div className="card-body">
                <h4 className="roboto-bold">{steps_array[currentStep - 1]}</h4>
                <hr />
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group typeahead-container">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_product_type
                          ? constLabel?.lbl_product_type
                          : "Product Type"}
                        <span className="text-danger"> *</span>{" "}
                        <Tooltip id={"open-tooltip1"} place="top" style={{ zIndex: 9999 }} />
                        <img
                          src={info}
                          alt="info"
                          className="cursor_pointer"
                          data-tooltip-id={"open-tooltip1"}
                          data-tooltip-html="Classifies items based on their role in the manufacturing process, including categories<br />
                             such as raw materials, components, finished goods, packaging materials, tools, and gauges. This field helps in<br /> 
                             organizing and tracking inventory for streamlined production, procurement, and management."
                          height={20}
                          width={20}
                        />
                      </label>
                      <Controller
                        name="type"
                        control={control}
                        defaultValue={
                          location.state?.product_type_id?.productType !==
                            undefined &&
                          location.state?.product_type_id?.productType !== null
                            ? [location.state?.product_type_id]
                            : []
                        }
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="productType"
                            className="mt-2"
                            multiple={false}
                            options={prodType.data}
                            isLoading={prodType.loading}
                            placeholder="Select product type"
                            onChange={onChange}
                            disabled={
                              location.pathname ==
                              "/pcms/editProductConfigurator"
                                ? true
                                : false
                            }
                            defaultSelected={
                              main_obj.prod_overview?.product_type_id
                                ?.productType !== undefined &&
                              main_obj.prod_overview?.product_type_id
                                ?.productType !== null
                                ? [main_obj.prod_overview?.product_type_id]
                                : []
                            }
                            selected={value}
                            onBlur={onBlur}
                            isInvalid={errors.type ? true : false}
                          />
                        )}
                      />
                      {!prodType.loading && !errors.type && <span className="arrow-icon"></span>}
                      {errors.type && (
                        <span className="text-danger err-msg">
                          {errors.type.message}
                        </span>
                      )}
                    </div>
                  </div>

                  {((currentStep === 4 && 
                    (selProductType[0]?.productType === MANUFACTURED_GOODS || selProductType[0]?.productType === FINISHED_GOODS ||
                      selProductType[0]?.productType === SEMI_FINISH_GOODS)) || 
                    (currentStep === 3 && selProductType[0]?.productType === RAW_MATERIAL)) && (
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold d-flex align-items-center">
                            {constLabel?.lbl_routes ? constLabel.lbl_routes : "Select Routes"}
                            <span className="text-danger"> *</span>
                            <Tooltip id="open-tooltip2" place="top" style={{ zIndex: 9999 }} />
                            <img
                              src={info}
                              alt="info"
                              className="cursor_pointer"
                              data-tooltip-id="open-tooltip2"
                              data-tooltip-html="Choose/Create the workflow or production path the manufacturing of this product will<br />
                                follow, determining the sequence of processes required for manufacturing or processing the product."
                              height={20}
                              width={20}
                            />
                            <AddRoute fetchRoutes={fetchRoutes} />
                          </label>

                          <Typeahead
                            className="mt-2"
                            id="basic-typeahead"
                            labelKey="routesType"
                            multiple={true}
                            options={routesList.data}
                            loading={routesList.loading}
                            placeholder="Select route"
                            onChange={(e) => {
                              console.log("e", e);
                              console.log("main_obj.selected_routes", main_obj.selected_routes);
                              console.log("oneRouteAtOneTime", oneRouteAtOneTime);

                              if (e.length > main_obj.selected_routes.length) {
                                if (oneRouteAtOneTime.counter === 0) {
                                  setMain_obj({ ...main_obj, selected_routes: e });
                                  setOneRouteAtOneTime({
                                    ...oneRouteAtOneTime,
                                    counter: 1,
                                    enableError: false,
                                  });
                                } else {
                                  toast.error("One Route at one time allowed", {
                                    autoClose: 2000,
                                  });
                                  setOneRouteAtOneTime({
                                    ...oneRouteAtOneTime,
                                    counter: 2,
                                    enableError: true,
                                  });
                                }
                              } else {
                                setMain_obj({ ...main_obj, selected_routes: e });
                                setOneRouteAtOneTime({
                                  ...oneRouteAtOneTime,
                                  counter: 0,
                                  enableError: false,
                                });
                              }
                            }}
                            selected={main_obj.selected_routes}
                          />
                        </div>
                      </div>
                  )}


                  {((currentStep === 4 &&
                    (selProductType[0]?.productType === MANUFACTURED_GOODS || selProductType[0]?.productType === FINISHED_GOODS ||
                      selProductType[0]?.productType == SEMI_FINISH_GOODS)) ||
                    (currentStep === 3 && selProductType[0]?.productType === RAW_MATERIAL)) && (
                    <>
                      <div className="col-12 col-md-6 d-flex">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold invisible">
                            Label
                          </label>

                          <div
                            className="d-flex align-items-end"
                            style={{ marginTop: "8px" }}
                          >
                            <button
                              className="btn btn-light-grey"
                              onClick={() => {
                                setDownloadTemplate(true);

                                download_prod_oper_template({
                                  product_id: location.state.uuid,
                                }).then(
                                  (res) => {
                                    console.log("res", res);
                                    base64ToExcel(res.data);
                                  },
                                  (err) => {
                                    console.log("err", err);
                                    toast.error("Something went wrong", {
                                      autoClose: 2000,
                                    });
                                    setDownloadTemplate(false);
                                  }
                                );
                              }}
                              disabled={downloadTemplate}
                            >
                              <FontAwesomeIcon
                                style={{ color: "#fff" }}
                                icon={faDownload}
                                size="sm"
                              />{" "}
                              Operation Template{" "}
                              {downloadTemplate && (
                                <Spinner animation="border" size="sm" />
                              )}
                            </button>
                          </div>
                        </div>

                        <div className="form-group ms-0 ms-md-3">
                          <label className="lbl-style roboto-bold invisible">
                            Label
                          </label>
                          <div style={{ marginTop: "8px" }}>
                            <input
                              type="file"
                              accept=".xls,.xlsx"
                              onChange={handleFileChange}
                              style={{ display: "none" }} // Hide the input element
                              id="fileInput"
                            />

                            <label htmlFor="fileInput">
                              <button
                                className="btn btn-grey px-4 ms-0 ms-md-2 mt-2 mt-md-0"
                                onClick={() => {
                                  const fileInput =
                                    document.getElementById("fileInput");
                                  fileInput.value = ""; // Reset file input value to ensure onChange fires
                                  fileInput.click();
                                }}
                                // onChange={handleFileChange}
                                disabled={importData}
                              >
                                <FontAwesomeIcon
                                  style={{ color: "#fff", marginRight: "6px" }}
                                  icon={faUpload}
                                  size="sm"
                                />
                                {constLabel?.lbl_import_pro_oper
                                  ? constLabel.lbl_import_pro_oper
                                  : `Import operations`}{" "}
                                {importData && (
                                  <Spinner animation="border" size="sm" />
                                )}
                              </button>
                            </label>
                          </div>
                        </div>

                        {main_obj && main_obj.selected_routes && main_obj.selected_routes?.length == 0 ?
                        <>
                        <div className="form-group"> 
                          <label className="lbl-style roboto-bold invisible">
                            Label
                          </label>
                          <div style={{ marginTop: "8px" }}>
                            <button
                            className="btn btn-outline-secondary px-4 ms-0 ms-md-2 mt-2 mt-md-0"
                            onClick={() => previousStep()}
                            >
                              {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
                            </button> 
                          </div>
                        </div>

                        <div className="form-group"> 
                          <label className="lbl-style roboto-bold invisible">
                            Label
                          </label>
                          <div style={{ marginTop: "8px" }}>
                            <button
                            className="btn btn-outline-secondary px-4 ms-0 ms-md-2 mt-2 mt-md-0"
                            onClick={() => {
                              nextStep();
                              }}
                            >
                              {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
                            </button> 
                          </div>
                        </div>
                        </>
                        : null }
                      </div>
                    </>
                  )}

                  {((currentStep === 4 &&
                    (selProductType[0]?.productType === MANUFACTURED_GOODS || selProductType[0]?.productType === FINISHED_GOODS ||
                      selProductType[0]?.productType == SEMI_FINISH_GOODS)) ||
                    (currentStep === 3 && selProductType[0]?.productType === RAW_MATERIAL)) && (
                    <i>
                      <p className="mt-4 mb-0">
                        <span className="text-danger roboto-bold">Note - </span>
                        Only one route can be saved at a time. Please fill and
                        save the current route's data before adding another.
                      </p>
                    </i>
                  )}
                </div>
                <hr />
                {currentStep === 1 && (
                  <>
                    {(location.state.productTypename ===
                      MANUFACTURED_GOODS || location.state.productTypename ===
                      FINISHED_GOODS) && (
                      <ManufacturedGoods
                        main_obj={main_obj}
                        setMain_obj={setMain_obj}
                        nextStep={nextStep}
                        // data={location.state}
                        product_type={selProductType?.[0]}
                      />
                    )}
                    {location.state.productTypename === RAW_MATERIAL && (
                      <RawMaterial
                        main_obj={main_obj}
                        setMain_obj={setMain_obj}
                        nextStep={nextStep}
                        // data={location.state}
                        product_type={selProductType?.[0]}
                      />
                    )}
                    {location.state.productTypename === SEMI_FINISH_GOODS && (
                      <SemiFinishGoods
                        main_obj={main_obj}
                        setMain_obj={setMain_obj}
                        nextStep={nextStep}
                        // data={location.state}
                        product_type={selProductType?.[0]}
                      />
                    )}
                    {selProductType &&
                      selProductType[0]?.productType === CONSUMABLE_ITEMS && (
                        <Tools
                          main_obj={main_obj}
                          setMain_obj={setMain_obj}
                          nextStep={nextStep}
                          data={location.state}
                          product_type={selProductType[0]}
                        />
                      )}
                    {selProductType &&
                      selProductType[0]?.productType === FIXTURES && (
                        <Fixtures
                          main_obj={main_obj}
                          setMain_obj={setMain_obj}
                          nextStep={nextStep}
                          data={location.state}
                          product_type={selProductType[0]}
                        />
                      )}

                    {selProductType &&
                      selProductType[0]?.productType === GAUGES && (
                        <Gauges
                          main_obj={main_obj}
                          setMain_obj={setMain_obj}
                          nextStep={nextStep}
                          data={location.state}
                          product_type={selProductType[0]}
                        />
                      )}

                    {selProductType &&
                      !product_types.includes(
                        selProductType[0]?.productType
                      ) && (
                        <GeneralItem
                          main_obj={main_obj}
                          setMain_obj={setMain_obj}
                          data={location.state}
                          product_type={selProductType[0]}
                        />
                      )}
                  </>
                )}

                {currentStep === 2 &&
                  selProductType &&
                  selProductType[0]?.productType !== RAW_MATERIAL &&
                  selProductType &&
                  selProductType[0]?.productType !== CONSUMABLE_ITEMS &&
                  selProductType &&
                  selProductType[0]?.productType !== FIXTURES &&
                  selProductType &&
                  selProductType[0]?.productType !== GAUGES && (
                    <CreateBOM
                      main_obj={main_obj}
                      setMain_obj={setMain_obj}
                      nextStep={nextStep}
                      previousStep={previousStep}
                    />
                  )}

                {currentStep === 2 &&
                  ((selProductType && selProductType[0]?.productType) ===
                    RAW_MATERIAL ||
                    (selProductType && selProductType[0]?.productType) ===
                      CONSUMABLE_ITEMS ||
                    (selProductType && selProductType[0]?.productType) ===
                      FIXTURES ||
                    (selProductType && selProductType[0]?.productType) ===
                      GAUGES) && (
                    <UploadProductImages
                      // currentStep={currentStep}
                      // setCurrentStep={setCurrentStep}
                      main_obj={main_obj}
                      setMain_obj={setMain_obj}
                      nextStep={nextStep}
                      previousStep={previousStep}

                      // data={location.state}
                    />
                  )}

                {currentStep === 3 &&
                  ((selProductType && selProductType[0]?.productType) ===
                    MANUFACTURED_GOODS ||
                    (selProductType && selProductType[0]?.productType) ===
                    FINISHED_GOODS ||
                    (selProductType && selProductType[0]?.productType) ===
                      SEMI_FINISH_GOODS ) && (
                    <UploadProductImages
                      // currentStep={currentStep}
                      // setCurrentStep={setCurrentStep}
                      main_obj={main_obj}
                      setMain_obj={setMain_obj}
                      nextStep={nextStep}
                      previousStep={previousStep}

                      // data={location.state}
                    />
                  )}

                {currentStep === 3 &&
                  ((selProductType && selProductType[0]?.productType) ===
                    CONSUMABLE_ITEMS ||
                    (selProductType && selProductType[0]?.productType) ===
                      FIXTURES ||
                    (selProductType && selProductType[0]?.productType) ===
                      GAUGES) && (
                    <ToolsFixturesOpration
                      // currentStep={currentStep}
                      // setCurrentStep={setCurrentStep}
                      main_obj={main_obj}
                      setMain_obj={setMain_obj}
                      nextStep={nextStep}
                      previousStep={previousStep}
                      data={location.state}

                      // bomStat={bomStat}
                    />
                  )}

                  {currentStep === 4 &&
                    selProductType?.[0]?.productType &&
                    ![CONSUMABLE_ITEMS, FIXTURES, GAUGES, RAW_MATERIAL].includes(selProductType[0].productType) && (
                      <ProductSpecification
                        main_obj={main_obj}
                        setMain_obj={setMain_obj}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        routesList={routesList}
                        importData={importData}
                      />
                    )}

                {currentStep === 5 &&
                  selProductType &&
                  selProductType[0]?.productType !== CONSUMABLE_ITEMS &&
                  selProductType &&
                  selProductType[0]?.productType !== FIXTURES &&
                  selProductType &&
                  selProductType[0]?.productType !== GAUGES && (
                    <ProductAttribute
                      // currentStep={currentStep}
                      // setCurrentStep={setCurrentStep}
                      main_obj={main_obj}
                      setMain_obj={setMain_obj}
                      nextStep={nextStep}
                      previousStep={previousStep}
                      // data={location.state}
                    />
                  )}
              </div>
            </div>
          )}
        </div>
      )}

      {currentStep === 6 &&
        ((selProductType &&
          selProductType[0]?.productType !== CONSUMABLE_ITEMS) ||
          (selProductType && selProductType[0]?.productType !== FIXTURES) ||
          (selProductType && selProductType[0]?.productType !== GAUGES)) && (
          <PreviewProduct
            main_obj={main_obj}
            setMain_obj={setMain_obj}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        )}

      {currentStep === 4 &&
        ((selProductType &&
          selProductType[0]?.productType == CONSUMABLE_ITEMS) ||
          (selProductType && selProductType[0]?.productType == FIXTURES) ||
          (selProductType && selProductType[0]?.productType == GAUGES)) && (
          <ToolsFixtureView
            // currentStep={currentStep}
            // setCurrentStep={setCurrentStep}
            main_obj={main_obj}
            setMain_obj={setMain_obj}
            nextStep={nextStep}
            previousStep={previousStep}
            data={location.state}
          />
        )}

        {currentStep === 3 &&
        ((selProductType &&
          selProductType[0]?.productType == RAW_MATERIAL)) && (
            <PreviewProduct
            main_obj={main_obj}
            setMain_obj={setMain_obj}
            nextStep={nextStep}
            previousStep={previousStep}
          />
        )}
    </React.Fragment>
  );
}

export default EditProductConfigurator;
