import React, { useState, useEffect } from 'react'
import { fetch_prod_wise_bom } from "../../../api/index";
import { toast } from "react-toastify";
import { convertDate, formatDateString, getLabels } from "../../../common/Functions/CommonFunctions";
import refresh from "../../../assets/images/refresh.png";
import { Typeahead } from "react-bootstrap-typeahead";
import { fetch_multi_prod_stock } from "../../../api/index";
import { useSelector, useDispatch } from 'react-redux';
import { updateStepTwo } from '../../../slices/production_planning/production_plan_slice';
import { Link } from "react-router-dom"
import CreateBom from "./CreateBom";
import AddProduct from './AddProduct';
import { Trash2, } from "react-feather";
import { Tooltip } from "react-tooltip";
import SweetAlert from "sweetalert2";

function StepTwo(props) {
    const { nextStep, prevStep } = props;

    const dispatch = useDispatch();
    const StepOneSavedData = useSelector((state) => state.steps.StepOne);
    const StepTwoSavedData = useSelector((state) => state.steps.StepTwo);

    const [consolidate, setConsolidate] = useState(false);
    const [ignoreQty, setIgnoreQty] = useState(false);

    const [constLabel, setConstLabel] = useState({});


    const [prodListWithBom, setProdListWithBom] = useState({ loading: false, data: [], });
    const [unConsolidated_prodListWithBom, setunConsolidated_prodListWithBom] =
        useState({ loading: false, data: [], });


    const [subprodListWithBom, setSubProdListWithBom] = useState({ loading: false, data: [], firstTime: false });
    const [disableBtn, setDisableBtn] = useState({ state: false, msg: "" });

    const [modalData, setModalData] = useState({ state: false, data: null, ind: null });
    const [visible, setVisible] = useState({ state: false, data: [] });

    useEffect(() => {
        console.log("prodListWithBom", prodListWithBom);
        console.log("StepTwoSavedData", StepTwoSavedData);
        console.log("StepOneSavedData", StepOneSavedData);
        setConstLabel(getLabels('Production', 'Production Planning'))
        preparingBomList(consolidate);

    }, []);



    function preparingBomList(consolidate_data, called_from) {
        console.log("called_from", called_from);
        console.log("modalData", modalData);
        if (called_from) {
            console.log("called_from", called_from);
            setProdListWithBom({
                ...prodListWithBom,
                loading: true,
                data: [...prodListWithBom.data]
            });
        } else {
            setProdListWithBom({ ...prodListWithBom, loading: true, data: [] });
        }
        setSubProdListWithBom({ ...subprodListWithBom, loading: false, data: [] });

        let uniqueProducts = [];


        const uuidSet = new Set();

        for (const so of StepOneSavedData.selectedSalesOrder) {
            for (const item of so.so_order_lines) {
                console.log("uuidSet", uuidSet);

                const productDetails = { ...item.product_id };
                productDetails.so_item_quantity = item.quantity;
                productDetails.order_date = so.order_date;
                productDetails.order_due_date = so.order_due_date;
                productDetails.so_uuid = so.uuid;
                productDetails.so_final_total = so.final_total;
                productDetails.so_customer_id = so.customer_id;
                productDetails.so_item_uuid = item.uuid;
                // console.log("productDetails", productDetails);

                if (consolidate_data == false) {
                    // console.log("+++++");
                    uuidSet.add(productDetails.uuid);
                    uniqueProducts.push(productDetails);



                } else {
                    console.log(uuidSet.has(productDetails.uuid));
                    if (!uuidSet.has(productDetails.uuid)) {
                        // console.log("----------");
                        uuidSet.add(productDetails.uuid);
                        uniqueProducts.push(productDetails);

                    } else {
                        // Ths step is required to sum quantity of so lineitems where product is same
                        uniqueProducts.map((val) => {
                            if (val.uuid == productDetails.uuid) {
                                val.so_item_quantity = val.so_item_quantity + productDetails.so_item_quantity;
                            }
                        });


                    }
                }

            }
        }


        console.log("StepOneSavedData", StepOneSavedData);
        console.log("uniqueProducts", uniqueProducts);

        fetch_prod_wise_bom({ prod_arr: uniqueProducts.map(val => val.uuid) }).then((res) => {
            console.log("res", res);

            const response = res.data.data;
            if (response.length > 0) {

                const prod_bom_unique_combo_list = [];
                // const product_unique_uuid = new Set();

                let j = 0;
                for (const prod of uniqueProducts) {
                    const bomListOfProd = response.filter(val => val.product_id == prod.uuid);



                    let newItem = null;
                    newItem = { ...prod };
                    newItem.bom = bomListOfProd;
                    console.log("bomListOfProd", bomListOfProd);
                    console.log("prodListWithBom", prodListWithBom);
                    console.log("modalData", modalData);

                    if (prodListWithBom.data.length > 0) {
                        if (j == modalData.ind) {
                            newItem.selected_bom = [bomListOfProd[0]];
                        } else {
                            newItem.selected_bom = [];

                            // newItem.selected_bom = prodListWithBom.data[j] ?
                            //     prodListWithBom.data[j].selected_bom : [];

                        }
                    } else {
                        newItem.selected_bom = [];
                    }
                    newItem.errStatus = false;


                    prod_bom_unique_combo_list.push(newItem);
                    j++;
                }
                console.log("uniqueProducts", uniqueProducts);
                console.log("prod_bom_unique_combo_list", prod_bom_unique_combo_list);

                setProdListWithBom({
                    ...prodListWithBom, loading: false,
                    data: prod_bom_unique_combo_list
                });

                if (called_from) {
                    setModalData({ ...modalData, state: false, data: null, ind: null });
                }

                if (consolidate_data == false) {
                    setunConsolidated_prodListWithBom({
                        ...unConsolidated_prodListWithBom,
                        loading: false, data: prod_bom_unique_combo_list
                    });
                }
            } else {
                setProdListWithBom({ ...prodListWithBom, loading: false, data: [] });
                toast.error("Something went wrong in fetching bom list", {
                    autoClose: 3000,
                });
            }

        }, (err) => {
            console.log("err", err);
            setProdListWithBom({ ...prodListWithBom, loading: false, data: [] });
            if (err.response.status !== 404) {
                toast.error("Something went wrong in fetching bom list", {
                    autoClose: 3000,
                });
            }
        })
    }


    function fetchSubBomItems(do_ignore_qty, called_from) {
        const allOK = validateErr();
        if (!allOK) {
            console.log("asdasd");
            return
        } else {

            const icon = document.getElementById('refresh-icon-img');
            icon.classList.add('spin');

            setSubProdListWithBom({ loading: true, data: [], firstTime: false });

            console.log("prodListWithBom", prodListWithBom);

            const product_having_sel_bom = [];
            var finalSubProdList = [];

            prodListWithBom.data.map((val) => {

                const selBOM = val.selected_bom[0];
                selBOM.so_item_quantity = val.so_item_quantity;

                product_having_sel_bom.push(selBOM);

                selBOM.bom_line_item.forEach((line_item) => {
                    const new_obj = { ...selBOM };
                    delete new_obj.bom_line_item;
                    new_obj.lineItemDetails = line_item;
                    new_obj.lineItemDetails.qtyRequiredAsPerBom = 0;
                    new_obj.lineItemDetails.so_item_uuid = val.so_item_uuid;
                    new_obj.lineItemDetails.so_uuid = val.so_uuid;
                    finalSubProdList.push(new_obj);
                })

            });

            console.log("product_having_sel_bom", product_having_sel_bom);



            console.log("visible.data", visible.data);
            if (visible.data.length && !called_from) {
                finalSubProdList = [...finalSubProdList, ...visible.data];
            } else {
                setVisible({ state: false, data: [] });
            }

            console.log("finalSubProdList", finalSubProdList);

            if (finalSubProdList.length > 0) {

                fetch_multi_prod_stock({
                    product_uuid_list: finalSubProdList.map(val => val.lineItemDetails.product_id),
                    location_uuid: StepOneSavedData?.formData?.location[0].uuid
                }).then((res) => {
                    console.log("res", res);
                    const invResponse = res.data.data;


                    // 
                    console.log("unConsolidated_prodListWithBom", unConsolidated_prodListWithBom);



                    const one_bom_one_lineitem_list = [];
                    const prod_bom_combi_Set = new Set();

                    finalSubProdList.forEach((prod, ind) => {
                        if (prod.lineItemDetails) {
                            const resProd = invResponse.find((val) => {
                                return val.product_id == prod.lineItemDetails.product_id
                            });
                            if (resProd) {
                                prod.lineItemDetails.active_po_qty = resProd.active_po_qty
                                prod.lineItemDetails.availqty = resProd.availqty
                                prod.lineItemDetails.materialCode = resProd.materialCode ?? null
                                prod.lineItemDetails.data = resProd;

                                if (resProd.storeDetails) {
                                    prod.lineItemDetails.inventoryCost = resProd.storeDetails.inventoryCost;
                                    prod.lineItemDetails.consume_price = resProd.storeDetails.consume_price;
                                }
                            } else {
                                prod.lineItemDetails.active_po_qty = 0;
                                prod.lineItemDetails.availqty = 0;
                                prod.lineItemDetails.materialCode = null
                                prod.lineItemDetails.data = null;
                            }

                            const combination = `${prod.lineItemDetails.product_id}-${prod.bomId}`;

                            if (!prod_bom_combi_Set.has(combination)) {
                                prod_bom_combi_Set.add(combination);
                                one_bom_one_lineitem_list.push(prod);
                            } else {
                                console.log("one_bom_one_lineitem_list", one_bom_one_lineitem_list);
                                console.log("ind", ind);

                                const foundedIndex = one_bom_one_lineitem_list.findIndex((val) =>
                                    val.lineItemDetails.product_id == prod.lineItemDetails.product_id && val.bomId == prod.bomId);
                                console.log("foundedIndex", foundedIndex);

                                const temp = { ...one_bom_one_lineitem_list[foundedIndex] };
                                temp.so_item_quantity =
                                    temp.so_item_quantity + prod.so_item_quantity;
                                one_bom_one_lineitem_list[foundedIndex] = temp;

                            }
                        }

                    });

                    console.log("one_bom_one_lineitem_list", one_bom_one_lineitem_list);

                    for (const val of one_bom_one_lineitem_list) {
                        var qtyReqToMakeOne = parseFloat(val.lineItemDetails.qty) / parseFloat(val.qty);
                        console.log("qtyReqToMakeOne", qtyReqToMakeOne);
                        console.log("val.so_item_quantity", val.so_item_quantity);

                        if (Number.isNaN(qtyReqToMakeOne)) {
                            qtyReqToMakeOne = 0;
                        }

                        val.lineItemDetails.qtyRequiredAsPerBom = qtyReqToMakeOne * val.so_item_quantity;
                        // val.lineItemDetails.checked = (val.lineItemDetails?.active_po_qty + val.lineItemDetails?.availqty) >= val.lineItemDetails?.qtyRequiredAsPerBom ? false : true;                 // This is added for third step dependancy
                        val.lineItemDetails.checked = (val.lineItemDetails?.availqty) >= val.lineItemDetails?.qtyRequiredAsPerBom ? false : true;// This is added for third step dependancy

                        // if (Number.isNaN(val.lineItemDetails.qtyRequiredAsPerBom)) {
                        //     val.lineItemDetails.qtyRequiredAsPerBom = val.plan_to_req_qty;
                        // }


                        if (!do_ignore_qty) {

                            if (!val.addhoc_item) {
                                if (val.lineItemDetails.availqty > val.lineItemDetails.qtyRequiredAsPerBom) {
                                    val.lineItemDetails.planToRequestQty = 0;
                                } else {
                                    val.lineItemDetails.planToRequestQty = val.lineItemDetails.qtyRequiredAsPerBom - val.lineItemDetails.availqty;
                                }
                            }

                        } else {
                            console.log("here 1");
                            if (val.addhoc_item) {
                                val.lineItemDetails.planToRequestQty = val.lineItemDetails.planToRequestQty;
                            } else {
                                val.lineItemDetails.planToRequestQty = val.lineItemDetails.qtyRequiredAsPerBom;
                            }
                        }


                    }

                    console.log("StepOneSavedData", StepOneSavedData);
                    if (!StepOneSavedData.formData.rawMaterialPlanning &&
                        StepOneSavedData.formData.employeePlanning) {
                        const prodHasZeroAvailQty = one_bom_one_lineitem_list.some(item => item.lineItemDetails?.availqty
                            < item.lineItemDetails.qtyRequiredAsPerBom);
                        console.log("prodHasZeroAvailQty", prodHasZeroAvailQty);
                        if (prodHasZeroAvailQty) {
                            setDisableBtn({
                                state: true,
                                msg: "Cannot proceed as all products dont have required available quantity"
                            });
                        } else {
                            setDisableBtn({
                                state: false,
                                msg: ""
                            });
                        }
                    }
                    console.log('one_bom_one_lineitem_list', one_bom_one_lineitem_list);
                    setSubProdListWithBom({
                        ...subprodListWithBom,
                        loading: false, data: one_bom_one_lineitem_list, firstTime: true
                    });
                    icon.classList.remove('spin');

                    if (visible.data.length > 0) {
                        setTimeout(() => {
                            const divToScroll = document.getElementById('bom_item_table_parent');
                            divToScroll.scrollTop = divToScroll.scrollHeight;
                        }, [500]);
                    }

                }, (err) => {
                    console.log("err", err);
                    icon.classList.remove('spin');
                    setSubProdListWithBom({ ...subprodListWithBom, loading: false, data: [], firstTime: true });
                    if (err.response.status !== 404) {
                        toast.error("Something went wrong in fetching stock", {
                            autoClose: 3000,
                        });
                    }
                });

            }

        }
    }


    function validateErr() {

        const tempData = { ...prodListWithBom };
        console.log("tempData", tempData);

        tempData.data.forEach((prod, ind) => {
            if (prod.selected_bom.length == 0) {
                console.log("prod", prod);
                prod.errStatus = true
                prod.msg = 'required'
            } else {
                prod.errStatus = false
                prod.msg = ''
            }
        });


        setProdListWithBom(tempData);

        const allValidated = tempData.data.every(item => item.errStatus === false);
        console.log("allValidated", allValidated);
        return allValidated;
    }


    function submit() {
        const allOK = validateErr();
        if (!allOK) {
            console.log("asdasd");
            return
        } else {

            const base_data = [...unConsolidated_prodListWithBom.data.map((val, ind) => {
                if (ind == 0) {
                    val.selected = true;
                } else {
                    val.selected = false;
                }
                // delete val.bom;
                return val
            })];

            const stepTwoData = {
                consolidate: consolidate,
                ignoreQty: ignoreQty,
                prodListWithBom: prodListWithBom,
                unConsolidated_prodListWithBom: unConsolidated_prodListWithBom,
                subprodListWithBom: subprodListWithBom,
                base_data: base_data,
            }
            console.log("stepTwoData", stepTwoData);

            dispatch(updateStepTwo(stepTwoData));
            // localStorage.setItem("StepTwo", JSON.stringify(stepTwoData));
            nextStep();

        }
    }

    return (
        <React.Fragment>
            {modalData.state &&
                <CreateBom
                    modalData={modalData}
                    setModalData={setModalData}
                    preparingBomList={preparingBomList}
                    consolidate={consolidate}
                />
            }

            {visible &&
                <AddProduct
                    visible={visible}
                    setVisible={setVisible}
                    fetchSubBomItems={fetchSubBomItems}
                    ignoreQty={ignoreQty}
                    subprodListWithBom={subprodListWithBom}
                />
            }

            <div className='stepTwo'>

                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>


                            <div className='col-12 col-md-6'>
                                <div className='row mt-4'>
                                    <div className='d-flex'>
                                        <p className='mb-2 mt-1' id='pro_plan_label'>Selected Sales Order's Products</p>

                                    </div>

                                   
                                    {StepOneSavedData.selectedSalesOrder?.map((item, ind) => (
                                        <span
                                            key={ind}
                                            className="badge rounded-pill text-dark badge-outline text-center col-md-2 border me-2 mb-2"
                                            style={{ display: "inline-block" }}
                                        >
                                            {item.so_id}
                                        </span>
                                    ))}


                                    <div className='col-12 mt-2'>
                                        <label><input
                                            className="form-check-input me-2 mb-2"
                                            type="checkbox"
                                            checked={consolidate}
                                            disabled={prodListWithBom.data.length == 0}
                                            onChange={(e) => {
                                                setConsolidate(!consolidate);
                                                preparingBomList(!consolidate);
                                            }}
                                        />Consolidate items</label>

                                        {
                                            prodListWithBom.loading ?
                                                <div className="text-center">
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                                :
                                                prodListWithBom.data.length > 0 ? (
                                                    // <div className='mt-2 ' style={{ maxHeight: '50vh', overflowX: "auto" }}>
                                                    <div className='mt-2 '>
                                                        {/* <div className="table-responsive"> */}
                                                        <table className="table table-bordered">
                                                            <thead className="table-grey roboto-bold">
                                                                <tr className="">

                                                                    <th scope="col">{"Sr.No"}</th>


                                                                    <th scope="col">
                                                                        {constLabel?.lbl_product
                                                                            ? constLabel.lbl_product
                                                                            : "Product"}
                                                                    </th>

                                                                    <th scope="col">
                                                                        {constLabel?.lbl_bom
                                                                            ? constLabel.lbl_bom
                                                                            : "BOM"}
                                                                    </th>

                                                                    <th scope="col">
                                                                        {constLabel?.lbl_sales_order_qty
                                                                            ? constLabel.lbl_sales_order_qty
                                                                            : "Sales Order Qty"}
                                                                    </th>


                                                                </tr>
                                                            </thead>
                                                            <tbody className="roboto">
                                                                {prodListWithBom.data.map((val, ind) => (
                                                                    <tr key={ind}>


                                                                        <td>{parseInt(ind) + 1}</td>

                                                                        <td>
                                                                            {val.productName !== null
                                                                                ? val.productName
                                                                                : "NA"}

                                                                            <div>
                                                                                <p style={{
                                                                                    color: "blue",
                                                                                    cursor: "pointer"
                                                                                }} onClick={() => {
                                                                                    setModalData({
                                                                                        ...modalData,
                                                                                        state: true,
                                                                                        data: val,
                                                                                        ind: ind
                                                                                    })
                                                                                }}>
                                                                                    <u>Create BOM</u></p>
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            {/* {val.bom !== null
                                                                                    ? val.bom.bomId
                                                                                    : "NA"} */}
                                                                            <div className='form-group typeahead-container'>

                                                                                <Typeahead
                                                                                    id={"basic-typeahead-single_" + ind}
                                                                                    labelKey="bom_name"
                                                                                    size="sm"
                                                                                    multiple={false}
                                                                                    options={val.bom}
                                                                                    loading={prodListWithBom.loading}
                                                                                    placeholder="Select BOM"
                                                                                    onChange={(selected) => {
                                                                                        console.log("selected", selected);
                                                                                        const temp = [...prodListWithBom.data];
                                                                                        temp[ind].selected_bom = selected;

                                                                                        if (selected.length == 0) {
                                                                                            temp[ind].errStatus = true
                                                                                        } else {
                                                                                            temp[ind].errStatus = false
                                                                                        }



                                                                                        setProdListWithBom({
                                                                                            ...prodListWithBom,
                                                                                            data: temp, loading: false
                                                                                        });

                                                                                        const temp_unConsolidated_prodListWithBom =
                                                                                            [...unConsolidated_prodListWithBom.data];

                                                                                        if (consolidate == true) {

                                                                                            temp_unConsolidated_prodListWithBom.filter(
                                                                                                (item) => item.uuid == val.uuid);

                                                                                            temp_unConsolidated_prodListWithBom.forEach((item2) => {
                                                                                                item2.selected_bom = selected;
                                                                                            })
                                                                                            setunConsolidated_prodListWithBom({
                                                                                                ...unConsolidated_prodListWithBom,
                                                                                                loading: false, data: temp_unConsolidated_prodListWithBom
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    isInvalid={val.errStatus}
                                                                                    selected={prodListWithBom.data[ind].selected_bom}
                                                                                // selected={prodListWithBom.data[ind].selected_bom}
                                                                                />

                                                                                {!prodListWithBom.loading && !val.errStatus && <span className="arrow-icon-2"></span>}
                                                                                {val.errStatus == true &&
                                                                                    <span className='text-danger err-msg'>Required</span>}
                                                                            </div>

                                                                        </td>


                                                                        <td>
                                                                            {val.bom !== null
                                                                                ? val.so_item_quantity
                                                                                : "NA"}
                                                                        </td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        {/* </div> */}
                                                    </div>
                                                ) :
                                                    < div
                                                        className="alert alert-danger mb-0 text-center my-4"
                                                        role="alert"
                                                    >
                                                        BOM not found for any product from sales orders !
                                                    </div>
                                        }
                                    </div>

                                    <div className='col-12 d-flex justify-content-end mt-3'>

                                        <button
                                            disabled={subprodListWithBom.loading == true || prodListWithBom.data.length == 0}
                                            onClick={() => fetchSubBomItems(ignoreQty, "button")}
                                            className='btn button-primary'>Get BOM Items
                                            <img className='ms-2' src={refresh}
                                                alt="refresh" id='refresh-icon-img' /></button>
                                    </div>
                                </div>

                            </div>



                            <div className='col-12 col-md-6'>
                                <div className='row mt-4'>
                                    <div className='d-flex'>
                                        <p className='mb-2 mt-1' id='pro_plan_label'>BOM Sub Items</p>

                                    </div>

                                    <div className='d-md-flex justify-content-between align-items-center'>
                                        <label><input
                                            className="form-check-input me-2 mb-2"
                                            type="checkbox"
                                            checked={ignoreQty}
                                            disabled={prodListWithBom.data.length == 0}
                                            onChange={(e) => {
                                                setIgnoreQty(!ignoreQty);
                                                fetchSubBomItems(!ignoreQty);

                                            }}
                                        />Ignore Existing Available Quantity</label>

                                        <button
                                            disabled={subprodListWithBom.data.length == 0}
                                            onClick={() => setVisible({ state: true, data: [...visible.data] })}
                                            className='btn btn-sm button-primary px-3'>Add Product</button>
                                    </div>

                                    <div>
                                        <i><p className="my-2 mb-0"><span className="text-danger roboto-bold">Note - </span>
                                            Only selected BOM's items will be displayed here.</p></i>
                                    </div>




                                    <div className='col-12'>

                                        {
                                            subprodListWithBom.loading ?
                                                null
                                                :
                                                subprodListWithBom.data.length > 0 ? (
                                                    <div className='mt-2 '>
                                                        <div className='mt-2' id="bom_item_table_parent"
                                                            style={{ maxHeight: '50vh', overflowX: "auto" }}>
                                                            {/* <div className="table-responsive"> */}
                                                            <table className="table table-bordered">
                                                                <thead className="table-grey roboto-bold">
                                                                    <tr className="">


                                                                        <th scope="col">{"Sr.No"}</th>


                                                                        <th scope="col">
                                                                            {constLabel?.lbl_product
                                                                                ? constLabel.lbl_product
                                                                                : "Product"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_bom
                                                                                ? constLabel.lbl_bom
                                                                                : "BOM"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_required_qty
                                                                                ? constLabel.lbl_required_qty
                                                                                : "Required Qty"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_active_po_qty
                                                                                ? constLabel.lbl_active_po_qty
                                                                                : "Active PO Qty"}
                                                                        </th>

                                                                        {/* <th scope="col">
                                                                            {constLabel?.lbl_last_po_date
                                                                                ? constLabel.lbl_last_po_date
                                                                                : "Last PO Date"}
                                                                        </th> */}

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_total_available_qty
                                                                                ? constLabel.lbl_total_available_qty
                                                                                : "Total Available Qty"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_plan_to_request_qty
                                                                                ? constLabel.lbl_plan_to_request_qty
                                                                                : "Plan To Request Qty"}
                                                                        </th>

                                                                        <th scope="col">
                                                                            {constLabel?.lbl_action
                                                                                ? constLabel.lbl_action
                                                                                : "Action"}
                                                                        </th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody className="roboto">
                                                                    {subprodListWithBom.data.map((val, ind) => (
                                                                        <tr key={ind}>


                                                                            <td>{parseInt(ind) + 1}</td>

                                                                            <td>
                                                                                {val.lineItemDetails.productname !== null
                                                                                    ? val.lineItemDetails.productname
                                                                                    : "NA"}
                                                                            </td>

                                                                            <td>
                                                                                {val.bom_name ?? "NA"}
                                                                            </td>

                                                                            <td>
                                                                                {val.lineItemDetails?.qtyRequiredAsPerBom ?? "NA"}
                                                                            </td>

                                                                            <td>
                                                                                {val.lineItemDetails?.active_po_qty ?? "NA"}
                                                                            </td>

                                                                            {/* <td>
                                                                                {formatDateString(val.lineItemDetails?.created_at) ?? "NA"}
                                                                            </td> */}

                                                                            <td>
                                                                                {val.lineItemDetails?.availqty ?? "NA"}
                                                                            </td>

                                                                            <td>
                                                                                {val.lineItemDetails?.planToRequestQty ?? "NA"}
                                                                            </td>

                                                                            <td>
                                                                                <Tooltip id={"delete-tooltip"} place="top" />
                                                                                <Trash2
                                                                                    data-tooltip-id={"delete-tooltip"}
                                                                                    data-tooltip-content={
                                                                                        constLabel?.lbl_delete_product
                                                                                            ? constLabel.lbl_delete_product
                                                                                            : "Delete Product"
                                                                                    }
                                                                                    className="menu_icon_red cursor_pointer"
                                                                                    size={20}
                                                                                    onClick={() => {
                                                                                        SweetAlert.fire({
                                                                                            title: "Are you sure to delete product ? \n",
                                                                                            // text: "Once deleted, you will not be able to recover this record !",
                                                                                            icon: "warning",
                                                                                            showCancelButton: true,
                                                                                            confirmButtonText: "Ok",
                                                                                            cancelButtonText: "cancel",
                                                                                            reverseButtons: true,
                                                                                        }).then((result) => {
                                                                                            if (result.value) {
                                                                                                const tempData = [...subprodListWithBom.data];
                                                                                                tempData.splice(ind, 1);

                                                                                                const product_index = visible.data.findIndex((item) => item.lineItemDetails.product_id
                                                                                                    == val.lineItemDetails.product_id);

                                                                                                const tempVisible = [...visible.data];
                                                                                                if (product_index !== -1) {
                                                                                                    tempVisible.splice(product_index, 1);

                                                                                                    console.log("tempVisible", tempVisible);
                                                                                                }
                                                                                                console.log("tempData", tempData);
                                                                                                setVisible({ ...visible, loading: false, data: tempVisible });

                                                                                                setSubProdListWithBom({ loading: false, data: tempData });
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                ) :
                                                    subprodListWithBom.firstTime && !subprodListWithBom.loading &&
                                                    < div
                                                        className="alert alert-danger mb-0 text-center my-4"
                                                        role="alert"
                                                    >
                                                        BOM Items not found !
                                                    </div>
                                        }


                                        {disableBtn.state &&
                                            < div
                                                className="alert alert-danger mb-0 text-center my-4"
                                                role="alert"
                                            >
                                                {disableBtn.msg}
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>





                            <div className='d-flex justify-content-end mt-3'>
                                <button className='btn btn-grey px-4'
                                    onClick={() => prevStep()}
                                >Previous</button>

                                <button className='btn button-primary px-4 ms-0 ms-md-2'
                                    disabled={subprodListWithBom.data.length == 0 || disableBtn.state}
                                    onClick={() => submit()}
                                >Next</button>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default StepTwo