import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import routes from "../../Routes/routes";

function GenericGrid() {
  let location = useLocation();
  let navigate = useNavigate();

  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    const result = location.state?.screens.map((item1) => {
      const matchingItem = routes.find(
        (item2) => item2.name == item1.screen_name
      );

      return {
        ...item1,
        route: matchingItem ? matchingItem.path : "",
      };
    });
    // console.log("resutl",result)
    result?.sort((a, b) => a.screen_name.localeCompare(b.screen_name));
    // console.log("result", result);
    setTiles(result);
  }, []);

  return (
    <React.Fragment>
      <div className="mb-4 master_screen">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button className="btn btn-yellow" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {"Masters"}
            </p>
          </div>
          <div className="card-body" id="ccard">
            <div className="row">
              {tiles?.map((val, ind) => (
                <div className="col-md-3 mt-2" key={ind}>
                  <Link to={val.route} className="no-decor">
                    <Card className="text-center shadow dark">
                      <Card.Body>
                        <Card.Title className="mb-0">
                          {val.screen_name}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GenericGrid;
