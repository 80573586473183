// import React, { useEffect } from "react";

import { screenList } from "../common/Labels/screenList";

//const Home = React.lazy(() => import("../Pages/Action"));
// import PartyType from '../Pages//PartyType/PartyType'
import Dashboard from "../Components/Dashboard/Dashboard";
import Customer from "../Components/Masters/Customer/Customer/Customer"
import ViewCustomer from "../Components/Masters/Customer/Customer/ViewCustomer";
import CustomerType from "../Components/Masters/Customer/Type/CustomerType";
import Supplier from "../Components/Masters/Supplier/Supplier/Supplier"
import ViewSupplier from "../Components/Masters/Supplier/Supplier/ViewSupplier";
import NC from "../Components/NC/NC";
import CreateNc from "../Components/NC/CreateNc";
import EditNC2 from "../Components/NC/EditNC2";
import Product from "../Components/Masters/Product/Product";
import OperationCategory from "../Components/Masters/Operations/Category/OperationCategory";
import Operation from "../Components/Masters/Operations/Operation/Operation";
import MachineCategory from "../Components/Masters/Machine/Category/MachineCategory";
import MachineAsset from "../Components/Masters/Machine/MachineAsset/MachineAsset";
import ManfGoodForm from "../Components/Masters/Product/Forms/ManfGoodForm";
import NCReason from "../Components/Masters/NCReason/NCReason";
import NCStatus from "../Components/Masters/NCStatus/NCStatus";
import LocationType from "../Components/Masters/Location/Type/LocationType";
import Location from "../Components/Masters/Location/Location/Location";
import LocationMapping from "../Components/Masters/Location/Mapping/LocationMapping";
import PackagingMaterial from "../Components/Masters/Dispatch/PackagingMaterial";
import ShiftMaster from "../Components/Masters/Shift/ShiftMaster";
import ChallanType from "../Components/Masters/ChallanType/ChallanType";
import SupplierType from "../Components/Masters/Supplier/Type/SupplierType";
import MastersMenu from "../Components/Dashboard/MastersMenu";
// import CreateCustomer from "../Components/Masters/Customer/Customer/CreateCustomer";
// import User from "../Components/Masters/UserMaster/User";
// import RawMaterialConversion from "../Components/Purchase/RMI/RawMaterialConversion";
import AppUsers from "../Components/UserManagement/Users/AppUsers";
import ScreenAccess from "../Components/UserManagement/Users/ScreenAccess";
import Modules from "../Components/Masters/Modules/Modules";
import Roles from "../Components/Masters/Roles/Roles";
import CreateRole from "../Components/Masters/Roles/CreateRole";
import UpdateRole from "../Components/Masters/Roles/UpdateRole";
import ViewProduct from "../Components/Masters/Product/ViewProduct";
import NCDashboard from "../Components/NC/NCDashboard";
import GoodsChallan from "../Components/GoodsChallan/GoodsChallan";
import CreateGoodsChallan from "../Components/GoodsChallan/CreateGoodsChallan";
import ProductConfigure from "../Components/Masters/Product/ProductConfigurator/ProductConfigure";
import GenericGrid from "../Components/Common/GenericGrid";
import EditProductConfigure from "../Components/Masters/Product/ProductConfigurator/EditProductConfigure";
import Bom from "../Components/Masters/BOM/Bom";
import CreateBom from "../Components/Masters/BOM/CreateBom";
import UpdateBom from "../Components/Masters/BOM/UpdateBom";
import Suppliers360 from "../Components/Masters/Supplier_360_View/Suppliers360";
import Quotation from "../Components/Masters/Quotation/Quotation"
import CreateQuotation from "../Components/Masters/Quotation/CreateQuotation";
import ViewRawMaterial from "../Components/Masters/Product/ProductForms/ViewRawMaterial";
import ViewSemiFinishGoods from "../Components/Masters/Product/ProductForms/ViewSemiFinishGoods";
import ViewQuotation from "../Components/Masters/Quotation/ViewQuotation";
import EditQuotation from "../Components/Masters/Quotation/EditQuotation";
import ViewQuotationTemp2 from "../Components/Masters/Quotation/ViewQuotationTemp2";
import PurchaseOrd from "../Components/Masters/PurchaseOrder/PurchaseOrd";
import CreatePurchaseOrd from "../Components/Masters/PurchaseOrder/CreatePurchaseOrd";
import UpdatePurchaseOrd from "../Components/Masters/PurchaseOrder/UpdatePurchaseOrd";
import PurchaseOrderPDF from "../Components/Masters/PurchaseOrder/PurchaseOrderPDF";
import CreateMachine from "../Components/Masters/Machine/MachineAsset/CreateMachine";
import EditMachine from "../Components/Masters/Machine/MachineAsset/EditMachine";
import TaskMaster from "../Components/Masters/Task/TaskMaster";
import TaskCategory from "../Components/Masters/Task Category/TaskCategory";
import CreateTaskMaster from "../Components/Masters/Task/CreateTaskMaster";
import EditTaskMaster from "../Components/Masters/Task/EditTaskMaster";
import Inventory from "../Components/Masters/Inventory/Inventory";
import PurchaseInv from "../Components/Masters/PurchaseInward/PurchaseInv";
import ViewPODetails from "../Components/Masters/PurchaseOrder/ViewPODetails";
import GSTTreatment from "../Components/Masters/GSTTreatment/GSTTreatment";
// import InternalCompany from "../Components/Masters/Internal Company/InternalCompany";
// import CreateInternalCompany from "../Components/Masters/Internal Company/CreateInternalCompany";
// import UpdateInternalCompany from "../Components/Masters/Internal Company/UpdateInternalComany";
// import ViewInternalCompany from "../Components/Masters/Internal Company/ViewInternalCoimpanty";
import GSTSlab from "../Components/Masters/GSTSlab/GSTSlab";
import CompanyType from "../Components/Masters/CompanyType/CompanyType";
import OrganisationType from "../Components/Masters/OrganisationType/OrganisationType";
import StoreType from "../Components/Masters/StoreType/StoreType";
import StoreMaster from "../Components/Masters/Store/StoreMaster";
import CreatePurchaseInv from "../Components/Masters/PurchaseInward/CreatePurchaseInv";
import POInwardItems from "../Components/Masters/PurchaseInward/POInwardItems";
import TransactionMaster from "../Components/Masters/Transaction Master/TransactionMaster";
import Organisation from "../Components/Masters/Orgnizations/Orgnizations";
import CreatedOrgnization from "../Components/Masters/Orgnizations/CreatedOrgnization";
import UpdateOrgnization from "../Components/Masters/Orgnizations/UpdateOrgnization";
import ViewOrgization from "../Components/Masters/Orgnizations/ViewOrgization";
// import HierarchyView from "../Components/Masters/Orgnizations/HierarchyView";
import EditPurchaseInv from "../Components/Masters/PurchaseInward/EditPurchaseInv";
import StatusMaster from "../Components/Masters/Status/StatusMaster";
import MovementMaster from "../Components/Masters/MovementMaster/MovementMaster";
import Manufacturing from "../Components/Masters/Manufacturing/Manufacturing";
import SalesOrder from "../Components/Sales/SalesOrder";
import CreateSalesOrder from "../Components/Sales/CreateSalesOrder";
import EditSalesOrder from "../Components/Sales/EditSalesOrder";
// import Customer360 from "../Components/Masters/Customer_360_View/Customer360";
import ViewSODetails from "../Components/Sales/ViewSODetails";
import Intercom from "../Components/Masters/InternalCompany-NewDesign/InterCom";
import CloneProduct from "../Components/Masters/Product/CloneProduct/CloneProduct";
import NewCustomer from "../Components/Masters/Customer/Customer/NewCustomer";
import NewEditCustomer from "../Components/Masters/Customer/Customer/NewEditCustomer";
import NewCustomer360 from "../Components/Masters/Customer_360_View/NewCustomer.360";
import ProductConfigurator from "../Components/Masters/ProductNew/ProductConfigurator";
import EditProductConfigurator from "../Components/Masters/ProductNew/EditProductConfigurator";
import CloneProductConfigurator from "../Components/Masters/ProductNew/CloneProductConfigurator";
import WorkOrder from "../Components/Masters/Manufacturing/WorkOrder";
import CreateWorkOrder from "../Components/Masters/Manufacturing/CreateWorkOrder";
import EditWorkOrder from "../Components/Masters/Manufacturing/EditWorkOrder";
import { JobCard } from "../Components/Masters/Job Card/JobCard";
import { CreateJobCard } from "../Components/Masters/Job Card/CreateJobCard";
import { UpdateJobCard } from "../Components/Masters/Job Card/UpdateJobCard";
import ProductionData from "../Components/Production/ProductionData";
import CreateProductionData from "../Components/Production/CreateProductionData";
import EditProductionData from "../Components/Production/EditProductionData";
import ViewProductionData from "../Components/Production/ViewProductionData";
import DocumentType from "../Components/Masters/DocumentType/DocumentType";
import EmployeeMaster from "../Components/Masters/EmployeeMaster/EmployeeMaster";
import CreateEmp from "../Components/Masters/EmployeeMaster/CreateEmp";
import ProductionDashboard from "../Components/Production/ProductionDashboard";
import ProductFamily from "../Components/Masters/ProductFamily/ProductFamily";
import ProductCategory from "../Components/Masters/ProductCategory/ProductCategory";
import JobCardBoard from "../Components/JobCardBoard/JobCardBoard";
import ToolTypeFamily from "../Components/Masters/Tooltypefamily/ToolTypeFamily";
import ToolSubType from "../Components/Masters/Tool Sub-Type/ToolSubType";
import ToolGrade from "../Components/Masters/Tool Grade/ToolGrade";
import RMI from "../Components/Masters/PurchaseInward/RMI/RMI";
import RawMaterialConversion from "../Components/Masters/PurchaseInward/BarConversion/RawMaterialConversion";
import ViewToolsFixture from "../Components/Masters/ProductNew/ViewToolsFixture";
import MachineMQTT from "../Components/MQTT/MachineMQTT";
import Cutlot from "../Components/Masters/Cutlot/Cutlot";
import EditGoodsChallan from "../Components/GoodsChallan/EditGoodsChallan";
import Create_Work_Order from "../Components/Masters/Manufacturing/Create_Work_Order";
import Edit_Work_Order from "../Components/Masters/Manufacturing/Edit_Work_Order";
import InspectionType from "../Components/Masters/Inspection_Type/InspectionType";
import Create_Job_Card from "../Components/Masters/Job Card/Create_Job_Card";
import Update_Job_Card from "../Components/Masters/Job Card/Update_Job_Card";
import GoodsChallanPDF from "../Components/GoodsChallan/GoodsChallanPDF";
import StockAvailability from "../Components/Reports/StockAvailability";
import StockLedger from "../Components/Reports/StockLedger";
import Dispatch from "../Components/Masters/Dispatch/DispatchMaster/Dispatch";
import Create_Dispatch from "../Components/Masters/Dispatch/DispatchMaster/Create_Dispatch";
import Update_Dispatch from "../Components/Masters/Dispatch/DispatchMaster/Update_Dispatch";
import NewSupplier from "../Components/Masters/Supplier/Supplier/NewSupplier";
import NewEditSupplier from "../Components/Masters/Supplier/Supplier/NewEditSupplier";
import HSNMaster from "../Components/Masters/HSN Master/HSNMaster";
import GoodsChallanWorkOrderView from "../Components/GoodsChallan/GoodsChallanWorkOrderView";
import CorrectiveAction from "../Components/Masters/CorrectiveAction/CorrectiveAction";
import PreventiveAction from "../Components/Masters/PreventiveAction/PreventiveAction";
import MaterialType from "../Components/Masters/MaterialType/MaterialType";
import ViewGeneralItem from "../Components/Masters/ProductNew/ViewGeneralItem";
import Setting from "../Components/Setting/Setting";
import ProductionPlanning from "../Components/Masters/ProductionPlanning/ProductionPlanning";
import Create_Update_Pro_Plan from "../Components/Masters/ProductionPlanning/Create_Update_Pro_Plan";
import AdjustmentReason from "../Components/Masters/Adjustment Reason/AdjustmentReason";
import AdminDashboard from "../Components/Dashboard/AdminDashboard";
import MaterialRequest from "../Components/Masters/Material Request/MaterialRequest";
import Global_Routing from "../Components/Masters/GlobalRoutine/Global_Routing";
import SetMasters from "../Components/Masters/OnBoarding/SetMasters";
import VendorType from "../Components/Masters/Vendor Type/VendorType";
import IndustryType from "../Components/Masters/Industry Type/IndustryType";
import EmpAttendance from "../Components/Masters/EmployeeMaster/EmpAttendance";
import SalesOrderPDF from "../Components/Sales/SalesOrderPDF";
import AttributeMaster from "../Components/Masters/AttributeMaster/AttributeMaster";
import SalesOrderMobilePdf from "../Components/Sales/SalesOrderMoiblePdf";
import SalesOrderStatus from "../Components/Masters/SalesOrderStatus/SalesOrderStatus";
import Designation from "../Components/Masters/Designation/Designation";

const routes = [
    { path: `${process.env.PUBLIC_URL}/pcms/dashboard`, prefix: screenList.generic_screen, access: "view", name: "pcmsDashboard", element: Dashboard },
    { path: `${process.env.PUBLIC_URL}/pcms/adminDashboard`, prefix: screenList.generic_screen, access: "view", name: "adminDashboard", element: AdminDashboard },
    { path: `${process.env.PUBLIC_URL}/pcms/masterMenu`, prefix: screenList.generic_screen, access: "view", name: "masterMenu", element: MastersMenu },


    { path: `${process.env.PUBLIC_URL}/pcms/customer`, prefix: screenList.customer_screen, access: "view", name: "Customer", element: Customer, screenForMaster: "Customer" },
    // { path: `${process.env.PUBLIC_URL}/pcms/addCustomer`, prefix: screenList.customer_screen, access: "write", name: "addCustomer", element: CreateCustomer },
    { path: `${process.env.PUBLIC_URL}/pcms/addCustomer`, prefix: screenList.customer_screen, access: "write", name: "addCustomer", element: NewCustomer, screenForMaster: "Customer" },
    { path: `${process.env.PUBLIC_URL}/pcms/neweditCustomer`, prefix: screenList.customer_screen, access: "write", name: "addCustomer", element: NewEditCustomer, screenForMaster: "Customer" },
    { path: `${process.env.PUBLIC_URL}/pcms/customerDetails`, prefix: screenList.customer_screen, access: "view", name: "customerDetails", element: ViewCustomer, screenForMaster: "Customer" },
    { path: `${process.env.PUBLIC_URL}/pcms/customerType`, prefix: screenList.customer_type_screen, access: "view", name: "Customer Type", element: CustomerType, screenForMaster: "Customer Type" },
    // { path: `${process.env.PUBLIC_URL}/pcms/customer360`, prefix: screenList.customer360_screen, access: "view", name: "customerManagement", element: Customer360, screenForMaster: "Customer" },
    { path: `${process.env.PUBLIC_URL}/pcms/customer360`, prefix: screenList.customer360_screen, access: "view", name: "customerManagement", element: NewCustomer360, screenForMaster: "Customer360" },


    { path: `${process.env.PUBLIC_URL}/pcms/supplier`, prefix: screenList.supplier_screen, access: "view", name: "Supplier", element: Supplier, screenForMaster: "Supplier" },
    // { path: `${process.env.PUBLIC_URL}/pcms/supplierDetails/:partyCode`, prefix: screenList.supplier_screen, access: "view", name: "supplierDetails", element: ViewSupplier },
    { path: `${process.env.PUBLIC_URL}/pcms/addSupplier`, prefix: screenList.supplier_screen, access: "write", name: "addSupplier", element: NewSupplier, screenForMaster: "Supplier" },
    { path: `${process.env.PUBLIC_URL}/pcms/editSupplier`, prefix: screenList.supplier_screen, access: "write", name: "editSupplier", element: NewEditSupplier, screenForMaster: "Supplier" },
    { path: `${process.env.PUBLIC_URL}/pcms/supplierDetails`, prefix: screenList.supplier_screen, access: "view", name: "supplierDetails", element: ViewSupplier, screenForMaster: "Supplier" },
    { path: `${process.env.PUBLIC_URL}/pcms/supplierType`, prefix: screenList.supplier_type_screen, access: "view", name: "Supplier Type", element: SupplierType, screenForMaster: "Supplier Type" },
    { path: `${process.env.PUBLIC_URL}/pcms/supplier360`, prefix: screenList.supplier360_screen, access: "view", name: "Supplier 360", element: Suppliers360, screenForMaster: "Supplier360" },


    { path: `${process.env.PUBLIC_URL}/pcms/operationCategory`, prefix: screenList.operation_category_screen, access: "view", name: "Operation Category", element: OperationCategory, screenForMaster: "Operation Category" },
    { path: `${process.env.PUBLIC_URL}/pcms/operation`, prefix: screenList.operation_screen, access: "view", name: "Operation", element: Operation, screenForMaster: "Operation" },


    { path: `${process.env.PUBLIC_URL}/pcms/machineCategory`, name: "Machine Category", element: MachineCategory, access: "write", prefix: screenList.machine_category_screen, screenForMaster: "Machine Category" },
    { path: `${process.env.PUBLIC_URL}/pcms/createMachine`, name: "createMachine", element: CreateMachine, access: "write", prefix: screenList.machine_screen, screenForMaster: "Machine" },
    { path: `${process.env.PUBLIC_URL}/pcms/editMachine`, name: "editMachine", element: EditMachine, access: "write", prefix: screenList.machine_screen, screenForMaster: "Machine" },
    { path: `${process.env.PUBLIC_URL}/pcms/machineAsset`, name: "Machine", element: MachineAsset, access: "write", prefix: screenList.machine_screen, screenForMaster: "Machine" },


    { path: `${process.env.PUBLIC_URL}/pcms/ncReason`, name: "NC Reason", element: NCReason, access: "write", prefix: screenList.nc_reason_screen, screenForMaster: "NC Reason" },
    { path: `${process.env.PUBLIC_URL}/pcms/ncStatus`, name: "Disposal Action", element: NCStatus, access: "write", prefix: screenList.disposal_action_screen, screenForMaster: "Disposal Action" },


    { path: `${process.env.PUBLIC_URL}/pcms/locationType`, prefix: screenList.location_type_screen, access: "view", name: "Location Type", element: LocationType, screenForMaster: 'Location Type' },
    { path: `${process.env.PUBLIC_URL}/pcms/location`, prefix: screenList.location_screen, access: "view", name: "Location", element: Location, screenForMaster: 'Location' },
    { path: `${process.env.PUBLIC_URL}/pcms/locationMapping`, prefix: screenList.location_mapping_screen, access: "view", name: "Location Mapping", element: LocationMapping, screenForMaster: 'Location Mapping' },


    { path: `${process.env.PUBLIC_URL}/pcms/packagingMaterial`, access: "write", name: "Packaging Material", element: PackagingMaterial, screenForMaster: "Packaging Material" },
    { path: `${process.env.PUBLIC_URL}/pcms/shiftMaster`, prefix: screenList.shift_master_screen, access: "write", name: "Shift Master", element: ShiftMaster, screenForMaster: "Shift Master" },
    { path: `${process.env.PUBLIC_URL}/pcms/challanType`, access: "write", prefix: screenList.challan_type_screen, name: "Challan Type", element: ChallanType, screenForMaster: "Challan Type" },

    // { path: `${process.env.PUBLIC_URL}/pcms/rmConversion`, access: "write", name: "rmConversion", element: RawMaterialConversion, screenForMaster: "Raw Material Inward" },

    { path: `${process.env.PUBLIC_URL}/pcms/product`, prefix: screenList.product_screen, access: "view", name: "Product", element: Product, screenForMaster: "Product" },
    { path: `${process.env.PUBLIC_URL}/pcms/viewProduct`, prefix: screenList.product_screen, access: "view", name: "viewProduct", element: ViewProduct, screenForMaster: "Product" },
    { path: `${process.env.PUBLIC_URL}/pcms/manufacturing-goods/`, name: "mg", element: ManfGoodForm, access: "view", screenForMaster: "Manufacture Goods" },
    { path: `${process.env.PUBLIC_URL}/pcms/productConfigure`, prefix: screenList.product_screen, access: "write", name: "productConfigure", element: ProductConfigure, screenForMaster: "Product" },
    { path: `${process.env.PUBLIC_URL}/pcms/editProductConfigure`, prefix: screenList.product_screen, access: "write", name: "editProductConfigure", element: EditProductConfigure, screenForMaster: "Product" },
    { path: `${process.env.PUBLIC_URL}/pcms/viewRawMaterial`, prefix: screenList.product_screen, access: "view", name: "viewRawMaterial", element: ViewRawMaterial, screenForMaster: "Product" },
    { path: `${process.env.PUBLIC_URL}/pcms/viewSemiFinishGoods`, prefix: screenList.product_screen, access: "view", name: "viewSemiFinishGoods", element: ViewSemiFinishGoods, screenForMaster: "Product" },
    { path: `${process.env.PUBLIC_URL}/pcms/viewToolsFixture`, prefix: screenList.product_screen, access: "view", name: "viewToolsFixture", element: ViewToolsFixture, screenForMaster: "Product" },
    { path: `${process.env.PUBLIC_URL}/pcms/viewGeneralItem`, prefix: screenList.product_screen, access: "view", name: "viewGeneralItem", element: ViewGeneralItem, screenForMaster: "Product" },

    // Product configurator screen revamp route
    { path: `${process.env.PUBLIC_URL}/pcms/productConfigurator`, prefix: screenList.product_screen, access: "write", name: "productConfigurator", element: ProductConfigurator, screenForMaster: "Product" },
    { path: `${process.env.PUBLIC_URL}/pcms/editProductConfigurator`, prefix: screenList.product_screen, access: "write", name: "editProductConfigurator", element: EditProductConfigurator, screenForMaster: "Product" },
    { path: `${process.env.PUBLIC_URL}/pcms/cloneProductConfigurator`, prefix: screenList.product_screen, access: "write", name: "cloneProductConfigurator", element: CloneProductConfigurator, screenForMaster: "Product" },

    { path: `${process.env.PUBLIC_URL}/pcms/ProductConfigure/clone`, prefix: screenList.product_screen, access: "write", name: "cloneProductConfigure", element: CloneProduct, screenForMaster: "Product" },

    { path: `${process.env.PUBLIC_URL}/pcms/appUsers`, prefix: screenList.user_screen, access: "view", name: "User Management", element: AppUsers, screenForMaster: "User Management" },
    { path: `${process.env.PUBLIC_URL}/pcms/appUsers/screen_access`, prefix: screenList.user_screen, access: "view", name: "Screen Access", element: ScreenAccess, screenForMaster: "Screen Access" },

    { path: `${process.env.PUBLIC_URL}/pcms/nc`, prefix: screenList.nc_screen, name: "User Management", access: "view", element: NC, screenForMaster: "Quality" },
    { path: `${process.env.PUBLIC_URL}/pcms/nc-create`, prefix: screenList.nc_screen, access: "write", name: "CreateUser", element: CreateNc, screenForMaster: "Quality" },
    { path: `${process.env.PUBLIC_URL}/pcms/nc-update`, prefix: screenList.nc_screen, access: "write", name: "UpdateUser", element: EditNC2, screenForMaster: "Quality" },
    { path: `${process.env.PUBLIC_URL}/pcms/nc-dashboard`, prefix: screenList.quality_dashbaord_screen, access: "view", name: "ViewUser", element: NCDashboard, screenForMaster: "Quality Dashboard" },


    { path: `${process.env.PUBLIC_URL}/pcms/modules`, prefix: screenList.modules_screen, access: "view", name: "Modules", element: Modules, screenForMaster: "Modules" },


    { path: `${process.env.PUBLIC_URL}/pcms/roles`, prefix: screenList.role_screen, access: "view", name: "Roles", element: Roles, screenForMaster: "Roles" },
    { path: `${process.env.PUBLIC_URL}/pcms/createrole`, prefix: screenList.role_screen, access: "write", name: "createRole", element: CreateRole, screenForMaster: "Roles" },
    { path: `${process.env.PUBLIC_URL}/pcms/updaterole`, prefix: screenList.role_screen, access: "write", name: "updateRole", element: UpdateRole, screenForMaster: "Roles" },

    { path: `${process.env.PUBLIC_URL}/pcms/grid-menu`, prefix: "Generic", access: "view", name: "GenericGrid", element: GenericGrid },
    { path: `${process.env.PUBLIC_URL}/pcms/bill-of-material`, prefix: screenList.bom_screen, access: "view", name: "Bill of Material", element: Bom, screenForMaster: "Bill of Material" },
    { path: `${process.env.PUBLIC_URL}/pcms/create/bill-of-material`, prefix: screenList.bom_screen, access: "write", name: "Bill of Material", element: CreateBom, screenForMaster: "Bill of Material" },
    { path: `${process.env.PUBLIC_URL}/pcms/update/bill-of-material`, prefix: screenList.bom_screen, access: "write", name: "Bill of Material", element: UpdateBom, screenForMaster: "Bill of Material" },

    { path: `${process.env.PUBLIC_URL}/pcms/quotation`, prefix: screenList.supplier_quotation_screen, access: "view", name: "Quotation", element: Quotation, screenForMaster: "Supplier Quotation" },
    { path: `${process.env.PUBLIC_URL}/pcms/createQuotation`, prefix: screenList.supplier_quotation_screen, access: "write", name: "createQuotation", element: CreateQuotation, screenForMaster: "Supplier Quotation" },
    { path: `${process.env.PUBLIC_URL}/pcms/editQuotation`, prefix: screenList.supplier_quotation_screen, access: "write", name: "editQuotation", element: EditQuotation, screenForMaster: "Supplier Quotation" },
    { path: `${process.env.PUBLIC_URL}/pcms/viewQuotation`, prefix: screenList.supplier_quotation_screen, access: "view", name: "viewQuotation", element: ViewQuotation, screenForMaster: "Supplier Quotation" },
    {
        path: `${process.env.PUBLIC_URL}/pcms/viewQuotation2`, prefix: screenList.supplier_quotation_screen, access: "view",
        name: "viewQuotation", element: ViewQuotationTemp2, screenForMaster: "Quotation"
    },

    { path: `${process.env.PUBLIC_URL}/pcms/purchase-order`, prefix: screenList.purchase_order_screen, access: "view", name: "Purchase Order", element: PurchaseOrd, screenForMaster: "Purchase Order" },
    { path: `${process.env.PUBLIC_URL}/pcms/create/purchase-order/:quoteId/:supplierId`, prefix: screenList.purchase_order_screen, name: "purchaseOrder", access: "write", element: CreatePurchaseOrd, screenForMaster: "Purchase Order" },
    { path: `${process.env.PUBLIC_URL}/pcms/update/purchase-order`, prefix: screenList.purchase_order_screen, name: "Create Purchase Order", access: "write", element: UpdatePurchaseOrd, screenForMaster: "Purchase Order" },
    { path: `${process.env.PUBLIC_URL}/pcms/view/purchase-order`, prefix: screenList.purchase_order_screen, name: "View Purchase Order", access: "view", element: ViewPODetails, screenForMaster: "Purchase Order" },

    {
        path: `${process.env.PUBLIC_URL}/pcms/view/purchase-order-pdf/:id`, prefix: screenList.purchase_order_screen, access: "view",
        name: "viewPurchaseOrder", element: PurchaseOrderPDF
    },

    { path: `${process.env.PUBLIC_URL}/pcms/task-category`, prefix: screenList.task_category_screen, access: "view", name: "Task Category", element: TaskCategory, screenForMaster: "Task Category" },
    { path: `${process.env.PUBLIC_URL}/pcms/taskMaster`, prefix: screenList.task_screen, name: "Task Master", access: "write", element: TaskMaster, screenForMaster: "Task Master" },
    { path: `${process.env.PUBLIC_URL}/pcms/createTask`, prefix: screenList.task_screen, name: "createTask", access: "write", element: CreateTaskMaster, screenForMaster: "Task Master" },
    { path: `${process.env.PUBLIC_URL}/pcms/editTask`, prefix: screenList.task_screen, name: "editTask", access: "write", element: EditTaskMaster, screenForMaster: "Task Master" },

    { path: `${process.env.PUBLIC_URL}/pcms/inventoryMaster`, prefix: screenList.inventory_screen, name: "Inventory Management", access: "view", element: Inventory, screenForMaster: "Inventory Management" },
    { path: `${process.env.PUBLIC_URL}/pcms/createInvenotry`, prefix: screenList.inventory_screen, name: "createInvenotry", access: "write", element: Inventory, screenForMaster: "Inventory Management" },
    { path: `${process.env.PUBLIC_URL}/pcms/editInvenotry`, prefix: screenList.inventory_screen, name: "editInvenotry", access: "write", element: Inventory, screenForMaster: "Inventory Management" },

    { path: `${process.env.PUBLIC_URL}/pcms/purchaseInward`, prefix: screenList.purchase_inward_screen, name: "Purchase Inward", access: "view", element: PurchaseInv, screenForMaster: "Purchase Inward" },
    { path: `${process.env.PUBLIC_URL}/pcms/createPurchaseInward`, prefix: screenList.purchase_inward_screen, name: "createPurchaseInward", access: "write", element: CreatePurchaseInv, screenForMaster: "Purchase Inward" },
    { path: `${process.env.PUBLIC_URL}/pcms/editPurchaseInward`, prefix: screenList.purchase_inward_screen, name: "editPurchaseInward", access: "write", element: EditPurchaseInv, screenForMaster: "Purchase Inward" },
    { path: `${process.env.PUBLIC_URL}/pcms/poInwardItems/:id`, prefix: screenList.purchase_inward_screen, name: "poInwardItems", access: "write", element: POInwardItems, screenForMaster: "Purchase Inward" },

    { path: `${process.env.PUBLIC_URL}/pcms/gstTreatment`, prefix: screenList.gst_treatment_screen, name: "GST Registration Type", access: "write", element: GSTTreatment, screenForMaster: "GST Registration Type" },


    // { path: `${process.env.PUBLIC_URL}/pcms/internalCompany`, name: "Internal Company", access: "write", element: InternalCompany, screenForMaster: "Internal Company" },
    // { path: `${process.env.PUBLIC_URL}/pcms/internalCompany/create`, name: "Internal Company", access: "write", element: CreateInternalCompany, screenForMaster: "Internal Company" },
    // { path: `${process.env.PUBLIC_URL}/pcms/internalCompany/update`, name: "Internal Company", access: "write", element: UpdateInternalCompany, screenForMaster: "Internal Company" },
    // { path: `${process.env.PUBLIC_URL}/pcms/internalCompany/view`, name: "Internal Company", access: "write", element: ViewInternalCompany, screenForMaster: "Internal Company" },

    { path: `${process.env.PUBLIC_URL}/pcms/orgnization`, prefix: screenList.organization_screen, name: "Organization", access: "write", element: Organisation, screenForMaster: "Organization" },
    { path: `${process.env.PUBLIC_URL}/pcms/orgnization/create`, prefix: screenList.organization_screen, name: "Create Organization", access: "write", element: CreatedOrgnization, screenForMaster: "Organization" },
    { path: `${process.env.PUBLIC_URL}/pcms/orgnization/update`, prefix: screenList.organization_screen, name: "Update Organization", access: "write", element: UpdateOrgnization, screenForMaster: "Organization" },
    { path: `${process.env.PUBLIC_URL}/pcms/orgnization/view`, prefix: screenList.organization_screen, name: "View Organization", access: "write", element: ViewOrgization, screenForMaster: "Organization" },
    // { path: `${process.env.PUBLIC_URL}/pcms/orgnization/hierarchyview`, prefix: screenList.organization_screen, name: "Internal Company", access: "view", element: HierarchyView, screenForMaster: "Internal Company" },

    { path: `${process.env.PUBLIC_URL}/pcms/orgnization/hierarchy-view`, prefix: screenList.organization_screen, name: "Organization", access: "write", element: Intercom, screenForMaster: "Organization" },

    { path: `${process.env.PUBLIC_URL}/pcms/gstSlab`, prefix: screenList.gst_slab_screen, name: "GST Slab", access: "write", element: GSTSlab, screenForMaster: "GST Slab" },

    { path: `${process.env.PUBLIC_URL}/pcms/companyType`, prefix: screenList.company_type_screen, name: "Company Type", access: "write", element: CompanyType, screenForMaster: "Company Type" },
    { path: `${process.env.PUBLIC_URL}/pcms/orgnisationType`, prefix: screenList.organization_type_screen, name: "Organisation Type", access: "write", element: OrganisationType, screenForMaster: "Organisation Type" },

    { path: `${process.env.PUBLIC_URL}/pcms/storeType`, prefix: screenList.store_type_screen, name: "Store Type", access: "write", element: StoreType, screenForMaster: "Store Type" },
    { path: `${process.env.PUBLIC_URL}/pcms/storeMaster`, prefix: screenList.store_screen, name: "Store", access: "write", element: StoreMaster, screenForMaster: "Store" },
    { path: `${process.env.PUBLIC_URL}/pcms/statusMaster`, prefix: screenList.status_screen, name: "statusMaster", access: "write", element: StatusMaster, screenForMaster: "Status Master" },


    { path: `${process.env.PUBLIC_URL}/pcms/transactionMaster`, prefix: screenList.transaction_screen, name: "Transaction Master", access: "write", element: TransactionMaster, screenForMaster: "Transaction Master" },
    { path: `${process.env.PUBLIC_URL}/pcms/movementMaster`, prefix: screenList.movement_screen, name: "Movement Master", access: "write", element: MovementMaster, screenForMaster: "Movement Master" },

    { path: `${process.env.PUBLIC_URL}/pcms/manufacturing`, prefix: screenList.manufacture_screen, name: "Manufacturing", access: "write", element: Manufacturing, screenForMaster: "Manufacturing" },
    { path: `${process.env.PUBLIC_URL}/pcms/work-order`, prefix: screenList.work_order, name: "Work Order", access: "view", element: WorkOrder, screenForMaster: "Work Order" },
    // { path: `${process.env.PUBLIC_URL}/pcms/create/work-order`, prefix: screenList.work_order, name: "Create Work Order", access: "write", element: CreateWorkOrder, screenForMaster: "Work Order" },
    // { path: `${process.env.PUBLIC_URL}/pcms/update/work-order`, prefix: screenList.work_order, name: "Update Work Order", access: "write", element: EditWorkOrder, screenForMaster: "Work Order" },

    { path: `${process.env.PUBLIC_URL}/pcms/create-work-order`, prefix: screenList.work_order, name: "Create Work Order", access: "write", element: Create_Work_Order, screenForMaster: "Work Order" },
    { path: `${process.env.PUBLIC_URL}/pcms/update-work-order`, prefix: screenList.work_order, name: "Update Work Order", access: "write", element: Edit_Work_Order, screenForMaster: "Work Order" },


    //Sales order 
    { path: `${process.env.PUBLIC_URL}/pcms/sales-order`, prefix: screenList.sales_order_screen, access: "view", name: "Sales Order", element: SalesOrder, screenForMaster: "Sales Order" },
    { path: `${process.env.PUBLIC_URL}/pcms/create/sales-order`, prefix: screenList.sales_order_screen, name: "Create Sales Order", access: "write", element: CreateSalesOrder, screenForMaster: "Sales Order" },
    { path: `${process.env.PUBLIC_URL}/pcms/update/sales-order`, prefix: screenList.sales_order_screen, name: "Update Sales Order", access: "write", element: EditSalesOrder, screenForMaster: "Sales Order" },
    { path: `${process.env.PUBLIC_URL}/pcms/view/sales-order/:id`, prefix: screenList.sales_order_screen, name: "View Sales Order", access: "view", element: ViewSODetails, screenForMaster: "Sales Order" },
    { path: `${process.env.PUBLIC_URL}/pcms/sales-order-pdf`, prefix: screenList.sales_order_screen, name: "Sales Order PDF", access: "view", element: SalesOrderPDF, screenForMaster: "Sales Order" },
    { path: `${process.env.PUBLIC_URL}/pcms/sales-mobile-view-pdf`, prefix: screenList.sales_order_screen, name: "Sales Order PDF", access: "view", element: SalesOrderMobilePdf, screenForMaster: "Sales Order" },

    //Job Card 
    { path: `${process.env.PUBLIC_URL}/pcms/job-card`, prefix: screenList.job_screen, access: "view", name: "Job Card", element: JobCard, screenForMaster: "Job Card" },
    { path: `${process.env.PUBLIC_URL}/pcms/create/job-card`, prefix: screenList.job_screen, access: "view", name: "Create Job Card", element: CreateJobCard, screenForMaster: "Job Card" },
    { path: `${process.env.PUBLIC_URL}/pcms/update/job-card`, prefix: screenList.job_screen, access: "view", name: "Update Job Card", element: UpdateJobCard, screenForMaster: "Job Card" },

    { path: `${process.env.PUBLIC_URL}/pcms/create-job-card`, prefix: screenList.job_screen, access: "view", name: "Create Job Card", element: Create_Job_Card, screenForMaster: "Job Card" },
    { path: `${process.env.PUBLIC_URL}/pcms/update-job-card`, prefix: screenList.job_screen, access: "view", name: "Update Job Card", element: Update_Job_Card, screenForMaster: "Job Card" },

    //Production Data
    { path: `${process.env.PUBLIC_URL}/pcms/production-data`, prefix: screenList.production_data_screen, access: "view", name: "Production Data", element: ProductionData, screenForMaster: "Production Data" },
    { path: `${process.env.PUBLIC_URL}/pcms/create/production-data`, prefix: screenList.production_data_screen, access: "write", name: "Production Data", element: CreateProductionData, screenForMaster: "Production Data" },
    { path: `${process.env.PUBLIC_URL}/pcms/update/production-data`, prefix: screenList.production_data_screen, access: "write", name: "Production Data", element: EditProductionData, screenForMaster: "Production Data" },
    { path: `${process.env.PUBLIC_URL}/pcms/production-dashboard`, prefix: screenList.production_dashboard_screen, access: "view", name: "Production Dashboard", element: ProductionDashboard, screenForMaster: "Production Dashboard" },
    {
        path: `${process.env.PUBLIC_URL}/pcms/view-production-data/:id`,
        prefix: screenList.production_data_screen, access: "view", element: ViewProductionData, screenForMaster: "Production Data View"
    },

    // Masters
    { path: `${process.env.PUBLIC_URL}/pcms/document_type`, prefix: screenList.document_type, access: "view", name: "Document Type", element: DocumentType, screenForMaster: "Document Type" },


    // Employee master
    { path: `${process.env.PUBLIC_URL}/pcms/employee_list`, prefix: screenList.employee_master, access: "view", name: "Employee Master", element: EmployeeMaster, screenForMaster: "Employee Master" },
    { path: `${process.env.PUBLIC_URL}/pcms/employee`, prefix: screenList.employee_master, access: "write", name: "Create Employee Master", element: CreateEmp, screenForMaster: "Employee Master" },
    { path: `${process.env.PUBLIC_URL}/pcms/employeeAttendance`, prefix: screenList.employee_master, access: "write", name: "Employee Attendance", element: EmpAttendance, screenForMaster: "Employee Attendance" },


    // Product Family Master
    { path: `${process.env.PUBLIC_URL}/pcms/product-family`, prefix: screenList.product_family_screen, name: "Product Family", access: "write", element: ProductFamily, screenForMaster: "Product Family" },

    // Product Category Master
    { path: `${process.env.PUBLIC_URL}/pcms/product-category`, prefix: screenList.product_category_screen, name: "Product Category", access: "write", element: ProductCategory, screenForMaster: "Product Category" },

    // Job Card Board
    { path: `${process.env.PUBLIC_URL}/pcms/job-card-board`, prefix: screenList.job_card_board_screen, name: "Job Card Board", access: "write", element: JobCardBoard, screenForMaster: "Job Card Board" },

    // Tool type Family 
    { path: `${process.env.PUBLIC_URL}/pcms/tool-type`, prefix: screenList.tool_type_screen, name: "Tool Type", access: "write", element: ToolTypeFamily, screenForMaster: "Tool Type" },

    //Tool Sub Type 
    { path: `${process.env.PUBLIC_URL}/pcms/tool-sub-type`, prefix: screenList.tool_sub_type_screen, name: "Tool Sub-Type", access: "write", element: ToolSubType, screenForMaster: "Tool Sub-Type" },

    //Tool Grade
    { path: `${process.env.PUBLIC_URL}/pcms/tool-grade`, prefix: screenList.tool_grade, name: "Tool Grade", access: "write", element: ToolGrade, screenForMaster: "Tool Grade" },

    //RMI
    { path: `${process.env.PUBLIC_URL}/pcms/rmi`, prefix: screenList.rmi_screen, name: "RMI", access: "write", element: RMI, screenForMaster: "RMI" },
    { path: `${process.env.PUBLIC_URL}/pcms/rmi-conversion`, prefix: screenList.rmi_screen, access: "write", name: "RMI Conversion", element: RawMaterialConversion, screenForMaster: "Raw Material Conversion" },

    { path: `${process.env.PUBLIC_URL}/pcms/machineMQTT`, prefix: screenList.machine_dashboard_screen, access: "write", name: "Machine Dashboard", element: MachineMQTT, screenForMaster: "Machine Dashboard" },


    { path: `${process.env.PUBLIC_URL}/pcms/cutlot`, prefix: screenList.cutlot_screen, access: "view", name: "Cut Lot", element: Cutlot, screenForMaster: "Cutlot" },

    // Inventory
    { path: `${process.env.PUBLIC_URL}/pcms/goodsMovementChallan`, prefix: screenList.goods_challan_screen, access: "view", name: "Goods Challan", element: GoodsChallan, screenForMaster: "Goods Challan" },
    { path: `${process.env.PUBLIC_URL}/pcms/createGoodsChallan`, prefix: screenList.goods_challan_screen, access: "write", name: "Goods Challan", element: CreateGoodsChallan, screenForMaster: "Goods Challan" },
    { path: `${process.env.PUBLIC_URL}/pcms/editGoodsChallan`, prefix: screenList.goods_challan_screen, access: "write", name: "Goods Challan", element: EditGoodsChallan, screenForMaster: "Goods Challan" },
    {
        path: `${process.env.PUBLIC_URL}/pcms/view/goodsChallan-challan-pdf`, prefix: screenList.goods_challan_screen, access: "view", name: "viewGoodsChallan", element: GoodsChallanPDF, screenForMaster: "Goods Challan"
    },
    { path: `${process.env.PUBLIC_URL}/pcms/goodschallan-work-order-view`, prefix: screenList.goods_challan_work_order_view, name: "Goods Challan Work Order View", access: "write", element: GoodsChallanWorkOrderView, screenForMaster: "Goods Challan Work Order View" },


    //Stock awailability
    { path: `${process.env.PUBLIC_URL}/pcms/report/stock-availability`, prefix: screenList.stock_availability_screen, access: "write", name: "Stock Availability", element: StockAvailability, screenForMaster: "Stock Availability" },
    { path: `${process.env.PUBLIC_URL}/pcms/report/stock-ledger`, prefix: screenList.stock_ledger_screen, access: "write", name: "Stock Ledger", element: StockLedger, screenForMaster: "Stock Ledger" },

    // Inspection Type
    { path: `${process.env.PUBLIC_URL}/pcms/inspectionType`, prefix: screenList.inspection_type_screen, access: "write", name: "Inspection Type", element: InspectionType, screenForMaster: "Inspection Type" },

    // Dispatch
    { path: `${process.env.PUBLIC_URL}/pcms/dispatch`, prefix: screenList.dispatch_screen, access: "write", name: "Dispatch", element: Dispatch, screenForMaster: "Dispatch" },
    { path: `${process.env.PUBLIC_URL}/pcms/create-dispatch`, prefix: screenList.dispatch_screen, access: "write", name: "Dispatch", element: Create_Dispatch, screenForMaster: "Dispatch" },
    { path: `${process.env.PUBLIC_URL}/pcms/update-dispatch`, prefix: screenList.dispatch_screen, access: "write", name: "Dispatch", element: Update_Dispatch, screenForMaster: "Dispatch" },

    // HSN Master
    { path: `${process.env.PUBLIC_URL}/pcms/hsn-master`, prefix: screenList.hsn_master_screen, name: "HSN Master", access: "write", element: HSNMaster, screenForMaster: "HSN Master" },

    // Corrective Action
    { path: `${process.env.PUBLIC_URL}/pcms/corrective_action`, prefix: screenList.corrective_action_screen, name: "Corrective Action", access: "write", element: CorrectiveAction, screenForMaster: "Corrective Action" },

    //Preventive Action
    { path: `${process.env.PUBLIC_URL}/pcms/preventive_action`, prefix: screenList.preventive_action_screen, name: "Preventive Action", access: "write", element: PreventiveAction, screenForMaster: "Preventive Action" },

    //Material Type
    { path: `${process.env.PUBLIC_URL}/pcms/material_type`, prefix: screenList.material_type_screen, name: "Material Type", access: "write", element: MaterialType, screenForMaster: "Material Type" },

    //Setting Page
    { path: `${process.env.PUBLIC_URL}/pcms/setting`, prefix: screenList.setting_screen, name: "Setting", access: "write", element: Setting, screenForMaster: "Setting" },


    //Planning Page
    {
        path: `${process.env.PUBLIC_URL}/pcms/production_planning`, prefix: screenList.production_planning_screen,
        name: "Production Planning", access: "write", element: ProductionPlanning, screenForMaster: "Production Planning"
    },

    {
        path: `${process.env.PUBLIC_URL}/pcms/save/production_planning`, prefix: screenList.production_planning_screen,
        name: "Production Planning", access: "write", element: Create_Update_Pro_Plan, screenForMaster: "Production Planning"
    },

    {
        path: `${process.env.PUBLIC_URL}/pcms/update/production_planning`, prefix: screenList.production_planning_screen,
        name: "Production Planning", access: "write", element: Create_Update_Pro_Plan, screenForMaster: "Production Planning"
    },

    //Adjustmet Reason
    { path: `${process.env.PUBLIC_URL}/pcms/adjutment-reason`, prefix: screenList.adjustment_reason_screen, name: "Adjustment Reason", access: "write", element: AdjustmentReason, screenForMaster: "Adjustment Reason" },

    //Material Request 
    { path: `${process.env.PUBLIC_URL}/pcms/material-request`, prefix: screenList.material_request_screen, access: "view", name: "Material Request", element: MaterialRequest, screenForMaster: "Material Request" },

    //Global Routing Master
    { path: `${process.env.PUBLIC_URL}/pcms/global/routing`, prefix: screenList.routing_master_screen, access: "view", name: "Global Routing", element: Global_Routing, screenForMaster: "Global Routing" },

    //Onboarding Set Masters
    { path: `${process.env.PUBLIC_URL}/onboarding/setMasters`, access: "view", name: "Set Masters", element: SetMasters, screenForMaster: "Set Masters" },

     //Vendor Type Master
     { path: `${process.env.PUBLIC_URL}/pcms/vendor-type`, prefix: screenList.vendor_type_screen, name: "Vendor Type", access: "write", element: VendorType, screenForMaster: "Vendor Type" },
     //Industry Type
     { path: `${process.env.PUBLIC_URL}/pcms/industry-type`, prefix: screenList.industry_type_screen, name: "Industry Type", access: "write", element: IndustryType, screenForMaster: "Industry Type" },
    //Break Code 
     { path: `${process.env.PUBLIC_URL}/pcms/break-code`, prefix: screenList.break_code_screen, name: "Break Code", access: "write", element: AdjustmentReason, screenForMaster: "Break Code" },
     
    // Attribute Master
     { path: `${process.env.PUBLIC_URL}/pcms/attribute-master`, prefix: screenList.attribute_master_screen, name: "Attribute Master", access: "write", element: AttributeMaster, screenForMaster: "Attribute Master" },
    
    // Sales Orde Status
     { path: `${process.env.PUBLIC_URL}/pcms/sales-order-status`, prefix: screenList.sales_order_status_screen, name: "Sales Order Status", access: "write", element: SalesOrderStatus, screenForMaster: "Attribute Master" },
     
    // Designation
     { path: `${process.env.PUBLIC_URL}/pcms/designation`, prefix: screenList.designation_screen, name: "Designation", access: "write", element: Designation, screenForMaster: "Designation" },


];

export default routes;