import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  update_store,
  get_store_type,
  getInternalCompany,
  getLocation,
  getCustomer,
  get_store,
} from "../../../api";
import { custom_name } from "../../../common/Labels/labelConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Store name is required!"),
    store_type: yup
      .array()
      .min(1, "Please select store type !")
      .required("Please select store type !"),
    location: yup
      .array()
      .min(1, "Please select location !")
      .required("Please select location !"),
    root_store: yup
      .array()
      .notRequired()
    // party: yup
    //   .array()
    //   .min(1, "Please select party !")
    //   .required("Please select party !"),
    // internal_company: yup
    //   .array()
    //   .min(1, "Please select organisation !")
    //   .required("Please select organisation !"),
  })
  .required();

function SingleBarcode(props) {
  const [visible, setVisible] = useState(false);
  

  const { register, handleSubmit, formState, control, reset, watch, setValue, setError } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;
 

  useEffect(() => {
    if (visible == true) {
      console.log(props);
      
    }
  }, [visible]);

  const onSubmit = (data) => {

  }


  return (
    <React.Fragment>
      <Tooltip id={"barcode-tooltip"} place="top" />
      <FontAwesomeIcon
        className="cursor_pointer"
        data-tooltip-id={"barcode-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_generate_barcode
            ? props.constLabel.lbl_generate_barcode
            : "Generate Barcode"
        }
        icon={faBarcode}
        size="lg"
        onClick={() => {
          setVisible(true);
        }}
      />
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_generate_barcode
              ? props.constLabel.lbl_generate_barcode
              : "Generate Barcode"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
 
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SingleBarcode;
