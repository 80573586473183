import React, { useState, useEffect } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import CalenderView from "./CalenderView";

function Create_Update_Pro_Plan() {
  const [constLabel, setConstLabel] = useState({});
  let navigate = useNavigate();
  let location = useLocation();

  const { prev_data, so_list } = location.state || {};

  const [steps, setSteps] = useState([
    { label: "Configure Plan", completed: false },
    { label: "Select Product", completed: false },
    { label: "Create Material Request", completed: false },
    // { label: 'Step 4', completed: false },
  ]);

  const [currentStep, setCurrentStep] = useState(0);
  const [skipStep, setSkipStep] = useState(0);

  const [layout, setLayout] = useState('calender');

  useEffect(() => {
    console.log("location", location);
    // console.log("so_list", so_list);

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // This is required for the browser to show the confirmation dialog
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const nextStep = () => {
    const StepOneData = JSON.parse(localStorage.getItem("StepOne"));

    console.log("currentStep", currentStep);
    console.log("steps", steps);

    if (currentStep == 3) {
      steps[currentStep - 1].completed = true;
    } else {
      steps[currentStep].completed = true;
    }

    setSteps(steps);

    if (!StepOneData.material_planning && StepOneData.resource_planning) {
      if (
        !StepOneData.material_planning &&
        StepOneData.resource_planning &&
        currentStep == 1
      ) {
        setCurrentStep((prevStep) => Math.min(prevStep + 1 + 1, steps.length));
      } else {
        setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length));
      }
    } else {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    }
  };

  const prevStep = () => {
    const StepOneData = JSON.parse(localStorage.getItem("StepOne"));

    if (currentStep == 4) {
      steps[currentStep - 1].completed = false;
    } else {
      steps[currentStep].completed = false;
    }
    setSteps(steps);

    if (!StepOneData.material_planning && StepOneData.resource_planning) {
      if (
        !StepOneData.material_planning &&
        StepOneData.resource_planning &&
        currentStep == 3
      ) {
        setCurrentStep((prevStep) => Math.min(prevStep - 1 - 1, steps.length));
      } else {
        setCurrentStep((prevStep) => Math.min(prevStep - 1, steps.length));
      }
    } else {
      setCurrentStep((prevStep) => Math.min(prevStep - 1, steps.length - 1));
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="min-vh-100" id="root_div_main">
        <div className="content-wrapper">
          <div className="card border-0 " id="production_planning">
            <div className="card-body">
              <div className="d-md-flex justify-content-start col-12 col-md-6">
                <h1 className="bold">
                  {prev_data
                    ? constLabel?.update_production_plan
                      ? constLabel.update_production_plan
                      : `Update Production Plan`
                    : constLabel?.lbl_create_production_plan
                      ? constLabel.lbl_create_production_plan
                      : `Create Production Plan`}
                </h1>
              </div>

              <div className="p-2">
                <div className="card-body p-0">

                  <div className="d-md-flex justify-content-between align-items-center">
                    <div>
                      <div className="stepper-container">

                        {steps.map((step, index) => (
                          <div key={index} className="step">
                            <div
                              className={`circle ${index <
                                (index > 1 ? currentStep - skipStep : currentStep)
                                ? "completed"
                                : ""
                                }`}
                            >
                              {index > 1
                                ? index < currentStep - skipStep
                                  ? "✔"
                                  : index + 1
                                : index < currentStep
                                  ? "✔"
                                  : index + 1}
                            </div>

                            <div className="label">{step.label}</div>
                            {index < steps.length - 1 && (
                              <div className="line"></div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    {currentStep == 3 &&
                      <div>
                        <ul class="list-group layout">
                          <li class="list-group-item px-4">
                            <div className="form-check">
                              <input className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="calender"
                                value="calender"
                                checked={layout == "calender"}
                                onChange={(e) => setLayout("calender")}
                              />
                              <label className="form-check-label" for="calender">
                                Calender View
                              </label>
                            </div>

                          </li>
                          <li class="list-group-item px-4">
                            <div className="form-check">
                              <input className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="regular"
                                value="regular"
                                checked={layout == "regular"}
                                onChange={(e) => setLayout("regular")}
                              />
                              <label className="form-check-label" for="regular">
                                Regular View
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    }
                  </div>

                  {currentStep == 0 ? (
                    <StepOne
                      nextStep={nextStep}
                      prevStep={prevStep}
                      setSteps={setSteps}
                      steps={steps}
                      prev_data={prev_data}
                      setSkipStep={setSkipStep}
                      so_list={so_list}
                    />
                  ) : currentStep == 1 ? (
                    <StepTwo nextStep={nextStep} prevStep={prevStep} />
                  ) : currentStep == 2 ? (
                    <StepThree
                      nextStep={nextStep}
                      prevStep={prevStep}
                      prev_data={prev_data}
                    />
                  ) : currentStep == 3 ? (
                    // <StepFourRevise
                    //     nextStep={nextStep}
                    //     prevStep={prevStep}
                    // />
                    layout == "calender" ?
                      <CalenderView nextStep={nextStep} prevStep={prevStep} />
                      :
                      <StepFour nextStep={nextStep} prevStep={prevStep} />
                  ) : currentStep == 4 ? (
                    <StepFive nextStep={nextStep} prevStep={prevStep} />
                  ) : null}

                  {/* <div className='d-flex justify-content-end'>
                                                <button className='btn btn-grey px-4' onClick={() => prevStep()}>Previous</button>
                                                <button className='btn button-primary px-4 ms-0 ms-md-2' onClick={() => nextStep()}>Next</button>
                                            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

export default Create_Update_Pro_Plan;
