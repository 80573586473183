import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCircle,
  faArrowLeft,
  faImage,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import SweetAlert from "sweetalert2";
import { Trash2 } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import {
  getUOM,
  getProdList,
  getProductType,
  getInternalCompany,
  getGST,
  getQuotation,
  master_multi_file_upload,
  getCustomer,
  getSOStatus,
  createSalesOrder,
} from "../../../../api/index";
import {
  convertDateYYMMDD,
  convertDate,
} from "../../../../common/Functions/CommonFunctions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import AddProduct from "../../AddProducts/AddProduct";
import SOImageGallary from "../../../Sales/SOImageGallary";

const schema = yup
  .object()
  .shape({
    organization: yup
      .array()
      .min(1, "Please select organization !")
      .required("Please select organization !"),
    customer: yup
      .array()
      .min(1, "Please select customer !")
      .required("Please select customer !"),
    invoiceTo: yup
      .array()
      .min(1, "Please select company !")
      .required("Please select company !"),
    dispatchTo: yup
      .array()
      .min(1, "Please select company !")
      .required("Please select company !"),
    party_po_ref: yup.string().required("Please enter po reference !"),
    status: yup
      .array()
      .min(1, "Please select status !")
      .required("Please select status !"),
    // remarks: yup
    //   .string()
    //   .required("Please enter remarks !"),
    internalRemarks: yup.string().required("Please enter internal remarks"),
    order_due_date: yup.date().required("Please select date !"),

    items: yup.array().of(
      yup.object().shape({
        prod_type: yup
          .array()
          .min(1, "Please select product type !")
          .required("Please select product type !"),
        product: yup
          .array()
          .min(1, "Please select product !")
          .required("Please select product !"),
        quantity: yup
          .string()
          .required("Quantity is required")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid quantity")
          .typeError("Enter valid quantity"),
        unit: yup
          .array()
          .min(1, "Please select unit !")
          .required("Please select unit !"),
        price: yup
          .string()
          .required("Price is required")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid price")
          .typeError("Enter valid price"),
      })
    ),
    // discount: yup
    //   .string()
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid discount")
    //   .required("Discount is required"),
  })
  .required();

function CreateSO(props) {
  const [visible, setVisible] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const [orgList, setOrgList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [prod_List, setProd_List] = useState({ data: [], loading: false });
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [soStatusList, setSOStatusList] = useState({
    data: [],
    loading: false,
  });

  const [quotationList, setQuotationList] = useState({
    data: [],
    loading: false,
  });
  const [taxDetails, setTaxDetails] = useState({});
  const [usingQuoteProd, setUsingQuoteProd] = useState(false);
  // const [cameFromQuoteTable] = useState(atob(urlParams.quoteId) !== "null" && atob(urlParams.supplierId) !== "null");
  const [gstErr, setGstErr] = useState(false);

  const [galVisible, setGalVisible] = useState(false);
  const [drawingFiles, setDrawingFiles] = useState([]);

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      CGST_amount: 0,
      SGST_amount: 0,
      IGST_amount: 0,
      items: [
        {
          prod_type: [],
          product: [],
          quantity: 0,
          unit: [],
          price: 0,
          amount: 0,
        },
      ],
    },
  });
  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const lineItems = watch("items");
  const org = watch('organization');
  const invTo = watch("invoiceTo");
  const cust = watch("customer");
  const qRef = watch("quoteRef");
  const tempQI = [];

  const [subTotalForRef, setSubtotalForRef] = useState(0);
  let subTotalForRef2 = "";

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
    
    
    
    
    if(visible == true){
      
      setValue('customer', props.selectedCust);
      setTaxDetails({});
      fetchUOM();
      // fetchCustomers();
      fetchProductType();
      fetchSOStatus();
      fetchQuotation();
      fetchOrganizations();
      
    }
  }, [props, visible]);

  useEffect(() => {
    
    calSubAmt();
    // calDiscount(getValues("discount"));
    calDiscount();
  }, [lineItems]);

  useEffect(() => {
    
    if (Object.keys(taxDetails).length > 0) {
      // calDiscount(getValues("discount"));
      calDiscount();
    }
  }, [taxDetails]);

  useEffect(() => {
    
    if(cust !== undefined && cust.length > 0){
      setValue('invoiceTo', cust);
      setValue('dispatchTo', cust); 
    }
  }, [cust])
  

  useEffect(() => {
    
    
    

    if (invTo !== undefined && org !== undefined) {
      if (invTo.length > 0 && org.length > 0) {

        fetchGST({
          suplier_gst_number: org[0].comGSTnumber, //cust[0]?.gst,
          invoice_gst_number: invTo[0]?.gst
        });
      }
    }
  }, [invTo, org]); 

  useEffect(() => {
    
    
    setValue("items", [{}]);
    // setUsingQuoteProd(false);
    setValue("subTotal", 0.0);
    setValue("taxAmt", 0.0);
    setValue("discount", 0);
    setValue("CGST", 0);
    setValue("CGST_amount", 0);
    setValue("SGST", 0);
    setValue("SGST_amount", 0);
    setValue("IGST", 0);
    setValue("IGST_amount", 0);
    setValue("UGST", 0);
    setValue("UGST_amount", 0);
    setTaxDetails({});
  }, [props.custList]);

  function fetchQuotation() {
    setQuotationList({ ...quotationList, data: [], loading: true });

    getQuotation().then(
      (res) => {
        

        // if (atob(urlParams.quoteId) !== "null" && atob(urlParams.supplierId) !== "null") {
        //   const result = res.data.data.filter((val) => {
        //     return val.uuid == atob(urlParams.quoteId)
        //   })

        
        //   setValue('quoteRef', result);
        
        //   setValue('invoiceTo', [result[0]?.quotation_for_id]);

        // }

        setQuotationList({
          ...quotationList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setQuotationList({ ...quotationList, data: [], loading: false });
        
      }
    );
  }

  async function fetchGST(payload) {
    await getGST(payload).then(
      (res) => {
        
        setTaxDetails(res.data);

        const subTot = parseFloat(getValues("subTotal"));

        setValue("SGST_amount", ((subTot * res.data.sgst) / 100).toFixed(2));
        setValue("CGST_amount", ((subTot * res.data.cgst) / 100).toFixed(2));
        setValue("IGST_amount", ((subTot * res.data.igst) / 100).toFixed(2));
        setValue("UGST_amount", ((subTot * res.data.ugst) / 100).toFixed(2));

        setValue("CGST", res.data.cgst);
        setValue("SGST", res.data.sgst);
        setValue("IGST", res.data.igst);
        setValue("UGST", res.data.ugst);

        setGstErr(false);
      },
      (err) => {
        
        toast.error("Error in fetching gst details !", {
          autoClose: 3000,
        });
        setGstErr(true);
      }
    );
  }

  function calSubAmt() {
    const totalAmount = lineItems.reduce(
      (accumulator, item) => parseFloat(accumulator) + parseFloat(item.amount),
      0
    );
    
    setValue("subTotal", totalAmount);
    setSubtotalForRef(parseFloat(totalAmount).toFixed(2));
    subTotalForRef2 = parseFloat(totalAmount);
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProdList(selVal) {
    if (selVal.length > 0) {
      setProd_List({ ...prod_List, loading: true, data: [] });

      getProdList({ product_type_id: selVal[0].uuid }).then(
        (res) => {
          
          setProd_List({ ...prod_List, loading: false, data: res.data.data });
        },
        (err) => {
          
        }
      );
    } else {
    }
  }

  function handleProdType(selVal, index) {
    
    setValue(`items[${index}].prod_type`, selVal);
    setValue(`items[${index}].product`, []);
    if (selVal.length > 0) {
      fetchProdList(selVal);
    } else {
      setProd_List({ ...prod_List, loading: false, data: [] });
    }
  }

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        
        setProdType({ ...prodType, data: res.data.data, loading: false });
      },
      (err) => {
        
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  // function fetchCustomers() {
  //   setCustomerList({ ...customerList, loading: true, data: [] });
  //   getCustomer({partyType: "Customer"}).then(
  //     (res) => {
  
  //       const result = res.data.data.filter(
  //         (item) => item.organization !== null
  //       );
  //       setCustomerList({
  //         ...customerList,
  //         loading: false,
  //         data: result,
  //       });
  //     },
  //     (err) => {
  
  //       setCustomerList({ ...customerList, loading: false, data: [] });
  //       toast.error("Something went wrong in suppliers!", {
  //         autoClose: 3000,
  //       });
  //     }
  //   );
  // }

  function fetchSOStatus() {
    setSOStatusList({ ...soStatusList, data: [], loading: true });
    getSOStatus().then(
      (res) => {
        
        setSOStatusList({
          ...soStatusList,
          data: res.data.data,
          loading: false,
        });
        const result = res.data.filter((item) => item.status_name == "Open");
        
        setValue("status", result);
      },
      (err) => {
        setSOStatusList({ ...soStatusList, data: [], loading: false });
        
      }
    );
  }

  function fetchOrganizations() {
    setOrgList({ ...orgList, data: [], loading: true });

    getInternalCompany().then(
      (res) => {
        
        setOrgList({ ...orgList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setOrgList({ ...orgList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    
    let reqPayload = [];

    for (let i = 0; i < data.items.length; i++) {
      const lineItems = {
        product_type: data.items[i].prod_type[0].productType,
        // product_code: "",
        productname: data.items[i].product[0].productName,
        partnumber: data.items[i].product[0].partNo,
        quantity: parseFloat(data.items[i].quantity),
        rate: data.items[i].price,
        unit: data.items[i].unit[0].uom,
        amount: data.items[i].amount,
        product_type_id: data.items[i].prod_type[0].uuid,
        product_id: data.items[i].product[0].uuid,
        unit_id: data.items[i].unit[0].uuid,
        org_id: data.organization[0].id,
      };

      
      reqPayload.push(lineItems);
    }

    const payload = {
      org_id: data.organization[0].uuid,
      customer_id: data.customer[0].uuid,
      customer_name: data.customer[0].name,
      party_address: data.customer[0].address,
      party_po_ref: data.party_po_ref,
      quote_ref: data.quote_ref,
      // order_date: ""
      order_due_date: convertDateYYMMDD(data.order_due_date, "-"),
      invoice_to: data.invoiceTo[0].uuid,
      invoice_to_address: data.invoiceTo[0].address,
      dispatch_to: data.dispatchTo[0].uuid,
      dispatch_to_address: data.dispatchTo[0].address,
      // discount: data.discount,
      sub_total: parseFloat(data.subTotal),
      final_total: parseFloat(data.totalAmount),
      status: data.status[0].id,
      // so_status: data.status[0].status_name,
      // so_status_id: data.status[0].uuid,
      // remark: data.remarks,
      internal_remark: data.internalRemarks,
    };

    if (taxDetails.type == "sgst") {
      payload.cgst = parseFloat(data.CGST);
      payload.cgst_cost = parseFloat(data.CGST_amount);
      payload.sgst = parseFloat(data.SGST);
      payload.sgst_cost = parseFloat(data.SGST_amount);
    } else if (taxDetails.type == "igst") {
      payload.igst = parseFloat(data.IGST);
      payload.igst_cost = parseFloat(data.IGST_amount);
    } else if (taxDetails.type == "ugst") {
      payload.ugst = parseFloat(data.UGST);
      payload.ugst_cost = parseFloat(data.UGST_amount);
    }

    const payload1 = {
      so_order: payload,
      so_order_lines: reqPayload,
    };
    

    createSalesOrder(payload1).then(
      (res) => {
        
        if (res.status === 200 || res.status === 201) {
          

          for (let i = 0; i < drawingFiles.length; i++) {
            drawingFiles[i].ref_model_id = "";
            drawingFiles[i].ref_model_uuid = res.data?.po?.uuid;
            drawingFiles[i].model_name = "SO";
            drawingFiles[i].name = drawingFiles[i].filename;
            drawingFiles[i].document_type = "image";
          }

          let imgArr = {};
          let formDataToSend = new FormData();
          for (let a = 0; a < drawingFiles.length; a++) {
            imgArr["attachment" + (a + 1)] = drawingFiles[a].file;
            imgArr["obj" + (a + 1)] = JSON.stringify(drawingFiles[a]);
            imgArr["img_count"] = drawingFiles.length;

            for (const [key, value] of Object.entries(imgArr)) {
              formDataToSend.append(key, value);
            }
          }

          

          if (drawingFiles.length > 0) {
            master_multi_file_upload(formDataToSend).then(
              (res) => {
                
                toast.success("Sales order created successfully !", {
                  autoClose: 2000,
                });
                setTimeout(() => {
                  navigate("/pcms/sales-order");
                  reset();
                }, [2000]);
              },
              (err) => {
                
                if (err.response.status == 413) {
                  toast.error("Image upload failed !", {
                    autoClose: 3000,
                  });
                }
              }
            );
          } else {
            toast.success("Sales order created successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/sales-order");
              reset();
            }, [2200]);
          }
        }
      },
      (err) => {
        
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  };

  async function calRate(para1, para2, index) {
    
    
    let rate;

    switch (para2) {
      case "quantity":
        // items[${index}].price
        const price = getValues(`items[${index}].price`);
        
        rate = para1 * price;
        setValue(`items[${index}].amount`, parseFloat(rate).toFixed(2));

        
        await calSubAmt();
        // calDiscount(getValues("discount"));
        calDiscount();

        break;

      case "price":
        const quantity = getValues(`items[${index}].quantity`);
        rate = para1 * quantity;
        setValue(`items[${index}].amount`, parseFloat(rate).toFixed(2));

        await calSubAmt();
        // calDiscount(getValues("discount"));
        calDiscount();
        break;
    }
  }

  async function calDiscount() {
    await calSubAmt();
    let subTotal = parseFloat(getValues("subTotal"));
    let SGST = parseFloat(getValues("SGST"));
    let CGST = parseFloat(getValues("CGST"));
    let IGST = parseFloat(getValues("IGST"));

    
    
    // Discount Calcultaion logic

    if (subTotal < 0) {
      return;
    }

    // const discountAmount = (subTotalForRef2 * val) / 100;
    // const discountedAmount = subTotalForRef2 - discountAmount;
    
    
    // setValue("subTotal", discountedAmount.toFixed(2));

    // setValue("discount_amount", (subTotalForRef2 - discountedAmount).toFixed(2));

    // If discount logic is used also need to change the CGST and SGST on subTotal
    subTotal = parseFloat(getValues("subTotal"));
    let taxAmt = "";
    let taxedAmt = 0;

    
    
    if (taxDetails.type == "sgst") {
      taxAmt = (subTotal * (parseFloat(CGST) + parseFloat(SGST))) / 100;
      taxedAmt = subTotal + taxAmt;
      
      
      
      

      setValue("SGST_amount", ((subTotal * SGST) / 100).toFixed(2));
      setValue("CGST_amount", ((subTotal * CGST) / 100).toFixed(2));
    } else if (taxDetails.type == "igst") {
      taxAmt = (subTotal * taxDetails.igst) / 100;
      taxedAmt = subTotal + taxAmt;

      setValue("IGST_amount", taxAmt.toFixed(2));
    } else if (taxDetails.type == "ugst") {
      taxAmt = (subTotal * taxDetails.ugst) / 100;
      taxedAmt = subTotal + taxAmt;

      setValue("UGST_amount", taxAmt.toFixed(2));
    }

    
    
    
    // setValue("totalAmount", taxedAmt?.toFixed(2));
    if (taxedAmt !== 0) {
      setValue("totalAmount", taxedAmt?.toFixed(2));
    } else {
      setValue("totalAmount", parseFloat(subTotal).toFixed(2));
    }
  }

  function CaltotalAmount(val, para2) {
    let subTotal = parseFloat(getValues("subTotal"));
    let SGST = getValues("SGST");
    let CGST = getValues("CGST");

    
    

    if (para2 == "CGST") {
      const taxAmt = (subTotal * (parseFloat(val) + parseFloat(SGST))) / 100;
      const taxedAmt = subTotal + taxAmt;
      
      setValue("totalAmount", taxedAmt);

      setValue("CGST_amount", ((subTotal * CGST) / 100).toFixed(2));
    }
    if (para2 == "SGST") {
      const taxAmt = (subTotal * (parseFloat(val) + parseFloat(CGST))) / 100;
      const taxedAmt = subTotal + taxAmt;
      
      setValue("totalAmount", taxedAmt);

      setValue("SGST_amount", ((subTotal * SGST) / 100).toFixed(2));
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div>
        <button
          className="btn button-primary btn-sm"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        >
          <FontAwesomeIcon
            style={{ color: "#fff", marginRight: "6px" }}
            icon={faPlus}
            size="sm"
          />
          {"Create Sales Order"}
        </button>
      </div>
      <SOImageGallary
        setGalVisible={setGalVisible}
        galVisible={galVisible}
        setDrawingFiles={setDrawingFiles}
        drawingFiles={drawingFiles}
      />
      <Modal show={visible} onHide={() => {setVisible(false); reset()}} size={"xl"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Create Sales Order"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="purchase_order mb-4">
            <div className="card border-0">
              <div className="card-body">
                <div className="createPO container-fluid">
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    Sales Order Details
                  </p>
                  <hr className="my-2" />

                  <div className="row my-4">
                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        Organization
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`organization`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="comName"
                              size="sm"
                              multiple={false}
                              options={orgList.data}
                              isLoading={orgList.loading}
                              placeholder="Select organization"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.organization ? true : false}
                            />
                          )}
                        />
                        {errors.organization && (
                          <span className="text-danger err-msg">
                            {errors.organization.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        Customer
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`customer`}
                          control={control}
                         //defaultValue={(props.selectedCust && Object.keys(props.selectedCust[0]).length > 0) ? props.selectedCust : []}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="organization"
                              size="sm"
                              multiple={false}
                              options={props.custList}
                              // isLoading={customerList.loading}
                              placeholder="Select customer"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              // disabled={cameFromQuoteTable}
                           //   defaultSelected={(props.selectedCust && Object.keys(props.selectedCust[0]).length > 0) ? props.selectedCust : []}
                              isInvalid={errors.customer ? true : false}
                            />
                          )}
                        />
                        {errors.supplier && (
                          <span className="text-danger err-msg">
                            {errors.supplier.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        Order Due Date{""}
                        <span className="text-danger">
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          control={control}
                          name="order_due_date"
                          defaultValue={new Date()}
                          render={({ field }) => (
                            <DatePicker
                              className={
                                errors.order_due_date
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm "
                              }
                              placeholderText="Select date"
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              defaultValue={new Date()}
                              minDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        />

                        {errors.order_due_date && (
                          <span className="text-danger err-msg">
                            {errors.order_due_date.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        Invoice To
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`invoiceTo`}
                          control={control}
                          // defaultValue={(props.selectedCust && Object.keys(props.selectedCust[0]).length > 0) ? props.selectedCust : []}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="organization"
                              size="sm"
                              multiple={false}
                              options={props.custList}
                              // isLoading={customerList.loading}
                              placeholder="Select company"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              // disabled={cameFromQuoteTable == true}
                              // defaultSelected={(props.selectedCust && Object.keys(props.selectedCust[0]).length > 0) ? props.selectedCust : []}
                              isInvalid={errors.invoiceTo ? true : false}
                            />
                          )}
                        />
                        {errors.invoiceTo && (
                          <span className="text-danger err-msg">
                            {errors.invoiceTo.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        Dispatch To
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`dispatchTo`}
                          control={control}
                          // defaultValue={(props.selectedCust && Object.keys(props.selectedCust[0]).length > 0) ? props.selectedCust : []}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="organization"
                              size="sm"
                              multiple={false}
                              options={props.custList}
                              // isLoading={customerList.loading}
                              placeholder="Select company"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              // defaultSelected={(props.selectedCust && Object.keys(props.selectedCust[0]).length > 0) ? props.selectedCust : []}
                              isInvalid={errors.dispatchTo ? true : false}
                            />
                          )}
                        />
                        {errors.dispatchTo && (
                          <span className="text-danger err-msg">
                            {errors.dispatchTo.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        Party PO Ref.
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.party_po_ref
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          id="party_po_ref"
                          name="party_po_ref"
                          type="text"
                          placeholder="Enter party po reference"
                          {...register("party_po_ref")}
                        />
                        {errors.party_po_ref && (
                          <span className="text-danger err-msg">
                            {errors.party_po_ref.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        Quote Ref.
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.quote_ref
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          id="quote_ref"
                          name="quote_ref"
                          type="text"
                          placeholder="Enter quote reference"
                          {...register("quote_ref")}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        Status
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>
                      <div className="col-12 col-md-9">
                        <Controller
                          name={`status`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="status_name"
                              size="sm"
                              multiple={false}
                              options={soStatusList.data}
                              isLoading={soStatusList.loading}
                              placeholder="Select status"
                              disabled
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.status ? true : false}
                            />
                          )}
                        />
                        {errors.status && (
                          <span className="text-danger err-msg">
                            {errors.status.message}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    Remarks
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <textarea
                      className={errors.remarks ?
                        "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"}
                      id="remarks"
                      name="remarks"
                      placeholder="Enter remarks"
                      {...register("remarks")}
                    >
                    </textarea>
                    {errors.remarks && (
                      <span className="text-danger err-msg">
                        {errors.remarks.message}
                      </span>
                    )}
                  </div>
                </div> */}

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-3"
                      >
                        Internal Remarks
                      </label>

                      <div className="col-12 col-md-9">
                        <textarea
                          className={
                            errors.internalRemarks
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          id="internalRemarks"
                          name="internalRemarks"
                          placeholder="Enter Internal Remarks"
                          {...register("internalRemarks")}
                        ></textarea>
                        {errors.internalRemarks && (
                          <span className="text-danger err-msg">
                            {errors.internalRemarks.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <p className="mt-2 mb-4">
                    <i>
                      {" "}
                      <span className="text-danger roboto-bold">Note: </span>
                      <FontAwesomeIcon
                        style={{
                          color: "#c7c7c7",
                        }}
                        icon={faCircle}
                        size="2xs"
                        className="mx-2"
                      />
                      Internal remarks will not be displayed in report
                    </i>
                    <i className=" d-block">
                      {" "}
                      <span className="text-danger roboto-bold invisible">
                        Note:{" "}
                      </span>
                      <FontAwesomeIcon
                        style={{
                          color: "#c7c7c7",
                        }}
                        icon={faCircle}
                        size="2xs"
                        className="mx-2"
                      />
                      Product type must be selected first then product
                    </i>
                  </p>

                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    Product Details
                  </p>
                  <hr className="my-2" />

                  <div
                    className="table-responsive my-3"
                    style={{ maxHeight: "80vh" }}
                  >
                    <table className="table table-bordered">
                      <thead className="thead-light text-center">
                        <tr>
                          <th scope="col">{"Product Type"}</th>
                          <th scope="col">{"Product"}</th>
                          <th scope="col">{"Quantity"}</th>
                          <th scope="col">{"Unit"}</th>
                          <th scope="col">{"Rate"}</th>
                          <th scope="col">
                            {"Amount "} <span>&#8377;</span>
                          </th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(({ id }, index) => (
                          <tr key={id} className="f-18">
                            <td style={{ verticalAlign: "top" }}>
                              <Controller
                                name={`items[${index}].prod_type`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="productType"
                                    size="sm"
                                    multiple={false}
                                    options={prodType.data}
                                    isLoading={prodType.loading}
                                    placeholder="Select product type"
                                    // onChange={onChange}
                                    onChange={(selected) => {
                                      handleProdType(selected, index);
                                    }}
                                    onBlur={onBlur}
                                    selected={value}
                                    // disabled={usingQuoteProd}
                                    isInvalid={
                                      errors.items?.[index]?.prod_type
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />

                              {errors.items?.[index]?.prod_type && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.prod_type.message}
                                </span>
                              )}

                              {index + 1 == fields.length ? (
                                <div>
                                  <button
                                    style={{ fontSize: "16px" }}
                                    className="btn btn-sm button-primary px-2 mt-2"
                                    onClick={() => {
                                      append({
                                        prod_type: [],
                                        product: [],
                                        quantity: 0,
                                        unit: [],
                                        price: 0,
                                        amount: 0,
                                      });
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        color: "#ffffff",
                                        marginRight: "5px",
                                      }}
                                      icon={faAdd}
                                      size="sm"
                                    />{" "}
                                    Add
                                  </button>
                                </div>
                              ) : null}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <Controller
                                name={`items[${index}].product`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="productName"
                                    size="sm"
                                    multiple={false}
                                    options={prod_List.data}
                                    isLoading={prod_List.loading}
                                    placeholder="Select product"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    // disabled={usingQuoteProd}
                                    isInvalid={
                                      errors.items?.[index]?.product
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />

                              {errors.items?.[index]?.product && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.product.message}
                                </span>
                              )}

                              {/* {index + 1 == fields.length ? (
                                <AddProduct fetchProdList={fetchProdList} />
                              ) : null} */}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.quantity
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                defaultValue={0}
                                name={`items[${index}].quantity`}
                                {...register(`items[${index}].quantity`, {
                                  onChange: (e) =>
                                    calRate(e.target.value, "quantity", index),
                                })}
                                // disabled={usingQuoteProd}
                              />

                              {errors.items?.[index]?.quantity && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.quantity.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <Controller
                                name={`items[${index}].unit`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="uom"
                                    size="sm"
                                    multiple={false}
                                    options={uomList.data}
                                    isLoading={uomList.loading}
                                    placeholder="Select unit"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={
                                      errors.items?.[index]?.unit ? true : false
                                    }
                                    // disabled={usingQuoteProd}
                                  />
                                )}
                              />

                              {errors.items?.[index]?.unit && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.unit.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.price
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                defaultValue={0}
                                min={0}
                                name={`items[${index}].price`}
                                {...register(`items[${index}].price`, {
                                  onChange: (e) =>
                                    calRate(e.target.value, "price", index),
                                })}
                                // disabled={usingQuoteProd}
                              />

                              {errors.items?.[index]?.price && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.price.message}
                                </span>
                              )}
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "10%",
                              }}
                            >
                              <input
                                className={
                                  errors.items?.[index]?.amount
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                defaultValue={0}
                                disabled
                                name={`items[${index}].amount`}
                                {...register(`items[${index}].amount`)}
                              />
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "10%",
                              }}
                            >
                              <Tooltip
                                id={"delete-tooltip" + index}
                                place="top"
                              />
                              {
                                <div
                                  data-tooltip-id={"delete-tooltip" + index}
                                  data-tooltip-content="Delete Product Item"
                                  className="deleteIcon cursor_pointer"
                                  onClick={
                                    index > 0
                                      ? () => {
                                          remove(index);
                                        }
                                      : null
                                  }
                                >
                                  <Trash2 color="#FFFFFF" size={18} />
                                </div>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className="p-3 ms-auto col-12 col-md-6"
                    style={{ border: "1px solid #c6c6c6", borderRadius: "2px" }}
                  >
                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label roboto-bold col-12 col-md-3"
                        >
                          Sub Total <span>&#8377;</span>
                        </label>

                        <div className="col-12 col-md-4 ">
                          <input
                            className="form-control form-control-sm"
                            type="number"
                            value={subTotalForRef}
                            {...register("subTotal")}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      Discount (%)
                    </label>

                    <div className="col-12 col-md-4">
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        {...register("discount", {
                          onChange: (e) => calDiscount(e.target.value)
                        })}
                        max={100}
                        min={0}
                        defaultValue={0}
                        placeholder="Enter discount"
                        disabled={usingQuoteProd}
                      />
                      {errors.discount && (
                        <span className="text-danger err-msg">
                          {errors.discount.message}
                        </span>
                      )}
                    </div>

                    <div className="col-12 col-md-4 d-flex align-items-center">
                      <span>&#8377;</span>
                      <input
                        className="form-control form-control-sm ms-2"
                        type="text"
                        disabled
                        {...register("discount_amount")}
                        defaultValue={0}
                        placeholder="Discounted Amount"
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      Amount after discount <span>&#8377;</span>
                    </label>

                    <div className="col-12 col-md-4 ">
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        {...register("subTotal")}
                        disabled
                      />
                    </div>
                  </div>
                </div> */}

                    <hr />

                    {taxDetails.cgst ? (
                      <div className="row mb-2">
                        <div className="col-12 row ms-auto">
                          <label
                            style={{ fontSize: "14px" }}
                            className="col-form-label roboto-bold col-12 col-md-3"
                          >
                            CGST (%)
                          </label>

                          <div className="col-12 col-md-4">
                            <input
                              className="form-control form-control-sm"
                              type="number"
                              {...register("CGST", {
                                onChange: (e) =>
                                  CaltotalAmount(e.target.value, "CGST"),
                              })}
                              defaultValue={0}
                              placeholder="Enter CGST"
                              disabled
                            />
                            {errors.CGST && (
                              <span className="text-danger err-msg">
                                {errors.CGST.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-4  d-flex align-items-center">
                            <span>&#8377;</span>
                            <input
                              className="form-control form-control-sm ms-2"
                              type="text"
                              disabled
                              defaultValue={0}
                              {...register("CGST_amount")}
                              placeholder="CGST Amount"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {taxDetails.sgst ? (
                      <div className="row mb-2">
                        <div className="col-12 row ms-auto">
                          <label
                            style={{ fontSize: "14px" }}
                            className="col-form-label roboto-bold col-12 col-md-3"
                          >
                            SGST (%)
                          </label>

                          <div className="col-12 col-md-4">
                            <input
                              className="form-control form-control-sm"
                              type="number"
                              defaultValue={0}
                              {...register("SGST", {
                                onChange: (e) =>
                                  CaltotalAmount(e.target.value, "SGST"),
                              })}
                              placeholder="Enter SGST"
                              disabled
                            />
                            {errors.SGST && (
                              <span className="text-danger err-msg">
                                {errors.SGST.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-4 d-flex align-items-center">
                            <span>&#8377;</span>
                            <input
                              className="form-control form-control-sm ms-2"
                              type="text"
                              defaultValue={0}
                              {...register("SGST_amount")}
                              disabled
                              placeholder="SGST Amount"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {taxDetails.igst ? (
                      <div className="row mb-2">
                        <div className="col-12 row ms-auto">
                          <label
                            style={{ fontSize: "14px" }}
                            className="col-form-label roboto-bold col-12 col-md-3"
                          >
                            IGST (%)
                          </label>

                          <div className="col-12 col-md-4">
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              value={taxDetails.igst}
                              {...register("IGST")}
                              // {...register("IGST", {
                              //     onChange: (e) => CaltotalAmount(e.target.value, "IGST")
                              // })}
                              placeholder="Enter IGST"
                              disabled
                            />
                            {errors.IGST && (
                              <span className="text-danger err-msg">
                                {errors.IGST.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-4 d-flex align-items-center">
                            <span>&#8377;</span>
                            <input
                              className="form-control form-control-sm ms-2"
                              type="text"
                              defaultValue={0}
                              {...register("IGST_amount")}
                              disabled
                              placeholder="IGST Amount"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {taxDetails.type == "ugst" ? (
                      <div className="row mb-2">
                        <div className="col-12 row ms-auto">
                          <label
                            style={{ fontSize: "14px" }}
                            className="col-form-label roboto-bold col-12 col-md-3"
                          >
                            UGST (%)
                            <span>
                              <i style={{ color: "red" }}>*</i>
                            </span>
                          </label>

                          <div className="col-12 col-md-4">
                            <input
                              className="form-control form-control-sm"
                              type="number"
                              placeholder="Enter ugst"
                              {...register("UGST")}
                              value={taxDetails.ugst}
                              disabled
                            />
                            {errors.ugst && (
                              <span className="text-danger err-msg">
                                {errors.ugst.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-4 d-flex align-items-center">
                            <span>&#8377;</span>
                            <input
                              className="form-control form-control-sm ms-2"
                              type="number"
                              disabled
                              {...register("UGST_amount")}
                              placeholder="ugst cost"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label roboto-bold col-12 col-md-3"
                        >
                          Total Amount &#8377;
                        </label>

                        <div className="col-12 col-md-4">
                          <input
                            className="form-control form-control-sm"
                            type="number"
                            {...register("totalAmount")}
                            defaultValue={0}
                            placeholder="Total Amount"
                            disabled
                          />
                          {errors.totalAmount && (
                            <span className="text-danger err-msg">
                              {errors.totalAmount.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {gstErr ? (
                        <div className="col-12 mt-3">
                          <div
                            className="alert alert-danger mb-0 text-center my-4"
                            role="alert"
                          >
                            GST number is not valid of Customer or Invoice To company, purchase order will be saved without GST.
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            {/* <button
                  onClick={() => setGalVisible(true)}
                  className="btn btn-yellow px-3"
                >
                  Image Gallery
                  <FontAwesomeIcon
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      marginLeft: "5px",
                    }}
                    icon={faImage}
                  />
                </button> */}

            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default CreateSO;
