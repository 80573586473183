import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { po_api_1, sales_order_api } from "../../constant/index";
import {
  PDFViewer,
  Document,
  Text,
  View,
  Image,
  Page,
  Link,
  StyleSheet,
  Font,
  PDFDownloadLink,
  BlobProvider,
} from "@react-pdf/renderer";
import numWords from "num-words";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
import Roboto from "../../assets/fonts/Roboto-Regular.ttf";
import Roboto_Bold from "../../assets/fonts/Roboto-Bold.ttf";
import { formatDateString } from "../../common/Functions/CommonFunctions";
function SOWebView() {
  const { id } = useParams();
  const location = useLocation();
  const pdfRef = useRef(null);
  const s3_url = process.env.REACT_APP_IMAGE_URL;

  console.log("id", id);
  const query = new URLSearchParams(location.search);
  const idtoken = query.get("token");

  console.log("idtoken", idtoken);

  const [data, setdata] = useState({});
  const [error, setError] = useState("");

  const [measurements, setMesurements] = useState([
    { name: "ABOVE BUST", value: null },
    { name: "BUST", value: null },
    { name: "BELOW BUST", value: null },
    { name: "WAIST", value: null },
    { name: "STOMACH", value: null },
    { name: "HIPS", value: null },
    { name: "SHOULDER", value: null },
    { name: "INCUT SLV-LESS SH", value: null },
    { name: "ARMHOLE", value: null },
    { name: "BICEP", value: null },
    { name: "SLEEVES", value: null },
    { name: "", value: null },
    { name: "", value: null },
    { name: "NECK FRONT", value: null },
    { name: "NECK BACK", value: null },
    { name: "COLLAR", value: null },
    { name: "ACROSS FRONT", value: null },
    { name: "ACROSS BACK", value: null },
  ]);
  const [below_waist_measurements, setBelow_waist_measurements] = useState([
    { name: "TROUSER WAIST", value: null },
    { name: "HIPS", value: null },
    { name: "FORK", value: null },
    { name: "SIDE SLIT", value: null },
    { name: "SALWAR PONCHA", value: null },
    { name: "CHURIDAR BOTTOM", value: null },
    { name: "TROUSER BOTTOM", value: null },
  ]);
  const [measurements_checkboxes, setMesurements_checkboxes] = useState([
    { name: "DOWN ROUND SH", value: null },
    { name: "SQUARE SH", value: null },
    { name: "V. DOWN SH", value: null },
    { name: "FORWARD CHEST", value: null },
    { name: "ROUND BACK", value: null },
    { name: "BELLY OUT", value: null },
    { name: "HIGH/FLAT HIP", value: null },
    { name: "HIGH NECK", value: null },
    { name: "LOW NECK", value: null },
    { name: "RUBBING THIGH", value: null },
  ]);
  const [sleeves_measurement, setSleeves_measurement] = useState([
    { name: "HALF", value1: null, value2: null },
    { name: "3/4TH", value1: null, value2: null },
    { name: "FULL", value1: null, value2: null },
  ]);
  const [sleeves_short, setsleeves_shot] = useState([
    { name: "SHORT", value1: null, value2: null },
  ]);
  const [points1, setpoints1] = useState([
    { position: 0.1, label: "", value: "" },
    { position: 0.3, label: "", value: "" },
    { position: 0.45, label: "", value: "" },
    { position: 0.65, label: "", value: "" },
    { position: 0.8, label: "", value: "" },
    { position: 0.9, label: "", value: "" },
  ]);
  const [points2, setpoints2] = useState([
    { position: 0.1, label: "", value: "" },
    { position: 0.3, label: "", value: "" },
    { position: 0.45, label: "", value: "" },
    { position: 0.65, label: "", value: "" },
    { position: 0.8, label: "", value: "" },
    { position: 0.9, label: "", value: "" },
  ]);
  const [points3, setpoints3] = useState([
    { position: 0.1, label: "", value: "" },
    { position: 0.3, label: "", value: "" },
    { position: 0.45, label: "", value: "" },
    { position: 0.65, label: "", value: "" },
    { position: 0.8, label: "", value: "" },
    { position: 0.9, label: "", value: "" },
  ]);
  const [points4, setpoints4] = useState([
    { position: 0.1, label: "", value: "" },
    { position: 0.3, label: "", value: "" },
    { position: 0.45, label: "", value: "" },
    { position: 0.65, label: "", value: "" },
    { position: 0.8, label: "", value: "" },
    { position: 0.9, label: "", value: "" },
  ]);
  const [legs, setlegs] = useState([]);
  const [length_data, setlength_data] = useState({
    name: "LENGTH",
    value: null,
  });
  Font.register({
    family: "roboto, roboto-light,roboto-bold",
    fonts: [
      {
        src: Roboto,
      },
      {
        src: Roboto_Bold,
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    viewer: {
      width: "100%",
      height: window.innerHeight,
    },
    page: {
      paddingTop: 15,
      paddingBottom: 40,
      paddingHorizontal: 15,
      color: "black",
      fontFamily: "roboto, roboto-light,roboto-bold",
      border: "1px solid black",
    },
    page2: {
      paddingTop: 15,
      paddingBottom: 40,
      paddingHorizontal: 8,
      color: "black",
      fontFamily: "roboto, roboto-light,roboto-bold",
      border: "1px solid black",
    },
    page3: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 10,
    },
    image: {
      width: "100%",
      height: "85%",
      // objectFit: "contain",
      objectFit: "cover",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 28,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
      paddingTop: "50px",
      paddingBottom: "10",
      paddingLeft: "15",
      paddingRight: "15",
      // borderTop:'1px solid black',
    },
    title: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "16px",
    },
    title1: {
      textAlign: "center",
      fontWeight: 500,
      fontSize: "30px",
    },
    title2: {
      textAlign: "center",
      // fontWeight: 500,
      fontSize: "21px",
    },
    header: {
      margin: 5,
      color: "black",
      fontSize: "10px",
    },
    headerrow: {
      flexDirection: "row",
      alignItems: "flex-start",
      margin: 5,
      alignItems: "justify-content-between",
      width: "100%",
      gap: "50px",
      // border: "1px solid black",
    },
    headerbox: {
      margin: 5,
      color: "black",
      fontSize: "10px",
      width: "50%",
    },
    // headerrow2: {
    //   flexDirection: "row",
    //   alignItems: "flex-start",
    //   marginBottom: 5,
    //   alignItems: "justify-content-between",
    //   width: "100%",
    //   gap: "0px",
    //   backgroundColor: "#BCF2F6",
    //   // border: "1px solid black",
    // },
    // headerbox2: {
    //   margin: 5,
    //   color: "black",
    //   fontSize: "12px",
    //   width: "50%",
    //   // border: "1px solid black",
    // },

    tofromrow: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
      alignItems: "justify-content-between",
      width: "100%",
      gap: "100Px",
    },
    tofrombox: {
      margin: 5,
      color: "black",
      fontSize: "12px",
      width: "50%",
      // border:"1px soild black"
    },
    tofrombox2: {
      margin: 5,
      color: "black",
      fontSize: "12px",
      width: "60%",
      // border:"1px soild black"
    },
    headerrow4: {
      flexDirection: "row",
      marginBottom: 5,
      padding: "5px",
      width: "100%",
      fontSize: "12px",
      backgroundColor: "#f5f5f5",
    },
    headerbox4: {
      margin: 5,
      color: "black",
      fontSize: "12px",
      width: "50%",
      // border: "1px solid black",
    },
    tofrominsiderow: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    tofromlabel: {
      fontSize: "10px",
      fontWeight: "bold",
    },
    tofromvalue: {
      flex: 1,
      fontSize: "10px",
      marginLeft: "5px",
    },
    pricetableheader: {
      flexDirection: "row",
      marginTop: 5,
      marginBottom: 5,
      width: "100%",
      fontSize: "10px",
      backgroundColor: "#f5f5f5",
    },
    pricetablerows: {
      flexDirection: "row",
      marginBottom: 5,
      width: "100%",
      fontSize: "10px",
    },
    tablecolumn1: {
      width: "6%",
    },
    tablecolumn2: {
      width: "35%",
    },
    tablecolumn3: {
      width: "15%",
    },
    tablecolumn4: {
      width: "10%",
    },
    tablecolumn5: {
      width: "12%",
    },
    tablerows: {
      flexDirection: "row",
      marginBottom: 5,
      width: "100%",
      fontSize: "10px",
      gap: "2%",
    },
    bottomSection: {
      marginTop: 20,
      flexDirection: "column",
      alignItems: "flex-end",
    },
    bottomSectiontext: {
      fontSize: 12,
      marginBottom: 10,
      fontWeight: "bold",
    },

    // Measurement Section
    headerrow2: {
      flexDirection: "row",
      alignItems: "flex-start",
      //   marginBottom: 5,
      alignItems: "justify-content-between",
      width: "100%",
      gap: "0px",
      //   backgroundColor: "#BCF2F6",
      // border: "1px solid black",
    },
    headerbox2: {
      //   margin: 5,
      color: "black",
      fontSize: "12px",
      width: "60%",
      // border: "1px solid black",
    },
    headerbox3: {
      //   margin: 5,
      color: "black",
      fontSize: "12px",
      width: "40%",
      // border: "1px solid black",
    },

    headerbox2row: {
      width: "100%",
      marginLeft: 5,
      //   textAlign: "center",
      // border:"1px solid black"
    },
    headerbox2row2: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
      border: "1px solid black",
    },
    headerrow3: {
      fontSize: "14px",
      //   margin: 5,
      width: "100%",
      color: "white",
      backgroundColor: "black",
      // border: "1px solid black",
    },
    headerrow4: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 3,
      alignItems: "justify-content-between",
      width: "100%",
      gap: "10px",
      //   backgroundColor: "#BCF2F6",
      // border: "1px solid black",
    },
    headerbox41: {
      //   margin: 5,
      color: "black",
      fontSize: "10px",
      width: "32%",
      // border: "1px solid black",
    },
    headerbox41row1: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      //   marginBottom: 5,
      border: "1px solid black",
      fontSize: "10px",
    },

    headerbox41rowforline: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      //   marginBottom: 5,
      // border: "1px solid black",
      fontSize: "10px",
    },

    headerbox41row1label: {
      fontSize: "10px",
      margin: 3,
      //   border: "1px solid black",
      width: "58%",
    },
    headerbox41row1value: {
      flex: 1,
      fontSize: "10px",
      margin: 3,
      width: "40%",

      //   borderLeft: "1px solid black",
    },

    headerbox41sleeverow: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 220,
      marginLeft: -10,
      border: "1px solid black",
      fontSize: "10px",
    },
    headerbox41sleeverow2: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 0,
      marginLeft: -10,
      border: "1px solid black",
      fontSize: "10px",
    },
    headerbox41sleeverow3: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 0,
      marginLeft: -10,
      border: "1px solid black",
      fontSize: "10px",
    },
    headerbox41sleevelabel: {
      fontSize: "10px",
      margin: 3,
      textAlign: "center",
      //   border: "1px solid black",
      width: "32%",
    },

    headerbox42: {
      marginLeft: 20,
      color: "black",
      fontSize: "10px",
      width: "10%",
      //   border: "1px solid black",
    },
    headerbox43: {
      // marginLeft: 10,
      color: "black",
      fontSize: "10px",
      width: "16%",
    },
    row: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    label: {
      fontSize: "12px",
      margin: 5,
      //   fontWeight: "bold",
    },
    value: {
      flex: 1,
      fontSize: "12px",
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,

      //   marginLeft: "5px",
    },
    line: {
      position: "absolute",
      backgroundColor: "black",
    },
    point: {
      position: "absolute",
      width: 6, // Diameter of the point
      height: 6,
      backgroundColor: "black",
      borderRadius: 3, // Makes it circular
    },
    labelline: {
      position: "absolute",
      fontSize: 10,
      textAlign: "right", // Aligns to the right of the label area
      // border:"1px solid black"
    },
    valueline: {
      position: "absolute",
      fontSize: 10,
      textAlign: "left",
    },

    footer1: {
      color: "black",
      marginTop: 5,
    },
  });
  useEffect(() => {
    let headers;
    let url; 
    headers = { "X-ID-TOKEN": `${idtoken}` };

    if(process.env.REACT_APP_ENV=="production"){

      url = process.env.REACT_APP_API_INV_URL + sales_order_api;
    }else{
      url = process.env.REACT_APP_API_INV_URL + "/"+ sales_order_api;

    }

    

    axios
      .get(url, {
        params: { uuid: id },
        headers: headers,
      })
      .then((response) => {
        console.log("response", response.data);

        setdata(response.data.data[0]);

        let res_data = response.data.data[0];

        const result = measurements.map((measurement) => {
          const match =
            res_data?.so_order_lines[0].measurement?.attributes.find(
              (item) =>
                item.name.toLowerCase() === measurement.name.toLowerCase()
            );
          if (measurement.name.includes("BUST")) {
            if (res_data?.so_order_lines[0]?.party?.gender == "Male") {
              let inputString = measurement.name.replace("BUST", "CHEST");
              // console.log("inputString",inputString)
              const match1 =
                res_data?.so_order_lines[0].measurement?.attributes.find(
                  (item) =>
                    item.name.toLowerCase() === inputString.toLowerCase()
                );
              // console.log("match1",match1)

              return { name: inputString, value: match1 ? match1.value : null };
            } else {
              return {
                name: measurement.name,
                value: match ? match.value : null,
              };
            }
          } else {
            return {
              name: measurement.name,
              value: match ? match.value : null,
            };
          }
        });
        const result2 = below_waist_measurements.map((measurement) => {
          const match =
            res_data?.so_order_lines[0].measurement?.attributes.find(
              (item) =>
                item.name.toLowerCase() === measurement.name.toLowerCase()
            );
          return { name: measurement.name, value: match ? match.value : null };
        });
        const result3 = measurements_checkboxes.map((measurement) => {
          const match =
            res_data?.so_order_lines[0].measurement?.attributes.find(
              (item) =>
                item.name.toLowerCase() === measurement.name.toLowerCase()
            );
          return { name: measurement.name, value: match ? match.value : null };
        });

        const line1_data =
          res_data?.so_order_lines[0].measurement?.attributes.find(
            (item) => item.name == "Line 1"
          );
        const leg_data =
          res_data?.so_order_lines[0].measurement?.attributes.find(
            (item) => item.name == "Leg"
          );
        const line2_data =
          res_data?.so_order_lines[0].measurement?.attributes.find(
            (item) => item.name == "Line 2"
          );
        const line3_data =
          res_data?.so_order_lines[0].measurement?.attributes.find(
            (item) => item.name == "Line 3"
          );
        const line4_data =
          res_data?.so_order_lines[0].measurement?.attributes.find(
            (item) => item.name == "Line 4"
          );

        const length_data_find =
          res_data?.so_order_lines[0].measurement?.attributes.find(
            (item) => item.name == "LENGTH"
          );
        //  console.log("line1_data",line1_data)
        if (length_data_find) {
          if (length_data_find.value) {
            setlength_data({ name: "LENGTH", value: length_data_find.value });
          }
        }

        //  console.log("line1_data",line1_data)
        if (line1_data) {
          // console.log("line1_data",line1_data)

          if (line1_data.value) {
            let newValues = JSON.parse(line1_data.value);
            let updatedPoints = points1.map((point, index) => ({
              ...point,
              value: newValues[index] || point.value,
            }));
            console.log("updatedPointe", updatedPoints);
            setpoints1(updatedPoints);
          }
        }
        if (leg_data) {
          if (leg_data.value) {
            let newValues = JSON.parse(leg_data.value);
            setlegs(newValues);
          }
        }

        if (line2_data) {
          // console.log("line1_data",line1_data)

          if (line2_data.value) {
            let newValues = JSON.parse(line2_data.value);
            let updatedPoints = points2.map((point, index) => ({
              ...point,
              value: newValues[index] || point.value,
            }));
            console.log("updatedPointe", updatedPoints);
            setpoints2(updatedPoints);
          }
        }

        if (line3_data) {
          // console.log("line1_data",line1_data)

          if (line3_data.value) {
            let newValues = JSON.parse(line3_data.value);
            let updatedPoints = points3.map((point, index) => ({
              ...point,
              value: newValues[index] || point.value,
            }));
            console.log("updatedPointe", updatedPoints);
            setpoints3(updatedPoints);
          }
        }

        if (line4_data) {
          if (line4_data.value) {
            let newValues = JSON.parse(line4_data.value);
            let updatedPoints = points4.map((point, index) => ({
              ...point,
              value: newValues[index] || point.value,
            }));
            setpoints4(updatedPoints);
          }
        }

        const updatedResult5 = sleeves_measurement.map((entry) => {
          const matchedData =
            res_data?.so_order_lines[0].measurement?.attributes.filter((item) =>
              item.name.includes(`SLEEVES ${entry.name}`)
            );
          const values = { value1: null, value2: null };

          matchedData.forEach((item) => {
            const suffix = item.name.endsWith("1") ? "value1" : "value2";
            values[suffix] = item.value;
          });

          return { name: entry.name, ...values };
        });

        setSleeves_measurement(updatedResult5);

        const updatedResult6 = sleeves_short.map((entry) => {
          const matchedData =
            res_data?.so_order_lines[0].measurement?.attributes.filter((item) =>
              item.name.includes(`SLEEVES ${entry.name}`)
            );
          const values = { value1: null, value2: null };

          matchedData.forEach((item) => {
            const suffix = item.name.endsWith("1") ? "value1" : "value2";
            values[suffix] = item.value;
          });

          return { name: entry.name, ...values };
        });
        console.log("updatedResult6", updatedResult6);
        setsleeves_shot(updatedResult6);
        // console.log("line1",JSON.parse(line1_data.value));
        // console.log("leg_data",JSON.parse(leg_data.value));
        // console.log("line2_data",JSON.parse(line2_data.value));
        // console.log("line3_data",JSON.parse(line3_data.value));
        // console.log("line4_data",JSON.parse(line4_data.value));
        setMesurements_checkboxes(result3);

        setBelow_waist_measurements(result2);

        setMesurements(result);
        // console.log("result", result);
      })
      .catch((error) => {
        // Handle errors

        console.log("error--->", error);
        if (error?.response?.status == 401) {
          setError(
            "Access Denied: You are not authorized to view this content."
          );
        } else {
          setError("Invalid Token");
          // toast.error(error.response.data.error, {
          //     autoClose: 2000,
          // });
        }
      });
  }, []);

  // Line Component
  const Line = ({ x1, y1, x2, y2, points = [] }) => {
    // Calculate the line's length and angle
    const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);

    // Function to calculate offset positions for labels and values
    const calculatePosition = (offset, angle) => {
      const angleRad = (angle * Math.PI) / 180;
      return {
        deltaX: offset * Math.cos(angleRad),
        deltaY: offset * Math.sin(angleRad),
      };
    };

    return (
      <View
        style={[
          styles.line,
          {
            width: length,
            top: y1,
            left: x1,
            height: 1, // Thickness of the main line
            transform: `rotate(${angle}deg)`,
            transformOrigin: "top left",
          },
        ]}
      >
        {/* Render Points with Labels and Values */}
        {points.map((point, index) => {
          const { position, label, value } = point;

          // Calculate position for the point along the line
          const pointX = position * length;

          // Calculate positions for label and value
          const { deltaX: labelOffsetX, deltaY: labelOffsetY } =
            calculatePosition(-15, angle); // Left of point
          const { deltaX: valueOffsetX, deltaY: valueOffsetY } =
            calculatePosition(15, angle); // Right of point

          return (
            <View key={index}>
              {/* Point (Circle) */}
              <View
                style={[
                  styles.point,
                  {
                    left: pointX - 3, // Center the point horizontally
                    top: -3, // Center the point vertically
                  },
                ]}
              />

              {/* Label (Left side of the line) */}
              <Text
                style={[
                  styles.labelline,
                  {
                    left: pointX + labelOffsetX,
                    top: labelOffsetY - 3, // Slight adjustment to make closer
                    transform: `rotate(-${angle}deg)`, // Rotate back to horizontal
                  },
                ]}
              >
                {value}
              </Text>

              {/* Value (Right side of the line) */}
              <Text
                style={[
                  styles.valueline,
                  {
                    left: pointX + valueOffsetX,
                    top: valueOffsetY - 3, // Slight adjustment to make closer
                    transform: `rotate(-${angle}deg)`, // Rotate back to horizontal
                  },
                ]}
              >
                {label}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };

  const MyDoc = () => {
    return (
      <Document title={`Sales Order`}>
        <Page size="A4" style={styles.page}>
          <View>
            <View style={[{ border: "1px solid black" }]}>
              <Text style={[styles.title, { marginTop: 5 }]}>Sales Order</Text>

              {/*Sales Order date & Delevery date  */}
              <View style={styles.headerrow}>
                {/* Sales order Number */}
                <View style={styles.headerbox}>
                  <View style={styles.tofrominsiderow}>
                    <Text style={styles.tofromlabel}>Sales Order: </Text>
                    <Text style={styles.tofromvalue}>
                      {data.so_id ? data.so_id : "SO"}
                    </Text>
                  </View>
                  <View style={styles.tofrominsiderow}>
                    <Text style={styles.tofromlabel}>Sales Order Date: </Text>
                    <Text style={styles.tofromvalue}>
                      {data.order_date
                        ? formatDateString(data.order_date, "-")
                        : "NA"}
                    </Text>
                  </View>
                </View>
                <View style={styles.headerbox}>
                  <View style={styles.tofrominsiderow}>
                    <Text style={styles.tofromlabel}>Sales Order Name: </Text>
                    <Text style={styles.tofromvalue}>
                      {data?.sales_order_name ? data.sales_order_name : "NA"}
                    </Text>
                  </View>
                  <View style={styles.tofrominsiderow}>
                    <Text style={styles.tofromlabel}>Valid Till Date: </Text>
                    <Text style={styles.tofromvalue}>
                      {data.final_date
                        ? formatDateString(data.final_date, "-")
                        : "NA"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* Sales order to & sales order from header */}
            <View style={[{ border: "1px solid black" }]}>
              <View style={styles.tofromrow}>
                <View style={styles.tofrombox}>
                  <Text>From:</Text>
                </View>
                <View style={styles.tofrombox}>
                  <Text>To:</Text>
                </View>
              </View>

              {/*  to & from infomation  */}
              <View style={styles.tofromrow}>
                <View style={styles.tofrombox}>
                  <View style={styles.tofrominsiderow}>
                    <Text style={styles.tofromlabel}>Company Name:</Text>
                    <Text style={styles.tofromvalue}>
                      {data?.org_id?.comName ? data.org_id.comName : "NA"}
                    </Text>
                  </View>
                  <View style={styles.tofrominsiderow}>
                    <Text style={styles.tofromlabel}>Address:</Text>
                    <Text style={styles.tofromvalue}>
                      {data?.org_id?.comaddress ? data.org_id.comaddress : "NA"}
                    </Text>
                  </View>

                  <View style={styles.tofrominsiderow}>
                    <Text style={styles.tofromlabel}>Email:</Text>
                    <Text style={styles.tofromvalue}>
                      {data?.org_id?.mail ? data.org_id.mail : "NA"}
                    </Text>
                  </View>
                </View>

                <View style={styles.tofrombox}>
                  <View style={styles.tofrominsiderow}>
                    <Text style={styles.tofromlabel}>Customer Name:</Text>
                    <Text style={styles.tofromvalue}>
                      {data.customer_name ? data.customer_name : "NA"}
                    </Text>
                  </View>
                  <View style={styles.tofrominsiderow}>
                    <Text style={styles.tofromlabel}>Address:</Text>
                    <Text style={styles.tofromvalue}>
                      {data.delivery_address ? data.delivery_address : "NA"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* Product  information */}

            <View style={[{ border: "1px solid black", padding: 5 }]}>
              <View style={styles.pricetableheader}>
                <View style={styles.tablecolumn1}>
                  <Text>Sr.No</Text>
                </View>
                <View style={styles.tablecolumn2}>
                  <Text>Product</Text>
                </View>
                <View style={styles.tablecolumn4}>
                  <Text>Quantity</Text>
                </View>
                <View style={styles.tablecolumn4}>
                  <Text>Unit</Text>
                </View>
                <View style={styles.tablecolumn4}>
                  <Text>Rate ₹</Text>
                </View>

                <View style={styles.tablecolumn4}>
                  <Text>GST %</Text>
                </View>
                <View style={styles.tablecolumn5}>
                  <Text>Discount %</Text>
                </View>
                <View style={styles.tablecolumn5}>
                  <Text>Amount ₹</Text>
                </View>
              </View>
              {data?.so_order_lines?.map((ele, index) => (
                <View key={index} style={styles.pricetablerows}>
                  <View style={styles.tablecolumn1}>
                    <Text>{index + 1}</Text>
                  </View>
                  <View style={styles.tablecolumn2}>
                    <Text>{ele?.productname ? ele.productname : "NA"}</Text>
                  </View>
                  <View style={styles.tablecolumn4}>
                    <Text>{ele?.quantity ? ele.quantity : "NA"}</Text>
                  </View>
                  <View style={styles.tablecolumn4}>
                    <Text>{ele?.unit ? ele.unit : "NA"}</Text>
                  </View>
                  <View style={styles.tablecolumn4}>
                    <Text>{ele?.rate ? ele.rate : "NA"}</Text>
                  </View>

                  <View style={styles.tablecolumn4}>
                    <Text>{ele?.gst_rate ? ele.gst_rate : "NA"}</Text>
                  </View>
                  <View style={styles.tablecolumn5}>
                    <Text>{ele?.discount ? ele.discount : "NA"}</Text>
                  </View>
                  <View style={styles.tablecolumn5}>
                    <Text>{ele?.amount ? ele.amount : "NA"}</Text>
                  </View>
                </View>
              ))}
            </View>

            {/* Bottom section */}
            <View style={[{ border: "1px solid black", padding: 5 }]}>
              <View style={styles.bottomSection}>
                <Text style={styles.bottomSectiontext}>
                  Sub Total:
                  {data.sub_total ? data.sub_total.toFixed(2) : "0.00"}
                </Text>
                <Text style={styles.bottomSectiontext}>
                  Total Tax: {"0.00"}
                </Text>
                <Text style={styles.bottomSectiontext}>
                  Total Amount:{" "}
                  {data.final_total ? data.final_total.toFixed(2) : "0.00"}
                </Text>
              </View>
            </View>
          </View>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />

          <Text style={styles.footer} fixed>
            This is a Computer Generated Document
          </Text>
        </Page>

        <Page size="A4" style={styles.page2}>
          <View>
            <View style={styles.headerrow2}>
              <View style={styles.headerbox2}>
                <View style={styles.headerbox2row}>
                  <Text style={styles.title1}>NIVEDITA SABOO</Text>
                </View>

                <View style={styles.headerbox2row}>
                  <Text style={styles.title2}>COUTURE</Text>
                </View>
                <View style={styles.headerbox2row}>
                  <Text style={[{ fontSize: "10px", textAlign: "center" }]}>
                    Bungalow 20,Samrat Society,Lane No.7, Kalyani Nagar,
                    Pune:411014
                  </Text>
                </View>
                <View style={styles.headerbox2row}>
                  <Text style={[{ fontSize: "10px", textAlign: "center" }]}>
                    Phone No.: +91 92204 8000
                  </Text>
                </View>
              </View>
              <View style={styles.headerbox3}>
                <View style={styles.headerbox2row2}>
                  <Text style={styles.label}>NAME:</Text>
                  <Text style={styles.value}>
                    {data?.dispatch_to?.name ? data.dispatch_to.name : "NA"}
                  </Text>
                </View>

                <View style={styles.headerbox2row2}>
                  <Text style={styles.label}>CONTACT NO.:</Text>
                  <Text style={styles.value}>
                    {data?.dispatch_to?.primaryContactNo
                      ? data.dispatch_to.primaryContactNo
                      : "NA"}
                  </Text>
                </View>

                <View style={styles.headerbox2row2}>
                  <Text style={styles.label}>EMAIL ID:</Text>
                  <Text style={styles.value}>
                    {data?.dispatch_to?.email ? data.dispatch_to.email : "NA"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.headerrow3}>
              <Text
                style={[{ textAlign: "center", margin: 5, fontWeight: "bold" }]}
              >
                {`MEASUREMENT CHART:  ${data?.so_id ? data.so_id : ""}`}
              </Text>
            </View>
            {/*Date & Measured by information */}
            <View style={styles.headerrow4}>
              <View style={styles.headerbox41}>
                {[1, 2].map((item, ind) => {
                  return (
                    <View key={ind} style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        {ind == 0 ? (
                          <Text>DATE</Text>
                        ) : (
                          <Text>MEASUREMENTS</Text>
                        )}
                      </View>
                      <View
                        style={[
                          {
                            border: "1px solid black",
                            width: "1%",
                            height: "20px",
                          },
                        ]}
                      ></View>
                      <View style={styles.headerbox41row1value}>
                        {ind == 0 ? (
                          <Text>
                            {data.order_date
                              ? formatDateString(data.order_date, "-")
                              : "NA"}
                          </Text>
                        ) : (
                          <Text>{""}</Text>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View>
              <View style={styles.headerbox41}>
                {[1, 2].map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        {ind == 0 ? (
                          <Text>MEASURED BY</Text>
                        ) : (
                          <Text>NOTED BY</Text>
                        )}
                      </View>
                      <View
                        style={[
                          {
                            border: "1px solid black",
                            width: "1%",
                            height: "20px",
                          },
                        ]}
                      ></View>
                      <View style={styles.headerbox41row1value}>
                        <Text>{}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
              <View style={styles.headerbox42}>
                {[1, 2].map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        {ind == 0 ? (
                          <Text>{"   "}</Text>
                        ) : (
                          <Text style={[{ height: "16px" }]}>BLOUSE</Text>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
            {/* Till Waist Measurement */}
            <View style={styles.headerrow4}>
              <View style={styles.headerbox41}>
                {measurements.map((item, ind) => {
                  return (
                    <View key={ind + item} style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        <Text>{item.name}</Text>
                      </View>
                      <View
                        style={[
                          {
                            border: "1px solid black",
                            width: "1%",
                            height: "20px",
                          },
                        ]}
                      ></View>
                      <View style={styles.headerbox41row1value}>
                        {/* {console.log("item.name",item.name, ind)} */}
                        {ind == 11 && <Text>{sleeves_short[0].value1}</Text>}
                        {ind == 12 && <Text>{sleeves_short[0].value2}</Text>}
                        {item.name == "SLEEVES" && <Text>{"SHORT"}</Text>}
                        {item.name !== "SLEEVES" && <Text>{item.value}</Text>}
                      </View>
                    </View>
                  );
                })}
              </View>
              <View style={styles.headerbox41}>
                <View style={styles.headerbox41sleeverow}>
                  {sleeves_measurement.map((item, ind) => {
                    return (
                      <>
                        <View style={styles.headerbox41sleevelabel}>
                          <Text>{item.name}</Text>
                        </View>
                        {ind < 2 ? (
                          <View
                            style={[
                              {
                                border: "1px solid black",
                                width: "1%",
                                height: "20px",
                              },
                            ]}
                          ></View>
                        ) : null}
                      </>
                    );
                  })}
                </View>
                <View style={styles.headerbox41sleeverow2}>
                  {sleeves_measurement.map((item, ind) => {
                    return (
                      <>
                        <View style={styles.headerbox41sleevelabel}>
                          <Text>{item.value1 ? item.value1 : ""}</Text>
                        </View>
                        {ind < 2 ? (
                          <View
                            style={[
                              {
                                border: "1px solid black",
                                width: "1%",
                                height: "20px",
                              },
                            ]}
                          ></View>
                        ) : null}
                      </>
                    );
                  })}
                </View>
                <View style={styles.headerbox41sleeverow3}>
                  {sleeves_measurement.map((item, ind) => {
                    return (
                      <>
                        <View style={styles.headerbox41sleevelabel}>
                          <Text>{item.value2 ? item.value2 : ""}</Text>
                        </View>
                        {ind < 2 ? (
                          <View
                            style={[
                              {
                                border: "1px solid black",
                                width: "1%",
                                height: "20px",
                              },
                            ]}
                          ></View>
                        ) : null}
                      </>
                    );
                  })}
                </View>

                <Line x1={42} y1={0} x2={58} y2={0} />
                <Line x1={50} y1={0} x2={50} y2={200} points={points1} />

                <Line x1={42} y1={200} x2={58} y2={200} />

                {/* Second line  */}
                <Line x1={142} y1={0} x2={158} y2={0} />
                {/* <Line x1={150} y1={0} x2={150} y2={95} />

                <Line x1={142} y1={95} x2={158} y2={95} />

                <Line x1={142} y1={100} x2={158} y2={100} /> */}
                {/* <Line x1={150} y1={100} x2={150} y2={200} /> */}
                <Line x1={150} y1={0} x2={150} y2={200} points={points2} />

                <Line x1={142} y1={200} x2={158} y2={200} />
              </View>
              <View style={styles.headerbox42}>
                {measurements.map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View
                        style={[
                          styles.headerbox41row1label,
                          { height: "14px" },
                        ]}
                      >
                        <Text>{"   "}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
              <View style={styles.headerbox43}>
                {measurements_checkboxes.map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        <Text>{item.name}</Text>
                      </View>
                      <View
                        style={[
                          {
                            border: "1px solid black",
                            width: "1%",
                            height: "27px",
                          },
                        ]}
                      ></View>
                      <View style={styles.headerbox41row1value}>
                        <Text>{item.value == "true" ? "Yes" : ""}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
            {/* Below Waist Measurement */}
            <View style={styles.headerrow4}>
              <View style={styles.headerbox41}>
                {below_waist_measurements.map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View style={styles.headerbox41row1label}>
                        <Text>{item.name}</Text>
                      </View>
                      <View
                        style={[
                          {
                            border: "1px solid black",
                            width: "1%",
                            height: "20px",
                          },
                        ]}
                      ></View>
                      <View style={styles.headerbox41row1value}>
                        <Text>{item.value}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
              <View style={styles.headerbox41}>
                {/* arrow  with line   */}
                <Line x1={55} y1={-5} x2={60} y2={0} />
                <Line x1={20} y1={0} x2={60} y2={0} />{" "}
                <Text style={[{ marginLeft: 62, marginTop: -6 }]}>
                  {legs[0] ? legs[0] : ""}
                </Text>
                <Line x1={55} y1={5} x2={60} y2={0} />
                <Line x1={55} y1={15} x2={60} y2={20} />
                <Line x1={20} y1={20} x2={60} y2={20} />{" "}
                <Text style={[{ marginLeft: 62, marginTop: 10 }]}>
                  {legs[1] ? legs[1] : ""}
                </Text>
                <Line x1={55} y1={25} x2={60} y2={20} />
                <Line x1={55} y1={35} x2={60} y2={40} />
                <Line x1={20} y1={40} x2={60} y2={40} />{" "}
                <Text style={[{ marginLeft: 62, marginTop: 8 }]}>
                  {legs[2] ? legs[2] : ""}
                </Text>
                <Line x1={55} y1={45} x2={60} y2={40} />
                <Line x1={55} y1={55} x2={60} y2={60} />
                <Line x1={20} y1={60} x2={60} y2={60} />{" "}
                <Text style={[{ marginLeft: 62, marginTop: 8 }]}>
                  {legs[3] ? legs[3] : ""}
                </Text>
                <Line x1={55} y1={65} x2={60} y2={60} />
                <Line x1={55} y1={75} x2={60} y2={80} />
                <Line x1={20} y1={80} x2={60} y2={80} />{" "}
                <Text style={[{ marginLeft: 62, marginTop: 8 }]}>
                  {legs[4] ? legs[4] : ""}
                </Text>
                <Line x1={55} y1={85} x2={60} y2={80} />
                <Line x1={55} y1={125} x2={60} y2={130} />
                <Line x1={20} y1={130} x2={60} y2={130} />{" "}
                <Text style={[{ marginLeft: 62, marginTop: 36 }]}>
                  {legs[5] ? legs[5] : ""}
                </Text>
                <Line x1={55} y1={135} x2={60} y2={130} />
                <Line x1={20} y1={0} x2={20} y2={150} />
                <Line x1={20} y1={150} x2={40} y2={150} />
                <Line x1={40} y1={0} x2={40} y2={150} />
                {/* <Line x1={182} y1={150} x2={198} y2={150} /> */}
                <Line x1={182} y1={0} x2={198} y2={0} />
                <Line x1={190} y1={0} x2={190} y2={150} points={points3} />
                <Line x1={182} y1={150} x2={198} y2={150} />
              </View>
              <View style={styles.headerbox42}>
                {below_waist_measurements.map((item, ind) => {
                  return (
                    <View style={styles.headerbox41row1}>
                      <View
                        style={[
                          styles.headerbox41row1label,
                          { height: "14px" },
                        ]}
                      >
                        {ind == 5 && <Text>{length_data.name}</Text>}
                        {ind == 6 && <Text>{length_data.value}</Text>}
                        {ind !== 5 && ind !== 6 && <Text>{"   "}</Text>}
                      </View>
                    </View>
                  );
                })}
              </View>

              <View style={styles.headerbox43}>
                <Line x1={2} y1={0} x2={18} y2={0} />
                <Line x1={10} y1={0} x2={10} y2={150} points={points4} />

                <Line x1={2} y1={150} x2={18} y2={150} />
              </View>
            </View>
            <View style={styles.footer1}>
              <Text>NIVEDITA SABOO COUTURE</Text>
              <Text style={[{ fontSize: "10px" }]}>
                Bungalow 20,Samrat Society,Lane No.7, Kalyani Nagar, Pune:411014
                Phone No.: +91 92204 8000
              </Text>
            </View>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>

        {data?.salesOrderattachments &&
          data?.salesOrderattachments?.length > 0 &&
          data?.salesOrderattachments
            .filter((item) => item.document_type == "image")
            .map((img, index) => (
              <Page key={index} size="A4" style={styles.page3}>
                <View
                  style={[
                    {
                      border: "3px solid black",
                      marginBottom: 10,
                      marginTop: -20,
                      width: "100%",
                      textAlign: "center",
                    },
                  ]}
                >
                  <Text style={[{ margin: 5 }]}>
                    {data?.so_id ? data.so_id : "SO"}
                  </Text>
                </View>
                {img.document_type == "image" && (
                  <Image
                    src={`${s3_url}${img.attachment}`}
                    style={styles.image}
                  />
                )}

                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              </Page>
            ))}
      </Document>
    );
  };

  return (
    <React.Fragment>
      {error.length > 0 ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh", overflow: "hidden" }}
        >
          <div
            className="alert alert-danger mb-0 text-center px-5"
            role="alert"
          >
            {error}
          </div>
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh", overflow: "hidden" }}
        >
          <PDFDownloadLink
            document={<MyDoc />}
            fileName={data?.so_id ? `${data.so_id}` : `SO.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <button className="btn btn-yellow px-4">
                {loading ? (
                  <Spinner animation="border" size="sm" className="me-2" />
                ) : (
                  <FontAwesomeIcon
                    style={{ color: "#212529", cursor: "pointer" }}
                    icon={faDownload}
                    size="sm"
                    className="me-2"
                  />
                )}
                {loading ? "Loading Report" : "Download Report"}
              </button>
            )}
          </PDFDownloadLink>
        </div>
      )}
    </React.Fragment>
  );
}

export default SOWebView;
