import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const present_date = new Date();
const nextMonthDate = new Date(present_date);
nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

const firstDate = new Date(present_date.getFullYear(), present_date.getMonth(), 1);
const lastDate = new Date(present_date.getFullYear(), present_date.getMonth() + 1, 0);

console.log("present_date", present_date);

const initialState = {
    StepOne: null,  // State for Component One
    StepTwo: null,  // State for Component Two
    StepThree: null,// State for Component Three
    StepFour: {}, // State for Component Four
    base_data: {
        start_date: present_date,
        end_date: nextMonthDate,
        big_calender_date: present_date,
        view: "month",
        data: []
    },
    loading: true
};

const stepSlice = createSlice({
    name: 'steps',
    initialState,
    reducers: {
        // Update state for Component One
        updateStepOne: (state, action) => {
            console.log("state", state);
            console.log("action", action);
            state.StepOne = { ...state.StepOne, ...action.payload };
        },
        // Update state for Component Two
        updateStepTwo: (state, action) => {
            state.StepTwo = { ...state.StepTwo, ...action.payload };
            // state.base_data.data = [...state.base_data.data, ...action.payload.base_data];
            state.base_data.data = [...action.payload.base_data];
        },
        // Update state for Component Three
        updateStepThree: (state, action) => {
            state.StepThree = { ...state.StepThree, ...action.payload };
        },
        // Update state for Component Four
        updateStepFour: (state, action) => {
            state.StepFour = { ...action.payload };
        },
        resetState: (state, action) => {
            state.StepOne = null;
            state.StepTwo = null;
            state.StepThree = null;
            state.base_data = {
                start_date: present_date,
                end_date: nextMonthDate,
                big_calender_date: present_date,
                data: []
            };
            state.StepFour = {};
            state.base_data = {
                start_date: present_date,
                end_date: nextMonthDate,
                big_calender_date: present_date,
                view: "month",
                data: []
            };
            state.loading = true;

        },
        handle_date: (state, action) => {
            console.log("action", action);
            console.log("present_date", present_date);
            state.base_data.start_date = new Date(action.payload.start_date);
            state.base_data.end_date = new Date(action.payload.end_date);
            state.base_data.view = action.payload.view;

            let big_calender_date_copy = new Date(action.payload.big_calender_date);

            if (big_calender_date_copy.getMonth() < new Date(action.payload.start_date).getMonth()) {
                const start_date_month = new Date(action.payload.start_date).getMonth();
                big_calender_date_copy.setMonth(start_date_month);
            }

            console.log("new Date(action.payload.start_date)", new Date(action.payload.start_date));
            console.log("new Date(action.payload.end_date)", new Date(action.payload.end_date));
            console.log("big_calender_date_copy", big_calender_date_copy);
            state.base_data.big_calender_date = new Date(big_calender_date_copy)
            state.loading = false
        },
        handle_data: (state, action) => {
            console.log("state", state);
            console.log("action", action);
            const { global_route, operation, operator, prod_index, route, base_data, opr_list, routeType } = action.payload
            // const newData = [...base_data.data];
            // console.log("newData", newData);
            let i = 0;
            const tempData = JSON.parse(JSON.stringify(base_data));

            for (const item of tempData.data) {
                console.log(`${i} -- ${prod_index}`);
                if (i == prod_index) {
                    item.selected = true;
                    item.routeType = routeType
                } else {
                    item.selected = false
                }
                i++;
            }
            if (route.length > 0) {
                tempData.data[prod_index].selected_route = route;
            } else {
                tempData.data[prod_index].selected_route = global_route;
            }

            const clonned_operation = [...operation];
            clonned_operation[0].selected_emp = [operator];
            for (const opr of opr_list) {
                if (opr.uuid == operation.uuid) {
                    opr = clonned_operation;
                }
            }

            tempData.data[prod_index].selected_route[0].route_operation_list = opr_list;



            // tempData.data[prod_index].selected_operation = operation;
            // tempData.data[prod_index].selected_operator = operator;
            console.log("tempData.data", tempData.data);
            state.base_data = tempData;
            state.loading = false
        },
        enable_loading: (state, action) => {
            console.log("action.payload", action.payload);
            state.loading = action.payload
        },
        save_job_card: (state, action) => {
            const prod = action.payload.base_data.data.find(val => val.selected == true)

            if (action.payload.data) {
                for (const item of prod.selected_route[0].route_operation_list) {
                    if (item.uuid == action.payload.operation.uuid) {
                        item.create_job_card = action.payload.data;
                    }
                }
            } else {
                for (const item of prod.selected_route[0].route_operation_list) {
                    if (item.uuid == action.payload.operation.uuid) {
                        delete item.create_job_card;
                    }
                }
            }

            state.base_data = action.payload.base_data;
        }
    },
});

export const { updateStepOne, updateStepTwo, updateStepThree, updateStepFour, resetState, handle_date,
    handle_data, enable_loading, save_job_card } = stepSlice.actions;
export default stepSlice.reducer;
