export const MANUFACTURED_GOODS = "Manufactured Goods";
export const FINISHED_GOODS = "Finished Goods";
export const RAW_MATERIAL = "Raw material";
export const SEMI_FINISH_GOODS = "Semi Finish Goods";
export const CONSUMABLE_ITEMS = "Consumable Items";
export const FIXTURES = "Fixtures";
export const GAUGES = "Gauges";
export const GENERAL_ITEM = "General Item";
export const TOOLS = "Tools";

export const OPEN = "Open";
export const DRAFT = "Draft";
export const START = "Start";
export const PENDING = "Pending";
export const IN_PROGRESS = "In Progress";
export const CLOSED = "Closed";
export const CANCELLED = "Cancelled";
export const COMPLETED = "Completed";
export const RELEASED = "Released";
export const APPROVED = "Approved";
export const DELIVERED = "Delivered";
export const FINALIZED = "Finalized";

export const REWORK = "Rework";
export const REWORK_S = "Rework S";
export const SALVAGE = "Salvage";
export const SCRAP = "Scrap";

export const PURCHASE = "Purchase";

export const OPERATOR = "Operator";
export const JUNIOR_OPERATOR = "Junior Operator";
export const SENIOR_OPERATOR = "Senior Operator";

export const PER_HOUR_WAGE = "Per Day Wage";
export const PER_DAY_WAGE = "Per Hour Wage";

export const FASHION = "Fashion";
export const APPAREL = "Apparel";
export const MANUFACTURING = "Manufacturing";

export const UPDATE = "Update";

export const CUSTOMER = "Customer"
export const SUPPLIER = "Supplier"

export const FORGING = "FORGING";
export const BAR = "BAR";
export const CASTING = "CASTING";

export const TEMPLATE = "Template";
export const REPORT = "Report";

export const MASTER = "Master";
export const DESIGNATION = "Designation";
export const SALES_ORDER_STATUS = "Sales Order Status";

