import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {updateSOStatus } from "../../../api";
import * as name_const from "../../../common/Constants/constants"


const schema = yup
  .object()
  .shape({
    status_name: yup.string().trim().required("Name is required!"),
  })
  .required();

function EditSalesOrderStatus(props) {
  console.log("props", props);
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;  

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        status_name: data.status_name?.trim(),
        status_description: data.status_description !== "" ? data.status_description : null,
      };

      updateSOStatus(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(`${name_const.SALES_ORDER_STATUS} updated successfully!`, {
              autoClose: 2000,
            });
            setVisible(false);
            props.fetchSO_Status(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_sales_order_status
            ? props.constLabel?.lbl_edit_sales_order_status
            : "Edit Sales Order Status"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          setVisible(true);
          reset();
        }}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"md"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_sales_order_status
              ? props.constLabel?.lbl_update_sales_order_status
              : `Update ${name_const.SALES_ORDER_STATUS}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
          <div className="form-group row mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-md-12 roboto-bold"
                  >
                    {props.constLabel?.lbl_name ? props.constLabel?.lbl_name : "Name"}
                    <span className="text-danger"> *</span>
                  </label>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className={
                        errors.status_name
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      id="status_name"
                      name="status_name"
                      defaultValue={props.data?.status_name}
                      placeholder="Enter name"
                      {...register("status_name")}
                    />
                    <span className="text-danger err-msg">
                      {errors.status_name?.message}
                    </span>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-md-12 roboto-bold"
                  >
                    {props.constLabel?.lbl_description ? props.constLabel?.lbl_description : "Description"}
                  </label>
                  <div className="col-md-12">
                    <textarea
                      type="text"
                      className="form-control"
                      id="status_description"
                      name="status_description"
                      defaultValue={props.data?.status_description}
                      placeholder="Enter description"
                      {...register("status_description")}
                    ></textarea>
                  </div>
                </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditSalesOrderStatus;