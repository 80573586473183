import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faFilter,
  faCog,
  faAdd,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
// import XLSX from "xlsx";
import { Tooltip } from "react-tooltip";
import {
  getOperationCat,
  deleteOperation,
  createOperation,
  getOperation,
  getMachineList,
  get_operation_template,
  upload_operation,
  createOperationCat,
} from "../../../../api/index";
import {
  calMaxPage_new,
  getLabels,
  isExcelFile,
  setAccessPrivilage,
} from "../../../../common/Functions/CommonFunctions";
import EditOperation from "./EditOperation";
import RegisterMapping from "./RegisterMapping";
import { Operation_Col_Name } from "../../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../../assets/images/icons/project_menu/checklist.png";
import AccessDenied from "../../../Common/AccessDenied";

const schema = yup
  .object()
  .shape({
    category: yup
      .array()
      .min(1, "Please select category")
      .required("Please select category !"),
    operationName: yup.string().required("Operation name is required !"),
    // man_hour_cost: yup
    //   .string()
    //   .matches(/^(?!0)[0-9]+(\.[0-9]+)?$/, "Enter valid man hour cost !")
    //   .nullable() // Allow null values
    //   .notRequired(),
    man_hour_cost: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid man hour cost !",
      excludeEmptyString: true,
    }),
    items: yup.array().of(
      yup.object().shape({
        machine: yup
          .array()
          .min(1, "Please select machine !")
          .required("Please select machine !"),
        cycleTime: yup
          .string()
          .required("Cycle time is required !")
          .matches(/^\d+(\.\d+)?$/, "Enter valid cycle time !"),
        settingTime: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter valid setting time !")
          .notRequired(),
        toolChangeTime: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter valid tool change time !")
          .notRequired(),
        machineRate: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter valid machine rate !")
          .notRequired(),
      })
    ),
  })
  .required();

function Operation() {
  const [showLabel, setShowLabel] = useState(Operation_Col_Name);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [selVal, setSelVal] = useState({});
  const [op_cat, setOp_Cat] = useState({ data: [], state: false });
  const [constLabel, setConstLabel] = useState({});

  const [machineList, setMachineList] = useState({ data: [], loading: false });

  const [sea_val, setSea_val] = useState(false);

  const [filterConfig, setFilterConfig] = useState({ searchVal: "" });
  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const [importData, setImportData] = useState(false);

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [
        {
          machine: [],
          cycleTime: "",
          settingTime: "",
          toolChangeTime: "",
          machineRate: "",
        },
      ],
    },
  }); // initialise the hook

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const selCategory = watch("category");

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Operation"));
  }, []);

  useEffect(() => {
    // resetting the form
    if (visible) {
      fetchMachineList();
      fetchOpCategory();
    }
  }, [visible]);

  useEffect(() => {
    setConstLabel(getLabels("Master", "Operation"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchOperation(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchOperation(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.searchVal !== "") {
      // payload.productName_list = filterConfig.productName;
      payload.searchVal = filterConfig.searchVal;

      if (
        filterConfig.searchVal?.length > 0 &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;

        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.searchVal;
    }

    getOperation(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        if (
          filterConfig.searchVal !== "" &&
          filterConfig.searchVal?.length > 0
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        //  console.log('calMaxPage_new(res.data.total)', calMaxPage_new(res.data.total,epp));
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  function fetchMachineList() {
    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        if (res.data.data.length > 0) {
          const result = res.data.data.map((item) => ({
            ...item,
            machineNameSerialNo: item.machineName + " - " + item.serialNo,
          }));
          setMachineList({ ...machineList, data: result, loading: false });
        } else {
          setMachineList({ ...machineList, data: [], loading: false });
        }
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  function fetchOpCategory(){
    setOp_Cat({ ...op_cat, state: true, data: [] });
      getOperationCat().then(
        (res) => {
          setOp_Cat({
            ...op_cat,
            state: false,
            data: res.data.data,
          });
        },
        (err) => {
          //
          setOp_Cat({ ...op_cat, state: false, data: [] });
          toast.error(err.response.data.message, {
            autoClose: 3000,
          });
        }
      );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      let machineArr = [];
      if (data.items?.length > 0) {
        for (let i = 0; i < data.items?.length; i++) {
          const payload = {
            cycleTime: data.items[i]?.cycleTime,
            settingTime: data.items[i]?.settingTime,
            toolChangeTime: data.items[i]?.toolChangeTime,
            machineRate: data.items[i]?.machineRate,
            machine: data.items[i]?.machine,
          };
          machineArr.push(payload);
        }
      }

      const reqPayload = {
        operationName: data.operationName,
        operationCategoryId: selCategory[0]?.operationCategoryId,
        oprationCategoryName: selCategory[0]?.oprationCategoryName,
        operation_category_id: selCategory?.[0]?.uuid,
        man_hour_cost: data.man_hour_cost == "" ? null : data.man_hour_cost,
        machine_data: machineArr,
      };

      createOperation(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Operation created successfully !", {
              autoClose: 2000,
            });
            fetchOperation(page, entriesPerPage);
            setVisible(false);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }

          if (err.response.status === 404) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  // Deleting operation master
  const delOperation = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        deleteOperation(payload).then(
          (res) => {
            toast.success("Operation deleted successfully !", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchOperation(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchOperation(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function clearFilter() {
    setFilterConfig({ ...filterConfig, searchVal: "" });
    setSrNo(0);
    setPage(1);
  }

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `Operation_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    setDownloadTemplate(false);
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    console.log("file", file);
    // setSelectedFile(file);

    if (isExcelFile(file.name)) {
      try {
        if (file) {
          console.log("file", file);
          const reader = new FileReader();

          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            console.log("base64String", base64String);
            setImportData(true);

            upload_operation({ file_base_64: base64String }).then(
              (res) => {
                if (res.status === 200 || res.status === 201) {
                  toast.success("Operations imported successfully !", {
                    autoClose: 3000,
                  });
                  fetchOperation(page, entriesPerPage);
                }
              },
              (err) => {
                if (err.response.status === 400) {
                  toast.error(err.response, {
                    autoClose: 2000,
                  });
                }
              }
            ).finally(() => {
              setImportData(false);
            });
          };

          reader.onerror = (error) => {
            toast.error("Error creating base 64 string !", {
              autoClose: 2000,
            });
          };


        }
      } catch (err) {
        toast.error("Something went wrong !", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("Import valid excel file !", {
        autoClose: 2000,
      });
    }
  };


  function addOpCategory(data){

    if (data !== "") {
      const reqPayload = {
        oprationCategoryName: data,
      };
      createOperationCat(reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Operation category created successfully !", {
              autoClose: 2000,
            });
            fetchOpCategory();
            setValue("category", [res.data], { shouldValidate: true });
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {visible2 === true ? (
        <RegisterMapping
          data={selVal}
          fetchOperation={fetchOperation}
          visible2={visible2}
          setVisible2={setVisible2}
        />
      ) : null}

      {/* Add Item Modal*/}
      {!privilage.read ?
        <AccessDenied />
        :
        <div className="min-vh-100" id="root_div_main">
          <Modal
            show={visible}
            onHide={() => setVisible(false)}
            size={"lg"}
            backdrop="static"
          >
            <Modal.Header
              style={{ background: "#2A3643", color: "white" }}
              closeButton
            >
              <Modal.Title>
                {constLabel?.lbl_add_operation
                  ? constLabel.lbl_add_operation
                  : "Add Operation"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form action="">
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-group typeahead-container">
                      <label
                        className="lbl-style roboto-bold mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        {constLabel?.lbl_category_name
                          ? constLabel.lbl_category_name
                          : "Category Name"}
                        <span className="text-danger"> *</span>
                      </label>

                      <Controller
                        name="category"
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="oprationCategoryName"
                            multiple={false}
                            options={op_cat.data !== undefined ? op_cat.data : []}
                            isLoading={op_cat.state}
                            placeholder="Select category name"
                            // onChange={onChange}
                            onChange={(selected) => {
                              // If the new option is added
                              const isNewOption = selected && selected[0]?.customOption;
                              if (isNewOption) {
                                const newItem = selected[0].oprationCategoryName;
                                // Fire API to add the new category
                                addOpCategory(newItem);
                              }
                              onChange(selected);
                            }}
                            newSelectionPrefix="Add New: "
                            allowNew                // Enables adding new options
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.category ? true : false}
                          />
                        )}
                      />
                      {!op_cat.loading && !errors.category && <span className="arrow-icon"></span>}
                      {errors.category && (
                        <span className="text-danger err-msg">
                          {errors.category.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <label
                      className="lbl-style roboto-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {constLabel?.lbl_operation_name
                        ? constLabel.lbl_operation_name
                        : "Operation Name"}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className={
                        errors.operationName
                          ? "form-control  is-invalid mt-2"
                          : "form-control mt-2 "
                      }
                      id="operationName"
                      name="operationName"
                      placeholder="Enter operation name"
                      {...register("operationName")}
                    />
                    {errors.operationName && (
                      <span className="text-danger err-msg">
                        {errors.operationName.message}
                      </span>
                    )}
                  </div>

                  <div className="col-12 col-md-4">
                    <label
                      className="lbl-style roboto-bold"
                      style={{ fontSize: "14px" }}
                    >
                      {constLabel?.lbl_man_hour_cost
                        ? constLabel.lbl_man_hour_cost
                        : "Man Hour Cost"}{" "}<span>&#8377;</span>
                    </label>
                    <input
                      type="text"
                      className={
                        errors.man_hour_cost
                          ? "form-control is-invalid mt-2"
                          : "form-control mt-2 "
                      }
                      id="man_hour_cost"
                      name="man_hour_cost"
                      placeholder="Enter man hour cost"
                      {...register("man_hour_cost")}
                    />
                    {errors.man_hour_cost && (
                      <span className="text-danger err-msg">
                        {errors.man_hour_cost.message}
                      </span>
                    )}
                  </div>
                </div>
                <hr className="mb-1" />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="roboto-bold mb-0" style={{ fontSize: "16px" }}>
                    {constLabel?.lbl_machine_detail
                      ? constLabel?.lbl_machine_detail
                      : "Machine Detail"}
                  </p>
                  <div>
                    <a
                      style={{ fontSize: "16px" }}
                      className="btn btn-sm button-primary px-2 mt-2"
                      onClick={() => {
                        append({
                          machine: [],
                          cycleTime: "",
                          settingTime: "",
                          toolChangeTime: "",
                          machineRate: "",
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          color: "#ffffff",
                          marginRight: "5px",
                        }}
                        icon={faAdd}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_add ? constLabel?.lbl_add : "Add"}
                    </a>
                  </div>
                </div>
                <hr className="mt-1" />
                <div className="col-12">
                  {/* {fields.length > 0 ? ( */}
                  <div className="">
                    <table className="table my-2 table-stirped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            {constLabel?.lbl_machine
                              ? constLabel?.lbl_machine
                              : "Machine"}
                            <span className="text-danger"> *</span>
                          </th>
                          <th>
                            {constLabel?.lbl_cycle_time
                              ? constLabel?.lbl_cycle_time
                              : "Cycle Time"}
                            <span className="text-danger"> *</span>
                          </th>
                          <th>
                            {constLabel?.lbl_setting_time
                              ? constLabel?.lbl_setting_time
                              : "Setting Time"}
                          </th>
                          <th>
                            {constLabel?.lbl_tool_change_time
                              ? constLabel?.lbl_tool_change_time
                              : "Tool Change Time"}
                          </th>
                          <th>
                            {constLabel?.lbl_machine_rate
                              ? constLabel?.lbl_machine_rate
                              : "Machine Rate"}
                          </th>
                          <th>{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(({ id }, index) => (
                          <tr key={id}>
                            <td style={{ verticalAlign: "top" }} width={"30%"}>
                              <Controller
                                name={`items[${index}].machine`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    size="sm"
                                    id="basic-typeahead"
                                    labelKey="machineNameSerialNo"
                                    multiple={false}
                                    options={machineList.data}
                                    isLoading={machineList.loading}
                                    placeholder="Select machine"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={
                                      errors.items?.[index]?.machine
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />
                              {errors.items?.[index]?.machine && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.machine.message}
                                </span>
                              )}

                              {/* {index + 1 == fields.length ? (
                              <div>
                                <button
                                  style={{ fontSize: "16px" }}
                                  className="btn btn-sm button-primary px-2 mt-2"
                                  onClick={() => {
                                    append({
                                      machine: [],
                                      cycleTime: "",
                                      settingTime: "",
                                      toolChangeTime: "",
                                      machineRate: "",
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      color: "#ffffff",
                                      marginRight: "5px",
                                    }}
                                    icon={faAdd}
                                    size="sm"
                                  />{" "}
                                  {constLabel?.lbl_add
                                    ? constLabel.lbl_add
                                    : "Add"}
                                </button>
                              </div>
                            ) : null} */}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.cycleTime
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].cycleTime`}
                                {...register(`items[${index}].cycleTime`)}
                                placeholder="Enter cycle time"
                              />

                              {errors.items?.[index]?.cycleTime && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.cycleTime.message}
                                </span>
                              )}
                            </td>
                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.settingTime
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].settingTime`}
                                {...register(`items[${index}].settingTime`)}
                                placeholder="Enter setting time"
                              />

                              {errors.items?.[index]?.settingTime && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.settingTime.message}
                                </span>
                              )}
                            </td>
                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.toolChangeTime
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].toolChangeTime`}
                                {...register(`items[${index}].toolChangeTime`)}
                                placeholder="Enter tool change time"
                              />

                              {errors.items?.[index]?.toolChangeTime && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.toolChangeTime.message}
                                </span>
                              )}
                            </td>
                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.machineRate
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].machineRate`}
                                {...register(`items[${index}].machineRate`)}
                                placeholder="Enter machine rate"
                              />

                              {errors.items?.[index]?.machineRate && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.machineRate.message}
                                </span>
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                              }}
                            >
                              <Tooltip
                                id={"delete-tooltip" + index}
                                place="top"
                              />
                              <Trash2
                                data-tooltip-id={"delete-tooltip" + index}
                                data-tooltip-content={"Delete"}
                                className="menu_icon_red cursor_pointer"
                                size={20}
                                onClick={
                                  index > 0
                                    ? () => {
                                      remove(index);
                                    }
                                    : null
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* ) : (
                  <div
                    className="alert alert-danger mb-0 text-center"
                    role="alert"
                  >
                    {"No machine data found !"}
                  </div>
                )} */}
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={handleSubmit(onSubmit)}
                className="f-16 btn btn-yellow "
              >
                {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
              </button>
            </Modal.Footer>
          </Modal>
          <div id="mySidebar" className="customsidebar ">
            <a className="closebtn" onClick={() => closeNav()}>
              ×
            </a>

            <div className=" content">
              <div className="filter row">
                <label
                  className="filterLabel mb-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
                </label>
                <div className="col-12">
                  <input
                    type="text"
                    id="searchField"
                    className="form-control form-control-sm"
                    placeholder="Search by name"
                    value={filterConfig.searchVal}
                    onChange={(val) => {
                      const inputValue = val.target.value;
                      if (!inputValue.trim()) {
                        // Input contains only whitespace, don't trigger the search.
                        if (filterConfig.searchVal.length == 1) {
                          setFilterConfig({ ...filterConfig, searchVal: "" });
                        }

                        if (inputValue.length == 0) {
                          setFilterConfig({ ...filterConfig, searchVal: "" });
                        }
                      } else {
                        setFilterConfig({
                          ...filterConfig,
                          searchVal: inputValue,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="my-3 d-flex justify-content-end">
                <button
                  className="btn btn-sm btn-yellow"
                  onClick={() => clearFilter()}
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start align-items-center col-12 col-md-4">
                    {/* <FontAwesomeIcon
                      onClick={() => toggleNav()}
                      className="cursor_pointer mb-2"
                      style={{
                        color: "Black",
                        marginRight: "10px",
                      }}
                      icon={faBars}
                      size="lg"
                    /> */}
                    <h1 className="bold">
                      {constLabel?.lbl_operation
                        ? constLabel.lbl_operation
                        : "Operation"}
                    </h1>
                  </div>
                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-8">
                    <div className="col-12 col-md-6 me-md-2 me-md-0">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search by name"
                        value={filterConfig.searchVal}
                        onChange={(val) => {
                          const inputValue = val.target.value;
                          if (!inputValue.trim()) {
                            // Input contains only whitespace, don't trigger the search.
                            if (filterConfig.searchVal.length == 1) {
                              setFilterConfig({ ...filterConfig, searchVal: "" });
                            }

                            if (inputValue.length == 0) {
                              setFilterConfig({ ...filterConfig, searchVal: "" });
                            }
                          } else {
                            setFilterConfig({
                              ...filterConfig,
                              searchVal: inputValue,
                            });
                          }
                        }}
                      />
                    </div>

                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => {
                          setVisible(true);
                          reset();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_add_operation
                          ? constLabel.lbl_add_operation
                          : "Add Operation"}
                      </button>
                    )}

                    {privilage.report &&
                      <>
                        <Tooltip id={"download-tooltip"} place="top" />
                        <button
                          className="btn btn-light-grey me-0 me-md-2 mt-2 mt-md-0"
                          data-tooltip-id={"download-tooltip"}
                          data-tooltip-content={
                            constLabel?.lbl_download_template
                              ? constLabel.lbl_download_template
                              : "Download Template"}
                          onClick={() => {
                            setDownloadTemplate(true);
                            get_operation_template().then((res) => {
                              // console.log("res", res);
                              base64ToExcel(res.data);
                            }, (err) => {
                              console.log("err", err);
                              toast.error("Something went wrong", {
                                autoClose: 2000,
                              });
                              setDownloadTemplate(false);
                            })
                          }}
                          disabled={downloadTemplate}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faDownload}
                            size="sm"
                          />{" "}{downloadTemplate && <Spinner animation="border" size="sm" />}
                        </button>
                      </>
                    }

                    {privilage.report &&
                      <>
                        <Tooltip id={"upload-tooltip"} place="top" />
                        <input
                          type="file"
                          accept=".pdf, .jpg, .jpeg, .png" // Define allowed file types
                          onChange={handleFileChange}
                          style={{ display: "none" }} // Hide the input element
                          id="fileInput"
                        />
                        <label htmlFor="fileInput">
                          <button
                            className="btn btn-grey me-0 me-md-2 mt-2 mt-md-0"
                            data-tooltip-id={"download-tooltip"}
                            data-tooltip-content={
                              constLabel?.lbl_import_operation
                                ? constLabel.lbl_import_operation
                                : "Import Operation"}
                            onClick={() =>
                              document.getElementById("fileInput").click()
                            }
                            onChange={handleFileChange}
                            disabled={importData}
                          >
                            <FontAwesomeIcon
                              style={{ color: "#fff" }}
                              icon={faUpload}
                              size="sm"
                            />{" "}{importData && <Spinner animation="border" size="sm" />}
                          </button>
                        </label>
                      </>
                    }

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {dataList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dataList && dataList.data?.length > 0 ? (
                    <>
                      <div className="">
                        <table className="table table-responsive table-bordered">
                          <thead className="table-grey roboto">
                            <tr className="f-14 ">
                              <th scope="col" width="10%">
                                {"Sr.No"}
                              </th>
                              {showLabel.map((v, i) =>
                                v.label === "Operation Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_operation_name
                                      ? constLabel.lbl_operation_name
                                      : "Operation Name"}
                                  </th>
                                ) : v.label === "Category Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_category_name
                                      ? constLabel.lbl_category_name
                                      : "Category Name"}
                                  </th>
                                ) : null
                              )}
                              {/* <th scope="col" width="15%">
                              {"Register Mapping"}
                            </th> */}
                              <th scope="col" width="10%">
                                {"Action"}
                              </th>
                            </tr>
                          </thead>
                          <tbody className=" roboto">
                            {dataList.data?.map((val, i) => (
                              <tr key={i}>
                                <td>{i + parseInt(srNo) + 1}</td>
                                {showLabel.map((item, idx) =>
                                  item.label === "Operation Name" && item.flag ? (
                                    <td key={idx}>
                                      {val.operationName != "" &&
                                        val.operationName !== null
                                        ? val.operationName
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Category Name" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.oprationCategoryName != "" &&
                                        val.oprationCategoryName !== null
                                        ? val.oprationCategoryName
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                {/* <td className="">

                              <Tooltip id={"view-tooltip"} place="top" />
                                  <div
                                    data-tooltip-id={"view-tooltip"}
                                    data-tooltip-content="Register Mapping" className="pdrIcon me-2" onClick={() => { setSelVal(val); setVisible2(true) }}>
                                    <FileText color="#FFFFFF" size={18} />
                                  </div>

                              </td> */}
                                <td className="">
                                  {privilage.write && (
                                    <EditOperation
                                      data={val}
                                      constLabel={constLabel}
                                      fetchOperation={fetchOperation}
                                      page={page}
                                      entriesPerPage={entriesPerPage}
                                    />
                                  )}

                                  {privilage.delete && (
                                    <>
                                      <Tooltip
                                        id={"delete-tooltip" + i}
                                        place="top"
                                      />
                                      <Trash2
                                        data-tooltip-id={"delete-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_delete_operation
                                            ? constLabel?.lbl_delete_operation
                                            : "Delete Operation"
                                        }
                                        className="menu_icon_red cursor_pointer"
                                        size={20}
                                        onClick={() => delOperation(val)}
                                      />
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <p className="mb-0">
                          Show
                          <select
                            className="mx-1"
                            defaultValue={entriesPerPage}
                            onChange={(e) => {
                              setEntriesPerPage(e.target.value);
                              fetchOperation(1, e.target.value);
                              setSrNo(0);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          Entries
                        </p>
                        <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                          } to ${Math.min(
                            parseInt(entriesPerPage) + parseInt(srNo),
                            dataList.totalRecords
                          )} of ${dataList.totalRecords} entries`}</p>
                        <Pagination className="my-2">
                          <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {
                              fetchOperation(
                                page - 1,
                                entriesPerPage,
                                parseInt(srNo) - parseInt(entriesPerPage)
                              );
                              setSrNo((prevC) =>
                                page - 1 == 1
                                  ? 0
                                  : prevC - parseInt(entriesPerPage)
                              );
                              setPage(page - 1);
                            }}
                          >
                            {"Prev"}
                          </Pagination.Prev>

                          <Pagination.Item active>{page}</Pagination.Item>

                          <Pagination.Next
                            disabled={
                              page === maxPage || maxPage === 0 ? true : false
                            }
                            onClick={() => {
                              fetchOperation(
                                page + 1,
                                entriesPerPage,
                                parseInt(srNo) + parseInt(entriesPerPage)
                              );
                              setSrNo(
                                (prevC) => prevC + parseInt(entriesPerPage)
                              );
                              setPage(page + 1);
                            }}
                          >
                            {"Next"}
                          </Pagination.Next>
                        </Pagination>
                      </div>
                    </>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Operations not found !"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default Operation;
