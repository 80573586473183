import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faPlus,
  faAdd,
  faCircle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Trash2 } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import {
  getSupplier,
  getUOM,
  getProdList,
  getProductType,
  getPOStatus,
  getInternalCompany,
  getQuotation,
  updatePO,
  getGST,
  getQuote_Products,
  get_master_files,
  master_multi_file_upload,
  deletePO_line_item, getMaterialRequest,
} from "../../../api/index";
import {
  convertDateYYMMDD,
  convertDateFormat,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import AddProduct from "../AddProducts/AddProduct";
import UpdatePOImageGallary from "./UpdatePOImageGallary";
import { material_request } from "../../../constant";

const schema = yup
  .object()
  .shape({
    // ncPrefix: yup.string().required("Prefix is required !").matches(/^[A-Z0-9]*$/, "Only alphabets and space Allowed !"),,
    supplier: yup
      .array()
      .min(1, "Please select supplier !")
      .required("Please select supplier !"),
    invoiceTo: yup
      .array()
      .min(1, "Please select company !")
      .required("Please select company !"),
    dispatchTo: yup
      .array()
      .min(1, "Please select company !")
      .required("Please select company !"),
    // quoteRef: yup.string().required("Please enter quotation reference !"),
    status: yup
      .array()
      .min(1, "Please select status !")
      .required("Please select status !"),
    material_request: yup
      .array()
      .notRequired(),
    // remarks: yup.string().required("Please enter remarks !"),
    // client_poid: yup.string().required("Please enter client po id !"),
    // internalRemarks: yup.string().when("quoteRef", {
    //   is: (quoteRef) => quoteRef == undefined || quoteRef?.length == 0,
    //   then: yup.string().required("Please enter internal remarks"),
    //   otherwise: yup.string(),
    // }),
    creditPeriod: yup.lazy((value) => {
      if (value?.trim() === '') {
        return yup.string().nullable().notRequired();  // Optional if empty or not provided
      }
      return yup.string()
        .matches(/^[0-9]+$/, "Enter valid credit period")  // Validation when value is present
        .required("Please enter a valid credit period");
    }),
    po_date: yup.date().required("Please select date !").typeError("Please select date !"),
    items: yup.array().of(
      yup.object().shape({
        prod_type: yup
          .array()
          .min(1, "Please select product type !")
          .required("Please select product type !"),
        product: yup
          .array()
          .min(1, "Please select product !")
          .required("Please select product !"),
        quantity: yup
          .string()
          .required("Quantity is required")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid quantity")
          .typeError("Enter valid quantity"),
        unit: yup
          .array()
          .min(1, "Please select unit !")
          .required("Please select unit !"),
        price: yup
          .string()
          .required("Price is required")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid price")
          .typeError("Enter valid price"),
        gst_rate: yup
          .string()
          .required("gst rate  is required !")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter gst rate !")
          .typeError("Enter gst rate !"),
        gst_amount: yup
          .string()
          .notRequired()
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter gst amount !")
          .typeError("gst amount is invalid !"),
        discount: yup
          .string()
          .required("Discount is required !")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter discount !")
          .typeError("Enter discount !"),
        hsn_code: yup
          .string()
          .nullable()
          .notRequired(),
      }),
    ),
    discount: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid discount")
      .required("Discount is required"),
  })
  .required();

function UpdatePurchaseOrd() {
  let navigate = useNavigate();
  let location = useLocation();

  const [supplierList, setSupplierList] = useState({
    data: [],
    loading: false,
  });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [prod_List, setProd_List] = useState({ data: [], loading: false });
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [mat_req_list, set_mat_req_list] = useState({ data: [], loading: false });
  const [poStatusList, setPoStatusList] = useState({
    data: [],
    loading: false,
  });
  const [internalCompList, setInternalComp] = useState({
    data: [],
    loading: false,
  });
  const [quotationList, setQuotationList] = useState({
    data: [],
    loading: false,
  });
  const [usingQuoteProd, setUsingQuoteProd] = useState(location.state.quote_ref_id ? true : false);
  const [galVisible, setGalVisible] = useState(false);
  const [drawingFiles, setDrawingFiles] = useState({
    loading: false,
    data: [],
  });
  const [taskImgs, setTaskImgs] = useState({ loading: false, data: [] });
  // const [img_arr, setImg_Arr] = useState({ loading: false, data: [] });

  const [constLabel, setConstLabel] = useState({});
  const [disableLineItems, setDisableLineItems] = useState(true);


  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [
        {
          prod_type: [],
          product: [],
          quantity: 0,
          unit: [],
          price: 0,
          amount: 0,
          gst_rate: 0,
          gst_amount: 0,
          discount: 0,
        },
      ],
    },
  });
  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const userData = JSON.parse(localStorage.getItem("localData"));
  const lineItems = watch("items");
  const invTo = watch("invoiceTo");
  const supp = watch("supplier");
  // let qRef = watch("quoteRef");
  const tempQI = [];

  const [subTotalForRef, setSubtotalForRef] = useState(0);
  const [taxDetails, setTaxDetails] = useState({});
  const [tempCount, setTempCount] = useState(0);
  const [gstErr, setGstErr] = useState(false);

  let subTotalForRef2 = "";

  useEffect(() => {
    console.log("location.state", location.state);


    setConstLabel(getLabels("Master", "Purchase Order"));
    fetchUOM();
    fetchSuppliers();
    // fetchProdList();
    fetchProductType();
    fetchPOStatus();
    fetchInternalComp();
    fetchQuotation();

    // setValue("IGST", location.state.igst);
    // setValue("CGST", location.state.cgst);
    // setValue("SGST", location.state.sgst);
    // setValue("UGST", location.state.ugst);
    if (location.state !== undefined) {
      fetchImage();
    }



    const robj = {
      // po_date: new Date(convertDateFormat(location.state.po_date)),
      po_date: new Date(location.state.po_date),
      creditPeriod: location.state.credit_period,
      // client_poid: location.state.client_poid,
      invoiceTo: [
        {
          id: location.state.invoice_to,
          uuid: location.state.invoice_to_id.uuid,
          comName: location.state.invoice_toname,
          comaddress: location.state.invoicetoaddress,
          comGSTnumber: location.state.invoice_to_id.comGSTnumber,
        },
      ],
      dispatchTo: [
        {
          id: location.state.despatch_to,
          uuid: location.state.despatch_to_id.uuid,
          comName: location.state.despatch_toname,
          comaddress: location.state.despatchtoaddress,
        },
      ],
      supplier: [
        {
          uuid: location.state.supplier_id.uuid,
          partyCode: location.state.supplier,
          name: location.state.suppliername,
          address: location.state.supplieraddress,
          gst: location.state.supplier_id.gst,
        },
      ],
      discount: location.state.discount,
      totalAmount: location.state.net_amount,
      status: [
        {
          uuid: location.state.po_status_id,
          postatus: location.state.po_status,
        },
      ],
      remarks: location.state.remark,
      material_request: location.state.mat_req_data !== undefined &&
        location.state.mat_req_data !== null ?
        [location.state.mat_req_data] : [],
      quoteRef: [
        {
          uuid: location.state.quote_ref_id,
          quotesid: location.state.quote_ref,
          supplierid_id: location.state.supplier_id.uuid,
          discount: location.state.discount,
        },
      ],
      // items: temp,
      internalRemarks: location.state.internal_remark,
      CGST: location.state.cgst,
      SGST: location.state.sgst,
      UGST: location.state.ugst,
      IGST: location.state.igst,
      CGST_amount: location.state.cgst_cost,
      IGST_amount: location.state.igst_cost,
      UGST_amount: location.state.ugst_cost,
      SGST_amount: location.state.sgst_cost,
    };
    // if (
    //   location.state.quote_ref_id == null ||
    //   location.state.quote_ref_id == undefined
    // ) {
    robj.quoteRef = [];
    const temp = [];
    // console.log("location.state", location.state);
    for (let i = 0; i < location.state.PO_line_item.length; i++) {
      temp.push({
        uuid: location.state.PO_line_item[i].uuid,
        prod_type: [
          {
            productType: location.state.PO_line_item[i].producttype,
            uuid: location.state.PO_line_item[i].product_type_id,
          },
        ],
        product: [
          {
            uuid: location.state.PO_line_item[i].productid_id?.uuid,
            productId: location.state.PO_line_item[i].product_id,
            productName: location.state.PO_line_item[i].productname,
            diameter: location.state.PO_line_item[i].diameter,
            partNo: location.state.PO_line_item[i].partNo,
          },
        ],
        quantity: location.state.PO_line_item[i].quantity,
        unit: [
          {
            uom: location.state.PO_line_item[i].unit,
            uuid: location.state.PO_line_item[i].unit_id,
          },
        ],
        price: location.state.PO_line_item[i].rate,
        amount: location.state.PO_line_item[i].amount,
        gst_rate: location.state.PO_line_item[i].gst_rate,
        gst_amount: location.state.PO_line_item[i].gst_amount,
        discount: location.state.PO_line_item[i].discount,
        hsn_code: location.state.PO_line_item[i].productid_id?.hsn,
        material_req_item_uuid: location.state.PO_line_item[i].material_req_item_uuid,
        from_material_req: location.state.PO_line_item[i].material_req_item_uuid ?? false,
      });
    }
    robj.items = temp;
    // }
    console.log(robj);
    reset(robj);
  }, []);


  useEffect(() => {
    let dis = getValues("discount");

    if (lineItems !== undefined) {
      if (lineItems.length > 0) {
        calSubAmt();
      }
    }

    if (dis !== null && dis !== undefined && dis?.toString()?.length > 0) {
      cal_final_total_with_tax_details();
    }
  }, [lineItems]);



  useEffect(() => {
    if (Object.keys(taxDetails).length > 0) {
      cal_final_total_with_tax_details();
    }
  }, [taxDetails]);


  useEffect(() => {
    if (invTo !== undefined && supp !== undefined) {
      if (invTo.length > 0 && supp.length > 0) {
        fetchGST({
          suplier_gst_number: supp[0].gst !== null ? supp[0].gst : "",
          invoice_gst_number: invTo[0].comGSTnumber,
        });
        setDisableLineItems(false);
      } else {
        setDisableLineItems(true);
      }

      // if (invTo && invTo.length > 0) {
      //   setValue("dispatchTo", invTo);
      // }
    }
  }, [invTo, supp]);


  function fetchMatReq() {
    set_mat_req_list({ ...mat_req_list, data: [], loading: true });

    getMaterialRequest().then((res) => {
      console.log("res", res);

      set_mat_req_list({ ...mat_req_list, data: res.data.data, loading: false });
    }, (err) => {

      set_mat_req_list({ ...mat_req_list, data: [], loading: false });
      console.log("err", err);
    });
  }

  function fetchImage() {
    setTaskImgs({ ...taskImgs, loading: true, data: [] });
    get_master_files({
      // ref_model_id: "",
      ref_model_uuid: location.state?.uuid,
      // model_name: "PO",
      // document_type: "image"
    }).then(
      (res) => {
        setTaskImgs({ ...taskImgs, loading: false, data: res.data.data });
      },
      (err) => {
        setTaskImgs({ ...taskImgs, loading: false, data: [] });
      }
    );
  }

  async function fetchGST(payload) {
    await getGST(payload).then(
      (res) => {
        setTaxDetails(res.data);
        // setValue("CGST", res.data.cgst);
        // setValue("SGST", res.data.sgst);
        // setValue("IGST", res.data.igst);
        // setValue("UGST", res.data.ugst);

        setGstErr(false);
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
        setGstErr(true);

        setValue("CGST", 0);
        setValue("SGST", 0);
        setValue("IGST", 0);
        setValue("UGST", 0);
        setTaxDetails({});
      }
    );
  }

  function calSubAmt() {
    if (lineItems !== undefined) {
      const totalAmount = lineItems.reduce(
        (accumulator, item) =>
          parseFloat(accumulator) + parseFloat(item.amount),
        0
      );

      setValue("subTotal", totalAmount);
      setSubtotalForRef(parseFloat(totalAmount).toFixed(2));
      subTotalForRef2 = parseFloat(totalAmount);
    }
  }

  function fetchQuotation() {
    setQuotationList({ ...quotationList, data: [], loading: true });
    getQuotation({ status_name: "Approved" }).then(
      (res) => {
        if (
          location.state !== null &&
          location.pathname === "/pcms/update/purchase-order" &&
          location.state.quote_ref_id !== null
        ) {
          const result = res.data.data.filter(
            (item) => item.uuid === location.state.quote_ref_id
          );
          // console.log("result", result);
          setValue("quoteRef", result, { shouldValidate: true });
        }
        setQuotationList({
          ...quotationList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setQuotationList({ ...quotationList, data: [], loading: false });
      }
    ).finally(() => {
      fetchMatReq();
    });
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProdList(selVal) {
    if (selVal.length > 0) {
      setProd_List({ ...prod_List, loading: true, data: [] });

      getProdList({ product_type_id: selVal[0].uuid }).then(
        (res) => {
          setProd_List({ ...prod_List, loading: false, data: res.data.data });
        },
        (err) => { }
      );
    } else {
    }
  }

  function handleProdType(selVal, index) {
    setValue(`items[${index}].prod_type`, selVal);
    setValue(`items[${index}].product`, []);
    if (selVal.length > 0) {
      fetchProdList(selVal);
    } else {
      setProd_List({ ...prod_List, loading: false, data: [] });
    }
  }

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        setProdType({ ...prodType, data: res.data.data, loading: false });
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  function fetchSuppliers() {
    setSupplierList({ ...supplierList, loading: true, data: [] });
    getSupplier({ partyType: "Supplier" }).then(
      (res) => {
        const result = res.data.data.filter((item) => item.name !== null);
        setSupplierList({
          ...supplierList,
          loading: false,
          data: result,
        });
      },
      (err) => {
        //
        setSupplierList({ ...supplierList, loading: false, data: [] });
        // toast.error(err.response.data.message, {
        //   autoClose: 3000,
        // });
      }
    );
  }

  function fetchPOStatus() {
    setPoStatusList({ ...poStatusList, data: [], loading: true });

    getPOStatus().then(
      (res) => {
        setPoStatusList({
          ...poStatusList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setPoStatusList({ ...poStatusList, data: [], loading: false });
      }
    );
  }

  function fetchInternalComp() {
    setInternalComp({ ...internalCompList, data: [], loading: true });

    getInternalCompany().then(
      (res) => {
        setInternalComp({
          ...internalCompList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setInternalComp({ ...internalCompList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    console.log("data", data);
    let reqPayload = [];

    for (let i = 0; i < data.items.length; i++) {
      const lineItems = {
        product_type_id: data.items[i].prod_type[0].uuid,
        producttype: data.items[i].prod_type[0].productType,
        productid_id: data.items[i].product[0].uuid,
        product_id: data.items[i].product[0].productId,
        productname: data.items[i].product[0].productName,
        diameter: data.items[i].product[0].diameter,
        partnumber: data.items[i].product[0].partNo,
        quantity: parseFloat(data.items[i].quantity),
        // qtyinkg: "12",
        rate: data.items[i].price,
        unit: data.items[i].unit[0].uom,
        amount: (data.items[i].amount).toString(),
        gst_rate: parseFloat(data.items[i]?.gst_rate),
        gst_amount: parseFloat(data.items[i]?.gst_amount),
        discount: parseFloat(data.items[i]?.discount),
        unit_id: data.items[i].unit[0].uuid,
        comp_id: null,
        material_req_item_uuid: data.items[i].material_req_item_uuid ?? null
        // "uuid": data.items[i].uuid
      };

      if (!lineItems.material_req_item_uuid) {
        delete lineItems.material_req_item_uuid;
      }
      
      if (data.items[i].uuid) {
        lineItems.uuid = data.items[i].uuid;
      }

      reqPayload.push(lineItems);
    }

    const payload = {
      uuid: location.state.uuid,
      // client_poid: data.client_poid,
      po_date: convertDateYYMMDD(data.po_date, "-"),
      // "purchaserequisitionid": "102",
      invoice_to: data.invoiceTo[0].id,
      invoice_to_id: data.invoiceTo[0].uuid,
      invoice_toname: data.invoiceTo[0].comName,
      invoicetoaddress: data.invoiceTo[0].comaddress,
      despatch_to: data.dispatchTo[0].id,
      despatch_to_id: data.dispatchTo[0].uuid,
      despatch_toname: data.dispatchTo[0].comName,
      despatchtoaddress: data.dispatchTo[0].comaddress,
      supplier_id: data.supplier[0].uuid,
      supplier: data.supplier[0].partyCode,
      suppliername: data.supplier[0].name,
      supplieraddress: data.supplier[0].address,
      // "supplier_ref_no": "121",
      credit_period: data.creditPeriod ?? "0",
      discount: data.discount,
      net_amount: parseFloat(data.totalAmount),
      sub_total: parseFloat(data.subTotal),
      po_status: data.status[0].postatus,
      po_status_id: data.status[0].uuid,
      remark: data.remarks,
      internal_remark: data.internalRemarks,
      quote_ref:
        data.quoteRef !== undefined ? data.quoteRef[0]?.quotesid : null,
      quote_ref_id: data.quoteRef !== undefined ? data.quoteRef[0]?.uuid : null,
      // "quote_ref_id": data.quoteRef[0].uuid,
      po_product_list: reqPayload,
      material_request_uuid:
        data.material_request !== undefined ? data.material_request[0]?.uuid : null,
      // sgst: data.SGST,
      // cgst: data.CGST,
      // sgst_cost: data.SGST_amount,
      // cgst_cost: data.CGST_amount,
    };
    if (taxDetails.type == "sgst") {
      payload.cgst = data.CGST;
      payload.cgst_cost = data.CGST_amount;
      payload.sgst = data.SGST;
      payload.sgst_cost = data.SGST_amount;
    } else if (taxDetails.type == "igst") {
      payload.igst = data.IGST;
      payload.igst_cost = data.IGST_amount;
    } else if (taxDetails.type == "ugst") {
      payload.ugst = data.UGST;
      payload.ugst_cost = data.UGST_amount;

      payload.cgst = data.CGST;
      payload.cgst_cost = data.CGST_amount;
    } else {
      payload.cgst = 0;
      payload.cgst_cost = 0;
      payload.sgst = 0;
      payload.sgst_cost = 0;
      payload.igst = 0;
      payload.igst_cost = 0;
      payload.ugst = 0;
      payload.ugst_cost = 0;
    }

    // if (taskImgs.data.length > 0) {
    //   const fileUuids = drawingFiles.data
    //     .filter((item) => item.uuid !== null && item.uuid !== undefined)
    //     .map((item) => item.uuid);

    //   if (fileUuids.length > 0) {
    //     payload.file_uuids = JSON.stringify(fileUuids);
    //   }
    // }

    // let newImg = drawingFiles.data.filter((item) => !item.uuid);

    console.log("payload", payload);
    updatePO(payload).then(
      (res) => {
        // for (let i = 0; i < newImg.length; i++) {
        //   newImg[i].ref_model_id = "";
        //   newImg[i].ref_model_uuid = res.data?.po?.uuid;
        //   newImg[i].model_name = "PO";
        //   newImg[i].name = newImg[i].filename;
        //   newImg[i].document_type = "image";
        // }

        // let imgArr = {};
        // let formDataToSend = new FormData();
        // for (let a = 0; a < newImg.length; a++) {
        //   imgArr["attachment" + (a + 1)] = newImg[a].file;
        //   imgArr["obj" + (a + 1)] = JSON.stringify(newImg[a]);
        //   imgArr["img_count"] = newImg.length;

        //   for (const [key, value] of Object.entries(imgArr)) {
        //     formDataToSend.append(key, value);
        //   }
        // }

        toast.success("Purchase order updated successfully !", {
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/pcms/purchase-order");
          reset();
        }, [2200]);
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  };

  async function calRate(para1, para2, index) {
    let rate;
    let gst_rate;
    let gst_amount;
    let discount;
    let discounted_amt;
    let amount;
    let quantity;
    let price;
    para1 = Number(para1);

    if (para1.length == 0) {
      para1 = parseFloat(0).toFixed(2);
    }

    switch (para2) {
      case "quantity":
        // items[${index}].price
        price = parseFloat(
          getValues(`items[${index}].price`)
        ).toFixed(2);

        gst_rate = Number(getValues(`items[${index}].gst_rate`));
        discount = parseFloat(getValues(`items[${index}].discount`));

        rate = parseFloat((para1 * Number(price)).toFixed(2));

        discounted_amt = parseFloat((rate) * discount / 100).toFixed(2);

        gst_amount = parseFloat(((rate - discounted_amt) * gst_rate / 100).toFixed(2));

        // console.log(rate)
        // console.log(gst_amount)
        amount = rate - discounted_amt;

        setValue(`items[${index}].amount`, amount.toFixed(2));
        setValue(`items[${index}].gst_amount`, gst_amount);

        await calSubAmt();
        cal_final_total_with_tax_details();

        break;

      case "price":
        quantity = Number(getValues(`items[${index}].quantity`));

        rate = parseFloat((para1 * quantity).toFixed(2));

        gst_rate = Number(getValues(`items[${index}].gst_rate`));
        discount = parseFloat(getValues(`items[${index}].discount`));



        discounted_amt = parseFloat((rate) * discount / 100).toFixed(2);

        gst_amount = parseFloat(((Number(rate) - discounted_amt) * Number(gst_rate) / 100).toFixed(2));

        amount = rate - discounted_amt;

        setValue(`items[${index}].amount`, amount.toFixed(2));
        setValue(`items[${index}].gst_amount`, gst_amount);

        await calSubAmt();
        cal_final_total_with_tax_details();
        break;

      case "gst_rate":
        quantity = Number(getValues(`items[${index}].quantity`));
        price = Number(getValues(`items[${index}].price`));
        discount = parseFloat(getValues(`items[${index}].discount`));

        // console.log("quantity", quantity);
        // console.log("price", price);
        // console.log("discount", discount);

        discounted_amt = parseFloat((((quantity * price) * discount) / 100).toFixed(2));
        const result = parseFloat(((Number(quantity) * Number(price) - discounted_amt) * para1 / 100).toFixed(2));

        // console.log("result", result);
        setValue(`items[${index}].gst_amount`, result);

        amount = (quantity * price) - discounted_amt;

        setValue(`items[${index}].amount`, amount.toFixed(2));

        await calSubAmt();
        cal_final_total_with_tax_details();
        break;


      case "discount":
        quantity = Number(getValues(`items[${index}].quantity`));
        price = Number(getValues(`items[${index}].price`));
        gst_rate = Number(getValues(`items[${index}].gst_rate`));
        discounted_amt = parseFloat((((quantity * price) * para1) / 100).toFixed(2));

        const result2 = parseFloat(((Number(quantity) * Number(price) - discounted_amt) * gst_rate / 100).toFixed(2));
        setValue(`items[${index}].gst_amount`, result2);

        amount = (quantity * price) - discounted_amt;

        setValue(`items[${index}].amount`, amount.toFixed(2));


        await calSubAmt();
        cal_final_total_with_tax_details();
        break;


    }
  }

  async function cal_final_total_with_tax_details() {
    await calSubAmt();

    // let SGST = parseFloat(getValues("SGST"));
    // let CGST = parseFloat(getValues("CGST"));

    // Discount Calcultaion logic
    // console.log("taxDetails.type", taxDetails.type);
    const line_items = getValues('items');

    // console.log("line_items", line_items);

    if (!taxDetails.type || line_items?.length == 0) {
      setValue("totalAmount", "0.00");
    }

    let total_of_gst_amount = 0;
    let total_of_amt_after_discount = 0;

    if (line_items.length > 0) {
      line_items.forEach((val) => {
        total_of_gst_amount += Number(val.gst_amount);
        total_of_amt_after_discount += Number(val.amount);
      })
    }

    // console.log("total_of_gst_amount", total_of_gst_amount);
    // console.log("total_of_amt_after_discount", total_of_amt_after_discount);

    if (taxDetails.type && line_items.length > 0) {
      setValue("totalAmount", (Number(total_of_gst_amount) + Number(total_of_amt_after_discount)).toFixed(2));
    }


    if (taxDetails.type == "sgst") {

      setValue("SGST_amount", (Number(total_of_gst_amount) / 2).toFixed(2));
      setValue("CGST_amount", (Number(total_of_gst_amount) / 2).toFixed(2));
    } else if (taxDetails.type == "igst") {


      setValue("IGST_amount", Number(total_of_gst_amount).toFixed(2));
    } else if (taxDetails.type == "ugst") {

      setValue("CGST_amount", (Number(total_of_gst_amount) / 2).toFixed(2));
      setValue("UGST_amount", (Number(total_of_gst_amount) / 2).toFixed(2));
    }
  }


  // function CaltotalAmount(val, para2) {
  //   let subTotal = parseFloat(getValues("subTotal"));
  //   let SGST = getValues("SGST");
  //   let CGST = getValues("CGST");

  //   if (para2 == "CGST") {
  //     const taxAmt = (subTotal * (parseFloat(val) + parseFloat(SGST))) / 100;
  //     const taxedAmt = subTotal + taxAmt;

  //     setValue("totalAmount", taxedAmt.toFixed(2));

  //     setValue("CGST_amount", ((subTotal * CGST) / 100).toFixed(2));
  //   }
  //   if (para2 == "SGST") {
  //     const taxAmt = (subTotal * (parseFloat(val) + parseFloat(CGST))) / 100;
  //     const taxedAmt = subTotal + taxAmt;

  //     setValue("totalAmount", taxedAmt.toFixed(2));

  //     setValue("SGST_amount", ((subTotal * SGST) / 100).toFixed(2));
  //   }
  // }

  function delPO_line_item_Product(index) {
    SweetAlert.fire({
      title: "Are you sure to delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (fields[index].uuid !== null && fields[index].uuid !== undefined) {
          deletePO_line_item({ uuid: fields[index].uuid }).then(
            (res) => {
              remove(index);
              toast.success("Product deleted successfully !", {
                autoClose: 1000,
              });
            },
            (err) => {
              toast.error("Something went wrong ");
            }
          );
        } else {
          remove(index);
        }
      }
    });
  }

  // function handleMaterialReqChange(val) {
  //   if (val.length == 0) {
  //     setValue("items", [{
  //       prod_type: [],
  //       product: [],
  //       quantity: 0,
  //       unit: [],
  //       price: 0,
  //       amount: 0,
  //       gst_rate: 0,
  //       gst_amount: 0,
  //       discount: 0,
  //     },]);
  //     setValue("subTotal", 0.0);
  //     setValue("taxAmt", 0.0);
  //     setValue("discount", 0);
  //     setValue("CGST", 0);
  //     setValue("CGST_amount", 0);
  //     setValue("SGST", 0);
  //     setValue("SGST_amount", 0);
  //     setValue("IGST", 0);
  //     setValue("IGST_amount", 0);
  //     setValue("UGST", 0);
  //     setValue("UGST_amount", 0);

  //   } else {
  //     for (const mat_req_item of val[0]?.material_req_lineitems) {
  //       let temp_obj = {
  //         prod_type: [
  //           {
  //             productType: mat_req_item.prod_data.prodtype.prodTypeId,
  //             uuid: mat_req_item.prod_data.prodtype.uuid,
  //           },
  //         ],
  //         product: [
  //           {
  //             uuid: mat_req_item.prod_data.uuid,
  //             productName: mat_req_item.prod_data.productName,
  //             productId: mat_req_item.prod_data.productId,
  //           },
  //         ],
  //         hsn_code: mat_req_item.prod_data.hsn ?? "",
  //         quantity: mat_req_item.qty,
  //         unit: [
  //           {
  //             uom: mat_req_item.prod_data.uomproduct.uom,
  //             uuid: mat_req_item.prod_data.uomproduct.uuid,
  //           },
  //         ],
  //         price: 0,
  //         amount: 0,
  //         gst_amount: 0,
  //         discount: 0,

  //       }

  //       if (mat_req_item.prod_data.hsn_model) {
  //         temp_obj.gst_rate = mat_req_item.prod_data.hsn_model?.gst_rate
  //       } else {
  //         temp_obj.gst_rate = 0
  //       }
  //       tempQI.push(temp_obj);
  //     }

  //     setValue("items", tempQI, { shouldValidate: true });
  //   }
  // }


  return (
    <React.Fragment>
      <ToastContainer />
      <UpdatePOImageGallary
        setGalVisible={setGalVisible}
        galVisible={galVisible}
        taskImgs={taskImgs}
        setDrawingFiles={setDrawingFiles}
        drawingFiles={drawingFiles}
        fetchImage={fetchImage}
        locData={location.state}
        constLabel={constLabel}
      />

      <div className="purchase_order mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                navigate("/pcms/purchase-order");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_revise_purchase_order
                ? constLabel.lbl_revise_purchase_order
                : "Revise Purchase Order"}{" - "}{location.state?.poid}
            </p>
          </div>
          <div className="card-body">
            <div className="createPO container-fluid">
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_purchase_order_detail
                  ? constLabel.lbl_purchase_order_detail
                  : "Purchase Order Detail"}
              </p>
              <hr className="my-2" />

              <div className="row my-4">
                {/* <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_client_po_no
                      ? constLabel.lbl_client_po_no
                      : "Client PO No"}
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.client_poid
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                      id="client_poid"
                      name="client_poid"
                      type="text"
                      {...register("client_poid")}
                    />
                  </div>
                </div> */}

                <div className="col-12 col-md-4">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_date ? constLabel.lbl_date : "Date"}{" "}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      control={control}
                      name="po_date"
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.po_date
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          placeholderText="Select date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                    />

                    {errors.po_date && (
                      <span className="text-danger err-msg">
                        {errors.po_date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_invoice_to
                      ? constLabel.lbl_invoice_to
                      : "Invoice To"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`invoiceTo`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={internalCompList.data}
                          isLoading={internalCompList.loading}
                          placeholder="Select company"
                          // onChange={onChange}
                          onChange={(e) => {
                            onChange(e);
                            // console.log("e", e);
                            if (e?.length > 0) {
                              setValue("dispatchTo", e);
                            }
                          }}
                          onBlur={onBlur}
                          selected={value}
                          disabled={usingQuoteProd}
                          isInvalid={errors.invoiceTo ? true : false}
                        />
                      )}
                    />
                    {!internalCompList.loading && !errors.invoiceTo && <span className="arrow-icon"></span>}
                    {errors.invoiceTo && (
                      <span className="text-danger err-msg">
                        {errors.invoiceTo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_dispatch_to
                      ? constLabel.lbl_dispatch_to
                      : "Dispatch To"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`dispatchTo`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={internalCompList.data}
                          isLoading={internalCompList.loading}
                          placeholder="Select company"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.dispatchTo ? true : false}
                        />
                      )}
                    />
                    {!internalCompList.loading && !errors.dispatchTo && <span className="arrow-icon"></span>}
                    {errors.dispatchTo && (
                      <span className="text-danger err-msg">
                        {errors.dispatchTo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_supplier_name
                      ? constLabel.lbl_supplier_name
                      : "Supplier Name"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`supplier`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="name"
                          multiple={false}
                          options={supplierList.data}
                          isLoading={supplierList.loading}
                          placeholder="Select supplier"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={usingQuoteProd}

                          isInvalid={errors.supplier ? true : false}
                        />
                      )}
                    />
                    {!supplierList.loading && !errors.supplier && <span className="arrow-icon"></span>}
                    {errors.supplier && (
                      <span className="text-danger err-msg">
                        {errors.supplier.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_quote_ref
                      ? constLabel.lbl_quote_ref
                      : "Quote Ref"}
                  </label>
                    <Controller
                      name={`quoteRef`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="quotesid"
                          multiple={false}
                          options={quotationList.data}
                          isLoading={quotationList.loading}
                          placeholder="Select Quotation"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={true}
                          isInvalid={errors.quoteRef ? true : false}
                        />
                      )}
                    />
                    {!quotationList.loading && !errors.quoteRef && <span className="arrow-icon"></span>}
                    {errors.quoteRef && (
                      <span className="text-danger err-msg">
                        {errors.quoteRef.message}
                      </span>
                    )}
                  </div>
                </div>


                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_material_request
                      ? constLabel.lbl_material_request
                      : "Material Request"}
                  </label>
                    <Controller
                      name={`material_request`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="material_req_id"
                          multiple={false}
                          options={mat_req_list.data}
                          isLoading={mat_req_list.loading}
                          placeholder="Select Material Request"
                          // onChange={(val) => {
                          //   setValue("material_request", val);
                          //   handleMaterialReqChange(val);

                          // }}
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          // disabled={location.state.po_status == "Approved"}
                          disabled={true}
                          isInvalid={errors.material_request ? true : false}
                        />
                      )}
                    />
                    {!mat_req_list.loading && !errors.material_request && <span className="arrow-icon"></span>}
                    {errors.material_request && (
                      <span className="text-danger err-msg">
                        {errors.material_request.message}
                      </span>
                    )}
                  </div>
                </div>


                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_credit_period
                      ? constLabel.lbl_credit_period
                      : "Credit Period"}
                    <span>
                      {/* <i style={{ color: "red" }}>*</i> */}
                    </span>
                  </label>
                    <input
                      className={
                        errors.creditPeriod
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      id="creditPeriod"
                      name="creditPeriod"
                      defaultValue={"0"}
                      type="text"
                      placeholder="Enter credit period (days)"
                      {...register("creditPeriod")}
                    />
                    {errors.creditPeriod && (
                      <span className="text-danger err-msg">
                        {errors.creditPeriod.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`status`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="postatus"
                          multiple={false}
                          options={poStatusList.data}
                          isLoading={poStatusList.loading}
                          placeholder="Select status"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.status ? true : false}
                        />
                      )}
                    />
                    {!poStatusList.loading && !errors.status && <span className="arrow-icon"></span>}
                    {errors.status && (
                      <span className="text-danger err-msg">
                        {errors.status.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_remark ? constLabel.lbl_remark : "Remark"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>
                    <textarea
                      // className={
                      //   errors.remarks
                      //     ? "form-control is-invalid"
                      //     : "form-control"
                      // }
                      className="form-control mt-2"
                      id="remarks"
                      name="remarks"
                      placeholder="Enter remarks"
                      {...register("remarks")}
                    ></textarea>
                    {/* {errors.remarks && (
                      <span className="text-danger err-msg">
                        {errors.remarks.message}
                      </span>
                    )} */}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_internal_remark
                      ? constLabel.lbl_internal_remark
                      : "Internal Remark"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>
                    <textarea
                      className={
                        errors.internalRemarks
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      id="internalRemarks"
                      name="internalRemarks"
                      placeholder="Enter Internal Remarks"
                      {...register("internalRemarks")}
                    ></textarea>
                    {/* {errors.internalRemarks && (
                      <span className="text-danger err-msg">
                        {errors.internalRemarks.message}
                      </span>
                    )} */}
                  </div>
                </div>


                <div className="col-12 col-md-6 mt-3">
                  <div className="col-12 col-md-9">
                    <div className="card shadow-sm" id="note_card">
                      <div className="card-body">
                        <span className="text-danger roboto-bold">Note: </span>
                        <div className="mt-2">
                          <ol>
                            <li>Internal remarks will not be displayed in report</li>
                            <li>Product type must be selected first then product</li>
                            <li>Approved purchase order cannot be revised</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>




              </div>

              {/* <p className="mt-2 mb-4">
                <i>
                  {" "}
                  <span className="text-danger roboto-bold">Note: </span>
                  <FontAwesomeIcon
                    style={{
                      color: "#c7c7c7",
                    }}
                    icon={faCircle}
                    size="2xs"
                    className="mx-2"
                  />
                  Internal remarks will not be displayed in report
                </i>
                <i className=" d-block">
                  {" "}
                  <span className="text-danger roboto-bold invisible">
                    Note:{" "}
                  </span>
                  <FontAwesomeIcon
                    style={{
                      color: "#c7c7c7",
                    }}
                    icon={faCircle}
                    size="2xs"
                    className="mx-2"
                  />
                  Product type must be selected first then product
                </i>
                {location.state.po_status == "Open" ? null : (
                  <i className=" d-block">
                    {" "}
                    <span className="text-danger roboto-bold invisible">
                      Note:{" "}
                    </span>
                    <FontAwesomeIcon
                      style={{
                        color: "#c7c7c7",
                      }}
                      icon={faCircle}
                      size="2xs"
                      className="mx-2"
                    />
                    Approved purchase order cannot be revised
                  </i>
                )}
              </p> */}

              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_product_detail
                  ? constLabel.lbl_product_detail
                  : "Product Detail"}
              </p>
              <hr className="my-2" />

              <div className="py-3" style={{ maxHeight: "80vh" }}>
                <table className="table table-responsive table-bordered">
                  <thead className="thead-light text-center">
                    <tr>
                      <th scope="col">
                        {constLabel?.lbl_product_type
                          ? constLabel.lbl_product_type
                          : "Product Type"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_product_name
                          ? constLabel.lbl_product_name
                          : "Product Name"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_quantity
                          ? constLabel.lbl_quantity
                          : "Quantity"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_unit ? constLabel.lbl_unit : "Unit"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_rate ? constLabel.lbl_rate : "Rate"}
                      </th>
                      <th scope="col">
                        {constLabel?.gst_rate
                          ? constLabel.gst_rate
                          : "GST %"}
                      </th>
                      <th scope="col">
                        {constLabel?.hsn_code
                          ? constLabel.hsn_code
                          : "HSN Code"}
                      </th>
                      {/* <th scope="col">
                        {constLabel?.gst_amount
                          ? constLabel.gst_amount
                          : "GST Amount "}
                        <span>&#8377;</span>
                      </th> */}
                      <th scope="col">
                        {constLabel?.discount
                          ? constLabel.discount
                          : "Discount "}
                        <span>%</span>
                      </th>


                      <th scope="col">
                        {constLabel?.lbl_amount
                          ? constLabel.lbl_amount
                          : "Amount (After Discount)"}{" "}
                        <span>&#8377;</span>
                      </th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="f-18">
                        <td style={{ verticalAlign: "top", width: '12%' }}>
                          <Controller
                            name={`items[${index}].prod_type`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productType"
                                size="sm"
                                multiple={false}
                                options={prodType.data}
                                isLoading={prodType.loading}
                                placeholder="Select type"
                                // onChange={onChange}
                                onChange={(selected) => {
                                  handleProdType(selected, index);
                                }}
                                disabled={usingQuoteProd || fields[index].from_material_req}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.prod_type
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />

                          {errors.items?.[index]?.prod_type && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.prod_type.message}
                            </span>
                          )}

                          {!usingQuoteProd ? (
                            index + 1 == fields.length ? (
                              <div>
                                <button
                                  style={{ fontSize: "16px" }}
                                  className="btn btn-sm button-primary px-2 mt-2"
                                  onClick={() => {
                                    append({
                                      prod_type: [],
                                      product: [],
                                      quantity: 0,
                                      unit: [],
                                      price: 0,
                                      gst_rate: 0,
                                      gst_amount: 0,
                                      amount: 0,
                                      discount: 0,
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      color: "#ffffff",
                                      marginRight: "5px",
                                    }}
                                    icon={faAdd}
                                    size="sm"
                                  />{" "}
                                  {constLabel?.lbl_add
                                    ? constLabel.lbl_add
                                    : "Add"}
                                </button>
                              </div>
                            ) : null
                          ) : null}
                        </td>

                        <td style={{ verticalAlign: "top", width: '18%' }}>
                          <Controller
                            name={`items[${index}].product`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productName"
                                size="sm"
                                multiple={false}
                                options={prod_List.data}
                                isLoading={prod_List.loading}
                                placeholder="Select product"
                                onChange={(selected) => {
                                  // console.log("selected", selected);
                                  if (selected.length > 0) {
                                    setValue(`items[${index}].product`, selected, { shouldValidate: true });
                                    setValue(`items[${index}].gst_rate`, selected[0].hsn_uuid?.gst_rate ?? 0,
                                      { shouldValidate: true });
                                    setValue(`items[${index}].hsn_code`, selected[0].hsn_uuid?.hsn_sac_code ?? "",
                                      { shouldValidate: true });
                                    setValue(`items[${index}].unit`, [selected[0].unit_id] ?? [],
                                      { shouldValidate: true });
                                    calRate(selected[0].hsn_uuid?.gst_rate ?? 0, "gst_rate", index);
                                  } else {
                                    setValue(`items[${index}].product`, [], { shouldValidate: true });
                                    setValue(`items[${index}].gst_rate`, [], { shouldValidate: true });
                                    setValue(`items[${index}].unit`, [], { shouldValidate: true });
                                  }
                                }}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.product ? true : false
                                }
                                disabled={usingQuoteProd || fields[index].from_material_req}
                              />
                            )}
                          />

                          {errors.items?.[index]?.product && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.product.message}
                            </span>
                          )}

                          {!usingQuoteProd ? (
                            index + 1 == fields.length ? (
                              <AddProduct fetchProdList={fetchProdList} />
                            ) : null
                          ) : null}
                        </td>

                        <td style={{ verticalAlign: "top", width: '8%' }}>
                          <input
                            className={
                              errors.items?.[index]?.quantity
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            defaultValue={0}
                            name={`items[${index}].quantity`}
                            {...register(`items[${index}].quantity`, {
                              onChange: (e) =>
                                calRate(e.target.value, "quantity", index),
                            })}
                            disabled={usingQuoteProd}
                          />

                          {errors.items?.[index]?.quantity && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.quantity.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: '10%' }}>
                          <Controller
                            name={`items[${index}].unit`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              const product = watch(`items[${index}].product`);
                              // Disable unit field if product.material_type_id is null
                              const isUnitDisabled = product?.[0]?.material_type_id == null || usingQuoteProd || disableLineItems;
                              return (
                                <Typeahead
                                  id="basic-typeahead-single"
                                  labelKey="uom"
                                  size="sm"
                                  multiple={false}
                                  options={uomList.data}
                                  isLoading={uomList.loading}
                                  placeholder="Select unit"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.items?.[index]?.unit ? true : false
                                  }
                                  disabled={isUnitDisabled}
                                />
                              )
                            }}
                          />

                          {errors.items?.[index]?.unit && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.unit.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: '10%' }}>
                          <input
                            className={
                              errors.items?.[index]?.price
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            defaultValue={0}
                            name={`items[${index}].price`}
                            {...register(`items[${index}].price`, {
                              onChange: (e) =>
                                calRate(e.target.value, "price", index),
                            })}
                            disabled={usingQuoteProd}
                          />

                          {errors.items?.[index]?.price && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.price.message}
                            </span>
                          )}
                        </td>


                        <td style={{ verticalAlign: "top", width: '7%' }}>
                          <input
                            className={
                              errors.items?.[index]?.gst_rate
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].gst_rate`}
                            {...register(`items[${index}].gst_rate`, {
                              onChange: (e) => {
                                // console.log("e", e);
                                calRate(e.target.value, "gst_rate", index);
                              }
                            })}
                            disabled={usingQuoteProd || disableLineItems}
                          />

                          {errors.items?.[index]?.gst_rate && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.gst_rate.message}
                            </span>
                          )}
                        </td>


                        <td style={{ verticalAlign: "top", width: '10%' }}>
                          <input
                            className={
                              errors.items?.[index]?.hsn_code
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].hsn_code`}
                            {...register(`items[${index}].hsn_code`)}
                            disabled
                          />

                          {errors.items?.[index]?.hsn_code && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.hsn_code.message}
                            </span>
                          )}
                        </td>

                        {/* <td style={{ verticalAlign: "top", }}>
                          <input
                            className={
                              errors.items?.[index]?.gst_amount
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].gst_amount`}
                            {...register(`items[${index}].gst_amount`)}
                            disabled
                          />

                          {errors.items?.[index]?.gst_amount && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.gst_amount.message}
                            </span>
                          )}
                        </td> */}


                        <td style={{ verticalAlign: "top", width: '8%' }}>
                          <input
                            className={
                              errors.items?.[index]?.discount
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            // defaultValue={0}
                            name={`items[${index}].discount`}
                            {...register(`items[${index}].discount`, {
                              onChange: (e) =>
                                calRate(e.target.value, "discount", index),
                            })}
                            disabled={usingQuoteProd || disableLineItems}
                          />

                          {errors.items?.[index]?.discount && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.discount.message}
                            </span>
                          )}
                        </td>





                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "12%",
                          }}
                        >
                          <input
                            className={
                              errors.items?.[index]?.amount
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            defaultValue={0}
                            disabled
                            name={`items[${index}].amount`}
                            {...register(`items[${index}].amount`)}
                          />
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "5%",
                          }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip">
                                {constLabel?.lbl_delete_product
                                  ? constLabel.lbl_delete_product
                                  : "Delete Product"}
                              </Tooltip>
                            }
                          >
                            <div
                              className={location.state.po_status == "Approved" ? "button-blur" : "deleteIcon cursor_pointer"}
                              disabled={location.state.po_status == "Approved" ? true : false}
                              onClick={
                                !usingQuoteProd
                                  ? index > 0
                                    ? () => {
                                      // remove(index);
                                      if (location.state.po_status !== "Approved") {
                                        delPO_line_item_Product(index)
                                      }
                                    }
                                    : null
                                  : null
                              }
                            >
                              <Trash2 color="#FFFFFF" size={18} />
                            </div>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div
                className="p-3 ms-auto col-12 col-md-6"
                style={{ border: "1px solid #c6c6c6", borderRadius: "2px" }}
              >
                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      {constLabel?.lbl_sub_total
                        ? constLabel.lbl_sub_total
                        : "Sub Total"}{" "}

                    </label>

                    <div className="col-12 col-md-4 d-flex align-items-center">
                      <span>&#8377;</span>
                      <input
                        className="form-control form-control-sm ms-2"
                        type="number"
                        value={subTotalForRef}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      {constLabel?.lbl_discount
                        ? constLabel.lbl_discount
                        : "Discount"}{" "}
                      (%)
                    </label>

                    <div className="col-12 col-md-4">
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        {...register("discount", {
                          onChange: (e) => cal_final_total_with_tax_details(e.target.value),
                        })}
                        max={100}
                        min={0}
                        placeholder="Enter discount"
                        disabled={usingQuoteProd}
                      />
                      {errors.discount && (
                        <span className="text-danger err-msg">
                          {errors.discount.message}
                        </span>
                      )}
                    </div>

                    <div className="col-12 col-md-4 d-flex align-items-center">
                      <span>&#8377;</span>
                      <input
                        className="form-control form-control-sm ms-2"
                        type="text"
                        disabled
                        {...register("discount_amount")}
                        defaultValue={0}
                        placeholder="Discounted Amount"
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      {constLabel?.lbl_amountAfter_discount
                        ? constLabel.lbl_amountAfter_discount
                        : "Amount after discount"}{" "}
                      <span>&#8377;</span>
                    </label>

                    <div className="col-12 col-md-4 ">
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        {...register("subTotal")}
                        disabled
                      />
                    </div>
                  </div>
                </div> */}

                <hr />

                {taxDetails.cgst ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold col-12 col-md-3"
                      >
                        {constLabel?.lbl_cgst ? constLabel.lbl_cgst : "CGST"}{" "}
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          {...register("CGST", {
                            // onChange: (e) =>
                            //   CaltotalAmount(e.target.value, "CGST"),
                          })}
                          placeholder="Enter CGST"
                          disabled
                        />
                        {errors.CGST && (
                          <span className="text-danger err-msg">
                            {errors.CGST.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="text"
                          disabled
                          {...register("CGST_amount")}
                          placeholder="CGST Amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.sgst ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold col-12 col-md-3"
                      >
                        {constLabel?.lbl_sgst ? constLabel.lbl_sgst : "SGST"}{" "}
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          {...register("SGST", {
                            onChange: (e) =>
                              CaltotalAmount(e.target.value, "SGST"),
                          })}
                          placeholder="Enter SGST"
                          disabled
                        />
                        {errors.SGST && (
                          <span className="text-danger err-msg">
                            {errors.SGST.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="text"
                          {...register("SGST_amount")}
                          disabled
                          placeholder="SGST Amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.igst ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold col-12 col-md-3"
                      >
                        {constLabel?.lbl_igst ? constLabel.lbl_igst : "IGST"}{" "}
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          value={taxDetails.igst}
                          {...register("IGST")}
                          // {...register("IGST", {
                          //     onChange: (e) => CaltotalAmount(e.target.value, "IGST")
                          // })}
                          placeholder="Enter IGST"
                          disabled
                        />
                        {errors.IGST && (
                          <span className="text-danger err-msg">
                            {errors.IGST.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="text"
                          defaultValue={0}
                          {...register("IGST_amount")}
                          disabled
                          placeholder="IGST Amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.type == "ugst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold col-12 col-md-3"
                      >
                        {constLabel?.lbl_ugst ? constLabel.lbl_ugst : "UGST"}{" "}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      {/* <div className="col-12 col-md-4">
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          placeholder="Enter ugst"
                          {...register("UGST")}
                          value={taxDetails.ugst}
                          disabled
                        />
                        {errors.ugst && (
                          <span className="text-danger err-msg">
                            {errors.ugst.message}
                          </span>
                        )}
                      </div> */}
                      <div className="col-12 col-md-4 d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="number"
                          disabled
                          {...register("UGST_amount")}
                          placeholder="ugst cost"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {taxDetails.type == "ugst" ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold col-12 col-md-3"
                      >
                        {constLabel?.lbl_cgst ? constLabel.lbl_cgst : "CGST"}{" "}
                      </label>

                      <div className="col-12 col-md-4  d-flex align-items-center">
                        <span>&#8377;</span>
                        <input
                          className="form-control form-control-sm ms-2"
                          type="text"
                          disabled
                          defaultValue={0}
                          {...register("CGST_amount")}
                          placeholder="CGST Amount"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      {constLabel?.lbl_total_amount
                        ? constLabel.lbl_total_amount
                        : "Total Amount"}{" "}

                    </label>

                    <div className="col-12 col-md-4 d-flex align-items-center">
                      <span>  &#8377;</span>
                      <input
                        className="form-control form-control-sm ms-2"
                        type="number"
                        {...register("totalAmount")}
                        defaultValue={0}
                        placeholder="Total Amount"
                        disabled
                      />
                      {errors.totalAmount && (
                        <span className="text-danger err-msg">
                          {errors.totalAmount.message}
                        </span>
                      )}
                    </div>
                  </div>

                  {gstErr ? (
                    <div className="col-12 mt-3">
                      <div
                        className="alert alert-danger mb-0 text-center my-4"
                        role="alert"
                      >
                        GST number is not valid of Supplier or Invoice To
                        company, purchase order will be saved without GST.
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-3">
            <button
              onClick={() => setGalVisible(true)}
              className="btn btn-yellow px-3"
            >
              {constLabel?.lbl_image_gallary
                ? constLabel.lbl_image_gallary
                : "Image Gallery"}
              <FontAwesomeIcon
                style={{
                  color: "#000",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
                icon={faImage}
              />
            </button>

            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
              disabled={location.state.po_status == "Open" ? false : true}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

export default UpdatePurchaseOrd;
