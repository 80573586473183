import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getProductType, updateProdCategory } from "../../../api";
import info from "../../../assets/images/info.png"

const schema = yup
  .object()
  .shape({
    categoryname: yup
      .string()
      .trim() // Trim leading and trailing spaces
      .required("Product category name is required!"),
  })
  .required();

function EditProductCategory(props) {
  const [visible, setVisible] = useState(false);
  const [prodType, setProdType] = useState({ data: [], loading: false });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    if(visible){
      fetchProductType();
    }
  }, [visible])

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setProdType({ ...prodType, data: res.data.data, loading: false });
        } else {
          setProdType({ ...prodType, data: [], loading: false });
        }
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }
  

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        product_type: data.type?.length > 0 ? data.type?.[0]?.uuid : null,
        categoryname: data.categoryname.trim(),
      };

      updateProdCategory(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Product category updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchProd_Category(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_product_category
            ? props.constLabel?.lbl_edit_product_category
            : "Edit Product Category"
        }
        className="menu_icon_grey cursor-pointer me-2"
        size={20}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
        }}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"md"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_product_category
              ? props.constLabel?.lbl_update_product_category
              : "Update Product Category"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
          <div className="form-group typeahead-container">
                      <label className="lbl-style roboto-bold">
                        {props.constLabel?.lbl_product_type
                          ? props.constLabel?.lbl_product_type
                          : "Product Type"}
                        <Tooltip id={"open-tooltip1"} place="top" style={{ zIndex: 9999 }} />
                        <img src={info} alt='info' className='cursor_pointer'
                            data-tooltip-id={"open-tooltip1"}
                            data-tooltip-html="Classifies items based on their role in the manufacturing process, including categories<br />
                             such as raw materials, components, finished goods, packaging materials, tools, and gauges. This field helps in<br /> 
                             organizing and tracking inventory for streamlined production, procurement, and management."
                            height={20} width={20} />
                      </label>
                      <Controller
                        name="type"
                        control={control}
                        defaultValue={
                          props.data?.typeDetails !== undefined &&
                          props.data?.typeDetails !== null
                            ? [props.data?.typeDetails]
                            : []
                        }
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="productType"
                            className="mt-2"
                            multiple={false}
                            options={prodType.data}
                            isLoading={prodType.loading}
                            placeholder="Select product type"
                            defaultSelected={
                              props.data?.typeDetails !== undefined &&
                              props.data?.typeDetails !== null
                                ? [props.data?.typeDetails]
                                : []
                            }
                            onChange={onChange}
                            selected={value}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      {!prodType.loading && <span className="arrow-icon"></span>}
                </div>
            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_category_name
                  ? props.constLabel?.lbl_category_name
                  : "Category Name"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.categoryname
                      ? "form-control  is-invalid"
                      : "form-control "
                  }
                  id="categoryname"
                  name="categoryname"
                  placeholder="Enter category name"
                  defaultValue={props.data?.categoryname}
                  {...register("categoryname")}
                />
                <span className="text-danger err-msg">
                  {errors.categoryname?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditProductCategory;
