import React, { useState, useEffect } from 'react';
import { create_mr_wo_jc_pro_plan } from "../../../api/index";
import { convertDateYYMMDD, formatDateString } from "../../../common/Functions/CommonFunctions"
import { toast } from "react-toastify";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateStepThree, resetState, updateStepTwo } from '../../../slices/production_planning/production_plan_slice';

const schema = yup
    .object()
    .shape({
        material_req_status: yup.array()
            .min(1, "Please select status !")
            .required("Please select status !"),
        material_req_remarks: yup.string().max(1000).notRequired("Please enter remarks !"),
        items: yup.array().of(
            yup.object().shape({
                qty: yup.string()
                    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, 'Enter valid quantity')
                    .required("Quantity is required"),
            })
        ),
    })
    .required();

function StepThree(props) {
    const { nextStep, prevStep, prev_data } = props;
    // const StepOneSavedData = JSON.parse(localStorage.getItem("StepOne"));
    // const StepTwoSavedData = JSON.parse(localStorage.getItem("StepTwo"));
    // const StepThreeSavedData = JSON.parse(localStorage.getItem("StepThree"));
    const dispatch = useDispatch();
    const StepOneSavedData = useSelector((state) => state.steps.StepOne);
    const StepTwoSavedData = useSelector((state) => state.steps.StepTwo);
    const StepThreeSavedData = useSelector((state) => state.steps.StepThree);
    const [disableButton, setDisableButton] = useState(false);


    const [constLabel, setConstLabel] = useState({});
    const statusList = [{ name: 'Draft' }, { name: "Finalized" }];
    let navigate = useNavigate();
    // const [productStock, setProductStock] = useState({ data: [], loading: false });

    const {
        register,
        handleSubmit,
        formState,
        control,
        watch,
        getValues,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    let { errors } = formState;


    useEffect(() => {
        // setProductStock({ ...productStock, data: [], loading: true });
        console.log("StepOneSavedData", StepOneSavedData);
        console.log("StepTwoSavedData", StepTwoSavedData);

        if (StepThreeSavedData) {
            reset(StepThreeSavedData);
        } else {
            setValue("material_req_status", [{ name: "Draft" }]);
            // if (StepOneSavedData.formData.status[0].name == "Finalized") {
            //     setValue("material_req_status", StepOneSavedData.formData.status);
            // } else {
            //     setValue("material_req_status", [{ name: 'Draft' }]);
            // }
        }


    }, []);

    const onSubmit = (data) => {
        console.log("data", data);
        console.log(StepOneSavedData);
        console.log(StepTwoSavedData);
        setDisableButton(true);



        if (Object.keys(StepOneSavedData).length !== 0 || Object.keys(StepTwoSavedData).length !== 0) {
            if (!StepOneSavedData.formData.employeePlanning) {
                const allZero = data.items.every(item => item.qty == 0);

                if (allZero) {
                    setDisableButton(false);
                    toast.error("Atleast one product should have plan to request qty greater than 0", {
                        autoClose: 3000,
                    });
                    return
                }
            }


            const payload = {
                ignore_available_qty: StepTwoSavedData.ignoreQty,
                material_req_remarks: data.material_req_remarks,
                material_req_status: data.material_req_status[0].name,
                draft_data: {
                    StepOneSavedData: StepOneSavedData
                }
            }

            payload.prod_plan_lineitems = [];

            let i = 0;
            for (const item of StepTwoSavedData.subprodListWithBom.data) {

                let obj = {
                    product_uuid: item.lineItemDetails.product_id,
                    // bom_uuid: item.lineItemDetails.bomid_id,
                    sales_order_item_uuid: item.lineItemDetails.so_item_uuid,
                    sales_order_uuid: item.lineItemDetails.so_uuid,
                    quantity: data.items[i].qty,
                }

                if (Number(data.items[i].qty) !== 0 && item.lineItemDetails?.checked == true) {
                    payload.prod_plan_lineitems.push(obj);
                }
                i++;
            }

            console.log("payload", payload);
            console.log("StepOneSavedData", StepOneSavedData);

            if (StepOneSavedData.formData.employeePlanning) {
                dispatch(updateStepThree(data));

                // localStorage.setItem("StepThree", JSON.stringify(data));
                nextStep();
            } else {
                if (prev_data) {
                    payload.production_plan_uuid = prev_data.uuid;
                } else {
                    payload.production_plan_uuid = StepOneSavedData.prod_plan_uuid;
                }

                create_mr_wo_jc_pro_plan(payload).then((res) => {
                    console.log("res", res);
                    toast.success("Production plan created successfully", {
                        autoClose: 2000,
                    });
                    setDisableButton(false);
                    // dispatch(resetState());

                    setTimeout(() => {
                        navigate(`${process.env.PUBLIC_URL}/pcms/production_planning`);
                    }, [2000]);

                }, (err) => {
                    setDisableButton(false);
                    console.log("err", err);
                    toast.error(err.response.data.message, {
                        autoClose: 3000,
                    });
                });
            }

        }
    }

    // Helper function to handle the toggle logic
    const toggleChecked = (index, isChecked) => {
        const updatedData = StepTwoSavedData.subprodListWithBom.data.map((ele, i) =>
            i === index
                ? {
                    ...ele,
                    lineItemDetails: {
                        ...ele.lineItemDetails,
                        checked: isChecked,
                    },
                }
                : ele
        );

        dispatch(updateStepTwo({
            ...StepTwoSavedData,
            subprodListWithBom: {
                ...StepTwoSavedData.subprodListWithBom,
                data: updatedData,
            },
        }));
    };

    return (
        <React.Fragment>
            <div className='stepThree'>

                <div className='row'>
                    <div className='col-12 col-md-8 mx-auto'>
                        <div className='row'>

                            <div className='d-flex'>
                                <p className='mb-2 mt-1' id='pro_plan_label'>Create Material Request</p>

                            </div>

                            <div className='col-12 col-md-4'>
                                <div className="form-group mt-md-1 mt-3 typeahead-container">
                                    <label
                                        style={{ fontSize: "14px" }}
                                        className="col-form-label roboto-bold"
                                    >
                                        {constLabel?.lbl_mat_req_status ? constLabel.lbl_mat_req_status : "Material Request Status"}
                                        <span className="text-danger"> *</span>
                                    </label>


                                    <Controller
                                        name={`material_req_status`}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                labelKey="name"
                                                multiple={false}
                                                options={statusList}
                                                isLoading={false}
                                                placeholder="Select status"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={value}
                                                // disabled={StepOneSavedData.formData.status[0].name == "Finalized"}
                                                disabled={true}
                                                isInvalid={errors.material_req_status ? true : false}
                                            />
                                        )}
                                    />
                                    {!errors.material_req_status && <span className="arrow-icon"></span>}


                                    {errors.material_req_status && (
                                        <span className="text-danger err-msg">
                                            {errors.material_req_status.message}
                                        </span>
                                    )}

                                </div>
                            </div>


                            <div className='col-12 col-md-8'>
                                <div className="form-group  typeahead-container mt-md-1 mt-3">
                                    <label
                                        style={{ fontSize: "14px" }}
                                        className="col-form-label roboto-bold"
                                    >
                                        {constLabel?.lbl_remarks ? constLabel.lbl_remarks : "Material Request Remarks"}

                                    </label>


                                    <textarea className={
                                        errors.material_req_remarks
                                            ? "form-control  is-invalid"
                                            : "form-control "
                                    }
                                        id="remarks"
                                        name="material_req_remarks"
                                        type="text"
                                        placeholder="Enter remarks"
                                        {...register("material_req_remarks")}
                                        rows={1}>

                                    </textarea>
                                    {errors.material_req_remarks && (
                                        <span className="text-danger err-msg">
                                            {errors.material_req_remarks.message}
                                        </span>
                                    )}

                                </div>
                            </div>

                            <div className='col-12 mt-4'>
                                <div>
                                    <i><p className="my-2 mb-0"><span className="text-danger roboto-bold">Note - </span>
                                        Plan To Request Qty having 0 and checkbox not selected will be ignored in material request</p></i>
                                </div>


                                {StepTwoSavedData &&
                                    StepTwoSavedData.subprodListWithBom.data.length > 0 ? (
                                    <div className='mt-2 ' style={{ maxHeight: '50vh', overflowX: "auto" }}>
                                        {/* <div className="table-responsive"> */}
                                        <table className="table table-bordered">
                                            <thead className="table-grey roboto-bold">
                                                <tr className="">
                                                    <th scope="col">#</th>
                                                    <th scope="col">{"Sr.No"}</th>


                                                    <th scope="col">
                                                        {constLabel?.lbl_product
                                                            ? constLabel.lbl_product
                                                            : "Product"}
                                                    </th>

                                                    <th scope="col">
                                                        {constLabel?.lbl_bom
                                                            ? constLabel.lbl_bom
                                                            : "BOM"}
                                                    </th>

                                                    <th scope="col">
                                                        {constLabel?.lbl_req_to_complete_so
                                                            ? constLabel.lbl_req_to_complete_so
                                                            : "Total Required Qty"}
                                                    </th>

                                                    <th scope="col">
                                                        {constLabel?.lbl_active_po_qty
                                                            ? constLabel.lbl_active_po_qty
                                                            : "Active PO Qty"}
                                                    </th>

                                                    {/* <th scope="col">
                                                        {constLabel?.lbl_last_po_date
                                                            ? constLabel.lbl_last_po_date
                                                            : "Last PO Date"}
                                                    </th> */}

                                                    <th scope="col">
                                                        {constLabel?.lbl_so_qty
                                                            ? constLabel.lbl_so_qty
                                                            : "Available Qty"}
                                                    </th>

                                                    <th scope="col">
                                                        {constLabel?.lbl_plan_to_req_qty
                                                            ? constLabel.lbl_plan_to_req_qty
                                                            : "Plan To Request Qty"}
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody className="roboto">
                                                {StepTwoSavedData.subprodListWithBom.data.map((val, ind) => (
                                                    <tr key={ind}>
                                                        <td>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={val.lineItemDetails.checked}
                                                                onChange={(e) => toggleChecked(ind, e.target.checked)}
                                                                // disabled={StepTwoSavedData.ignoreQty ?
                                                                //     false
                                                                //     :
                                                                //     (val.lineItemDetails?.availqty)
                                                                //     >= val.lineItemDetails?.qtyRequiredAsPerBom}
                                                            // disabled={StepTwoSavedData.ignoreQty ?
                                                            //     (val.lineItemDetails?.active_po_qty)
                                                            //     >= val.lineItemDetails?.qtyRequiredAsPerBom
                                                            //     :
                                                            //     (val.lineItemDetails?.active_po_qty + val.lineItemDetails?.availqty)
                                                            //     >= val.lineItemDetails?.qtyRequiredAsPerBom}
                                                            />
                                                        </td>
                                                        <td>{parseInt(ind) + 1}</td>

                                                        <td>
                                                            {val.lineItemDetails.productname !== null
                                                                ? val.lineItemDetails.productname
                                                                : "NA"}
                                                        </td>

                                                        <td>
                                                            {val.bom_name ?? "NA"}
                                                        </td>

                                                        <td>
                                                            {val.lineItemDetails?.qtyRequiredAsPerBom ?? "NA"}
                                                        </td>

                                                        <td>
                                                            {val.lineItemDetails?.active_po_qty ?? "NA"}
                                                        </td>

                                                        {/* <td>
                                                            {formatDateString(val.lineItemDetails?.created_at) ?? "NA"}
                                                        </td> */}

                                                        <td>
                                                            {val.lineItemDetails?.availqty ?? "NA"}
                                                        </td>

                                                        <td>
                                                            <input
                                                                type="text"
                                                                className={
                                                                    errors.items?.[ind]?.qty
                                                                        ? "form-control form-control-sm is-invalid"
                                                                        : "form-control form-control-sm"
                                                                }
                                                                id="qty"
                                                                name={`items[${ind}].qty`}
                                                                defaultValue={val.lineItemDetails?.planToRequestQty}
                                                                {...register(`items[${ind}].qty`)}
                                                            />

                                                            <span className="text-danger err-msg">
                                                                {errors.items?.[ind]?.qty.message}
                                                            </span>
                                                        </td>


                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/* </div> */}
                                    </div>
                                ) :
                                    < div
                                        className="alert alert-danger mb-0 text-center my-4"
                                        role="alert"
                                    >
                                        BOM Sub Items not found !
                                    </div>
                                }
                            </div>



                        </div>


                        <div className='d-flex justify-content-end mt-4'>
                            <button className='btn btn-grey px-4'
                                onClick={() => prevStep()}
                            >Previous</button>

                            <button
                                disabled={disableButton}
                                className='btn button-primary px-4 ms-0 ms-md-2'
                                onClick={handleSubmit(onSubmit)}
                            >Save Material Request</button>


                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default StepThree