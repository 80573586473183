import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "../Routes/routes";
// import qmsAdminRoutes from "../Routes/qmsAdminRoutes";
import "../App.css";
import NullHandleErrorBoundary from "../Components/Common/ErrorBoundaries/NullHandleErrorBoundary";
import SetMasters from "../Components/Masters/OnBoarding/SetMasters";

function AppContent() {
  // let navigate = useNavigate();
  //   const token = localStorage.getItem("token");
  const token = localStorage.getItem("access");
  const mst_count = JSON.parse(localStorage.getItem("mst_count"));
  // const localData = JSON.parse(localStorage.getItem('localData'));
  const login_data = useSelector((state) => state.auth);
  // const modulesList = useSelector((state) => state.module);

  const [validRoutes, setValidRoutes] = useState([]);
  let setting_find=null; 

  useEffect(() => {
    setting_find = routes.find((item)=>item.name == "Setting");
    // console.log("setting",setting_find)
    setRouting();
  }, []);


  let vRoutes = [];

  function validateScreens(loginModules, i) {
    // loginModules[0].screens.push(
    //   { screen_name: 'Product', display_name: 'Product' });

    // console.log("loginModules[i].screens", loginModules[i].screens);
    if (loginModules[i].write == true) {
      if (loginModules[i].screens.length > 0) {
        loginModules[i].screens.map((scr, scri) => {
          routes.filter((val) => {
            if (val.prefix == scr.screen_name && val.access == "write") {
              vRoutes.push(val);
            }
          });
        });
      }
    }
    if (loginModules[i].view == true) {
      if (loginModules[i].screens.length > 0) {
        loginModules[i].screens.map((scr, scri) => {
          routes.filter((val) => {
            if (val.prefix == scr.screen_name && val.access == "view") {
              vRoutes.push(val);
            }
          });
        });
      }
    }
    if (loginModules[i].view == false && loginModules[i].write == true) {
      if (loginModules[i].screens.length > 0) {
        loginModules[i].screens.map((scr, scri) => {
          routes.filter((val) => {
            if (val.prefix == scr.screen_name && val.access == "view") {
              vRoutes.push(val);
            }
          });
        });
      }
    }
  }

  function validateScreens2(loginModules, i) {
    // temporary code for simulating logic delete it later

    if (loginModules[i].screens.length > 0) {
      loginModules[i].screens.map((scr, scri) => {
        routes.filter((val) => {
          if (val.prefix == scr.screen_name) {
            vRoutes.push(val);
          }
        });
      });
    }
  }

  function setRouting() {
    // push generic routes which should be visible for all users
    routes.filter((val) => {
      if (val.prefix == "Generic" && val.access == "view") {
        vRoutes.push(val);
      }
    });

    if (
      login_data.loginData?.data.response.module_list.length > 0 &&
      login_data.loginData?.data.response.role_name !== "client_admin" &&
      login_data.loginData?.data.response.role_name !== "admin"
    ) {
      let loginModules = [...login_data.loginData.data.response.module_list];

      for (let i = 0; i < loginModules.length; i++) {
        if (loginModules[i].module == "Master") {
          validateScreens2(loginModules, i);
        } else {
          validateScreens(loginModules, i);
        }
        
        // switch (loginModules[i].module) {
        //   case "Operations Master":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Quality":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Attendance":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Payroll":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Invoices":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Sales":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Dispatch":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "KPI Definition":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "MIS":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Quality Dashboard":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Production Dashboard":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Production Lot":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Production Cut Lot":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Role Management":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "User Management":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Machine Master":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Item Operations":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Item Master":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Party Master":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Inventory Management":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Module Master":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Master":
        //     validateScreens2(loginModules, i);
        //     break;
        //   case "Product":
        //     validateScreens(loginModules, i);
        //     break;
        //   case "Customer Type":
        //     validateScreens(loginModules, i);
        //     break;
        // }
      }
        // console.log("vRoutes", vRoutes);
        let arr=[...vRoutes, setting_find]
        // console.log("arr", arr);

      setValidRoutes(arr);
    } else {
      setValidRoutes(routes);
    }
  }

  // console.log("validRoutes",validRoutes)

  return (
    <Routes>
      {token && token.length > 0 ? (
        mst_count.customerMaster > 0 &&
        mst_count.supplierMaster > 0 &&
        mst_count.locationMaster > 0 && 
        mst_count.storeMaster > 0 && 
        mst_count.machineMaster > 0 &&
        mst_count.operationMaster > 0 &&
        (mst_count.ManufacturedGoods > 0 || mst_count.finishedGoods > 0 ) &&
        mst_count.rawMaterial > 0 ? (
          validRoutes.map(
            (route, idx) =>
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={
                    <NullHandleErrorBoundary>
                      <route.element />
                    </NullHandleErrorBoundary>
                  }
                />
              )
          )
        ) : (
          <Route path="*" element={<SetMasters />} />
          // <Route path="*" element={<Navigate to="/onboarding/setMasters" replace />} />
        )
      ) : (
        <Route path="*" element={<Navigate to="/" replace />} />
      )}
    </Routes>
  );
}

export default AppContent;
