import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import NavbarComp from "../Navbar/NavbarComp";
import AppContent from "./AppContent";
import Footer from "../FooterSection/Footer"
import NavbarErrorBoundary from "../Components/Common/NavbarErrorBoundary";
import NullHandleErrorBoundary from "../Components/Common/ErrorBoundaries/NullHandleErrorBoundary"

function DefaultLayout() {
  const mst_count = JSON.parse(localStorage.getItem('mst_count'));
  return (
    <React.Fragment>
      <div className="min-vh-100" id="root_div">
        {/* <Sidebar /> */}
        {/* <NavbarErrorBoundary> */}
      {
        mst_count?.customerMaster > 0 && 
        mst_count?.supplierMaster > 0 && 
        mst_count?.locationMaster > 0 && 
        mst_count?.storeMaster > 0 && 
        mst_count?.machineMaster > 0 &&
        mst_count?.operationMaster > 0 && 
        (mst_count?.ManufacturedGoods > 0 || mst_count?.finishedGoods > 0 ) &&
        mst_count?.rawMaterial > 0  ?
        <NavbarComp /> : null 
      }
        {/* </NavbarErrorBoundary> */}
        <div id="root_body" >
          {/* <NullHandleErrorBoundary> */}
            {/* <div id="root_body" style={{ marginLeft: "64px" }}> */}
            <AppContent />
          {/* </NullHandleErrorBoundary> */}
        </div>
        {
          mst_count?.customerMaster > 0 && 
          mst_count?.supplierMaster > 0 && 
          mst_count?.locationMaster > 0 && 
          mst_count?.storeMaster > 0 && 
          mst_count?.machineMaster > 0 &&
          mst_count?.operationMaster > 0 && 
          (mst_count?.ManufacturedGoods > 0 || mst_count?.finishedGoods > 0 ) &&
          mst_count?.rawMaterial > 0 ?
          <Footer />
          : null 
        }
      </div>
    </React.Fragment>
  );
}

export default DefaultLayout;
