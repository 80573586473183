import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  updateAttribute,
} from "../../../api";
import { FASHION, APPAREL } from "../../../common/Constants/constants";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Attribute name is required!"),
  })
  .required();

function EditAttributeMaster(props) {
  const [visible, setVisible] = useState(false);

  const [attribTypeList, setAttribTypeList] = useState([
    { id: 1, attribute_type: "Male" },
    { id: 2, attribute_type: "Female" },
  ]);

  const [attribSubTypeList, setAttribSubTypeList] = useState([
    { id: 1, sub_attribute_type: "upperbody" },
    { id: 2, sub_attribute_type: "lowerbody" },
  ]);

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    // Check if the industry type is FASHION or APPAREL
    if ([FASHION, APPAREL].includes(props.localData?.industry_type)) {
      // If the attribute type is empty, set an error for the attribute type field
      if (!data.attribType?.length) {
        setError("attribType", {
          type: "custom",
          message: "Please select the attribute type!",
        });
        return; // Stop further execution if validation fails
      }

      // If the attribute sub type is empty, set an error for the attribute sub type field
      if (!data.attribSubType?.length) {
        setError("attribSubType", {
          type: "custom",
          message: "Please select the attribute sub type!",
        });
        return; // Stop further execution if validation fails
      }
    }
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        name: data.name,
        attribute_type:
          data.attribType?.length > 0
            ? data.attribType?.[0]?.attribute_type
            : null,
        sub_attribute_type:
          data.attribSubType?.length > 0
            ? data.attribSubType?.[0]?.sub_attribute_type
            : null,
      };

      updateAttribute(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Attribute updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchAttributeList(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_attribute
            ? props.constLabel.lbl_edit_attribute
            : "Edit Attribute"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
          reset();
        }}
      />
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_attribute
              ? props.constLabel.lbl_update_attribute
              : "Update Attribute"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group">
              <label className="lbl-style roboto-bold">
                {props.constLabel?.lbl_attribute_name
                  ? props.constLabel?.lbl_attribute_name
                  : "Name"}
                <span className="text-danger"> *</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.name ? "form-control mt-2 is-invalid" : "form-control mt-2"
                  }
                  id="name"
                  name="name"
                  defaultValue={props.data?.name !== "" && props.data?.name !== null ? props.data?.name : "" }
                  placeholder="Enter attribute name"
                  {...register("name")}
                />
                <span className="text-danger err-msg">
                  {errors.name?.message}
                </span>
              </div>
            </div>

            <div className="form-group mt-3 typeahead-container">
              <label className="lbl-style roboto-bold">
                {props.constLabel?.lbl_attribute_type
                  ? props.constLabel.lbl_attribute_type
                  : "Attribute Type"}
                  {[FASHION, APPAREL].includes(props.localData?.industry_type) ? <span className="text-danger"> *</span> : null }
              </label>
              <Controller
                name="attribType"
                control={control}
                defaultValue={
                    props.data.attribute_type &&
                    props.data.attribute_type !== null
                      ? [{attribute_type: props.data.attribute_type}]
                      : []
                  }
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    className="mt-2"
                    id="basic-typeahead-single"
                    labelKey="attribute_type"
                    multiple={false}
                    options={attribTypeList}
                    // isLoading={attribType.state}
                    placeholder="Select attribute type"
                    defaultSelected={
                        props.data.attribute_type &&
                        props.data.attribute_type !== null
                          ? [{attribute_type: props.data.attribute_type}]
                          : []
                      }
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid = {[FASHION, APPAREL].includes(props.localData?.industry_type) ? !!errors.attribType : null}
                  />
                )}
              />
              {!errors.attribType && <span className="arrow-icon"></span>}
              {[FASHION, APPAREL].includes(props.localData?.industry_type) && errors.attribType && (
                  <span className="text-danger err-msg">
                    {errors.attribType.message}
                  </span>
                )}
            </div>

            <div className="form-group mt-3 typeahead-container">
              <label className="lbl-style roboto-bold">
                {props.constLabel?.lbl_attribute_sub_type
                  ? props.constLabel.lbl_attribute_sub_type
                  : "Attribute Sub Type"}
                  {[FASHION, APPAREL].includes(props.localData?.industry_type) ? <span className="text-danger"> *</span> : null }
              </label>
              <Controller
                name="attribSubType"
                control={control}
                defaultValue={
                    props.data.sub_attribute_type &&
                    props.data.sub_attribute_type !== null
                      ? [{sub_attribute_type: props.data.sub_attribute_type}]
                      : []
                  }
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    className="mt-2"
                    id="basic-typeahead-single"
                    labelKey="sub_attribute_type"
                    multiple={false}
                    options={attribSubTypeList}
                    placeholder="Select attribute sub type"
                    defaultSelected={
                        props.data.sub_attribute_type &&
                        props.data.sub_attribute_type !== null
                          ? [{sub_attribute_type: props.data.sub_attribute_type}]
                          : []
                      }
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid = {[FASHION, APPAREL].includes(props.localData?.industry_type) ? !!errors.attribSubType : null}
                  />
                )}
              />
              {!errors.attribSubType && <span className="arrow-icon"></span>}
              {[FASHION, APPAREL].includes(props.localData?.industry_type) && errors.attribSubType && (
                  <span className="text-danger err-msg">
                    {errors.attribSubType.message}
                  </span>
                )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditAttributeMaster;
