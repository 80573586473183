import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../../assets/images/proditm.png";
import fastforward from "../../../assets/images/icons/project_menu/fast-forward.png";
import SetCustomer from "./MastersSetup/SetCustomer";
import SetSupplier from "./MastersSetup/SetSupplier";
import SetOperation from "./MastersSetup/SetOperation";
import SetMachine from "./MastersSetup/SetMachine";
import SetProduct from "./MastersSetup/SetProduct";
import SetLocation from "./MastersSetup/SetLocation";
import { useDispatch } from 'react-redux';
import SweetAlert from "sweetalert2";
import SetStore from "./MastersSetup/SetStore";
 

function SetMasters() {
  const [constLabel, setConstLabel] = useState({});
  let navigate = useNavigate();
  let location = useLocation();
  let dispatch = useDispatch();
  const [mst_count, setMst_Count] = useState(JSON.parse(localStorage.getItem('mst_count')))
  // const mst_count = JSON.parse(localStorage.getItem('mst_count'));

  const [steps, setSteps] = useState([
    { label: "Customer", completed: false },
    { label: "Supplier", completed: false },
    { label: "Location", completed: false },
    { label: "Store", completed: false },
    { label: "Machine", completed: false },
    { label: "Operation", completed: false },
    { label: "Product", completed: false },
  ]);

  const [stepArr, setStepArr] = useState([
    { label: "Set Customer", completed: false },
    { label: "Set Supplier", completed: false },
    { label: "Set Location", completed: false },
    { label: "Set Store", completed: false },
    { label: "Set Machine", completed: false },
    { label: "Set Operation", completed: false },
    { label: "Set Product", completed: false },
  ]);

  const [currentStep, setCurrentStep] = useState(0);

  // useEffect(() => {
  //   // console.log("location", location);

  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     // This is required for the browser to show the confirmation dialog
  //     event.returnValue = "";
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);


    const nextStep = () => {
    if(currentStep >= (steps.length - 1)){
        return;
    }
        let next = currentStep + 1;
        setCurrentStep(next);
    };

  const prevStep = () => {
    if (currentStep == 1) {
        setCurrentStep(0);
      } else {
        let prev = currentStep - 1;
        if (prev < 1) {
          prev = (steps.length - 1);
        }
        setCurrentStep(prev);
      }
  };

  function logout() {
    localStorage.clear();
    // navigate("/login");

    SweetAlert.fire({
      title: "Confirm Logout",
      text: "Are you sure you want to logout!",
      icon: "warning",
      showCancelButton:true, 
      confirmButtonText: "Ok",
      cancelButtonText:"Cancel"
    }).then((result) => {

      if(result.value){
        setTimeout(() => {
          window.location.href = "/login";
            dispatch({
                type: "LOGOUT",
                payload: null,
            })
        }, [1000]);
      }
      
    });
  
  }

  return (
    <React.Fragment>
      <ToastContainer />
        <div className="content-wrapper">
          <div className="card border-0" id="onboarding" style={{minHeight: "150vh"}}>
            <div className="card-body mx-5">
              <div className="d-md-flex justify-content-between col-12">
                <div>
                  <img
                    src={logo}
                    className="img-fluid px-1"
                    alt="Logo"
                    style={{ height: "50px", width: "100px", margin: "0px 3px" }} // Adjust image size
                  />
                </div>

                <div className="pt-3">
                  <a href="#" className="text-decoration-none" onClick={(e) => { e.preventDefault(); logout() }}>Logout</a>
                </div>
              </div>

              <div className="px-2">
                <div className="card-body p-0">
                  <div className="text-center py-2">
                    <h3 className="bold">Great Going...</h3>
                    <h5 className="bold">
                      Now let's setup the Masters for You
                    </h5>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-10 mx-auto">
                      <div className="row justify-content-start overflow-auto flex-nowrap px-3"
                      style={{ scrollPadding: "0 1rem" }}>
                        {steps.map((step, index) => {
                          const isCompleted = index < currentStep;
                          const isActive = index === currentStep;
                          
                          const imageFilter = isCompleted
                            ? 'invert(35%) sepia(83%) saturate(750%) hue-rotate(70deg) brightness(90%) contrast(85%)' // Greenish filter for all completed steps
                            : isActive
                            ? 'invert(35%) sepia(83%) saturate(750%) hue-rotate(200deg) brightness(90%) contrast(85%)' // Blueish filter for active step
                            : 'grayscale(100%) brightness(70%)'; // Grey filter for inactive steps

                          return (
                            <div key={index} className="step col-6 col-sm-4 col-md-3 col-lg-2 my-2">
                              <div className="card_parent d-flex flex-column align-items-center">
                                {index + 1 < steps.length && (
                                  <img
                                    src={fastforward}
                                    alt="arrow"
                                    height={20}
                                    width={20}
                                    style={{ filter: imageFilter, margin: "0px 5px"}}
                                  />
                                )}
                                <div
                                  className={`title_cards card ${
                                    isCompleted
                                      ? 'bg-success text-white'   // Green for completed steps
                                      : isActive
                                      ? 'bg-primary text-white'    // Blue for current step
                                      : 'bg-light text-dark'       // Light grey for inactive steps
                                  }`}
                                  style={{ height: '100px', width: '100%' }} // Fixed height for card across screens
                                >
                                  <div className="card-body py-1 text-center cursor_pointer">
                                    <p className={`body3 ${isActive ? 'fw-bold' : ''}`}>
                                      {stepArr?.[index]?.label}
                                    </p>
                                    <p className="fw-bold">
                                      {(() => {
                                        const label = steps?.[index]?.label;
                                        if (label === "Customer") return mst_count.customerMaster;
                                        if (label === "Supplier") return mst_count.supplierMaster;
                                        if (label === "Location") return mst_count.locationMaster;
                                        if (label === "Store") return mst_count.storeMaster;
                                        if (label === "Operation") return mst_count.operationMaster;
                                        if (label === "Machine") return mst_count.machineMaster;
                                        if (label === "Product") return mst_count.productMaster;
                                        return 0;
                                      })()}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  {currentStep == 0 ? (
                    <SetCustomer
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setSteps={setSteps}
                        setMst_Count={setMst_Count}
                        mst_count={mst_count}
                        steps={steps}
                    />
                  ) : currentStep == 1 ? (
                    <SetSupplier 
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setSteps={setSteps}
                        setMst_Count={setMst_Count}
                        mst_count={mst_count}
                        steps={steps}
                    />
                  ) : currentStep == 2 ? (
                    <SetLocation 
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setSteps={setSteps}
                        setMst_Count={setMst_Count}
                        mst_count={mst_count}
                        steps={steps}
                    />
                  ) : currentStep == 3 ? (
                    <SetStore 
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setSteps={setSteps}
                        setMst_Count={setMst_Count}
                        mst_count={mst_count}
                        steps={steps}
                    />
                  ) : currentStep == 4 ? (
                    <SetMachine 
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setSteps={setSteps}
                        setMst_Count={setMst_Count}
                        mst_count={mst_count}
                        steps={steps}
                    />
                  ) : currentStep == 5 ? (
                    <SetOperation
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setSteps={setSteps}
                        setMst_Count={setMst_Count}
                        mst_count={mst_count}
                        steps={steps}
                    />
                  ) : currentStep == 6 ? (
                    <SetProduct 
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setSteps={setSteps}
                        setCurrentStep={setCurrentStep}
                        mst_count={mst_count}
                        setMst_Count={setMst_Count}
                        steps={steps} 
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  );
}

export default SetMasters;
