import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getProdCategory, getProductType, updateProduct_Family } from "../../../api";
import info from "../../../assets/images/info.png";

const schema = yup
  .object()
  .shape({
    familyname: yup
      .string()
      .trim()
      .required("Product family name is required!"),
  })
  .required();

function EditProductFamily(props) {
  const [visible, setVisible] = useState(false);
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;
  
  useEffect(() => {
    if(visible){
      fetchProductType();
    }
    
  }, [visible])
  

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setProdType({ ...prodType, data: res.data.data, loading: false });
        } else {
          setProdType({ ...prodType, data: [], loading: false });
        }
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory(prod_type_uuid) {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory({product_type: prod_type_uuid}).then(
      (res) => {
        if(res && res.data.data?.length > 0){
          setProdCategory({
            ...prodCategory,
            data: res.data.data,
            loading: false,
          });
        } else {
          setProdCategory({ ...prodCategory, data: [], loading: false });
        }
      },
      (err) => {
        console.log("err", err);
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        product_type: data.type?.length > 0 ? data.type?.[0]?.uuid : null,
        product_category_id: data.category?.length > 0 ? data.category?.[0]?.uuid : null,
        familyname: data.familyname.trim(),
      };

      updateProduct_Family(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Product family updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchProd_Family(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_product_family
            ? props.constLabel?.lbl_edit_product_family
            : "Edit Product Family"
        }
        className="menu_icon_grey cursor-pointer me-2"
        size={20}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
        }}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"md"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_product_family
              ? props.constLabel?.lbl_update_product_family
              : "Update Product Family"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
                <div className="form-group typeahead-container">
                      <label className="lbl-style roboto-bold">
                        {props.constLabel?.lbl_product_type
                          ? props.constLabel?.lbl_product_type
                          : "Product Type"}
                        <Tooltip id={"open-tooltip1"} place="top" style={{ zIndex: 9999 }} />
                        <img src={info} alt='info' className='cursor_pointer'
                            data-tooltip-id={"open-tooltip1"}
                            data-tooltip-html="Classifies items based on their role in the manufacturing process, including categories<br />
                             such as raw materials, components, finished goods, packaging materials, tools, and gauges. This field helps in<br /> 
                             organizing and tracking inventory for streamlined production, procurement, and management."
                            height={20} width={20} />
                      </label>
                      <Controller
                        name="type"
                        control={control}
                        defaultValue={
                          props.data?.product_type_details !== undefined &&
                          props.data?.product_type_details !== null
                            ? [props.data?.product_type_details]
                            : []
                        }
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="productType"
                            className="mt-2"
                            multiple={false}
                            options={prodType.data}
                            isLoading={prodType.loading}
                            placeholder="Select product type"
                            defaultSelected={
                              props.data?.product_type_details !== undefined &&
                              props.data?.product_type_details !== null
                                ? [props.data?.product_type_details]
                                : []
                            }
                            onChange={(selected) => {
                              onChange(selected);
                              fetchProductCategory(selected?.[0]?.uuid);
                            }}
                            selected={value}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      {!prodType.loading && <span className="arrow-icon"></span>}
                </div>
                <div className="form-group typeahead-container mt-3">
                <label className="lbl-style roboto-bold">
                  {props.constLabel?.lbl_product_category
                    ? props.constLabel?.lbl_product_category
                    : "Product Category"}
                  <Tooltip id={"open-tooltip1"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip1"}
                    data-tooltip-html="To classify products into distinct categories for easier<br />
                       management, searchability, and reporting within the system."
                    height={20} width={20} />
                </label>
                <Controller
                  name="category"
                  control={control}
                  defaultValue={
                    props.data?.product_category_details !== undefined &&
                    props.data?.product_category_details !== null
                      ? [props.data?.product_category_details]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      placeholder="Select product category"
                      defaultSelected={
                        props.data?.product_category_details !== undefined &&
                        props.data?.product_category_details !== null
                          ? [props.data?.product_category_details]
                          : []
                      }
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                    />
                  )}
                />
                {!prodCategory.loading && <span className="arrow-icon"></span>}
                </div>
            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_family_name
                  ? props.constLabel?.lbl_family_name
                  : "Family Name"}
                <span className="text-danger"> *</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.familyname
                      ? "form-control  is-invalid"
                      : "form-control "
                  }
                  id="familyname"
                  name="familyname"
                  placeholder="Enter family name"
                  defaultValue={props.data?.familyname}
                  {...register("familyname")}
                />
                <span className="text-danger err-msg">
                  {errors.familyname?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditProductFamily;
