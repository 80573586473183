import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
    getProdConfig,
    getOperatorList,
    getProdList,
    get_inspection_type,
    getProductAttribute,
    create_job_card,
} from "../../../api";
import {
    convertDateYYMMDD,
    create_job_obj, convert_date_and_time
} from "../../../common/Functions/CommonFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import { save_job_card } from '../../../slices/production_planning/production_plan_slice';

const schema = yup
    .object()
    .shape({
        startDate: yup
            .date()
            .typeError("Please select start date!")
            .required("Please select start date!"),
        startTime: yup
            .date()
            .typeError("Please select start time!")
            .required("Please select start time!"),
        endDate: yup
            .date()
            .typeError("Please select end date!")
            .required("Please select end date!"),
        endTime: yup
            .date()
            .typeError("Please select end time!")
            .required("Please select end time!"),
        target_qty: yup
            .string()
            .matches(/^[1-9][0-9]*$/, "Enter valid target qty")
            .required("Enter target qty !"),
        operator: yup
            .array()
            .min(1, "Please select operator !")
            .required("Please select operator !"),
        machine: yup
            .array()
            .min(1, "Please select machine !")
            .required("Please select machine !")

    })
    .required().test(
        'endDateTime-after-startDateTime',
        'End date and time must be after start date and time',
        function (values) {
            const { startDate, startTime, endDate, endTime, } = values;
            // console.log("startDate", startDate);
            // console.log("startTime", startTime);
            // console.log("endDate", endDate);
            // console.log("endTime", endTime);

            // Check if all fields are present
            if (!startDate || !startTime || !endDate || !endTime) {
                return this.createError({
                    path: 'endTime',
                    message: 'Please ensure all date and time fields are selected',
                });
            }
            // console.log('setting 0 time');
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            // console.log('before if');
            if (startDate > endDate) {
                return this.createError({
                    path: 'endDate',
                    message: 'Start date should be greater or equal to end date',
                });
            }
            // console.log('afterr if');
            const startDateTime = convert_date_and_time(startDate, startTime);
            const endDateTime = convert_date_and_time(endDate, endTime);

            // console.log("startDateTime", startDateTime);
            // console.log("endDateTime", endDateTime);

            if (endDateTime <= startDateTime) {
                return this.createError({
                    path: 'endTime',
                    message: 'End date and time must be after start date and time',
                });
            }

            return true;
        }
    );

function JobCardModalCalender(props) {
    const { visible, setVisible, set_calender_events, calender_events,
        delete_added_job_card, empListWithAvail, setEmpListWithAvail } = props;
    const dispatch = useDispatch();
    const [constLabel, setConstLabel] = useState({});

    let navigate = useNavigate();
    let location = useLocation();
    const [operations, setOperations] = useState({ data: [], loading: false });
    const [oprtList, setOprtList] = useState({ data: [], loading: false });

    const [machineList, setMachineList] = useState({ data: [], loading: false });
    const loginData = useSelector((state) => state.auth);
    const base_data = useSelector((state) => state.steps.base_data);
    const [disDeleteBtn, setDisDeleteBtn] = useState(false);
    // setting this value in useEffect every time modal is opened setting latest value from localstorage


    const disableTargetQty = loginData.loginData?.data?.response.role_name !==
        "client_admin" &&
        loginData.loginData?.data?.response.role_name !==
        "admin" ? true : false;

    const [jobStatus, setJobStatus] = useState([
        { id: 1, statusName: "Open" },
        { id: 2, statusName: "In Progress" },
        { id: 3, statusName: "Hold" },
        { id: 4, statusName: "Closed" },
        { id: 5, statusName: "Cancelled" }
    ]);
    const cuur_date_plus_one_hr = new Date();
    cuur_date_plus_one_hr.setHours(cuur_date_plus_one_hr.getHours() + 1);

    const { register, handleSubmit, formState, control, reset, watch, setError, setValue, clearErrors } = useForm({
        resolver: yupResolver(schema),
        criteriaMode: 'all',
        mode: "onChange",
    }); // initialise the hook

    let { errors } = formState;


    const selProdOpr = watch("prod_operation");






    useEffect(() => {
        if (visible.state && visible.data) {
            console.log("visible", visible);
            let product = visible.data.base_data.data.find((val) => val.selected == true);
            product = JSON.parse(JSON.stringify(product));
            delete product.selected_bom;
            delete product.selected_route;

            const nineAM = new Date(visible.data.slot);
            nineAM.setHours(9, 0, 0, 0); // 9:00:00 AM

            // Set 6 PM
            const sixPM = new Date(visible.data.slot);
            sixPM.setHours(18, 0, 0, 0);


            setValue("product", [product]);
            setValue("partNo", product.partNo);
            setValue("startDate", nineAM);
            setValue("startTime", nineAM);
            setValue("endDate", sixPM);
            setValue("endTime", sixPM);
            setValue("target_qty", 1);
            setValue("actual_qty", 0);
            setValue("prod_operation", visible.data.sel_operation);
            setValue("operator", visible.data.sel_operator);
            setValue("remarks", visible.data.remarks ?? "");

            let default_machine
            if (visible.data.sel_operation[0].machine.length == 1) {
                default_machine = visible.data.sel_operation[0].machine;
            } else if (visible.data.sel_operation[0].machine.length > 1) {

                visible.data.sel_operation[0].machine.forEach(element => {
                    if (element.name.includes("default")) {
                        default_machine = [element];
                    }
                });

            }

            default_machine[0].machineNameSerialNo =
                default_machine[0].serialNo && default_machine[0].serialNo !== ""
                    ? default_machine[0].name + " - " + default_machine[0].serialNo
                    : default_machine[0].name;

            setValue("machine", default_machine);
            setValue("cycle_time", default_machine?.[0]?.cycle_time);


        } else if (visible.state && visible.displayData) {
            display_data();
        }
    }, [visible.state]);


    function display_data() {
        console.log(visible.displayData);
        if (visible.displayData.uuid) {
            setValue("product", [visible.displayData.productDetail]);
            setValue("partNo", visible.displayData.productDetail.partNo);
            setValue("startDate", new Date(visible.displayData.start_time));
            setValue("startTime", new Date(visible.displayData.start_time));
            setValue("endDate", new Date(visible.displayData.end_time));
            setValue("endTime", new Date(visible.displayData.end_time));
            setValue("target_qty", visible.displayData.planned_qty);
            setValue("actual_qty", visible.displayData.actual_qty ?? 0);
            setValue("prod_operation", [visible.displayData.productOperDetails]);
            setValue("remarks", visible.displayData.remarks ?? "");
            // setValue("machine", [visible.displayData.machineDetails]);

            const operator_data = { ...visible.displayData.operatorDetails };

            if (operator_data) {
                operator_data.FullName = operator_data.MiddleName !== null && operator_data.MiddleName !== ""
                    ? operator_data.FirstName + " " + operator_data.MiddleName + " " + operator_data.LastName
                    : operator_data.FirstName + " " + operator_data.LastName;

                setValue("operator", [operator_data]);
            } else {
                setValue("operator", []);
            }

            const machine_data = { ...visible.displayData.machineDetails };
            if (machine_data) {
                visible.displayData.machineDetails.machineNameSerialNo =
                    machine_data.serialNo && machine_data.serialNo !== ""
                        ? machine_data.machineName + " - " + machine_data.serialNo
                        : machine_data.machineName;
                setValue("machine", [visible.displayData.machineDetails]);
            } else {
                setValue("machine", []);
            }
            setValue("cycle_time", visible.displayData.cycle_time);

        } else {
            setValue("product", [visible.displayData.productDetail]);
            setValue("partNo", visible.displayData.productDetail.partNo);
            setValue("startDate", new Date(visible.displayData.start_time));
            setValue("startTime", new Date(visible.displayData.start_time));
            setValue("endDate", new Date(visible.displayData.end_time));
            setValue("endTime", new Date(visible.displayData.end_time));
            setValue("target_qty", visible.displayData.planned_qty);
            setValue("actual_qty", visible.displayData.actual_qty);
            setValue("prod_operation", [visible.displayData.productOperDetails]);
            setValue("operator", [visible.displayData.operatorDetails]);

            console.log(visible.displayData.operationDetails);
            console.log(visible.displayData.operationDetails.machine_data[0].machine);

            if (visible.displayData.operationDetails &&
                visible.displayData.operationDetails.machine_data) {
                setValue("machine", visible.displayData.operationDetails.machine_data[0].machine);
                console.log(visible.displayData.operationDetails.machine_data[0].machine);
            } else {
                console.log("asdasdsd");
                setValue("machine", []);
            }

            setValue("cycle_time", visible.displayData.cycle_time);
            setValue("remarks", visible.displayData.remarks ?? "");
        }
    }



    const onSubmit = (data) => {
        if (data !== "") {
            console.log("data", data);

            const product = visible.data.base_data.data.find((val) => val.selected == true);
            if (data.target_qty > product.so_item_quantity) {
                setError('target_qty', {
                    type: 'custom',
                    message: `Target qty ${data.target_qty} should be less than WO - ${product.so_item_quantity} qty`
                });
                return;
            }




            const startDateTime = convert_date_and_time(data.startDate, data.startTime);
            const endDateTime = convert_date_and_time(data.endDate, data.endTime);

            const timeDiff = Math.abs(new Date(startDateTime) - new Date(endDateTime));
            data.total_time_hrs = Math.floor(timeDiff / 3600000).toString();
            data.total_time_mins = Math.floor(timeDiff / 60000).toString();
            data.total_time_sec = Math.floor(timeDiff / 1000).toString();


            console.log("data", data);


            const job_card_to_append_in_calender = create_job_obj(data);
            console.log("job_card_to_append_in_calender", job_card_to_append_in_calender)


            let temp_empListWithAvail = [...empListWithAvail.data];
            console.log("temp_empListWithAvail", temp_empListWithAvail)
            temp_empListWithAvail.forEach(val => {
                if (val.uuid == job_card_to_append_in_calender.operator_id) {
                    val.jobcard_list
                        = [...val.jobcard_list, job_card_to_append_in_calender];
                }
            });


            setEmpListWithAvail({
                ...empListWithAvail,
                data: temp_empListWithAvail, loading: false
            });



            if (calender_events.data.length == 0) {
                set_calender_events({
                    data: [...calender_events.data, job_card_to_append_in_calender],
                    loading: false
                })

            } else {
                const new_calender_events = { ...calender_events };
                console.log("new_calender_events", new_calender_events);

                const index = new_calender_events.data.findIndex(item =>
                    item.uuid == null
                    && item.productOperDetails.uuid === data.prod_operation[0].uuid
                    // && new Date(item.start_time).getTime() == new Date(data.startTime).getTime()
                    // && new Date(item.end_time).getTime() == new Date(data.endTime).getTime()
                );
                console.log("index", index);

                // If the object is found, replace it with the new object
                if (index !== -1) {
                    new_calender_events.data[index] = { ...job_card_to_append_in_calender };

                    set_calender_events({
                        data: new_calender_events.data,
                        loading: false
                    })
                    // Preserve the UUID if needed
                } else {
                    set_calender_events({
                        data: [...calender_events.data, job_card_to_append_in_calender],
                        loading: false
                    })
                }

            }

            const data_required = {
                data: data,
                operation: visible.data.sel_operation[0],
                base_data: JSON.parse(JSON.stringify(base_data))
            };
            dispatch(save_job_card(data_required));

            setVisible({ state: false, data: null, displayData: null });
            reset();

        } else {
            errors.showMessages();
        }
    };

    return (
        <React.Fragment>



            <Modal show={visible.state} onHide={() => {
                setVisible({ state: false, data: null, displayData: null });
            }} size={"xl"}>
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>
                        {visible.displayData !== null ?
                            constLabel?.lbl_view_job_card
                                ? constLabel.lbl_view_job_card
                                : `Job Card Details - ${visible.displayData.job_card_no}`
                            :
                            constLabel?.lbl_create_job_card
                                ? constLabel.lbl_create_job_card
                                : "Create Job Card"
                        }


                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row pb-3">

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_product_name
                                        ? constLabel.lbl_product_name
                                        : "Product Name"}
                                </label>

                                <Controller
                                    name="product"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="productName"
                                            multiple={false}
                                            placeholder="Select product"
                                            options={[]}
                                            disabled
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.product ? true : false}
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.product?.message}
                                </span>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_part_no
                                        ? constLabel.lbl_part_no
                                        : "Part No"}
                                </label>

                                <input
                                    type="text"
                                    className="form-control "
                                    id="partNo"
                                    name="partNo"
                                    placeholder="Enter part no"
                                    disabled
                                    {...register("partNo")}
                                />

                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                                </label>

                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue={[{ id: 1, statusName: "Open" }]}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="statusName"
                                            multiple={false}
                                            defaultSelected={[{ id: 1, statusName: "Open" }]}
                                            // size="sm"
                                            options={jobStatus}
                                            placeholder="Select status"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            disabled
                                        />
                                    )}
                                />

                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_start_date
                                        ? constLabel.lbl_start_date
                                        : "Start Date"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    control={control}
                                    name="startDate"
                                    render={({ field }) => (
                                        <DatePicker
                                            className={
                                                errors.startDate
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select date"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            autoComplete="false"
                                            disabled
                                        />
                                    )}
                                />

                                {errors.startDate && (
                                    <span className="text-danger err-msg">
                                        {errors.startDate.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_start_time
                                        ? constLabel?.lbl_start_time
                                        : "Start Time"}
                                    <span className="text-danger"> *</span>
                                </label>


                                <Controller
                                    control={control}
                                    name="startTime"
                                    render={({ field }) => (
                                        <DatePicker
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            className={
                                                errors.startTime
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select start time"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="h:mm aa"
                                            autoComplete="false"
                                            disabled
                                        // minDate={
                                        //     new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).getTime()
                                        // }
                                        />
                                    )}
                                />


                                {errors.startTime && (
                                    <span className="text-danger err-msg">
                                        {errors.startTime.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_end_date
                                        ? constLabel.lbl_end_date
                                        : "End Date"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    control={control}
                                    name="endDate"
                                    render={({ field }) => (
                                        <DatePicker
                                            className={
                                                errors.endDate
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select date"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            autoComplete="false"
                                            disabled
                                        />
                                    )}
                                />

                                {errors.endDate && (
                                    <span className="text-danger err-msg">
                                        {errors.endDate.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_end_time
                                        ? constLabel?.lbl_end_time
                                        : "End Time"}
                                    <span className="text-danger"> *</span>
                                </label>



                                <Controller
                                    control={control}
                                    name="endTime"
                                    render={({ field }) => (
                                        <DatePicker
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            className={
                                                errors.endTime
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select start time"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="h:mm aa"
                                            autoComplete="false"
                                            disabled
                                        // minDate={
                                        //     new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).getTime()
                                        // }
                                        />
                                    )}
                                />


                                {errors.endTime && (
                                    <span className="text-danger err-msg">
                                        {errors.endTime.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <hr className="my-2" />
                    <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                        {constLabel?.lbl_job_card_detail
                            ? constLabel.lbl_job_card_detail
                            : "Job Card Detail"}
                    </p>
                    <hr className="my-2" />
                    <div className="row pb-3">
                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_product_operation
                                        ? constLabel.lbl_product_operation
                                        : "Product Operation"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    name="prod_operation"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="operationname"
                                            multiple={false}
                                            options={[]}
                                            placeholder="Select product operation"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.prod_operation ? true : false}
                                            disabled
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.prod_operation?.message}
                                </span>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_machine
                                        ? constLabel.lbl_machine
                                        : "Machine"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    name={`machine`}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="machineNameSerialNo"
                                            multiple={false}
                                            options={machineList.data}
                                            isLoading={machineList.loading}
                                            placeholder="Select machine"
                                            onChange={(selected) => {
                                                onChange(selected);
                                                if (selected.length > 0) {
                                                    setValue("cycle_time", selected?.[0]?.cycle_time);
                                                    setValue(
                                                        "setting_time",
                                                        selected?.[0]?.settings_time
                                                    );
                                                } else {
                                                    setValue("cycle_time", "");
                                                    setValue("setting_time", "");
                                                }
                                            }}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.machine ? true : false}
                                            disabled
                                        />
                                    )}
                                />
                                {errors.machine && (
                                    <span className="text-danger err-msg">
                                        {errors.machine.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_cycle_time
                                        ? constLabel.lbl_cycle_time
                                        : "Cycle Time"}

                                </label>
                                <input
                                    type="text"
                                    className="form-control "
                                    id="cycle_time"
                                    name="cycle_time"
                                    placeholder="Enter cycle time"
                                    disabled
                                    {...register("cycle_time")}
                                />

                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_target_qty
                                        ? constLabel.lbl_target_qty
                                        : "Target Qty"}
                                    <span className="text-danger"> *</span>{" "}

                                </label>
                                <input
                                    type="text"
                                    className={
                                        errors.target_qty
                                            ? "form-control  is-invalid"
                                            : "form-control  "
                                    }
                                    id="target_qty"
                                    name="target_qty"
                                    placeholder="Enter target qty"
                                    {...register(`target_qty`, {
                                        onChange: (e) => {
                                            setValue("target_qty", e.target.value, { shouldValidate: true });
                                        },
                                    })}
                                    // disabled={disableTargetQty}
                                    disabled={true}
                                />
                                <span className="text-danger err-msg">
                                    {errors.target_qty?.message}
                                </span>
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_actual_qty
                                        ? constLabel.lbl_actual_qty
                                        : "Actual Qty"}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="actual_qty"
                                    defaultValue={0}
                                    name="actual_qty"
                                    placeholder="Enter actual qty"
                                    {...register("actual_qty")}
                                    disabled
                                />
                            </div>
                        </div>


                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_operator
                                        ? constLabel.lbl_operator
                                        : "Operator"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    name="operator"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="FullName"
                                            multiple={false}
                                            options={[]}
                                            disabled
                                            placeholder="Select operator"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.operator ? true : false}
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.operator?.message}
                                </span>
                            </div>
                        </div>


                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_remarks
                                        ? constLabel.lbl_remarks
                                        : "Remarks"}
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="remarks"
                                    name="remarks"
                                    placeholder="Enter remarks"
                                    {...register("remarks")}
                                >
                                </textarea>

                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <label className="lbl-style roboto-bold invisible">
                                {"Unit"}
                            </label>
                            <div className="d-flex align-items-center mt-3">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={selProdOpr?.[0]?.serial_number_tracking}
                                        disabled
                                        checked={selProdOpr?.[0]?.serial_number_tracking}
                                    />
                                    <label className="form-check-label lbl-style roboto-bold">
                                        {constLabel?.lbl_serial_number_tracking
                                            ? constLabel?.lbl_serial_number_tracking
                                            : "Serial number tracking"}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <div className="d-md-flex justify-content-end">
                        {visible.displayData && visible.displayData?.uuid == null &&
                            <button
                                onClick={() => {
                                    setDisDeleteBtn(true);
                                    delete_added_job_card();
                                }}
                                className="f-16 btn button-danger me-2"
                                disabled={disDeleteBtn}
                            >
                                {constLabel?.lbl_delete ? constLabel.lbl_delete : "Delete Job Card"}
                            </button>
                        }
                        <button
                            disabled={visible.displayData !== null}
                            onClick={handleSubmit(onSubmit)}
                            className="f-16 btn btn-yellow "
                        >
                            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default JobCardModalCalender