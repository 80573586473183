import React, { useEffect, useState } from "react";
import { getEmpAttendance } from "../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  calMaxPage,
  getLabels,
  setAccessPrivilage,
  convertUrl,
  formatDateString,
  formatDateToAMPM,
  convertMinutesToHHMM,
} from "../../../common/Functions/CommonFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";

import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import { Emp_Attendance_Col_Name } from "../../../common/Labels/labelConstant";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../../assets/images/icons/project_menu/collapse.png";
import AccessDenied from "../../Common/AccessDenied";
import Back from "../../../assets/images/icons/project_menu/back-button.png";

function EmpAttendance() {
  let navigate = useNavigate();
  let location = useLocation();
  const [empAttendance, setEmpAttendance] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  const [showLabel, setShowLabel] = useState(Emp_Attendance_Col_Name);
  const [constLabel, setConstLabel] = useState({});
  const [visible, setVisible] = useState(false);

  const [filterConfig, setFilterConfig] = useState({});

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    mode: "onChange",
  }); // initialise the hook

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Employee Master"));
    setConstLabel(getLabels("Master", "Employee Master"));
    // storing modules access got in login in state
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchEmpAttendance(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);


  function fetchEmpAttendance(pg, epp) {
    setEmpAttendance({ ...empAttendance, loading: true });
    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    payload.employee_id = location.state?.uuid;

    getEmpAttendance(payload).then(
      (res) => {
        // console.log("res", res);
        setEmpAttendance({
          ...empAttendance,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(res.data.total);
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setEmpAttendance({ ...empAttendance, data: [], loading: false });
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
      }
    );
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function clearFilter() {
    setFilterConfig({});
    setSrNo(0);
    setPage(1);
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {!privilage.read ?
        <AccessDenied />
        :
        <>
          <div id="mySidebar" className="customsidebar ">
            <a className="closebtn" 
                // onClick={() => toggleNav()}
            >
              ×
            </a>

          </div>
          <div className="min-vh-100" id="root_div_main">
            <div className="content-wrapper">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="d-md-flex justify-content-start col-12 col-md-6">
                      {/* <FontAwesomeIcon
                    onClick={() => toggleNav()}
                    className="cursor_pointer mb-2"
                    style={{
                      color: "Black",
                      marginRight: "10px",
                    }}
                    icon={faBars}
                    size="lg"
                  /> */}
                      <Tooltip id={"open-tooltip"} place="top" />
                      <img
                        src={Slide}
                        height="32"
                        width="32"
                        className="cursor_pointer my-1 me-2"
                        // onClick={() => toggleNav()}
                        data-tooltip-id={"open-tooltip"}
                        data-tooltip-content={"Filters"}
                      />

                      <h1 className="bold">
                        {constLabel?.lbl_employee_attendance
                          ? constLabel.lbl_employee_attendance
                          : "Employee Attendance"}
                      </h1>
                    </div>
                    <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                      
                      <Tooltip id={"show-tooltip"} place="top" />
                      <div
                        className="dropdown"
                        data-tooltip-id={"show-tooltip"}
                        data-tooltip-content={"Show / Hide Columns"}
                      >
                        <div
                          className="d-md-flex justify-content-start align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                          {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                          <img
                            src={List}
                            height="32"
                            width="32"
                            className="cursor_pointer"
                          />
                        </div>

                        <ul className="dropdown-menu px-2">
                          {showLabel !== undefined && showLabel.length > 0
                            ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                      {/* <FontAwesomeIcon
                    onClick={() => toggleNav()}
                    className="cursor_pointer mb-2"
                    style={{
                      color: "Black",
                      marginRight: "10px",
                    }}
                    icon={faBars}
                    size="lg"
                  /> */}
                      <img
                        src={Back}
                        height="20"
                        width="20"
                        className="cursor_pointer my-1 me-2"
                        onClick={() => navigate(-1)}
                      />

                      <h4 className="">
                        {location.state?.FirstName + " " + location.state?.LastName + " - " + location.state?.Designation}
                      </h4>
                    </div>
                  </div>
                  <div className="mt-3">
                    {empAttendance.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : empAttendance.data && empAttendance.data.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-grey roboto">
                            <tr className="f-14">
                              <th scope="col">{"Sr.No"}</th>
                              {showLabel.map((v, i) =>
                                v.label === "Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Date"}
                                  </th>
                                ) : v.label === "Check In" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Check In"}
                                  </th>
                                ) : v.label === "Check Out" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Check Out"}
                                  </th>
                                ) : v.label === "Duration" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Duration"}{" "}{"( HH:MM )"}
                                  </th>
                                ) : null
                              )}
                            </tr>
                          </thead>
                          <tbody className="roboto">
                            {empAttendance.data.map((val, i) => (
                              <tr key={i}>
                                <td>{i + parseInt(srNo) + 1}</td>
                                {showLabel.map((item, idx) =>
                                  item.label === "Date" && item.flag ? (
                                    <td key={idx}>
                                      {val.check_in_timestamp !== "" && val.check_in_timestamp !== null
                                        ? formatDateString(val.check_in_timestamp)
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Check In" && item.flag ? (
                                    <td key={idx}>
                                      {val.check_in_timestamp !== "" && val.check_in_timestamp !== null
                                        ? formatDateToAMPM(val.check_in_timestamp)
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Check Out" && item.flag ? (
                                    <td key={idx}>
                                      {val.check_out_timestamp !== "" && val.check_out_timestamp !== null
                                        ? formatDateToAMPM(val.check_out_timestamp)
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Duration" && item.flag ? (
                                    <td key={idx}>
                                      {val.duration !== "" &&
                                        val.duration !== null
                                        ? convertMinutesToHHMM(val.duration)
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                          <p className="mb-0">
                            Show
                            <select
                              className="mx-1"
                              defaultValue={entriesPerPage}
                              onChange={(e) => {
                                setEntriesPerPage(e.target.value);
                                fetchEmpAttendance(1, e.target.value);
                                setPage(1);
                                setSrNo(0);
                              }}
                            >
                              <option value={"10"}>10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                            Entries
                          </p>
                          <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                            } to ${Math.min(
                              parseInt(entriesPerPage) + parseInt(srNo),
                              empAttendance.totalRecords
                            )} of ${empAttendance.totalRecords} entries`}</p>
                          <Pagination className="my-2">
                            <Pagination.Prev
                              disabled={page === 1 ? true : false}
                              onClick={() => {
                                fetchEmpAttendance(
                                  page - 1,
                                  entriesPerPage,
                                  parseInt(srNo) - parseInt(entriesPerPage)
                                );
                                setSrNo((prevC) =>
                                  page - 1 == 1
                                    ? 0
                                    : prevC - parseInt(entriesPerPage)
                                );
                                setPage(page - 1);
                              }}
                            >
                              {"Prev"}
                            </Pagination.Prev>

                            <Pagination.Item active>{page}</Pagination.Item>

                            <Pagination.Next
                              disabled={
                                page === maxPage ||
                                  maxPage === 0 ||
                                  entriesPerPage > empAttendance.data.length
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                fetchEmpAttendance(
                                  page + 1,
                                  entriesPerPage,
                                  parseInt(srNo) + parseInt(entriesPerPage)
                                );
                                setSrNo(
                                  (prevC) => prevC + parseInt(entriesPerPage)
                                );
                                setPage(page + 1);
                              }}
                            >
                              {"Next"}
                            </Pagination.Next>
                          </Pagination>
                      </div>
                    </>
                    ) : (
                      <p className="text-danger mb-0 f-22 text-center mt-4">
                        {"Employee attendance not found!"}
                      </p>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>}
    </React.Fragment>
  );
}

export default EmpAttendance;
