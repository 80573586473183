import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAdd,
  faEye,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Modal, Spinner, Tab, Tabs } from "react-bootstrap";
import { Trash2, Plus } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import {
  getLotNumList,
  getOperationListForNc,
  getMachineList,
  getNCStatus,
  getNCReason,
  saveNCAPI,
  updateNCAPI,
  getOperatorList,
  getProductAttribute,
  get_WorkOrder,
  getProdConfig,
  get_job_card,
  getGlobalRouting,
  getInternalCompany,
} from "../../api/index";
import {
  convertDateYYMMDD,
  convertDate,
  getLabels,
  convertBase64,
} from "../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import camshaft from "../../assets/images/camshaft.jpg";
import NcAttributes from "./NC-Attributes/NcAttributes";
import { Tooltip } from "react-tooltip";
import { work_order } from "../../constant";

const schema = yup
  .object()
  .shape({
    // ncPrefix: yup.string().required("Prefix is required !").matches(/^[A-Z0-9]*$/, "Only alphabets and space Allowed !"),,
    ncTag: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Please enter nc tag"),
    date: yup
      .date()
      .typeError("Please select date !")
      .required("Please select date !"),
    // camType: yup.string().required("Cam shaft type is required !"),
    quantity: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Please enter quantity"),
    ncOperation: yup
      .array()
      .min(1, "Please select NC operation")
      .required("Please select NC operation"),
    machineNo: yup
      .array()
      .min(1, "Please select machine !")
      .required("Please select machine !"),
    ncReason: yup
      .array()
      .min(1, "Please select NC reason")
      .required("Please select NC reason"),
    qty: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Quantity is required !"),
    operation: yup
      .array()
      .min(1, "Please select operation")
      .required("Please select operation"),
    disposalAction: yup
      .array()
      .min(1, "Please select disposal action")
      .required("Please select disposal action"),
    operator: yup
      .array()
      .min(1, "Please select nc by operator")
      .required("Please select nc by operator"),
    operator_tag: yup
      .array()
      .min(1, "Please select nc tag operator")
      .required("Please select nc tag operator"),
    organization: yup
      .array()
      .min(1, "Please select organization")
      .required("Please select organization"),
    remark: yup.string().required("Please enter root cause !"),
    items: yup.array().of(
      yup.object().shape({
        jobNo: yup
          .number()
          // .integer("Please enter a valid number")
          .typeError("Please enter a valid number")
          .required("Job no is required !"),
        jobDate: yup
          .date()
          .typeError("Please select date !")
          .required("Please select date !"),
      })
    ),
    // selfile: yup
    //     .mixed()
    //     .test('fileSize', 'Image can be uploaded upto 10 MB',
    //         (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)),
  })
  .required();

function CreateNc(props) {
  let navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [lotList, setLotList] = useState({ data: [], loading: false });
  const [opList, setOpList] = useState({ data: [], loading: false });
  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [disActionList, setDisActionList] = useState({
    data: [],
    loading: false,
  });
  const [ncRsnList, setNcRsnList] = useState({ data: [], loading: false });
  const [operatorList, setOperatorList] = useState({
    data: [],
    loading: false,
  });
  const [operatorList1, setOperatorList1] = useState({
    data: [],
    loading: false,
  });
  const [jobNoRange, setJobNoRange] = useState({ start: "", end: "" });
  const [lotNo, setLotNo] = useState("");
  // const [sellot, setSelLot] = useState({});

  const [attributeModal, setAttributeModal] = useState({ state: false });
  const [nc_attrib, setNC_Attrib] = useState([]);
  const [prod_attr, setProd_Attr] = useState({ data: [], loading: false });
  const [currIndex, setCurrIndex] = useState();

  const [woList, setWOList] = useState({ data: [], loading: false });
  const [jobCardList, setJobCardList] = useState({ data: [], loading: false });

  const [constLabel, setConstLabel] = useState({});

  const [isLot, setIsLot] = useState(false);
  const [lockBtn, setLockBtn] = useState(false);

  const [orgList, setOrgList] = useState({ data: [], loading: false });

  const localData = JSON.parse(localStorage.getItem("localData"));

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [{ jobNo: "", jobDate: null, selfile: "" }],
    },
  });
  let image = watch("selfile");
  // let lotNo = watch("lotNo")

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    const result = localData?.organizations?.filter((item) => item.default_org == true);
    setValue("organization", result);
  }, [localData])
  

  useEffect(() => {
    if (image?.length > 0) {
      setImagePreview(URL.createObjectURL(image[0]));
    } else {
      setImagePreview(null);
    }
  }, [image]);

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file && file.size > 10 * 1024 * 1024) {
      // Manually set an error message for the file input
      setError(`items[${index}].selfile`, {
        type: "manual",
        message: "File size should be below 10 MB.",
      });
      // Clear the input value to allow reselection
      event.target.value = null;
    } else {
      // Clear any previous error message
      setError(`items[${index}].selfile`, null);
    }
  };

  function searchLotNoDetails() {
    if (lotNo && lotNo?.length > 0) {
      fetchLotList();
    } else {
      if (isLot && isLot === true) {
        toast.error("Please enter lot no", {
          autoClose: 2000,
        });
      } else {
        toast.error("Please enter work order no", {
          autoClose: 2000,
        });
      }
      clearAll();
    }
  }

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
    // if (visible) {
    setConstLabel(getLabels("Quality", "NC"));
    // fetchOperationList();
    fetchMachineList();
    fetchDisposalList();
    fetchNcReasonList();
    fetchOperatorList();
    fetchOrg();
    // }
  }, []);

  let selDate = watch("date");
  let selQuantity = watch("quantity");
  const selOperation = watch("operation");
  let selJobCard = watch("job-card");

  useEffect(() => {
    if (selJobCard && selJobCard.length > 0) {
      setLotList({ ...lotList, data: selJobCard, loading: false });
      console.log("selJobCard", selJobCard);
      
      setValue("machineNo", [
        {
          uuid: selJobCard[0]?.machineDetails?.uuid,
          machineId: selJobCard[0]?.machineDetails?.machineId,
          machineNameSerialNo:
            selJobCard[0]?.machineDetails?.machineName +
            " - " +
            selJobCard[0]?.machineDetails?.serialNo,
        },
      ], {shouldValidate: true});
      setValue("operator", [
        {
          uuid: selJobCard[0]?.operatorDetails?.uuid,
          fullName:
            selJobCard[0]?.operatorDetails.MiddleName !== null &&
            selJobCard[0]?.operatorDetails.MiddleName !== ""
              ? selJobCard[0]?.operatorDetails.FirstName +
                " " +
                selJobCard[0]?.operatorDetails.MiddleName +
                " " +
                selJobCard[0]?.operatorDetails.LastName
              : selJobCard[0]?.operatorDetails.FirstName +
                " " +
                selJobCard[0]?.operatorDetails.LastName,
            FirstName: selJobCard[0]?.operatorDetails.FirstName,
            MiddleName: selJobCard[0]?.operatorDetails.MiddleName,
            LastName: selJobCard[0]?.operatorDetails.LastName,
        },
      ], {shouldValidate: true});

      setValue("operator_tag", [
        {
          uuid: selJobCard[0]?.operatorDetails?.uuid,
          fullName:
            selJobCard[0]?.operatorDetails.MiddleName !== null &&
            selJobCard[0]?.operatorDetails.MiddleName !== ""
              ? selJobCard[0]?.operatorDetails.FirstName +
                " " +
                selJobCard[0]?.operatorDetails.MiddleName +
                " " +
                selJobCard[0]?.operatorDetails.LastName
              : selJobCard[0]?.operatorDetails.FirstName +
                " " +
                selJobCard[0]?.operatorDetails.LastName,
            FirstName: selJobCard[0]?.operatorDetails.FirstName,
            MiddleName: selJobCard[0]?.operatorDetails.MiddleName,
            LastName: selJobCard[0]?.operatorDetails.LastName,
        },
      ], {shouldValidate: true});
    if(selJobCard?.[0]?.workorderDetails?.global_route_uuid !== null){
      setValue("operation", [
        {
          operationName: selJobCard[0]?.operationDetails?.operationName,
          operationID: selJobCard[0]?.operationDetails?.operationID,
          uuid: selJobCard[0]?.operationDetails?.uuid,
        },
      ], {shouldValidate: true});
      setValue("ncOperation", [
        {
          operationName: selJobCard[0]?.operationDetails?.operationName,
          operationID: selJobCard[0]?.operationDetails?.operationID,
          uuid: selJobCard[0]?.operationDetails?.uuid,
        },
      ], {shouldValidate: true});
      fetchGlobalRoute(selJobCard?.[0]?.workorderDetails?.global_route_uuid);
    } else {
      setValue("operation", [
        {
          operationName: selJobCard[0]?.productOperDetails?.operationname,
          operationID: selJobCard[0]?.productOperDetails?.operationid,
          uuid: selJobCard[0]?.productOperDetails?.operation_id,
        },
      ], {shouldValidate: true});
      setValue("ncOperation", [
        {
          operationName: selJobCard[0]?.productOperDetails?.operationname,
          operationID: selJobCard[0]?.productOperDetails?.operationid,
          uuid: selJobCard[0]?.productOperDetails?.operation_id,
          sequenceNo: selJobCard[0]?.productOperDetails?.sequenceNo
        },
      ], {shouldValidate: true});
      fetchOperationList(selJobCard[0]?.productDetail?.uuid);
    }
    } else {
      setLotList({ ...lotList, data: [], loading: false });
      clearAll();
    }
  }, [selJobCard]);

  useEffect(() => {
    const itm = getValues("items");
    for (let i = 0; i < itm.length; i++) {
      setValue(`items[${i}].jobDate`, selDate);
    }
  }, [selDate, selQuantity]);

  useEffect(() => {
    if (
      selOperation !== undefined &&
      selOperation.length > 0 &&
      lotList.data !== undefined &&
      lotList.data.length > 0
    ) {
      console.log("lotList.data", lotList.data);
      fetchProductAttributes(
        isLot && isLot === true
          ? lotList.data?.[0].product_id.uuid
          : lotList.data?.[0].product_id
      );
    }
  }, [selOperation, lotList.data]);

  function handleAppend(e) {
    let i;
    if (
      parseInt(e.target.value) !== undefined &&
      parseInt(e.target.value) !== ""
    ) {
      for (i = 0; i < parseInt(e.target.value); i++) {
        append({});
      }
    }

    if (parseInt(e.target.value) !== "") {
      if (fields.length > 0) {
        remove(fields);
      }
    }
  }

  function fetchLotList() {
    setLotList({ ...lotList, data: [], loading: true });
    if (isLot && isLot === true) {
      getLotNumList({ lotId: lotNo }).then(
        (res) => {
          let arr = [];
          if (res.data.data.length > 0) {
            const { productDetail, product_id, ...rest } = res.data.data[0];
            arr.push({ ...rest, product_id: productDetail });
          }
          setLotList({ ...lotList, data: arr, loading: false });
          if (res.data.data.length == 0) {
            toast.error("Lot number not found", {
              autoClose: 2000,
            });
          } else {
            // console.log("arr",arr)
            fetchOperationList(arr[0].product_id.uuid);
            // fetchOperationList(res.data.data[0].productDetail.uuid);
            // fetchProductAttributes(res.data.data[0].product_id.uuid);
            // fetchMachineList();
            setValue("camType", res.data.data[0].productname);
            setValue("partNo", res.data.data[0].partno);
            setValue("qty", res.data.data[0].qty);
            // setSelLot(res.data.data[0]);
            setJobNoRange({
              ...jobNoRange,
              start: res.data.data[0].lotstartnum,
              end: res.data.data[0].lotendnum,
            });
          }
        },
        (err) => {
          setLotList({ ...lotList, data: [], loading: false });
        }
      );
    } else {
      // setLotList({ ...lotList, data: res.data.data, loading: false });
      get_WorkOrder({
        work_order_no: lotNo,
        work_order_status: "Released",
      }).then(
        (res) => {
          setWOList({ ...woList, data: res.data.data, loading: false });
          if (res.data.data.length == 0) {
            toast.error("Work order no not found", {
              autoClose: 2000,
            });
          } else {
            // console.log("work order res", res);
            // fetchOperationList(res.data.data[0].product_id);
            // // fetchProductAttributes(res.data.data[0].product_id.uuid);
            // // fetchMachineList();
            setValue("camType", res.data.data[0]?.product_info?.productName);
            setValue("partNo", res.data.data[0]?.product_info?.partno);
            setValue("qty", res.data.data[0].quantity);
            // // setSelLot(res.data.data[0]);
            setJobNoRange({
              ...jobNoRange,
              start: res.data.data[0].serial_number_start,
              end: res.data.data[0].serial_number_end,
            });

            setJobCardList({ ...jobCardList, data: [], loading: true });
            // Job card api
            get_job_card({ work_order_id: res.data.data[0]?.uuid }).then(
              (ress) => {
                setJobCardList({ ...jobCardList, data: [], loading: true });
                if (ress.data.data.length == 0) {
                  toast.error("Job card not found", {
                    autoClose: 2000,
                  });
                } else {
                  setJobCardList({
                    ...jobCardList,
                    data: ress.data.data,
                    loading: false,
                  });
                }
              },
              (errr) => {
                setJobCardList({ ...jobCardList, data: [], loading: false });
              }
            );
            // Job card api
          }
        },
        (err) => {
          // console.log("err", err);
          if (err.response.status == 404) {
            toast.error("Released work order not found !", {
              autoClose: 2000,
            });
          }
          setWOList({ ...woList, data: [], loading: false });
        }
      );
    }
  }

  function fetchOperationList(pid) {
    if (pid == null) {
      toast.error("Product id not found in lot response", {
        autoClose: 2000,
      });
    } else {
      setOpList({ ...opList, data: [], loading: true });
      getProdConfig({ product_id: pid }).then(
        (res) => {
          console.log("resda",res.data.data);
          let arr = res.data.data
            ? res.data.data.map((item) => {
                const {
                  operationname,
                  operationid,
                  operation_id,
                  uuid,
                  ...rest
                } = item;
                let obj = {
                  ...rest,
                  operationName: operationname,
                  operationID: operationid,
                  uuid: operation_id,
                };
                return obj;
              })
            : [];
          setOpList({ ...opList, data: arr, loading: false });
          console.log("arr", arr);
        },
        (err) => {
          setOpList({ ...opList, data: [], loading: false });
        }
      );
    }
  }

  function fetchMachineList() {
    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        if (res.data.data.length > 0) {
          const result = res.data.data.map((item) => ({
            ...item,
            machineNameSerialNo: item.machineName + " - " + item.serialNo,
          }));
          setMachineList({ ...machineList, data: result, loading: false });
        } else {
          setMachineList({ ...machineList, data: [], loading: false });
        }
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  function fetchDisposalList() {
    setDisActionList({ ...disActionList, data: [], loading: true });
    getNCStatus().then(
      (res) => {
        setDisActionList({
          ...disActionList,
          data: res.data.data,
          loading: false,
        });

        const def_sts = res.data.data.filter((item) => item.statusname == "Pending");
        setValue("disposalAction", def_sts, {shouldValidate: true})
      },
      (err) => {
        setDisActionList({ ...disActionList, data: [], loading: false });
      }
    );
  }

  function fetchNcReasonList() {
    setNcRsnList({ ...ncRsnList, data: [], loading: true });
    getNCReason().then(
      (res) => {
        setNcRsnList({ ...ncRsnList, data: res.data.data, loading: false });
      },
      (err) => {
        setNcRsnList({ ...ncRsnList, data: [], loading: false });
      }
    );
  }

  function fetchOperatorList() {
    setOperatorList({ ...operatorList, data: [], loading: true });
    setOperatorList1({ ...operatorList1, data: [], loading: true });
    getOperatorList({ designation: "Operator" }).then(
      (res) => {
        if (res.data.data.length > 0) {
          const updatedPeopleList = res.data.data.map((person) => ({
            ...person,
            fullName:
              person.MiddleName !== null && person.MiddleName !== ""
                ? person.FirstName +
                  " " +
                  person.MiddleName +
                  " " +
                  person.LastName
                : person.FirstName + " " + person.LastName,
          }));

          setOperatorList({
            ...operatorList,
            data: updatedPeopleList,
            loading: false,
          });
          setOperatorList1({
            ...operatorList1,
            data: updatedPeopleList,
            loading: false,
          });
        } else {
          setOperatorList({ ...operatorList, data: [], loading: false });
          setOperatorList1({ ...operatorList1, data: [], loading: false });
        }
      },
      (err) => {
        setOperatorList({ ...operatorList, data: [], loading: false });
        setOperatorList1({ ...operatorList1, data: [], loading: false });
      }
    );
  }

  function fetchOrg() {
    setOrgList({ ...orgList, loading: true, data: [] });

    getInternalCompany().then(
      (res) => {
        setOrgList({ ...orgList, loading: false, data: res.data.data });
      },
      (err) => {
        setOrgList({ ...orgList, loading: false, data: [] });
      }
    );
  }

  function isNumberInRange(number, min, max) {
    return number >= min && number <= max;
  }

  const uniqueJobNumbers = [];
  function uniqueJobNo(data) {
    if (uniqueJobNumbers.includes(data)) {
      return true; // Exit early if a duplicate is found
    } else {
      uniqueJobNumbers.push(data);
      return false;
    }
  }

  function fetchProductAttributes(prod_uuid) {
    setProd_Attr({ ...prod_attr, data: [], loading: true });
    const payload = {
      product_id: prod_uuid,
    };
    getProductAttribute(payload).then(
      (res) => {
        // const result = res.data.data.filter(
        //   (item) =>
        //     selOperation?.[0]?.uuid === item.product_operation?.operation_id
        // );

        const result = res.data.data.filter(
          (item) =>
            selOperation?.[0]?.operationName == item.product_operation_name
        );

        if (result) {
          setProd_Attr({ ...prod_attr, data: result, loading: false });
        } else {
          setProd_Attr({ ...prod_attr, data: [], loading: false });
        }
      },
      (err) => {
        setProd_Attr({ ...prod_attr, data: [], loading: false });
      }
    );
  }

  function clearAll() {
    reset({
      items: [{ jobNo: "", jobDate: null, selfile: "" }],
      ncTag: "",
      date: null,
      // camType: "",
      lotNo: [],
      // jobNo: "",
      quantity: "",
      machineNo: [],
      qty: "",
      operation: [],
      disposalAction: [],
      operator: [],
      operator_tag: [],
      // selfile: "",
      remark: "",
      ncReason: [],
      ncOperation: [],
    });
    // setLotNo("");
    setLotList({ data: [] });
    // setJobCardList({ data: []});
  }

  const calculateSum = (sq_no) => {

    let sum = 0;
      sum = opList.data
        .filter(item => item.sequenceNo < sq_no)  // Get operations before sqNo 4
        .reduce((acc, curr) => acc + Number(curr.oprcost), 0); // Sum their costs
      return Number(sum);
    };


  const onSubmit = async (data) => {
    // console.log("data", data);
    setLockBtn(true);
    let reqPayload = [];
    let hasError = false;

    for (let i = 0; i < data.items.length; i++) {
      const payload = {
        // nc_date: convertDate(data.items[i]?.jobDate, "-"),
        nc_date: convertDateYYMMDD(data.items[i]?.jobDate, "-"),
        ncreason: data.ncReason[0].NCReason,
        ncreason_id: data.ncReason[0].uuid,
        ncstatus: data.disposalAction[0].statusname,
        ncstatus_id: data.disposalAction[0].uuid,
        finished_weight:
          isLot && isLot === true
            ? lotList.data[0].product_id?.finishweight
            : 0,
        selling_price:
          isLot && isLot === true
            ? lotList.data[0].product_id?.selling_price
            : 0,
        // nc_prefix: data.ncPrefix,
        nc_tag: data.ncTag,
        qty: 1,
        // qty: data.quantity,
        // qty: data.qty,
        machine_id: data.machineNo[0].machineId,
        machine_id_id: data.machineNo[0].uuid,
        operation: data.operation[0].operationName,
        operation_id: data.operation[0].operationID,
        operation_id_id: data.operation[0].uuid,
        operation_inwhich: data.ncOperation[0].operationName,
        operation_inwhich_id: data.ncOperation[0].uuid,
        // operator: data.operator[0].FirstName + " " + data.operator[0].MiddleName + " " + data.operator[0].LastName,
        operator: data.operator[0].FirstName + " " + data.operator[0].LastName,
        operator_id: data.operator[0].uuid,
        nc_tag_operator: data.operator_tag[0].FirstName + " " + data.operator_tag[0].LastName,
        nc_tag_operator_id: data.operator_tag[0].uuid,
        
        // lot_no: lotList.data[0]?.lotId,
        // lot_no_id: lotList.data[0]?.uuid,
        job_no: data.items[i]?.jobNo,
        // lot_size: data.quantity,
        lot_size:
          isLot && isLot === true
            ? lotList.data?.[0]?.qty
            : lotList.data?.[0]?.workorderDetails?.quantity,
        camshaft_type:
          isLot && isLot === true
            ? lotList.data?.[0]?.product_id?.productName
            : lotList.data?.[0]?.productDetail?.productName,
        camshaft_type_id:
          isLot && isLot === true
            ? lotList.data?.[0]?.product_id?.uuid
            : lotList.data?.[0]?.productDetail?.uuid,
        part_no:
          isLot && isLot === true
            ? lotList.data?.[0]?.product_id?.partNo
            : lotList.data?.[0]?.productDetail?.partNo,
        remark: data.remark,
        // user_id: userData.user_id,
        // client_id: userData.client_id,
        // isActive: "True",
        isLot: isLot,
        operation_labor_cost: selJobCard?.[0]?.workorderDetails?.global_route_uuid == null ? calculateSum(data.ncOperation[0].sequenceNo) : 0,
        org_id: data.organization?.[0]?.uuid,
      };

      if (isLot && isLot === true) {
        payload.lot_no = lotList.data[0]?.lotId;
        payload.lot_no_id = lotList.data[0]?.uuid;
        payload.product_id = lotList.data?.[0]?.product_id?.productId;
        payload.work_order_no = null;
        payload.work_order_id = null;
        payload.work_order_status = null;
        payload.job_card_id = null;
      } else {
        payload.lot_no = null;
        payload.lot_no_id = null;
        payload.work_order_no = woList.data[0]?.work_order_no;
        payload.work_order_id = woList.data[0]?.uuid;
        payload.work_order_status = woList.data[0]?.work_order_status;
        payload.product_id = lotList.data[0]?.productDetail?.productId;
        payload.job_card_id = lotList.data[0]?.uuid;
      }

      if (nc_attrib !== undefined && nc_attrib[i]?.length > 0) {
        payload.nc_parameter_detail = nc_attrib[i];
      } else {
        payload.nc_parameter_detail = [];
      }

      // console.log("payload", payload); 

      if (
        data.items[i]?.selfile !== undefined &&
        data.items[i]?.selfile.length > 0
      ) {
        const getExt = data.items[i]?.selfile?.[0]?.name?.split(".").pop();
        let dataimage = await convertBase64(data.items[i]?.selfile[0]);
        let newImage = dataimage.replace(
          /data:image\/[a-zA-Z]+;/,
          "data:image/" + getExt + ";"
        );

        payload.ncjobimage = newImage;
        payload.ncjobimgname = data.items[i]?.selfile[0]?.name;
      } else {
        payload.ncjobimage = null;
        payload.ncjobimgname = null;
      }

      reqPayload.push(payload);

      if (
        !isNumberInRange(data.items[i]?.jobNo, jobNoRange.start, jobNoRange.end)
      ) {
        setError(
          `items[${i}].jobNo`,
          {
            type: "focus",
            message: `Job no must be between ${jobNoRange.start} and ${jobNoRange.end}`,
          },
          { shouldFocus: true }
        );
        hasError = true;
      }

      if (uniqueJobNo(data.items[i]?.jobNo)) {
        setError(
          `items[${i}].jobNo`,
          {
            type: "focus",
            message: `Job no must be unique ${data.items[i].jobNo}`,
          },
          { shouldFocus: true }
        );
        hasError = true;
      }
    }

    if (hasError) {
      // If there is an error, do not proceed to saveNCAPI
      return;
    }

    // As per api changes below code added 03-01-2023
    const new_payload = {
      datalist: reqPayload,
    };

    // console.log("new_payload", new_payload); 

    saveNCAPI(new_payload).then(
      (res) => {
        if (res.status === 201) {
          toast.success("NC created successfully", {
            autoClose: 1000,
          });

          setTimeout(() => {
            navigate("/pcms/nc");
          }, [1200]);
          setLockBtn(false);
        }

        setImagePreview(null);
        clearAll();
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
        setLockBtn(false);
      }
    );
  };

  function fetchGlobalRoute(glob_route_uuid){
    getGlobalRouting({uuid: glob_route_uuid}).then(
      (res) => {
        console.log("res", res);
        let arr = res.data.data
            ? res.data.data?.[0]?.routeData.map((item) => {
                const {
                  operationname,
                  operationid,
                  operation_id,
                  uuid,
                  ...rest
                } = item;
                let obj = {
                  ...rest,
                  operationName: operationname,
                  operationID: operationid,
                  uuid: operation_id,
                };
                return obj;
              })
            : [];
        setOpList({ ...opList, data: arr, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <NcAttributes
        attributeModal={attributeModal}
        setAttributeModal={setAttributeModal}
        prod_attr={prod_attr}
        nc_attrib={nc_attrib}
        setNC_Attrib={setNC_Attrib}
        currInd={currIndex}
      />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;
                    navigate("/pcms/nc");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_create_non_conformance
                ? constLabel.lbl_create_non_conformance
                : "Create Non-Conformance"}
            </p>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <div className="row mb-3">
                <div
                  className="form-check form-switch"
                  style={{ paddingLeft: "3rem" }}
                >
                  <label
                    className="form-check-label lbl-style roboto-bold"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    {"Lot / Work Order"}
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={isLot}
                    onChange={() => {
                      setIsLot(!isLot);
                      clearAll();
                      setLotNo("");
                    }}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12 col-md-6 row me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold col-12 col-md-3"
                  >
                    {isLot && isLot === true
                      ? constLabel?.lbl_lot_no
                        ? constLabel.lbl_lot_no
                        : "Lot No"
                      : constLabel?.lbl_work_order_no
                      ? constLabel.lbl_work_order_no
                      : "Work Order No"}
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder={
                        isLot && isLot === true
                          ? "Search lot no"
                          : "Search work order no"
                      }
                      onChange={(e) => setLotNo(e.target.value)}
                      value={lotNo}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <button
                    className="btn btn-sm btn-grey px-3"
                    onClick={() => searchLotNoDetails()}
                  >
                    {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
                  </button>
                </div>
              </div>

              {isLot === false ? (
                <div className="row mb-3">
                  <div className="col-12 col-md-6 row">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      {constLabel?.lbl_job_card_no
                        ? constLabel.lbl_job_card_no
                        : "Job Card No"}
                    </label>

                    <div className="col-12 col-md-9">
                      <Controller
                        name={`job-card`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="job_card_no"
                            size="sm"
                            multiple={false}
                            options={jobCardList.data}
                            isLoading={jobCardList.loading}
                            placeholder="Select job card no"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            // isInvalid={errors.job-card ? true : false}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {lotList.data?.length > 0 &&
              lotList.data !== null &&
              lotList.data !== undefined ? (
                <>
                  <hr className="my-2" />
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {constLabel?.lbl_product_detail
                      ? constLabel.lbl_product_detail
                      : "Product Detail"}
                  </p>
                  <hr className="my-2" />

                  <div className="row my-3" style={{ fontSize: "16px" }}>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_name
                              ? constLabel.lbl_name
                              : "Name"}
                          </p>
                          <p className="mb-0" style={{ color: "#FF134E" }}>
                            {lotList.data[0]?.productname ||
                              lotList.data[0]?.productDetail?.productName}
                          </p>
                        </div>
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_part_no
                              ? constLabel.lbl_part_no
                              : "Part No"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].product_id.partNo ||
                              lotList.data[0]?.productDetail?.partNo}
                          </p>
                        </div>
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_lot_size
                              ? constLabel.lbl_lot_size
                              : "Lot Size"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].qty ||
                              lotList.data[0]?.workorderDetails?.quantity}
                          </p>
                        </div>
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_family
                              ? constLabel.lbl_family
                              : "Family"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].product_familyname ||
                              woList.data[0]?.product_info?.prodfamily1
                                ?.familyname}
                          </p>
                        </div>
                        <div className="col-12 col-md-3 mt-2">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_lot_start_no
                              ? constLabel.lbl_lot_start_no
                              : "Lot Start No"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].lotstartnum ||
                              lotList.data[0]?.workorderDetails
                                ?.serial_number_start}
                          </p>
                        </div>
                        <div className="col-12 col-md-3 mt-2">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_lot_end_no
                              ? constLabel.lbl_lot_end_no
                              : "Lot End No"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].lotendnum ||
                              lotList.data[0]?.workorderDetails
                                ?.serial_number_end}
                          </p>
                        </div>
                        <div className="col-12 col-md-3 mt-2">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_finished_weight
                              ? constLabel.lbl_finished_weight
                              : "Finished Weight"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].product_id?.finishweight || 0}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <button
                            onClick={() => setVisible(true)}
                            className="btn btn-sm btn-grey px-3"
                          >
                            {constLabel?.lbl_view_product_image
                              ? constLabel.lbl_view_product_image
                              : "View Product Images"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

              <hr className="my-2" />
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_product_detail
                  ? constLabel.lbl_product_detail
                  : "NC Detail"}
              </p>
              <hr className="my-2" />

              <div className="row mt-3">
                {/* <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    Prefix
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.ncPrefix
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      type="text"
                      {...register("ncPrefix")}
                      placeholder="Enter nc prefix"
                    />
                    {errors.ncPrefix && (
                      <span className="text-danger err-msg">
                        {errors.ncPrefix.message}
                      </span>
                    )}
                  </div>
                </div> */}

                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_organization
                        ? constLabel?.lbl_organization
                        : "Organization"}
                      <span className="text-danger"> *</span>
                    </label>
                    <Controller
                      name={`organization`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={orgList.data}
                          isLoading={orgList.loading}
                          placeholder="Select organization"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.organization ? true : false}
                        />
                      )}
                    />
                    {!orgList.loading && !errors.organization && <span className="arrow-icon"></span>}
                    {errors.organization && (
                      <span className="text-danger err-msg">
                        {errors.organization.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_nc_tag ? constLabel.lbl_nc_tag : "NC Tag"}
                        <span className="text-danger" > *</span>
                    </label>

                    <input
                      className={
                        errors.ncTag
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="number"
                      {...register("ncTag")}
                      placeholder="Enter NC Tag"
                    />
                    {errors.ncTag && (
                      <span className="text-danger err-msg">
                        {errors.ncTag.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_nc_reason
                        ? constLabel.lbl_nc_reason
                        : "NC Reason"}
                        <span className="text-danger"> *</span>
                    </label>
                    <Controller
                      name={`ncReason`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="NCReason"
                          multiple={false}
                          options={ncRsnList.data}
                          isLoading={ncRsnList.loading}
                          placeholder="Select NC Reason"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.ncReason ? true : false}
                        />
                      )}
                    />
                    {!ncRsnList.loading && !errors.ncReason && <span className="arrow-icon"></span>}
                    {errors.ncReason && (
                      <span className="text-danger err-msg">
                        {errors.ncReason.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_machine
                      ? constLabel.lbl_machine
                      : "Machine"}
                      <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`machineNo`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="machineNameSerialNo"
                          multiple={false}
                          options={machineList.data}
                          isLoading={machineList.loading}
                          placeholder="Select Machine"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.machineNo ? true : false}
                          // disabled ={isLot === false ?  true : false}
                        />
                      )}
                    />
                    {!machineList.loading && !errors.machineNo && <span className="arrow-icon"></span>}
                    {errors.machineNo && (
                      <span className="text-danger err-msg">
                        {errors.machineNo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_nc_detected_operation
                      ? constLabel.lbl_nc_detected_operation
                      : "NC Detected Operation"}
                      <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`ncOperation`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="operationName"
                          multiple={false}
                          options={opList.data}
                          isLoading={opList.loading}
                          placeholder="Select NC Operation"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.ncOperation ? true : false}
                        />
                      )}
                    />
                    {!opList.loading && !errors.ncOperation && <span className="arrow-icon"></span>}
                    {errors.ncOperation && (
                      <span className="text-danger err-msg">
                        {errors.ncOperation.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_disposal_action
                      ? constLabel.lbl_disposal_action
                      : "Disposal Action"}
                      <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`disposalAction`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="statusname"
                          multiple={false}
                          options={disActionList.data}
                          isLoading={disActionList.loading}
                          placeholder="Select Disposal Action"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.disposalAction ? true : false}
                          disabled={value?.[0]?.statusname == "Pending" ? true : false}
                          
                        />
                      )}
                    />
                    {!disActionList.loading && !errors.disposalAction && <span className="arrow-icon"></span>}
                    {errors.disposalAction && (
                      <span className="text-danger err-msg">
                        {errors.disposalAction.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_root_cause_at_operation
                      ? constLabel.lbl_root_cause_at_operation
                      : "Root Cause At Operation"}
                      <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`operation`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="operationName"
                          multiple={false}
                          options={opList.data}
                          isLoading={opList.loading}
                          placeholder="Select Operation"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.operation ? true : false}
                          // disabled ={isLot === false ?  true : false}
                        />
                      )}
                    />
                    {!opList.loading && !errors.operation && <span className="arrow-icon"></span>}
                    {errors.operation && (
                      <span className="text-danger err-msg">
                        {errors.operation.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                <div className="form-group typeahead-container">
                <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_nc_by_operator
                      ? constLabel.lbl_nc_by_operator
                      : "NC By Operator"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`operator`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="fullName"
                          multiple={false}
                          options={operatorList.data}
                          isLoading={operatorList.loading}
                          placeholder="Select nc by operator"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.operator ? true : false}
                          // disabled ={isLot === false ?  true : false}
                        />
                      )}
                    />
                    {!operatorList.loading && !errors.operator && <span className="arrow-icon"></span>}
                    {errors.operator && (
                      <span className="text-danger err-msg">
                        {errors.operator.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_nc_tag_operator
                      ? constLabel.lbl_nc_tag_operator
                      : "NC Tag Operator"}
                      <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name={`operator_tag`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className="mt-2"
                          id="basic-typeahead-single"
                          labelKey="fullName"
                          multiple={false}
                          options={operatorList1.data}
                          isLoading={operatorList1.loading}
                          placeholder="Select nc tag operator"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.operator_tag ? true : false}
                        />
                      )}
                    />
                    {!operatorList1.loading && !errors.operator_tag && <span className="arrow-icon"></span>}
                    {errors.operator_tag && (
                      <span className="text-danger err-msg">
                        {errors.operator_tag.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_root_cause
                      ? constLabel.lbl_root_cause
                      : "Root Cause"}
                      <span className="text-danger"> *</span>
                  </label>
                    <input
                      className={
                        errors.remark
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      placeholder="Enter root cause"
                      {...register("remark")}
                    />
                    {errors.remark && (
                      <span className="text-danger err-msg">
                        {errors.remark.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_date ? constLabel.lbl_date : "Date"}
                    <span className="text-danger"> *</span>
                  </label>
                    <Controller
                      name="date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.date
                              ? "form-control mt-2 is-invalid"
                              : "form-control mt-2"
                          }
                          {...field}
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          selected={field.value}
                          maxDate={new Date()}
                          placeholderText="Select a date"
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.date && (
                      <span className="text-danger err-msg">
                        {errors.date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 mt-3">
                  <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_quantity
                      ? constLabel.lbl_quantity
                      : "Quantity"}
                      <span className="text-danger"> *</span>
                  </label>
                    <input
                      className={
                        errors.quantity
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="number"
                      defaultValue={fields.length}
                      // {...register("quantity")}
                      {...register("quantity", {
                        onChange: (e) => {
                          handleAppend(e);
                        },
                      })}
                    />
                    {errors.quantity && (
                      <span className="text-danger err-msg">
                        {errors.quantity.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <hr className="my-2" />
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_job_detail
                  ? constLabel.lbl_job_detail
                  : "Job Detail"}
              </p>
              <hr className="my-2" />

              <div
                className="table-responsive py-3"
                style={{ overflowX: "scroll" }}
              >
                <table className="table table-bordered">
                  <thead className="thead-light text-center">
                    <tr>
                      <th scope="col">
                        {constLabel?.lbl_job_no
                          ? constLabel.lbl_job_no
                          : "Job No"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_date ? constLabel.lbl_date : "Date"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_upload_image
                          ? constLabel.lbl_upload_image
                          : "Upload Image"}
                      </th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="f-18">
                        <td>
                          <input
                            className={
                              errors.items?.[index]?.jobNo
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].jobNo`}
                            {...register(`items[${index}].jobNo`)}
                            placeholder="Enter job no"
                          />

                          {errors.items?.[index]?.jobNo && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.jobNo.message}
                            </span>
                          )}

                          {index + 1 == fields.length ? (
                            <div>
                              <button
                                style={{ fontSize: "16px" }}
                                className="btn btn-sm button-primary px-2 mt-2"
                                onClick={() => {
                                  append({});
                                  const getQty = getValues("quantity");
                                  if (getQty) {
                                    setValue("quantity", parseInt(getQty) + 1);
                                  }
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: "#ffffff",
                                    marginRight: "5px",
                                  }}
                                  icon={faAdd}
                                  size="sm"
                                />{" "}
                                {constLabel?.lbl_add
                                  ? constLabel.lbl_add
                                  : "Add"}
                              </button>
                            </div>
                          ) : null}
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <Controller
                            name={`items[${index}].jobDate`}
                            control={control}
                            // defaultValue={selDate !== undefined && selDate !== null ? selDate : null}
                            render={({ field }) => (
                              <DatePicker
                                className={
                                  errors.items?.[index]?.jobDate
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                {...field}
                                showYearDropdown
                                scrollableMonthYearDropdown
                                dateFormat="dd/MM/yyyy"
                                selected={field.value}
                                maxDate={new Date()}
                                // placeholderText="Select a date"
                                autoComplete="off"
                              />
                            )}
                          />
                          {errors.items?.[index]?.jobDate && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.jobDate.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className="form-control form-control-sm"
                            type="file"
                            accept="image/*"
                            name={`items[${index}].selfile`}
                            {...register(`items[${index}].selfile`)}
                            onChange={(e) => handleFileChange(e, index)}
                            placeholder="select file"
                          />

                          {errors.items?.[index]?.selfile && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.selfile.message}
                            </span>
                          )}
                        </td>

                        <td
                          style={{
                            verticalAlign: "top",
                            width: "10%",
                          }}
                          className="d-flex justify-content-start"
                        >
                          <div
                            className="deleteIcon cursor_pointer"
                            onClick={
                              index > 0
                                ? () => {
                                    remove(index);
                                    const getQty = getValues("quantity");
                                    if (getQty) {
                                      setValue(
                                        "quantity",
                                        parseInt(getQty) - 1
                                      );
                                    }
                                  }
                                : null
                            }
                          >
                            <Trash2 color="#FFFFFF" size={18} />
                          </div>
                          {lotList.data[0]?.product_id?.uuid ||
                          lotList.data[0]?.product_id ? (
                            <>
                              <Tooltip id={"add-tooltip" + index} place="top" />
                              <button
                                data-tooltip-id={"add-tooltip" + index}
                                data-tooltip-content={"Add Attribute"}
                                style={{ fontSize: "13px" }}
                                className="btn btn-sm button-primary ms-2"
                                onClick={() => {
                                  setAttributeModal({ state: true });
                                  setCurrIndex(index);
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: "#ffffff",
                                  }}
                                  icon={faAdd}
                                  size="sm"
                                />
                              </button>
                            </>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </>
            ) : (
                <div className="alert alert-danger mb-0 text-center my-2" role="alert">
                  {isLot && isLot === true ? "Please search lot no !" : "Please search work order no and select job card no !" }
                </div>
              )}
            </div>
          </div>

          <div className="card-footer py-3">
            <button
              className="btn btn-yellow px-4 float-end"
              onClick={handleSubmit(onSubmit)}
              disabled={lockBtn}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_product_images
              ? constLabel.lbl_product_images
              : "Product Images"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row image_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
            {lotList.data?.length > 0 &&
            lotList.data !== null &&
            lotList.data !== undefined
              ? (
                  lotList.data[0]?.product_id?.product_img ||
                  lotList.data[0]?.work_order_files
                )?.map((val, i) => (
                  <div className="col" key={i}>
                    <div className="image-container">
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + val.attachment}
                        className="img-fluid"
                        alt="Image 1"
                      />

                      <div className="image-overlay">
                        <FontAwesomeIcon
                          onClick={() =>
                            window.open(
                              process.env.REACT_APP_IMAGE_URL + val.attachment,
                              "_blank"
                            )
                          }
                          className="icon"
                          style={{ color: "#fff" }}
                          icon={faEye}
                          size="xl"
                        />
                      </div>
                    </div>
                  </div>
                ))
              : null}

            {lotList.data?.length > 0 &&
            lotList.data !== null &&
            lotList.data !== undefined ? (
              lotList.data[0]?.product_id?.product_img?.length == 0 ||
              lotList.data[0]?.work_order_files?.length == 0 ? (
                <div className="col">
                  <div className="image-container">
                    <img src={camshaft} className="img-fluid" alt="Image 1" />

                    <div className="image-overlay">
                      <FontAwesomeIcon
                        onClick={() => window.open(camshaft, "_blank")}
                        className="icon"
                        style={{ color: "#fff" }}
                        icon={faEye}
                        size="xl"
                      />
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={() => setVisible(false)}
          >
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default CreateNc;
