import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getSalesOrder } from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheck,
  faCircle,
  faClose,
  faEye,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Modal } from "react-bootstrap";
import { Accordion, Spinner } from "react-bootstrap";
import { convertDate } from "../../common/Functions/CommonFunctions";
import { APPAREL, CUSTOMER, FASHION } from "../../common/Constants/constants";

function ViewSODetails() {
    let location = useLocation();
    let navigate = useNavigate();
    const SOdata = location.state;
    const imageFileExtensions = ["png", "jpg", "jpeg"];
    const [attribModal, setAttribModal] = useState(false);
    const [imgModal, setImgModal] = useState(false);

    const [attribList, setAttribList] = useState([]);
    const [imgList, setImgList] = useState([]);

    const [showName, setShowName] = useState("");

    const localData = JSON.parse(localStorage.getItem("localData"));

    function displayAttributes(list){
      if(list?.length > 0){
        setAttribList(list);
        setAttribModal(true);
      } else {
        SweetAlert.fire({
          title: "Measurements not found !",
          icon: "warning",
          showCancelButton:true, 
          confirmButtonText: "Ok",
          cancelButtonText:"Cancel"
        }).then((result) => {
          
        });
      } 
    }

    function displayImage(list){
      if(list?.length > 0){
        setImgList(list);
        setImgModal(true);
      } else {
        SweetAlert.fire({
          title: "Images not found !",
          icon: "warning",
          showCancelButton:true, 
          confirmButtonText: "Ok",
          cancelButtonText:"Cancel"
        }).then((result) => {
          
        });
      } 
    }

  

  return (
     <React.Fragment>
      <div className="PO_Details">
        <div className="card">
          <div className="card-header bg-grey d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <button
                className="btn btn-yellow"
                onClick={() => {
                  navigate("/pcms/sales-order");
              }}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="sm" />
              </button>

              <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                {"Sales Order Details"}
              </p>
            </div>
          </div>

          <div className="card-body">
            <div className="container-fluid">
              <div className="bg-lightgrey" style={{ padding: "12px", borderRadius: "12px" }}>
                <div className="row my-2">
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"SO Id"}
                    </p>
                    <p className="mb-2 roboto-bold">{SOdata.so_id}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Order Date"}
                    </p>
                    <p className="mb-2 roboto-bold">{convertDate(SOdata.order_date,'-')}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Order Due Date"}
                    </p>
                    <p className="mb-2 roboto-bold">{convertDate(SOdata.order_due_date,'-')}</p>
                  </div>
                 
                 
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Organization"}
                    </p>
                    <p className="mb-2 roboto-bold">{SOdata.org_id?.comName}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Invoice To"}
                    </p>
                    <p className="mb-2 roboto-bold">{SOdata.invoice_to?.organization}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Dispatch To"}
                    </p>
                    <p className="mb-2 roboto-bold">{SOdata.dispatch_to?.organization}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Customer"}
                    </p>
                    <p className="mb-2 roboto-bold">{SOdata.customer_name}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Party PO Ref."}
                    </p>
                    <p className="mb-2 roboto-bold">{SOdata.party_po_ref == null
                        ? "NA"
                        : SOdata.party_po_ref}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Status"}
                    </p>
                    <p className="mb-2 roboto-bold">{SOdata.status?.status_name}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Remark"}
                    </p>
                    <p className="mb-2 roboto-bold">{SOdata.internal_remark}</p>
                  </div>
                </div>
              </div>

              <hr className="my-2" />
              <h4 className="roboto-bold mb-0">
                {"Product Details"}
              </h4>
              <hr className="my-2" />

              <div className="my-3">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  <table className="table table-bordered">
                    <thead className="table-grey roboto-bold">
                      <tr>
                        <th scope="col">{"Product Type"}</th>
                        <th scope="col">{"Product"}</th>
                        <th scope="col">{"Quantity"}</th>
                        <th scope="col">{"Unit"}</th>
                        <th scope="col">
                          {"Rate "} <span>&#8377;</span>
                        </th>
                        <th scope="col">{"GST "}(%)</th>
                        <th scope="col">{"Discount "}(%)</th>
                        <th scope="col">
                          {"Amount "} <span>&#8377;</span>
                        </th>
                      {[FASHION, APPAREL].includes(localData?.industry_type) ? (<>
                        <th>Party</th>
                        <th>Measurment</th>
                        <th>Attachment</th> </>)
                      : null}
                      </tr>
                    </thead>
                    <tbody>
                      {SOdata.so_order_lines?.map((val, ind) => (
                        <tr key={ind}>
                          <td style={{ verticalAlign: "top" }}>{val.product_type}</td>

                          <td style={{ verticalAlign: "top" }}>
                            {val.productname}
                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            {val.quantity}
                          </td>

                          <td style={{ verticalAlign: "top" }}>{val.unit}</td>

                          <td style={{ verticalAlign: "top" }}>{val.rate}</td>
                          <td style={{ verticalAlign: "top" }}>{val.gst_rate}</td>
                          <td style={{ verticalAlign: "top" }}>{val.discount}</td>

                          <td style={{ verticalAlign: "top" }}>
                            {parseFloat(val.amount).toFixed(2)}
                          </td>
                          {[FASHION, APPAREL].includes(localData?.industry_type) ? (<>
                            <td style={{ verticalAlign: "top" }}>{val.party !== null && val.party !== undefined ? (val.party?.partyType == CUSTOMER ? val.party?.organization : val.party?.name) : "N/A" }</td>
                            <td>
                              <button
                                  className="btn btn-sm btn-darkblue px-3"
                                  onClick={() => { 
                                    displayAttributes(val.measurement?.attributes);
                                    setShowName(val.party !== null && val.party !== undefined ? (val.party?.partyType == CUSTOMER ? val.party?.organization : val.party?.name) : "N/A"); 

                                  }}
                                >
                                {"Measurement"}
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-warning px-3"
                                onClick={() => displayImage(val.attachments)}
                              >
                                {"Images"}
                              </button>
                            </td> 
                            </>)
                          : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className="p-3 ms-auto col-12 col-md-3"
                style={{
                  border: "1px solid #c6c6c6",
                  borderRadius: "2px",
                  fontSize: "14px",
                }}
              >
                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-6 roboto-bold">
                      {"Sub Total"} <span>&#8377;</span>
                    </label>

                    <div className="col-12 col-md-6 ">
                      {parseFloat(SOdata.sub_total).toFixed(2)}
                    </div>
                  </div>
                </div>

                <hr />
                {SOdata && SOdata.cgst_cost !== 0 ? (
                  <>
                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label className="col-12 col-md-6 roboto-bold">
                          {"CGST "} &#8377;
                        </label>

                        {/* <div className="col-12 col-md-4">
                          {SOdata.cgst}
                        </div> */}
                        <div className="col-12 col-md-6">
                          {parseFloat(SOdata.cgst_cost).toFixed(
                            2
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label className="col-12 col-md-6 roboto-bold">
                          {"SGST "} &#8377;
                        </label>

                        {/* <div className="col-12 col-md-4">
                          {SOdata.sgst}
                        </div> */}
                        <div className="col-12 col-md-6">
                          {parseFloat(SOdata.sgst_cost).toFixed(
                            2
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {SOdata && SOdata.igst_cost !== 0 ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label className="col-12 col-md-6 roboto-bold">
                      {"IGST "} &#8377;
                      </label>

                      {/* <div className="col-12 col-md-4">
                        {SOdata.igst}
                      </div> */}
                      <div className="col-12 col-md-6">
                        <span>&#8377;</span>
                        {parseFloat(SOdata.igst_cost).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : null}

                {SOdata && SOdata.ugst_cost !== 0 ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label className="col-12 col-md-6 roboto-bold">
                        {"UGST "} &#8377;
                      </label>

                      {/* <div className="col-12 col-md-4">
                        {SOdata.ugst}
                      </div> */}
                      <div className="col-12 col-md-6">
                        {parseFloat(SOdata.ugst_cost).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-6 roboto-bold">
                      {"Total Amount "} &#8377;
                    </label>

                    <div className="col-12 col-md-6">
                      {parseFloat(SOdata.final_total).toFixed(2)}
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={attribModal}
        onHide={() => setAttribModal(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Measurment Details" + " - " + showName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {attribList.length > 0 ? 
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="table-grey roboto-bold">
                  <tr className="">
                    <th scope="col">{"Name"}</th>
                    <th scope="col">{"Value"}</th>
                  </tr>
                </thead>
                <tbody className="roboto">
                  {attribList?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          : 
            <div className="alert alert-danger mb-0 text-center my-4" role="alert" >{"No data found!"}</div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setAttribModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={imgModal}
        onHide={() => setImgModal(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Image Gallary" + " - " + showName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row image_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
            {imgList?.length == 0 ? (
              <p className="text-danger mb-0 f-22 mx-auto mt-4">
                {"No image found !"}
              </p>
            ) : (
              imgList.map((val, i) => (
                <div className="col" key={i}>
                  {imageFileExtensions.includes(
                    val.attachment.split(".").pop().toLowerCase()
                  ) ? (
                    <div className="image-container">
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + val.attachment}
                        className="img-fluid"
                        alt="Image 1"
                      />

                      <div className="image-overlay">
                        <FontAwesomeIcon
                          onClick={() =>
                            window.open(
                              process.env.REACT_APP_IMAGE_URL + val.attachment,
                              "_blank"
                            )
                          }
                          className="icon"
                          style={{ color: "#fff" }}
                          icon={faEye}
                          size="xl"
                        />
                      </div>
                      <p  className="mt-2 text-center">{val.name}</p>
                    </div>
                  ) : (
                    <div className="image-container"
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_IMAGE_URL + val.attachment,
                          "_blank"
                        )
                      }
                    >
                      
                        <FontAwesomeIcon
                          className="icon-file-pdf"
                           style={{  height:"120px", marginLeft: "40px" }}
                          icon={faFileAlt}
                          size="3x"
                         
                        />

                        <div className="image-overlay">
                          <FontAwesomeIcon
                            onClick={() =>
                              window.open(
                                process.env.REACT_APP_IMAGE_URL + val.attachment,
                                "_blank"
                              )
                            }
                            className="icon"
                            style={{ color: "#fff" }}
                            icon={faEye}
                            size="xl"
                          />

                          <FontAwesomeIcon
                            // onClick={() => deletefile(val)}
                            className="icon-close"
                            style={{ color: "#fff" }}
                            icon={faClose}
                            size="lg"
                          />
                        </div>

                      <p className="mt-2 text-center">{val.name}</p>
                    </div>

                  )}
              
                </div>
              ))
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setImgModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default ViewSODetails