import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { getProductType } from "../../../api";
import AddManufacturedGoods from "./AddManufacturedGoods";
import AddRawMaterial from "./AddRawMaterial";
import AddSemiFinishedGoods from "./AddSemiFinishedGoods";
import { getLabels } from "../../../common/Functions/CommonFunctions";
import { MANUFACTURED_GOODS, FINISHED_GOODS, SEMI_FINISH_GOODS, RAW_MATERIAL } from "../../../common/Constants/constants";

function AddProduct(props) {
  const [visible, setVisible] = useState(false);
  const [prodType, setProdType] = useState({ data: [], loading: false });

  const [constLabel, setConstLabel] = useState({});

  const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    // resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels('Master', 'Product'));
    fetchProductType();
  }, []);

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        if(res && res.data.data?.length > 0){

          const productTypes = [MANUFACTURED_GOODS, RAW_MATERIAL, FINISHED_GOODS, SEMI_FINISH_GOODS];
          let prod_type_arr = res.data.data.filter(
            (item) => productTypes.includes(item.productType)
          );
          setProdType({ ...prodType, data: prod_type_arr, loading: false });

          const result = prod_type_arr.filter((item) => item.productType === MANUFACTURED_GOODS);
          setValue("type", result);

        } else {
          setProdType({ ...prodType, data: [], loading: false });
        }
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  const selProductType = watch("type");

  return (
    <React.Fragment>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{constLabel?.lbl_add_product ? constLabel.lbl_add_product : "Add Product"}</Tooltip>}
      >
        <div className="d-flex">
          <a
            className="px-2 mt-2 cursor_pointer"
            onClick={() => {
              // setUpdateForm(val)
              setVisible(true);
            }}
          >
            {constLabel?.lbl_add_product ? constLabel.lbl_add_product : "Add Product"}
          </a>
        </div>
      </OverlayTrigger>
      <Modal show={visible} onHide={() => {setVisible(false); reset();}} size={"xl"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{constLabel?.lbl_add_product ? constLabel.lbl_add_product : "Add Product"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pc">
            <div className="card border-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group typeahead-container">
                      <label className="lbl-style roboto-bold">
                        {constLabel?.lbl_product_type ? constLabel.lbl_product_type : "Product Type"}
                        <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="type"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="productType"
                            className="mt-2"
                            multiple={false}
                            options={prodType.data}
                            isLoading={prodType.loading}
                            placeholder="Select product type"
                            onChange={onChange}
                            selected={value}
                            onBlur={onBlur}
                            isInvalid={errors.type ? true : false}
                          />
                        )}
                      />
                      {!prodType.loading && !errors.type && <span className="arrow-icon"></span>}
                      {errors.type && (
                        <span className="text-danger err-msg">
                          {errors.type.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {selProductType !== undefined && selProductType.length > 0 ? (
              <>
                {(selProductType[0]?.productType === MANUFACTURED_GOODS || selProductType[0]?.productType === FINISHED_GOODS ) && (
                  <AddManufacturedGoods
                    product_type={selProductType[0]}
                    setVisible={setVisible}
                    fetchProdList={props.fetchProdList}
                    constLabel={constLabel}
                  />
                )}
                {selProductType[0]?.productType === RAW_MATERIAL && (
                  <AddRawMaterial
                    product_type={selProductType[0]}
                    setVisible={setVisible}
                    fetchProdList={props.fetchProdList}
                    constLabel={constLabel}
                  />
                )}
                {selProductType[0]?.productType === SEMI_FINISH_GOODS && (
                  <AddSemiFinishedGoods
                    product_type={selProductType[0]}
                    setVisible={setVisible}
                    fetchProdList={props.fetchProdList}
                    constLabel={constLabel}
                  />
                )}
              </>
            ) : (
              <div
                className="alert alert-danger mb-0 text-center my-4"
                role="alert"
              >
                {"Please select product type !"}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default AddProduct;
