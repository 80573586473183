import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  convertBase64,
  convertDateYYMMDD,
  getLabels,
} from "../../../../common/Functions/CommonFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faEye,
  faFileExcel,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import {
  getProdList,
  create_dispatch,
  getCustomer,
  get_transporter,
  getStatusMaster,
  get_dispatch_pkg_store_qty,
  get_dispatch_prev_rec,
  get_WorkOrder,
  get_dispatch_line_items,
  update_dispatch,
  getLocation,
  getNCList,
} from "../../../../api";
import DatePicker from "react-datepicker";
import { Trash2 } from "react-feather";
import { workOrder } from "../../../../constant";
import { Tooltip } from "react-tooltip";
import { Spinner } from "react-bootstrap";

const imageFileTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
  // "application/vnd.ms-excel",
  // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // "application/msword", 
  // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const schema = yup
  .object()
  .shape({
    startDate: yup
      .date()
      .typeError("Please select date!")
      .required("Please select date!"),
    party: yup
      .array()
      .min(1, "Please select party!")
      .required("Please select party!"),
    transporter: yup
      .array()
      .min(1, "Please select transporter!")
      .required("Please select transporter!"),
    items: yup.array().of(
      yup.object().shape({
        workOrder: yup
          .array()
          .min(1, "Please select work order !")
          .required("Please select work order !"),
        qty: yup
          .string()
          .matches(/^\d+$/, "Enter valid qty")
          .required("Please enter qty"),
      })
    ),
    image: yup
      .mixed()
      .test("fileSize", "File can be uploaded up to 10 MB", (value) => {
        if (value.length == 0) return true;
        return value && value[0]?.size <= 10 * 1024 * 1024;
      })
      .test(
        "fileType",
        "Only image and document files are allowed",
        (value) => {
          if (value.length == 0) return true; // If no file is selected, it's considered valid.
          // console.log("value.[0].type", value[0].type )
          return imageFileTypes.includes(value[0]?.type);
        }
      ),
    fileName: yup.string().when("image", {
      is: (image) => image && image.length > 0,
      then: yup
        .string()
        .required("File name is required!"),
        // .matches(
        //   /\.(jpeg|jpg|png|pdf)$/,
        //   "File name must end with .jpeg, .jpg, .png, .pdf"
        // ),
      otherwise: yup.string().notRequired(),
    }),
  })
  .required();

function Update_Dispatch() {
  let navigate = useNavigate();
  let location = useLocation();
  const [start_date, setStart_Date] = useState();

  // console.log("location.state.dispatch_line_items?.[0]?.date",location.state.dispatch_line_items?.[0]?.date)
  const [constLabel, setConstLabel] = useState({});
  const [workOrders, setWorkOrders] = useState({ data: [], loading: false });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [prodList, setProdList] = useState({ data: [], loading: false });
  const [lock_scrap, setLock_Scrap] = useState(false);
  const [img, setImg] = useState(null);
  const [lockBtn, setLockBtn] = useState(false);

  const [dispatchStatus, setDispatchStatus] = useState({
    data: [],
    loading: false,
  });
  const [transpList, setTranspList] = useState({ data: [], loading: false });
  const [itemsList, setItemsList] = useState({ data: [], loading: false });
  const [locList, setLocList] = useState({ data: [], loading: false });

  const {
    handleSubmit,
    register,
    control,
    formState,
    watch,
    reset,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [
        {
          workOrder: [],
          product: [],
          qty: "",
          ht_qty: "0",
          dispatch_qty: "",
          scrapqty: "",
          amount: 0,
          remark: "",
        },
      ],
    },
  });

  let { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const selWorkOrder = watch("work_order");
  const selLocation = watch("location");
  let file = watch("image");

  useEffect(() => {
    setConstLabel(getLabels("Dispatch", "Dispatch"));
    if (location.state !== undefined && location.state !== null) {
      fetchWorkOrder(null);
      fetchParty();
      fetchTransporter();
      fetchStatus();
      fetchLocation();
      setValue("startDate", new Date(location.state.dispatch_line_items?.[0]?.date));
      setStart_Date(new Date(location.state.dispatch_line_items?.[0]?.date))
      setValue("party", [location.state.party_details]);
      setValue("amount", location.state.amount);
      fetchLineItems(location.state.uuid);
      setImg(location.state?.invoice_attachment);
    }
  }, [location.state]);

  useEffect(() => {
    var lineItems = [];
    if (itemsList.data && itemsList.data.length > 0) {
      for (let i = 0; i < itemsList.data?.length; i++) {
        lineItems.push({
          uuid: itemsList?.data[i]?.uuid,
          workOrder: [itemsList?.data[i]?.work_order_details],
          product: [
            {
              productId: itemsList?.data[i]?.product_id, ///
              uuid: itemsList?.data[i]?.work_order_details?.product_id,
              productName: itemsList?.data[i]?.work_order_details?.product_name,
            },
          ],
          wo_qty: itemsList?.data[i]?.work_order_details?.quantity,
          partNo: itemsList?.data[i]?.work_order_details?.part_no,
          ht_qty:
            itemsList?.data[i]?.ht_qty !== null
              ? itemsList?.data[i]?.ht_qty
              : "0",
          available_qty: itemsList?.data[i]?.availqty,
          dispatch_qty: itemsList?.data[i]?.dispatch_qty,
          qty: itemsList.data?.[i]?.qty,
          scrapqty: itemsList?.data?.[i]?.scrapqty,
          amount: itemsList?.data?.[i]?.amount,
          remark: itemsList.data?.[i]?.remark,
        });
        // fetchStoreQty(itemsList?.data[i]?.work_order_id, i);
      }
      setValue("items", lineItems);
      // reset({ items: lineItems });
    }
  }, [itemsList.data]);

  useEffect(() => {
    if (file?.length > 0) {
      setValue("fileName", file[0].name);
    } else {
      setValue("fileName", "");
    }
  }, [file]);

  function fetchLocation() {
    setLocList({ ...locList, data: [], loading: true });
    getLocation().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.uuid == location.state?.location_id
        );
        if (result) {
          setValue("location", result, { shouldValidate: true });
        }
        setLocList({ ...locList, data: res.data.data, loading: false });
      },
      (err) => {
        setLocList({ ...locList, data: [], loading: false });
      }
    );
  }

  function fetchLineItems(disp_uuid) {
    setItemsList({ ...itemsList, data: [], loading: true });
    get_dispatch_line_items({ uuid: disp_uuid }).then(
      (res) => {
        // console.log("res",res);
        setItemsList({ ...itemsList, data: res.data, loading: false });
      },
      (err) => {
        setItemsList({ ...itemsList, data: [], loading: false });
      }
    );
  }

  async function fetchWorkOrder(wo_uuid) {
    setWorkOrders({ ...workOrders, data: [], loading: true });
    await get_WorkOrder({ work_order_status: ["Released", "Completed"] }).then(
      (res) => {
        if (wo_uuid) {
          let result = res.data.data.filter((item) => item.uuid === wo_uuid);
          return result;
        }

        setWorkOrders({ ...workOrders, data: res.data.data, loading: false });
      },
      (err) => {
        setWorkOrders({ ...workOrders, data: [], loading: false });
      }
    );
  }

  function fetchTransporter() {
    setTranspList({ ...transpList, data: [], loading: true });
    get_transporter().then(
      (res) => {
        let result = res.data.data.filter(
          (item) => item.uuid === location.state.transporter_id
        );
        setValue("transporter", result);
        setTranspList({ ...transpList, data: res.data.data, loading: false });
      },
      (err) => {
        setTranspList({ ...transpList, data: [], loading: false });
      }
    );
  }

  function fetchStatus() {
    setDispatchStatus({ ...dispatchStatus, data: [], loading: true });
    getStatusMaster().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.model_name == "dispatch"
        );

        const dis_st = result.filter(
          (item) => item.status_name === location.state.status
        );
        setValue("status", dis_st);
        setDispatchStatus({ ...dispatchStatus, data: result, loading: false });
      },
      (err) => {
        setDispatchStatus({ ...dispatchStatus, data: [], loading: false });
      }
    );
  }

  function fetchParty() {
    setPartyList({ ...partyList, data: [], loading: true });
    getCustomer({ partyType: "Customer" }).then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.organization !== null
        );
        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {
        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  const onSubmit = async (data) => {
    // console.log("data", data);
    setLockBtn(true);
    let reqPayload = [];

    for (let i = 0; i < data.items.length; i++) {
      const lineItems = {
        date: convertDateYYMMDD(data.startDate, "-"),
        ht_qty:
          data?.items?.[i]?.ht_qty !== ""
            ? parseInt(data?.items?.[i]?.ht_qty)
            : 0,
        // dispatch_qty: parseInt(data?.items?.[i]?.dispatch_qty),
        qty: parseInt(data?.items?.[i]?.qty),
        work_order_id: data?.items?.[i]?.workOrder?.[0]?.uuid,
        productId: data?.items?.[i]?.product?.[0]?.productId,
        invoicedate: convertDateYYMMDD(data.startDate, "-"),
        scrapqty: 
          data?.items?.[i]?.scrapqty !== ""
            ? parseInt(data?.items?.[i]?.scrapqty)
            : null,
        amount: Number(data?.items?.[i]?.amount) ?? 0,
        remark: data.items?.[i]?.remark !== "" ? data.items?.[i]?.remark : null,
        uuid: data.items?.[i]?.uuid,
      };
      reqPayload.push(lineItems);
    }

    // console.log("reqPayload",reqPayload)
    if (data !== "") {
      const payload = {
        uuid: location.state.uuid,
        party_id: data.party?.[0]?.uuid,
        status: data.status?.[0]?.status_name,
        transporter: data.transporter?.[0]?.name,
        transporter_id: data.transporter?.[0]?.uuid,
        dispatch_line_items: reqPayload,
        location_id: data.location?.[0]?.uuid,
        amount: Number(data?.amount) ?? 0,
      };

      if (data.image.length > 0) {
        const getExt = data.image[0].name?.split(".").pop();
        const dataimage = await convertBase64(data.image[0]);
        const newImage = dataimage.replace(
          /data:image\/[a-zA-Z]+;/,
          "data:image/" + getExt + ";"
        );
        payload.invoice_attachment = newImage;
        payload.attachment_name = data.fileName;
      }

      // console.log("update payload", payload);
      // return;
      update_dispatch(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Dispatch updated successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/dispatch");
            }, 2200);
            setLockBtn(false);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
          setLockBtn(false);
        }
      );
    } else {
      errors.showMessages();
    }
  };

  function fetchProd(prod_uuid, ind) {
    // setProdList({ ...prodList, data: [], loading: true });

    getProdList({ uuid: prod_uuid }).then(
      (res) => {
        // console.log("product data", res.data.data);
        if (res) {
          setValue(`items[${ind}].product`, res.data.data, {
            shouldValidate: true,
          });
          setValue(`items[${ind}].partNo`, res.data.data?.[0]?.partNo, {
            shouldValidate: true,
          });
        } else {
          setValue(`items[${ind}].product`, [], { shouldValidate: true });
          setValue(`items[${ind}].partNo`, "", { shouldValidate: true });
        }
      },
      (err) => {
        // setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  function fetchPrevQty(wo_uuid, ind) {
    // setProdList({ ...prodList, data: [], loading: true });
    get_dispatch_prev_rec({ work_order_id: wo_uuid }).then(
      (res) => {
        // console.log("prev rec", res);
        if (res) {
          setValue(`items[${ind}].dispatch_qty`, res.data.data, {
            shouldValidate: true,
          });
        } else {
          setValue(`items[${ind}].dispatch_qty`, 0, { shouldValidate: true });
        }
      },
      (err) => {
        // setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  function fetchStoreQty(wo_uuid, ind) {
    // setProdList({ ...prodList, data: [], loading: true });
    get_dispatch_pkg_store_qty({
      work_order_uuid: wo_uuid,
      location_uuid: selLocation?.[0]?.uuid,
    }).then(
      (res) => {
        if (res) {
          setValue(
            `items[${ind}].available_qty`,
            res.data.data.storeDetails.availqty,
            { shouldValidate: true }
          );
        } else {
          setValue(`items[${ind}].available_qty`, 0, { shouldValidate: true });
        }
      },
      (err) => {
        // setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  function fetchScrpQty(wo_uuid, ind) {
    // setProdList({ ...prodList, data: [], loading: true });
    getNCList({ work_order_id: wo_uuid }).then(
      (res) => {
        if(res && res.data?.data?.length > 0){
          if (res.data.data?.[0]?.ncstatus == "Scrap") {
            setValue(`items[${ind}].scrapqty`, res.data.data?.[0]?.qty, {
              shouldValidate: true,
            });
            setLock_Scrap(true);
          } else {
            setValue(`items[${ind}].scrapqty`, 0, { shouldValidate: true });
            setLock_Scrap(false);
          }
        } else {
          setValue(`items[${ind}].scrapqty`, 0, { shouldValidate: true });
            setLock_Scrap(false);
        }

      },
      (err) => {
        // setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  function checkQtyValidation(value, ind) {
    let preQty = getValues(`items[${ind}].dispatch_qty`);
    let avlQty = getValues(`items[${ind}].available_qty`);
    let woQty = getValues(`items[${ind}].wo_qty`);

    preQty = parseFloat(preQty) || 0;
    avlQty = parseFloat(avlQty) || 0;
    value = parseFloat(value) || 0;
    woQty = parseFloat(woQty) || 0;

    if(avlQty > 0){
      if(value > avlQty) {
        toast.error(
          "Qty is less than or equal to Available Qty for row " + (ind + 1),
          {
            autoClose: 2000,
          }
        );
        setValue(`items[${ind}].qty`, 0);
      }
    }

    if (woQty > 0) {
      let result = value + preQty;
      if (result > woQty) {
        toast.error(
          "Qty is less than or equal to Work Order Qty for row " + (ind + 1),
          {
            autoClose: 2000,
          }
        );
        setValue(`items[${ind}].qty`, 0);
      }
    }
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  async function calAmount(val, index) {
    
    // console.log("val", val);

    if(val?.so_details !== null){
      // console.log("if");
      setValue(`items[${index}].amount`, Number(val.so_details?.so_order_lines?.[0]?.amount).toFixed(2) ?? 0)
    } else {
      // console.log("else");
      setValue(`items[${index}].amount`, Number(val.product_info?.salesPrice).toFixed(2) ?? 0)
    }

    await cal_final_total();

  }

  async function cal_final_total() {
    const line_items = getValues('items');

    let total_amount = 0;

    if (line_items.length > 0) {
      line_items.forEach((val) => {
        total_amount += Number(val.amount);
      })
    }

    setValue("amount", Number(total_amount).toFixed(2) ?? 0)    
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="purchase_order mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                navigate("/pcms/dispatch");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_update_dispatch
                ? constLabel.lbl_update_dispatch
                : "Update Dispatch"}
            </p>
          </div>
          <div className="card-body">
            <div className="row pb-3">
              <div className="col-12 col-md-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_date ? constLabel.lbl_date : "Date"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.startDate
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        {...field}
                        selected={field.value}
                        placeholderText="Select date"
                        // selected={start_date}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        // minDate={
                        //   start_date?.getTime() - 30 * 24 * 60 * 60 * 1000
                        // }
                        dateFormat="dd/MM/yyyy"
                        autoComplete="false"
                      />
                    )}
                  />

                  {errors.startDate && (
                    <span className="text-danger err-msg">
                      {errors.startDate.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_party ? constLabel.lbl_party : "Party"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name="party"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className="mt-2"
                        id="basic-typeahead-single"
                        labelKey="organization"
                        multiple={false}
                        // size="sm"
                        options={
                          partyList.data !== undefined ? partyList.data : []
                        }
                        isLoading={partyList.loading}
                        placeholder="Select party"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.party ? true : false}
                      />
                    )}
                  />
                  {!partyList.loading && !errors.party && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.party?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_transporter
                      ? constLabel.lbl_transporter
                      : "Transporter"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name="transporter"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className="mt-2"
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        // size="sm"
                        options={
                          transpList.data !== undefined ? transpList.data : []
                        }
                        isLoading={transpList.loading}
                        placeholder="Select transporter"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.transporter ? true : false}
                      />
                    )}
                  />
                  {!transpList.loading && !errors.transporter && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.transporter?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                  </label>

                  <Controller
                    name="status"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className="mt-2"
                        id="basic-typeahead-single"
                        labelKey="status_name"
                        multiple={false}
                        // size="sm"
                        options={
                          dispatchStatus.data !== undefined
                            ? dispatchStatus.data
                            : []
                        }
                        isLoading={dispatchStatus.loading}
                        placeholder="Select status"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        // isInvalid={errors.status ? true : false}
                      />
                    )}
                  />
                  {!dispatchStatus.loading && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                    {errors.status?.message}
                  </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_location
                      ? constLabel.lbl_location
                      : "Location"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name="location"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className="mt-2"
                        id="basic-typeahead-single"
                        labelKey="locationname"
                        multiple={false}
                        options={locList.data !== undefined ? locList.data : []}
                        isLoading={locList.loading}
                        placeholder="Select location"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.location ? true : false}
                      />
                    )}
                  />
                  {!locList.loading && !errors.location && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.location?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_upload_invoice ? constLabel.lbl_upload_invoice : "Upload Invoice"}
                </label>
                <input
                  className={
                    errors.image ? "form-control mt-2 is-invalid" : "form-control mt-2"
                  }
                  type="file"
                  {...register("image")}
                  accept="image/*, application/*"
                />
                {errors.image && (
                  <span className="text-danger err-msg">
                    {errors.image.message}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-3 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_file_name
                    ? constLabel.lbl_file_name
                    : "Filename"}
                </label>
                <input
                  className={
                    errors.fileName ? "form-control mt-2 is-invalid" : "form-control mt-2"
                  }
                  type="text"
                  {...register("fileName")}
                  placeholder="Enter filename"
                />
                {errors.fileName && (
                  <span className="text-danger err-msg">
                    {errors.fileName.message}
                  </span>
                )}
              </div>
              {img !== null ? (
                <>
                  <Tooltip id={"view-tooltip"} place="top" />
                  <div className="col-12 col-md-1 mt-5 text-center" data-tooltip-id={"view-tooltip"}
                                        data-tooltip-content={
                                          constLabel?.lbl_view_attachment
                                            ? constLabel.lbl_view_attachment
                                            : "View Attachment"
                                        }>
                    <FontAwesomeIcon
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_IMAGE_URL + img,
                          "_blank"
                        )
                      }
                      className="mt-2 cursor_pointer"
                      icon={faImage}
                      size="xl"
                    />
                  </div>
                </>
              ) : null}

              <div className="col-12 col-md-3 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_amount
                    ? constLabel.lbl_amount
                    : "Amount"}
                </label>
                <input
                  className={
                    errors.amount ? "form-control mt-2 is-invalid" : "form-control mt-2"
                  }
                  type="text"
                  {...register("amount")}
                  placeholder="Enter total amount"
                />
                {errors.amount && (
                  <span className="text-danger err-msg">
                    {errors.amount.message}
                  </span>
                )}
              </div>
            </div>

            <hr className="my-2" />
            <div className="row">
              <div className="col-12 col-md-12">
                <table className="table table-responsive table-bordered table-sm">
                  <thead className="table-grey roboto-bold">
                    <tr>
                      <th>
                        {constLabel?.lbl_work_order_no
                          ? constLabel?.lbl_work_order_no
                          : "Work Order No"}
                        <span className="text-danger"> *</span>
                      </th>
                      <th>
                        {constLabel?.lbl_product
                          ? constLabel?.lbl_product
                          : "Product"}
                      </th>
                      <th className="">{"WO Qty"}</th>
                      <th>
                        {constLabel?.lbl_part_no
                          ? constLabel?.lbl_part_no
                          : "Part No"}
                      </th>
                      <th>
                        {constLabel?.lbl_ht_inward_qty
                          ? constLabel?.lbl_ht_inward_qty
                          : "HT Inward Qty"}
                      </th>

                      <th>
                        {constLabel?.lbl_available_qty
                          ? constLabel?.lbl_available_qty
                          : "Available Qty"}
                      </th>
                      <th>
                        {constLabel?.lbl_total_dispatch_qty
                          ? constLabel?.lbl_total_dispatch_qty
                          : "Total Dispatched Qty"}
                      </th>
                      <th>
                        {constLabel?.lbl_quantity
                          ? constLabel?.lbl_quantity
                          : "Quantity"}
                      </th>
                      <th>
                        {constLabel?.lbl_scrap_qty
                          ? constLabel?.lbl_scrap_qty
                          : "Scrap Qty"}
                      </th>
                      <th>
                        {constLabel?.lbl_amount
                          ? constLabel?.lbl_amount
                          : "Amount"}
                      </th>
                      <th>
                        {constLabel?.lbl_remark
                          ? constLabel?.lbl_remark
                          : "Remark"}
                      </th>
                      <th>{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id, uuid }, index) => (
                      <tr key={id}>
                        <td style={{ verticalAlign: "top", width: "15%" }}>
                          <Controller
                            name={`items[${index}].workOrder`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                size="sm"
                                id="basic-typeahead"
                                labelKey="work_order_no"
                                multiple={false}
                                options={
                                  workOrders.data !== undefined
                                    ? workOrders.data
                                    : []
                                }
                                isLoading={workOrders.loading}
                                placeholder="Select work order"
                                onChange={(selected) => {
                                  onChange(selected);
                                  if (selected.length > 0) {
                                    fetchProd(selected?.[0]?.product_id, index);
                                    fetchPrevQty(selected?.[0]?.uuid, index);
                                    fetchStoreQty(selected?.[0]?.uuid, index);
                                    if(uuid === undefined){
                                      fetchScrpQty(selected?.[0]?.uuid, index);
                                    }
                                    setValue(
                                      `items[${index}].wo_qty`,
                                      selected?.[0]?.quantity,
                                      { shouldValidate: true }
                                    );
                                    calAmount(selected[0], index);
                                  } else {
                                  }
                                }}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.workOrder
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />
                          {errors.items?.[index]?.workOrder && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.workOrder.message}
                            </span>
                          )}

                          {index + 1 == fields.length && (
                            <button
                              style={{ fontSize: "16px" }}
                              className="btn btn-sm button-primary px-2 mt-2"
                              onClick={() => {
                                append({
                                  workOrder: [],
                                  product: [],
                                  qty: "",
                                  ht_qty: "0",
                                  dispatch_qty: "",
                                  scrapqty: "",
                                  amount: 0,
                                  remark: "",
                                });
                              }}
                            >
                              <FontAwesomeIcon
                                style={{
                                  color: "#ffffff",
                                }}
                                icon={faAdd}
                                size="sm"
                              />
                              {"Add"}
                            </button>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top", width: "18%" }}>
                          <Controller
                            name={`items[${index}].product`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                size="sm"
                                id="basic-typeahead"
                                labelKey="productName"
                                multiple={false}
                                options={[]}
                                // options={
                                //   workOrders.data !== undefined
                                //     ? workOrders.data
                                //     : []
                                // }
                                // isLoading={workOrders.loading}
                                placeholder="Select product"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                disabled
                                isInvalid={
                                  errors.items?.[index]?.product ? true : false
                                }
                              />
                            )}
                          />
                          {errors.items?.[index]?.product && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.product.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={"form-control form-control-sm"}
                            type="text"
                            name={`items[${index}].wo_qty`}
                            {...register(`items[${index}].wo_qty`)}
                            placeholder="Enter work order qty"
                            disabled
                          />
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.partNo
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].partNo`}
                            {...register(`items[${index}].partNo`)}
                            placeholder="Enter part no"
                            disabled
                          />

                          {errors.items?.[index]?.partNo && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.partNo.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.ht_qty
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].ht_qty`}
                            {...register(`items[${index}].ht_qty`)}
                            placeholder="Enter ht qty"
                          />

                          {errors.items?.[index]?.ht_qty && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.ht_qty.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.available_qty
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].available_qty`}
                            {...register(`items[${index}].available_qty`)}
                            placeholder="Enter dispatch qty"
                            disabled
                          />

                          {errors.items?.[index]?.available_qty && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.available_qty.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.dispatch_qty
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].dispatch_qty`}
                            {...register(`items[${index}].dispatch_qty`)}
                            placeholder="Enter dispatch qty"
                            disabled
                          />

                          {errors.items?.[index]?.dispatch_qty && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.dispatch_qty.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.qty
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].qty`}
                            // {...register(`items[${index}].qty`, {
                            //   onChange: (e) => {
                            //     const inputValue = e.target.value;
                            //     checkQtyValidation(inputValue, index);
                            //   },
                            // })}
                            {...register(`items[${index}].qty`, {
                              onChange: debounce((e) => {
                                const inputValue = e.target.value;
                                checkQtyValidation(inputValue, index);
                              }, 300), // Adjust the debounce delay as needed (300ms in this example)
                            })}
                            placeholder="Enter qty"
                          />

                          {errors.items?.[index]?.qty && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.qty.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.scrapqty
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].scrapqty`}
                            {...register(`items[${index}].scrapqty`)}
                            placeholder="Enter scrap qty"
                            disabled
                          />

                          {errors.items?.[index]?.scrapqty && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.scrapqty.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.amount
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].amount`}
                            {...register(`items[${index}].amount`)}
                            placeholder="Enter amount"
                            disabled={lock_scrap}
                          />

                          {/* {errors.items?.[index]?.amount && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.amount.message}
                            </span>
                          )} */}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={"form-control form-control-sm"}
                            type="text"
                            name={`items[${index}].remark`}
                            {...register(`items[${index}].remark`)}
                            placeholder="Enter remark"
                          />
                        </td>
                        <td
                          style={{
                            verticalAlign: "top",
                          }}
                        >
                          <div
                            className="deleteIcon cursor_pointer"
                            onClick={
                              index > 0
                                ? () => {
                                    remove(index);
                                    cal_final_total();
                                  }
                                : null
                            }
                          >
                            <Trash2 color="#FFFFFF" size={18} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-3">
            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Update_Dispatch;
