import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import Roboto from "../../assets/fonts/Roboto-Regular.ttf";
import Roboto_Bold from "../../assets/fonts/Roboto-Bold.ttf";

import {
  PDFViewer,
  Document,
  Text,
  View,
  Image,
  Page,
  Link,
  StyleSheet,
  Font,
  pdf,
  PDFDownloadLink,
  Svg,
  Polygon,
} from "@react-pdf/renderer";

function SalesOrderMobilePdf() {
  let navigate = useNavigate();
  let location = useLocation();
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const [measurements, setMesurements] = useState([
    "ABOVE BURST",
    "BUST",
    "BELOW BUST",
    "WAIST",
    "STOMACH",
    "HIPS",
    "SHOULDER",
    "INCUT SLV-LESS SH",
    "ARMHOLE",
    "BICEP",
    "SLEEVES",
    "",
    "",
    "NECK FRONT",
    "NECK BACK",
    "COLLAR",
    "ARMHOLE-AH,FRONT",
    "ARMHOLE-AH,BACK",
  ]);
  const [below_waist_measurements, setBelow_waist_measurements] = useState([
    "TROUSER WAIST",
    "HIPS",
    "FORK",
    "SIDE SLIT",
    "SALWAR PONCHA",
    "CHURIDAR BOTTOM",
    "TROUSER BOTTOM",
  ]);
  const [measurements_checkboxes, setMesurements_checkboxes] = useState([
    "DOWN ROUND SH",
    "SQUARE SH",
    "V. DOWN SH",
    "FORWARD CHEST",
    "ROUND BACK",
    "BELLY OUT",
    "HIGH/FLAT HIP",
    "HIGH NECK",
    "LOW NECK",
    "RUBBING THIGH",
  ]);
  const [sleeves_measurement, setSleeves_measurement]=useState([
    "HALF",
    "3/4TH",
    "FULL"
  ])
  const downloadLinkRef = useRef(null);
  const data = location.state;

  Font.register({
    family: "roboto, roboto-light,roboto-bold",
    fonts: [
      {
        src: Roboto,
      },
      {
        src: Roboto_Bold,
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    viewer: {
      width: "100%",
      height: window.innerHeight,
    },
    page: {
      paddingTop: 15,
      paddingBottom: 40,
      paddingHorizontal: 8,
      color: "black",
      fontFamily: "roboto, roboto-light,roboto-bold",
      border: "1px solid black",
    },

    title: {
      textAlign: "center",
      fontWeight: 500,
      fontSize: "30px",
    },
    title2: {
      textAlign: "center",
      // fontWeight: 500,
      fontSize: "21px",
    },

    headerrow2: {
      flexDirection: "row",
      alignItems: "flex-start",
      //   marginBottom: 5,
      alignItems: "justify-content-between",
      width: "100%",
      gap: "0px",
      //   backgroundColor: "#BCF2F6",
      // border: "1px solid black",
    },
    headerbox2: {
      //   margin: 5,
      color: "black",
      fontSize: "12px",
      width: "60%",
      // border: "1px solid black",
    },
    headerbox3: {
      //   margin: 5,
      color: "black",
      fontSize: "12px",
      width: "40%",
      // border: "1px solid black",
    },

    headerbox2row: {
      width: "100%",
      marginLeft: 5,
      //   textAlign: "center",
      // border:"1px solid black"
    },
    headerbox2row2: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
      border: "1px solid black",
    },
    headerrow3: {
      fontSize: "14px",
      //   margin: 5,
      width: "100%",
      color: "white",
      backgroundColor: "black",
      // border: "1px solid black",
    },
    headerrow4: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginTop: 3,
      alignItems: "justify-content-between",
      width: "100%",
      gap: "10px",
      //   backgroundColor: "#BCF2F6",
      // border: "1px solid black",
    },
    headerbox41: {
      //   margin: 5,
      color: "black",
      fontSize: "10px",
      width: "32%",
      // border: "1px solid black",
    },
    headerbox41row1: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      //   marginBottom: 5,
      border: "1px solid black",
      fontSize: "10px",
    },

    headerbox41rowforline: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      //   marginBottom: 5,
      // border: "1px solid black",
      fontSize: "10px",
    },

    headerbox41row1label: {
      fontSize: "10px",
      margin: 3,
      //   border: "1px solid black",
      width: "58%",
    },
    headerbox41row1value: {
      flex: 1,
      fontSize: "10px",
      margin: 3,
      width: "40%",

      //   borderLeft: "1px solid black",
    },

    headerbox41sleeverow: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
        marginTop: 220,
      marginLeft:-10,
      border: "1px solid black",
      fontSize: "10px",
    },
    headerbox41sleeverow2: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
        marginTop: 0,
      marginLeft:-10,
      border: "1px solid black",
      fontSize: "10px",
    },
    headerbox41sleeverow3: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
        marginTop: 0,
      marginLeft:-10,
      border: "1px solid black",
      fontSize: "10px",
    },
    headerbox41sleevelabel: {
      fontSize: "10px",
      margin: 3,
      textAlign:"center",
      //   border: "1px solid black",
      width: "32%",
    },

    headerbox42: {
      marginLeft: 20,
      color: "black",
      fontSize: "10px",
      width: "10%",
      //   border: "1px solid black",
    },
    headerbox43: {
      // marginLeft: 10,
      color: "black",
      fontSize: "10px",
      width: "16%",
    },
    row: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    label: {
      fontSize: "12px",
      margin: 5,
      //   fontWeight: "bold",
    },
    value: {
      flex: 1,
      fontSize: "12px",
      marginTop: 5,
      marginBottom: 5,
      marginRight: 5,

      //   marginLeft: "5px",
    },
    line: {
      position: "absolute",
      backgroundColor: "black",
    },
    point: {
      position: "absolute",
      width: 6, // Diameter of the point
      height: 6,
      backgroundColor: "black",
      borderRadius: 3, // Makes it circular
    },
    labelline: {
      position: "absolute",
      fontSize: 10,
      textAlign: "right", // Aligns to the right of the label area
      // border:"1px solid black"
    },
    valueline: {
      position: "absolute",
      fontSize: 10,
      textAlign: "left",
    },

    footer1: {
      color: "black",
      marginTop: 5,
    },
  });

  useEffect(() => {
    const generatePdfBlob = async () => {
      const doc = <SaleOrderDocument data={data} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      const url = URL.createObjectURL(blob);
      setPdfBlobUrl(url);
      setIsPdfLoaded(true);
    };

    generatePdfBlob();
  }, [data]);

  const handleDownload = () => {
    const link = downloadLinkRef.current;
    link.href = pdfBlobUrl;
    link.download = `Measurement Sheet.pdf`;
    link.click();
  };

  const points = [
    { position: 0.1, label: "Chest", value: 41 },
    { position: 0.3, label: "Waist", value: 36 },
    { position: 0.7, label: "Hip", value: 42 },
  ];

  // Line Component
  const Line = ({ x1, y1, x2, y2, points = [] }) => {
    // Calculate the line's length and angle
    const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);

    // Function to calculate offset positions for labels and values
    const calculatePosition = (offset, angle) => {
      const angleRad = (angle * Math.PI) / 180;
      return {
        deltaX: offset * Math.cos(angleRad),
        deltaY: offset * Math.sin(angleRad),
      };
    };

    return (
      <View
        style={[
          styles.line,
          {
            width: length,
            top: y1,
            left: x1,
            height: 1, // Thickness of the main line
            transform: `rotate(${angle}deg)`,
            transformOrigin: "top left",
          },
        ]}
      >
        {/* Render Points with Labels and Values */}
        {points.map((point, index) => {
          const { position, label, value } = point;

          // Calculate position for the point along the line
          const pointX = position * length;

          // Calculate positions for label and value
          const { deltaX: labelOffsetX, deltaY: labelOffsetY } =
            calculatePosition(-15, angle); // Left of point
          const { deltaX: valueOffsetX, deltaY: valueOffsetY } =
            calculatePosition(15, angle); // Right of point

          return (
            <View key={index}>
              {/* Point (Circle) */}
              <View
                style={[
                  styles.point,
                  {
                    left: pointX - 3, // Center the point horizontally
                    top: -3, // Center the point vertically
                  },
                ]}
              />

              {/* Label (Left side of the line) */}
              <Text
                style={[
                  styles.labelline,
                  {
                    left: pointX + labelOffsetX,
                    top: labelOffsetY - 3, // Slight adjustment to make closer
                    transform: `rotate(-${angle}deg)`, // Rotate back to horizontal
                  },
                ]}
              >
                {value}
              </Text>

              {/* Value (Right side of the line) */}
              <Text
                style={[
                  styles.valueline,
                  {
                    left: pointX + valueOffsetX,
                    top: valueOffsetY - 3, // Slight adjustment to make closer
                    transform: `rotate(-${angle}deg)`, // Rotate back to horizontal
                  },
                ]}
              >
                {label}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };

 

  const SaleOrderDocument = ({ data }) => (
    <Document title={`Measurement`}>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.headerrow2}>
            <View style={styles.headerbox2}>
              <View style={styles.headerbox2row}>
                <Text style={styles.title}>NIVEDITA SABOO</Text>
              </View>

              <View style={styles.headerbox2row}>
                <Text style={styles.title2}>COUTURE</Text>
              </View>
              <View style={styles.headerbox2row}>
                <Text style={[{ fontSize: "10px", textAlign: "center" }]}>
                  Bungalow 20,Samrat Society,Lane No.7, Kalyani Nagar,
                  Pune:411014
                </Text>
              </View>
              <View style={styles.headerbox2row}>
                <Text style={[{ fontSize: "10px", textAlign: "center" }]}>
                  Phone No.: +91 92204 8000
                </Text>
              </View>
            </View>
            <View style={styles.headerbox3}>
              <View style={styles.headerbox2row2}>
                <Text style={styles.label}>NAME:</Text>
                <Text style={styles.value}>Rushikesh Pawar</Text>
              </View>

              <View style={styles.headerbox2row2}>
                <Text style={styles.label}>CONTACT NO.:</Text>
                <Text style={styles.value}>7083983988</Text>
              </View>

              <View style={styles.headerbox2row2}>
                <Text style={styles.label}>EMAIL ID:</Text>
                <Text style={styles.value}>rushikesh.pawar@gmail.com</Text>
              </View>
            </View>
          </View>
          <View style={styles.headerrow3}>
            <Text
              style={[{ textAlign: "center", margin: 5, fontWeight: "bold" }]}
            >
              {"MEASUREMENT CHART :"}
            </Text>
          </View>
          {/*Date & Measured by information */}
          <View style={styles.headerrow4}>
            <View style={styles.headerbox41}>
              {[1, 2].map((item, ind) => {
                return (
                  <View key={ind} style={styles.headerbox41row1}>
                    <View style={styles.headerbox41row1label}>
                      {ind == 0 ? <Text>DATE</Text> : <Text>MEASUREMENTS</Text>}
                    </View>
                    <View
                      style={[
                        {
                          border: "1px solid black",
                          width: "1%",
                          height: "20px",
                        },
                      ]}
                    ></View>
                    <View style={styles.headerbox41row1value}>
                      <Text>{}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
            <View style={styles.headerbox41}>
              {[1, 2].map((item, ind) => {
                return (
                  <View style={styles.headerbox41row1}>
                    <View style={styles.headerbox41row1label}>
                      {ind == 0 ? (
                        <Text>MEASURED BY</Text>
                      ) : (
                        <Text>NOTED BY</Text>
                      )}
                    </View>
                    <View
                      style={[
                        {
                          border: "1px solid black",
                          width: "1%",
                          height: "20px",
                        },
                      ]}
                    ></View>
                    <View style={styles.headerbox41row1value}>
                      <Text>{}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
            <View style={styles.headerbox42}>
              {[1, 2].map((item, ind) => {
                return (
                  <View style={styles.headerbox41row1}>
                    <View style={styles.headerbox41row1label}>
                      {ind == 0 ? (
                        <Text>{"   "}</Text>
                      ) : (
                        <Text style={[{ height: "16px" }]}>BLOUSE</Text>
                      )}
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
          {/* Till Waist Measurement */}
          <View style={styles.headerrow4}>
            <View style={styles.headerbox41}>
              {measurements.map((item, ind) => {
                return (
                  <View key={ind+item} style={styles.headerbox41row1}>
                    <View style={styles.headerbox41row1label}>
                      <Text>{item}</Text>
                    </View>
                    <View
                      style={[
                        {
                          border: "1px solid black",
                          width: "1%",
                          height: "20px",
                        },
                      ]}
                    ></View>
                    <View style={styles.headerbox41row1value}>
                     {item=="SLEEVES"?<Text>{"SHORT"}</Text> :<Text>{}</Text>}
                    </View>
                  </View>
                );
              })}
            </View>
            <View style={styles.headerbox41}>
            
                    <View  style={styles.headerbox41sleeverow}>
                  {sleeves_measurement.map((item,ind)=>{
                    return <>
                     <View style={styles.headerbox41sleevelabel}>
                      <Text >{item}</Text>
                    </View>
                    {ind<2?
                    <View
                    style={[
                      {
                        border: "1px solid black",
                        width: "1%",
                        height: "20px",
                      },
                    ]}
                  ></View>
                 :null
                    }
                    </>
                  })}
                    
                    
                  </View>
                  <View  style={styles.headerbox41sleeverow2}>
                  {sleeves_measurement.map((item,ind)=>{
                    return <>
                     <View style={styles.headerbox41sleevelabel}>
                      <Text >{}</Text>
                    </View>
                    {ind<2?
                    <View
                    style={[
                      {
                        border: "1px solid black",
                        width: "1%",
                        height: "20px",
                      },
                    ]}
                  ></View>
                 :null
                    }
                    </>
                  })}
                    
                    
                  </View>
                  <View  style={styles.headerbox41sleeverow3}>
                  {sleeves_measurement.map((item,ind)=>{
                    return <>
                     <View style={styles.headerbox41sleevelabel}>
                      <Text >{}</Text>
                    </View>
                    {ind<2?
                    <View
                    style={[
                      {
                        border: "1px solid black",
                        width: "1%",
                        height: "20px",
                      },
                    ]}
                  ></View>
                 :null
                    }
                    </>
                  })}
                    
                    
                  </View>

              <Line x1={42} y1={0} x2={58} y2={0} />
              <Line x1={50} y1={0} x2={50} y2={200} points={points} />

              <Line x1={42} y1={200} x2={58} y2={200} />

              {/* Second line  */}
              <Line x1={142} y1={0} x2={158} y2={0} />
              <Line x1={150} y1={0} x2={150} y2={95} />

              <Line x1={142} y1={95} x2={158} y2={95} />

              <Line x1={142} y1={100} x2={158} y2={100} />
              <Line x1={150} y1={100} x2={150} y2={200} />

              <Line x1={142} y1={200} x2={158} y2={200} />
            </View>
            <View style={styles.headerbox42}>
              {measurements.map((item, ind) => {
                return (
                  <View style={styles.headerbox41row1}>
                    <View
                      style={[styles.headerbox41row1label, { height: "14px" }]}
                    >
                      <Text>{"   "}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
            <View style={styles.headerbox43}>
              {measurements_checkboxes.map((item, ind) => {
                return (
                  <View style={styles.headerbox41row1}>
                    <View style={styles.headerbox41row1label}>
                      <Text>{item}</Text>
                    </View>
                    <View
                      style={[
                        {
                          border: "1px solid black",
                          width: "1%",
                          height: "27px",
                        },
                      ]}
                    ></View>
                    <View style={styles.headerbox41row1value}>
                      <Text>{}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
          {/* Below Waist Measurement */}
          <View style={styles.headerrow4}>
            <View style={styles.headerbox41}>
              {below_waist_measurements.map((item, ind) => {
                return (
                  <View style={styles.headerbox41row1}>
                    <View style={styles.headerbox41row1label}>
                      <Text>{item}</Text>
                    </View>
                    <View
                      style={[
                        {
                          border: "1px solid black",
                          width: "1%",
                          height: "20px",
                        },
                      ]}
                    ></View>
                    <View style={styles.headerbox41row1value}>
                      <Text>{}</Text>
                    </View>
                  </View>
                );
              })}
            </View>
            <View style={styles.headerbox41}>
              {/* arrow  with line   */}
            <Line x1={55} y1={-5} x2={60} y2={0} /> 
              <Line x1={20} y1={0} x2={60} y2={0} /> <Text style={[{marginLeft:62,marginTop:-6}]}>29</Text>
              <Line x1={55} y1={5} x2={60} y2={0} /> 
              
              <Line x1={55} y1={15} x2={60} y2={20} /> 
              <Line x1={20} y1={20} x2={60} y2={20} /> <Text style={[{marginLeft:62, marginTop:10}]}>23</Text>
              <Line x1={55} y1={25} x2={60} y2={20} /> 

              <Line x1={55} y1={35} x2={60} y2={40} /> 
              <Line x1={20} y1={40} x2={60} y2={40} /> <Text style={[{marginLeft:62, marginTop:8}]}>19</Text>
              <Line x1={55} y1={45} x2={60} y2={40} /> 

              <Line x1={55} y1={55} x2={60} y2={60} /> 
              <Line x1={20} y1={60} x2={60} y2={60} /> <Text style={[{marginLeft:62, marginTop:8}]}>16</Text>
              <Line x1={55} y1={65} x2={60} y2={60} /> 

              <Line x1={55} y1={75} x2={60} y2={80} /> 
              <Line x1={20} y1={80} x2={60} y2={80} /> <Text style={[{marginLeft:62, marginTop:8}]}>15</Text>
              <Line x1={55} y1={85} x2={60} y2={80} /> 

              <Line x1={55} y1={125} x2={60} y2={130} /> 
              <Line x1={20} y1={130} x2={60} y2={130} /> <Text style={[{marginLeft:62, marginTop:36}]}>24</Text>
              <Line x1={55} y1={135} x2={60} y2={130} /> 



              <Line x1={20} y1={0} x2={20} y2={150} />
              <Line x1={20} y1={150} x2={40} y2={150} />
              <Line x1={40} y1={0} x2={40} y2={150} />

              {/* <Line x1={182} y1={150} x2={198} y2={150} /> */}

              <Line x1={182} y1={0} x2={198} y2={0} />
              <Line x1={190} y1={0} x2={190} y2={150} />

              <Line x1={182} y1={150} x2={198} y2={150} />
            </View>
            <View style={styles.headerbox42}>
              {below_waist_measurements.map((item, ind) => {
                return (
                  <View style={styles.headerbox41row1}>
                    <View
                      style={[styles.headerbox41row1label, { height: "14px" }]}
                    >
                     {ind==5?<Text>{"LENGTH"}</Text> :<Text>{"   "}</Text>}
                    </View>
                  </View>
                );
              })}
            </View>
            <View style={styles.headerbox43}>
              <Line x1={2} y1={0} x2={18} y2={0} />
              <Line x1={10} y1={0} x2={10} y2={150} />

              <Line x1={2} y1={150} x2={18} y2={150} />
            </View>
          </View>
          <View style={styles.footer1}>
            <Text>NIVEDITA SABOO COUTURE</Text>
            <Text style={[{ fontSize: "10px" }]}>
              Bungalow 20,Samrat Society,Lane No.7, Kalyani Nagar, Pune:411014
              Phone No.: +91 92204 8000
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center justify-content-between">
            <div>
              <div className="p-2 d-flex align-items-center">
                <button
                  className="btn btn-yellow"
                  onClick={() => {
                    navigate("/pcms/sales-order");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                </button>

                <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                  Sales Order PDF
                </p>
              </div>
            </div>

            <div className=" p-2">
              <button
                className="btn btn-yellow"
                style={styles.downloadButton}
                onClick={handleDownload}
                disabled={!isPdfLoaded}
              >
                <FontAwesomeIcon icon={faDownload} size="sm" /> Download PDF
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <PDFViewer style={styles.viewer}>
                <SaleOrderDocument data={data} />
              </PDFViewer>
              <a ref={downloadLinkRef} style={{ display: "none" }} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SalesOrderMobilePdf;
